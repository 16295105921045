import React, { useEffect, useState } from 'react';

import Icon from 'components/icons/Icon';
import Input from 'components/inputs/Input';
import { useTranslation } from 'react-i18next';
import { generateRandAlphaNumStr } from 'Utils/StringRandom';

import {
  ErrorSpan,
  MarginPreview,
  MarginUploadFile
} from '../UploadFile/StyleUploadFile';

interface IUploadFileProps {
  icon?: string;
  name?: string;
  handleImage?: any;
  id?: string;
  className?: string;
  content?: string;
  allowedFileTypes?: any;
  imagesArray?: Array<any>;
  maxNumberOfFiles?: number;
  maxFileSize?: number;
  ref?: any;
  infoBox?: string;
  setImage?: any;
  images?: any;
  setImages?: any;
  cropData?: any;
  setShowCropper?: any;
  disabled?: boolean;
  setCropper?: any;
  setCropData?: any;
}

const UploadFileImage: React.FC<IUploadFileProps> = props => {
  const typeFile = 'image/png, image/jpg, image/jpeg';
  const {
    icon,
    name,
    handleImage,
    id,
    className,
    content = 'photo',
    allowedFileTypes = typeFile,
    imagesArray = [],
    maxNumberOfFiles = 1,
    maxFileSize = 20000,
    ref,
    infoBox,
    images,
    setImages,
    setImage,
    cropData,
    setShowCropper,
    disabled,
    setCropper,
    setCropData
  } = props;

  const { t } = useTranslation(['campaigns_page']);
  const [errorSize, setErrorSize] = useState<boolean>(false);
  const [errorMB, setErrorMB] = useState<boolean>(false);
  const [errorFileType, setErrorFileType] = useState<boolean>(false);

  const photo = imagesArray?.map(image => {
    return {
      index: generateRandAlphaNumStr(),
      name: '',
      url: image
    };
  });

  useEffect(() => {
    if (photo?.length > 0) {
      setImages(photo);
    }
  }, []);

  const changeInput = (e: any) => {
    e.preventDefault();
    setErrorSize(false);
    setErrorMB(false);
    setShowCropper(true);
    setErrorFileType(false);
    const sizekiloBytes = parseInt(e?.currentTarget?.files[0]?.size) / 1024;
    const sizeImage = parseInt(sizekiloBytes.toString());

    if (!typeFile.includes(e?.target?.files[0]?.type)) {
      setErrorFileType(true);
      cancelCropper();
      return;
    }

    if (sizeImage > maxFileSize) {
      setErrorMB(true);
      cancelCropper();
      return;
    }

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);

    const newImgsToState = readmultifiles(e);
    const newImgsState = [...images, ...newImgsToState];
    setImages(newImgsState);
  };

  function readmultifiles(e: any) {
    const files = e.currentTarget.files;
    const arrayImages: any = [];

    Object.keys(files).forEach(i => {
      const file = files[i];
      const url = URL.createObjectURL(file);

      arrayImages.push({
        index: generateRandAlphaNumStr(),
        name: file.name,
        url: url,
        file
      });
    });

    return arrayImages;
  }

  const onImgLoad = ({ target: img }: any, index: any) => {
    const { naturalHeight, naturalWidth } = img;
    if (naturalWidth > 1920 && naturalHeight > 1920) {
      deleteImg(index);
      setErrorSize(true);
    }
  };

  const deleteImg = (index: any) => {
    const newImgs = images.filter((image: any) => image.index !== index);
    handleImage(newImgs, content);
    setImages(newImgs);
    setCropper(undefined);
    setCropData(undefined);
    setShowCropper(false);
  };

  const cancelCropper = () => {
    setCropper(undefined);
    setCropData(undefined);
    setShowCropper(false);
  };

  const size = maxFileSize / 1000;

  return (
    <>
      {images.length === 0 || images.length < maxNumberOfFiles ? (
        <MarginUploadFile id={id} className={className}>
          <>
            <Icon name={icon ?? 'file'} size="big" color="#979797" />
            <h3>{t('attach_image')}</h3>
            <p>{infoBox}</p>
            <Input
              name={name}
              type="file"
              accept={allowedFileTypes}
              onChange={changeInput}
              ref={ref}
            />
          </>
        </MarginUploadFile>
      ) : (
        <></>
      )}
      <MarginPreview>
        {React.Children.toArray(
          images.map((image: any) => (
            <>
              <img
                alt="cropIcon"
                src={
                  !cropData && imagesArray.length > 0
                    ? imagesArray[0]
                    : cropData
                }
                onLoad={(e: any) => onImgLoad(e, image.index)}
              />
              {!disabled && (
                <Icon
                  size="small"
                  name="close-rounded"
                  onClick={() => deleteImg(image.index)}
                  color="rgba(0,0,0,0.54)"
                />
              )}
            </>
          ))
        )}
      </MarginPreview>
      {errorSize && <ErrorSpan>{t('error_resolution_image')}</ErrorSpan>}
      {errorMB && <ErrorSpan>{t('error_weight_image', { size })}</ErrorSpan>}
      {errorFileType && <ErrorSpan>{t('error_type_file_image')}</ErrorSpan>}
    </>
  );
};

export default UploadFileImage;
