import React from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/Tabs';

import SectionWrapper from 'pages/Layout/SectionWrapper';

import AmbassadorProfile from './AmbassadorProfile';
import ChangePassword from '../ChangePassword';

const AdminSettings = () => {
  const { t } = useTranslation(['settings']);
  return (
    <SectionWrapper>
      <h1>{t('settings')}</h1>
      <Tabs
        orientation="vertical"
        tabsTitle={[
          { id: 1, label: t('account') },
          { id: 2, label: t('password') }
        ]}
        tabsContent={[
          { id: 1, children: <AmbassadorProfile /> },
          { id: 2, children: <ChangePassword /> }
        ]}
      />
    </SectionWrapper>
  );
};

export default AdminSettings;
