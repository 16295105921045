export const headerValidations = (headers: any, requiredHeaders: any) => {
  let error = '';
  const cleanHeaders = headers?.map((header: any) =>
    header
      .toString()
      .trim()
      .toLowerCase()
      .replace(/[^\w\s]/gi, '')
  );
  if (!cleanHeaders.includes(requiredHeaders?.name?.toLowerCase())) {
    error = 'nameRequired';
  }
  if (!cleanHeaders.includes(requiredHeaders?.lastName?.toLowerCase())) {
    error = 'lastNameRequired';
  }
  if (!cleanHeaders.includes(requiredHeaders?.email?.toLowerCase())) {
    error = 'emailRequired';
  }
  if (
    !cleanHeaders.includes(requiredHeaders?.name?.toString().toLowerCase()) &&
    !cleanHeaders.includes(
      requiredHeaders?.lastName?.toString().toLowerCase()
    ) &&
    !cleanHeaders.includes(requiredHeaders?.email?.toString().toLowerCase())
  ) {
    error = 'headersRequired';
  }
  return error;
};
