import React, { useState } from 'react';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';
import 'cropperjs/dist/cropper.css';
import { ImageCropperContainer } from './Styled';
import UploadFileImage from 'components/inputs/UploadFileCropper/UploadFileImage';
import Button from 'components/buttons/Button';

export interface UploadFileCropperProps {
  icon?: string;
  name?: any;
  handleImage: any;
  maxFileSize: any;
  ref?: any;
  id?: string;
  infoBox: string;
  imagesArray?: any;
  disabled?: boolean;
}

export const UploadFileCropper: React.FC<UploadFileCropperProps> = props => {
  const {
    icon,
    name,
    handleImage,
    maxFileSize,
    ref,
    id,
    infoBox,
    imagesArray,
    disabled
  } = props;
  const [image, setImage] = useState();
  const [images, setImages] = useState<Array<any>>([]);
  const { t } = useTranslation(['layout']);
  const [showCropper, setShowCropper] = useState(false);
  const [cropData, setCropData] = useState<any>();
  const [cropper, setCropper] = useState<any>();

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setShowCropper(false);
      const crop = cropper.getCroppedCanvas().toDataURL();
      if (crop) {
        const cropReplace = crop.replace(/^data:image\/\w+;base64,/, '');
        const buffer = Buffer.from(cropReplace, 'base64');
        handleImage(buffer, 'photo');
      }
    }
  };

  return (
    <>
      <UploadFileImage
        icon={icon}
        name={name}
        handleImage={handleImage}
        maxFileSize={maxFileSize}
        ref={ref}
        setImage={setImage}
        images={images}
        setImages={setImages}
        cropData={cropData}
        setShowCropper={setShowCropper}
        infoBox={infoBox}
        id={id}
        imagesArray={imagesArray}
        disabled={disabled}
        setCropper={setCropper}
        setCropData={setCropData}
      />
      {showCropper && (
        <ImageCropperContainer>
          <Cropper
            className="open-cropper"
            src={image}
            aspectRatio={6 / 6}
            onInitialized={instance => {
              setCropper(instance);
            }}
            guides={false}
          />
          <div className="button-margin">
            <Button
              onClick={getCropData}
              value={t('layout:crop')}
              variant="primary"
            />
            <Button
              onClick={() => {
                setShowCropper(false);
                setImages([]);
                handleImage([], 'photo');
                setCropper(undefined);
                setCropData(undefined);
              }}
              value={t('layout:cancel')}
              variant="secondary"
            />
          </div>
        </ImageCropperContainer>
      )}
    </>
  );
};

export default UploadFileCropper;
