import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FiltersForm } from './Styled';
import Input from 'components/inputs/Input';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import Calendar from 'components/Calendar';
import useIsAdmin from 'hooks/useIsAdmin.hook';
import Button from 'components/buttons/Button';
import { getCurrentCompany } from 'helpers/companyHelper';

export interface PlainFilters {
  periodType: string;
  periodValue: string;
  year?: number;
}

export interface PerformanceFiltersProps {
  periodTypeOptions: any;
  periodType: any;
  setPeriodType: any;
  periodValue: any;
  setPeriodValue: any;
  months: any;
  quarters: any;
  years: any;
  year: any;
  setYear: any;
  currentMonth: number;
  onChangeFilters: any;
}

const PerformanceFilters: React.FC<PerformanceFiltersProps> = props => {
  const {
    periodType,
    setPeriodType,
    periodTypeOptions,
    periodValue,
    setPeriodValue,
    months,
    quarters,
    years,
    year,
    setYear,
    currentMonth,
    onChangeFilters
  } = props;

  const { t } = useTranslation(['dates', 'ranking_filters']);
  const company = getCurrentCompany();
  const [selectedPeriodOptions, setSelectedPeriodOptions] = useState<any>(
    months
  );

  const [activeYear, setActiveYear] = useState(false);

  const [filters, setFilters] = useState({
    periodType: periodType,
    periodValue: periodValue,
    year: year.value
  });

  const onChangePeriodType = (event: any) => {
    setPeriodType(event);
    switch (event.value) {
      case 'month':
        setActiveYear(false);
        setSelectedPeriodOptions(months);
        setPeriodValue(months[currentMonth - 1]);
        onChangeFilter({
          periodType: 'month',
          periodValue: months[currentMonth - 1].value,
          year: year.value
        });
        break;
      case 'quarter':
        setActiveYear(false);
        setSelectedPeriodOptions(quarters);
        setPeriodValue(quarters[0]);
        onChangeFilter({
          periodType: 'quarter',
          periodValue: quarters[0].value,
          year: year.value
        });
        break;
      case 'year':
        setActiveYear(true);
        onChangeFilter({
          periodType: 'year',
          periodValue: years[0].value,
          year: year.value
        });
        break;
      default:
        setActiveYear(false);
        setSelectedPeriodOptions(months);
        setPeriodValue(months[currentMonth - 1]);
        onChangeFilter({
          periodType: 'all',
          year: year.value
        });
    }
  };

  const onChangeFilter = (newFilters: any) => {
    setFilters(newFilters);
    onChangeFilters(newFilters);
  };

  return (
    <>
      <FiltersForm>
        <span className="filter-by-label">
          {t('ranking_filters:filter_by')}
        </span>
        <DropdownSelect
          onSelect={onChangePeriodType}
          value={periodType}
          defaultValue={periodTypeOptions[0]}
          options={periodTypeOptions}
        />

        {periodType.value !== 'all' && !activeYear && (
          <DropdownSelect
            onSelect={e => {
              const periodValue = e.value;
              onChangeFilter({ ...filters, periodValue });
              setPeriodValue(e);
            }}
            value={periodValue}
            defaultValue={periodValue}
            options={selectedPeriodOptions}
          />
        )}
        {periodType.value !== 'all' && (
          <DropdownSelect
            onSelect={e => {
              const year = e.value;
              onChangeFilter({ ...filters, year });
              setYear(e);
            }}
            value={year}
            defaultValue={year}
            options={years}
          />
        )}
      </FiltersForm>
    </>
  );
};

export default PerformanceFilters;
