import styled from 'styled-components';
import { Colors } from 'styles/Constants';

export const FilterChallenges = styled.div`
  display: flex;
  margin-bottom: 15px;
  button {
    border-radius: 20px;
    font-size: 0.6875rem;
    padding: 6px 15px;
    height: 35px;
    margin-right: 20px;
  }

  input {
    width: 30%;
    margin-right: 20px;
  }

  span {
    margin-top: 7px;
  }
`;

export const StyleTable = styled.div`
  margin-top: 30px;
  .column {
    background-color: ${Colors.normalWhite};
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: ${Colors.secondaryNormalGrey};
    padding: 10px 0px;
    border: none;
    font-weight: bold;
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 20px;
    font-size: 1rem;
    color: ${Colors.secondaryNormalGrey};
    margin-bottom: 10px;
    max-width: 100%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .table_container {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .column-body {
    box-shadow: 0 1px 6px 0 rgb(79 79 79 / 38%);
    transition: all 0.5s ease-out 0s;
    background: ${Colors.normalWhite};
    margin-bottom: 20px;
    border-radius: 10px;
    z-index: 2;
    height: 70px;

    th {
      font-family: 'Montserrat', sans-serif;
      font-size: 1.125rem;
      padding: 16px;
      border-collapse: separate;
      border-spacing: 0 20px;
      font-size: 1rem;
      color: ${Colors.secondaryNormalGrey};
      border-bottom: none;
    }

    img {
      height: 20px;
      width: 20px;
    }

    svg {
      height: 15px;
      width: 15px;
    }
  }

  .icons * {
    margin: 0 10px;
  }

  .pointer {
    cursor: pointer;
  }
`;

export const HeardMargin = styled.div`
  display: flex;
  align-items: center;

  .option {
    display: flex;
    margin-right: 10px;
    img {
      margin-top: 15px;
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }
    svg {
      margin-top: 15px;
      margin-right: 10px;
      height: 22px;
      width: 22px;
    }
  }
`;

export const TextStyled = styled.p`
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: ${Colors.secondaryNormalGrey};
`;

export const MarginTitlePage = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 60%;
    font-size: 1.125rem;
    height: 20%;
    margin-bottom: 30px;
    border: 1px solid #d113fe;
    background-color: #d113fe;
  }
`;
