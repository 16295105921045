import React from 'react';
import { useTranslation } from 'react-i18next';

import ChallengeAdminCard from './ChallengeAdminCard';

import { MarginAdminPage, ChallengeSection } from './Styled';

export interface AllChallengesStatusProps {
  challenges: any;
  title: string;
  timeLine: any;
  setLoading: any;
  changeChallenge: any;
  setChangeChallenge: any;
}

const AllChallengesStatus: React.FC<AllChallengesStatusProps> = props => {
  const {
    challenges = [],
    title,
    timeLine,
    setLoading,
    changeChallenge,
    setChangeChallenge
  } = props;
  const { t } = useTranslation(['admin_dashboard', 'campaign_section']);

  return (
    <>
      {challenges?.length > 0 ? (
        <ChallengeSection>
          <MarginAdminPage>
            <h2>{title}</h2>
          </MarginAdminPage>
          {challenges.slice(0, 5).map((row: any, index: any) => {
            return (
              <ChallengeAdminCard
                key={index}
                challenge={row}
                timeLine={timeLine}
                setLoading={setLoading}
                changeChallenge={changeChallenge}
                setChangeChallenge={setChangeChallenge}
              />
            );
          })}
        </ChallengeSection>
      ) : (
        <></>
      )}
    </>
  );
};

export default AllChallengesStatus;
