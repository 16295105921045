import Client from 'config/axios';

export const getAmbassadorPerformance = async (token: string) => {
  const { data } = await Client.get('get-ambassador-performance-data', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
