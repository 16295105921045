import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'components/Calendar';
import Icon from 'components/icons/Icon';
import Input from 'components/inputs/Input';
import TextArea from 'components/inputs/TextArea';
import UploadFileCropper from 'components/inputs/UploadFileCropper';
import NavigationLink from 'components/links/NavigationLink';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import {
  Igroup,
  IGroupAmssador
} from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';
import { Colors } from 'styles/Constants';
import { Hourt, Minutes } from 'Utils/Hourt';

import {
  ErrorSpan,
  FlexSection,
  FormWrapper,
  GridComponent,
  LabelForm,
  MarginAmbassadorPage,
  MarginButton,
  NetworkFlexContainer,
  TimeZoneCampaign
} from './Styled';
import { ChallengeData } from 'services/rest/Challenge/createChallenge';
import Button from 'components/buttons/Button';
import Spinner from 'components/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

export interface EditChallengeFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<ChallengeData>;
  register: UseFormMethods['register'];
  setValue: any;
  values?: any;
  handleChange?: any;
  handleBlur?: any;
  handleClick?: any;
  handleParticipation: any;
  groupsAmbassador: IGroupAmssador;
  getData?: any;
  handleImage: any;
  errorSocialNetwork?: string;
  errorFormat?: string;
  loader: any;
  setHourt: any;
  setMinutes: any;
  sHour: any;
  sMinutes: any;
  challenge: any;
  setBackendError: any;
  isDisabled: boolean;
}

const Option = (
  id: number,
  name: string,
  key: any,
  onChange: any,
  type: string,
  group: Array<Igroup>,
  groupSelect: Array<number>
) => {
  const groupselect = groupSelect.find(group => group == id);
  const select: Igroup = { id: id, name: name };
  const selectGroup = group.find(group => group.id == id);
  return (
    <div
      className="checkbok-wrapper"
      key={key}
      id={id.toString()}
      onClick={() => (groupselect ? null : onChange(select, type))}
    >
      <input
        id={id.toString()}
        type="checkbox"
        disabled={groupselect ? true : false}
        onChange={() => onChange(select, type)}
        defaultChecked={selectGroup ? true : false}
        checked={selectGroup ? true : false}
      />
      <Icon
        className="icon-Group"
        name={type == 'group' ? `group` : 'single-user'}
        color="#9b9b9b"
        size="small"
      />
      <label className="label-groups">{name}</label>
    </div>
  );
};

const EditChallengeForm: React.FC<EditChallengeFormProps> = props => {
  const {
    errors,
    onSubmit,
    register,
    handleClick,
    groupsAmbassador: { groups = [], ambassador = [] },
    getData,
    handleParticipation,
    handleImage,
    errorSocialNetwork = '',
    errorFormat = '',
    loader,
    setHourt,
    setMinutes,
    sHour,
    sMinutes,
    challenge,
    setBackendError,
    isDisabled
  } = props;
  const { t } = useTranslation(['challenge']);
  const [onShow, setShow] = useState(true);
  const refOne = useRef<HTMLDivElement>(null);
  const [dateStart, setStartDate] = useState<Date | null>(null);
  const [dateEnd, setEndDate] = useState<Date | null>(null);
  const [checked, setChecked] = useState({
    facebook: challenge?.facebook,
    twitter: challenge?.twitter,
    linkedin: challenge?.linkedin,
    instagram: challenge?.instagram,
    tiktok: challenge?.tiktok,
    snapchat: challenge?.snapchat,
    youtube: challenge?.youtube,
    threads: challenge?.threads,
    others: challenge?.others
  });
  const [formatType, setFormatType] = useState({
    formatImage: challenge?.format.includes('image') ? true : false,
    formatVideo: challenge?.format.includes('video') ? true : false
  });
  const [startDateValidation, setStartDateValidation] = useState(false);
  const currentDate = new Date(challenge?.startDateUTC);
  const currentEndDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );

  const [group, setGroup] = useState<Array<Igroup>>([]);
  const [ambassadors, setAmbassadors] = useState<Array<Igroup>>([]);
  const [selectedGroups, setSelectedGroups] = useState(challenge?.groupsIds);
  const [selectedAmbassadors, setSelectedAmbassadors] = useState(
    challenge?.ambassadorsIds
  );

  const defaultCurrentTime = (param: any) => {
    const date = new Date();
    const currentTime = date.toLocaleTimeString(navigator.languages[0]);
    const splitTime = currentTime.split(':');
    return param == 'hour' ? parseInt(splitTime[0]) : parseInt(splitTime[1]);
  };
  const { company } = useSelector(selectCompany);
  const timezone = company?.timezone;

  useEffect(() => {
    setEndDate(new Date(challenge?.finishDate));
    setStartDate(new Date(challenge?.startDateUTC));
    if (challenge?.groupsIds?.length > 0) {
      const groups = challenge?.groupsIds.map((groups: any) => {
        return {
          id: groups,
          name: groups
        };
      });
      setGroup(groups);
      setSelectedGroups(groups);
    }

    if (challenge?.ambassadorsIds?.length > 0) {
      const groups = challenge?.ambassadorsIds.map((groups: any) => {
        return {
          id: groups,
          name: groups
        };
      });
      setAmbassadors(groups);
      setSelectedAmbassadors(groups);
    }
  }, []);

  useEffect(() => {
    handleParticipation(group, ambassadors);
  }, [group, ambassadors]);

  useEffect(() => {
    getData('');
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeOpenShow, true);
  }, []);

  useEffect(() => {
    setMinutes(defaultCurrentTime('minutes'));
    setHourt(defaultCurrentTime('hour'));
  }, []);

  const closeOpenShow = (e: any) => {
    if (refOne.current && onShow && !refOne.current.contains(e.target)) {
      setShow(true);
    }
  };

  const handlRemoveGroup = (idItem: any) => {
    const newGroup = group.filter(group => group.id != idItem);
    setGroup(newGroup);
  };
  const handlRemoveAmbbassador = (idItem: any) => {
    const newGroup = ambassadors.filter(group => group.id != idItem);
    setAmbassadors(newGroup);
  };

  const handleAddGroup = (select: Igroup, type: string) => {
    switch (type) {
      case 'group':
        if (group.length == 0) {
          setGroup([select]);
        } else {
          const selectGroup = group.find(group => group.id == select.id);
          if (selectGroup) {
            handlRemoveGroup(selectGroup.id);
            break;
          }
          setGroup([...group, select]);
        }
        break;
      case 'ambassadors':
        if (ambassadors.length == 0) {
          setAmbassadors([select]);
        } else {
          const selectGroup = ambassadors.find(group => group.id == select.id);
          if (selectGroup) {
            handlRemoveAmbbassador(selectGroup.id);
            break;
          }
          setAmbassadors([...ambassadors, select]);
        }

        break;
    }
  };

  const selectedGroupsOptions: any = [];
  const remainingGroupsOptions: any = [];
  groups.forEach(option => {
    if (selectedGroups.some((selected: any) => selected.id === option.id)) {
      selectedGroupsOptions.push(option);
    } else {
      remainingGroupsOptions.push(option);
    }
  });

  const selectedAmbassadorsOptions: any = [];
  const remainingAmbassadorsOptions: any = [];
  ambassador.forEach(option => {
    if (
      selectedAmbassadors.some((selected: any) => selected.id === option.id)
    ) {
      selectedAmbassadorsOptions.push(option);
    } else {
      remainingAmbassadorsOptions.push(option);
    }
  });

  const hourt = Hourt.map(hourt => {
    return { label: hourt, value: hourt };
  });

  const minutes = Minutes.map(minutes => {
    return { label: minutes, value: minutes };
  });

  const onSelectedSocialNetwork = (socialNetwork: string) => {
    const socialNetworkSelected = checked as any;
    const sns = socialNetworkSelected[socialNetwork];
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: !sns
    };
    setChecked(socialNetworks);
  };

  const onSelectedFormat = (format: string) => {
    const formatSelected = formatType as any;
    const formatValue = formatSelected[format];
    const formats = {
      ...formatSelected,
      [format]: !formatValue
    };
    setFormatType(formats);
  };

  const onSelectCalendar = (startDate: Date, endDate: Date) => {
    if (dateStart == null) {
      setStartDate(startDate);
    }

    if (dateEnd == null) {
      setEndDate(endDate);
    }
    handleClick(startDate, endDate, sHour, sMinutes);
    if (startDateValidation) {
      setBackendError(t('challenge:start_date_error'));
    }
  };

  const onChange = (e: any) => {
    getData(e.target.value);
  };

  const onChangeHourtMinute = (hour: any, minutes: any) => {
    setHourt(hour);
    setMinutes(minutes);
  };

  return (
    <>
      <SectionWrapper className="hide-on-mobile">
        <NavigationLink
          text={t('challenge:back')}
          direction="back"
          to="/creators"
        />
        <MarginAmbassadorPage>
          <h1>{t('challenge:challenge')}</h1>
          <NavigationLink
            text={t('challenge:challenge_metrics')}
            direction="forward"
            to={`/challenges/detail/${challenge?.id}`}
          />
        </MarginAmbassadorPage>
        <form onSubmit={onSubmit}>
          <FormWrapper>
            <div>
              <LabelForm>
                <div>
                  <label>{t('challenge:name_challenge')}</label>
                </div>
                <div>
                  <Input
                    type="text"
                    name="name"
                    ref={register}
                    error={errors.name?.message}
                    data-testid="name"
                    max={150}
                    maxLength={150}
                    style={{ textTransform: 'capitalize' }}
                    defaultValue={challenge.name}
                    disabled={isDisabled}
                  />
                </div>
                <div className="marginFile">
                  <UploadFileCropper
                    name="photo"
                    handleImage={handleImage}
                    id="content-photo"
                    maxFileSize={5000}
                    ref={register}
                    infoBox={t('challenge:info_box_photo_content')}
                    imagesArray={[challenge.photo]}
                    disabled={isDisabled}
                  />
                </div>
              </LabelForm>
              <LabelForm>
                <div>
                  <label>{t('challenge:description_challenge')}</label>
                </div>
                <div>
                  <TextArea
                    name="content"
                    rows={8}
                    ref={register}
                    error={errors.content?.message}
                    placeholder={t('challenge:suggestion_description')}
                    maxLength={1500}
                    defaultValue={challenge.content}
                    disabled={isDisabled}
                  />
                </div>
              </LabelForm>
              <LabelForm>
                <div>
                  <label>{t('challenge:date_challenge')}</label>
                  <p>{t('challenge:suggestion_date')}</p>
                </div>
                <GridComponent>
                  {/*GET STARTDATE AND FINISHDATE*/}
                  <FlexSection className="notranslate">
                    <Icon size="big" name="custom-calendar" color="#9b9b9b" />
                    <Calendar
                      onChange={onSelectCalendar}
                      startDate={currentDate}
                      endDate={currentEndDate}
                      defaultStartDate={dateStart}
                      defaultEndDate={dateEnd}
                      editMode={true}
                      setStartDateValidation={setStartDateValidation}
                      startDateValidation={startDateValidation}
                      disabled={isDisabled}
                      editStarDate={challenge?.status === 'pending'}
                    />
                  </FlexSection>
                  <FlexSection
                    disabled={
                      challenge?.status === 'completed' ||
                      challenge?.status === 'activated'
                    }
                  >
                    <ReactSelect
                      options={hourt}
                      defaultValue={
                        hourt[
                          currentDate.getHours() != 0
                            ? currentDate.getHours()
                            : 0
                        ]
                      }
                      onChange={e => onChangeHourtMinute(e?.value, sMinutes)}
                      name="hourtCampaign"
                      className="dropdown-filter-container"
                      classNamePrefix="dropdown-filter-content"
                      isDisabled={
                        challenge?.status === 'completed' ||
                        challenge?.status === 'activated'
                      }
                    />
                    <span id="spanPoint">:</span>
                    <ReactSelect
                      name="hourtCampaign"
                      options={minutes}
                      onChange={e => onChangeHourtMinute(sHour, e?.value)}
                      defaultValue={
                        minutes[
                          currentDate.getMinutes() != 0
                            ? currentDate.getMinutes()
                            : 0
                        ]
                      }
                      className="dropdown-filter-container"
                      classNamePrefix="dropdown-filter-content"
                      isDisabled={
                        challenge?.status === 'completed' ||
                        challenge?.status === 'activated'
                      }
                    />
                    <TimeZoneCampaign>
                      <label>
                        <b>{t('challenge:timezone_challenge')}</b>
                      </label>
                      <label>{timezone}</label>
                    </TimeZoneCampaign>
                  </FlexSection>
                </GridComponent>
              </LabelForm>
              <LabelForm>
                <div>
                  <label>{t('challenge:participants_challenge')}</label>
                  <p>{t('challenge:suggestion_participants')}</p>
                </div>
                <div ref={refOne}>
                  <input
                    type="search"
                    name="searcher"
                    autoComplete="off"
                    placeholder={
                      group.length > 0 || ambassadors.length > 0
                        ? `Grupos +${group.length} /Personas +${ambassadors.length}`
                        : 'Buscar grupos o personas'
                    }
                    className={
                      group.length > 0 || ambassadors.length > 0
                        ? `input-search selected`
                        : 'input-search'
                    }
                    onClick={() => {
                      setShow(!onShow);
                    }}
                    onChange={e => onChange(e)}
                  />
                  {onShow ? (
                    <></>
                  ) : (
                    <div className="search-containe notranslate">
                      <div className="search-result notranslate">
                        {selectedGroupsOptions.map((groups: any, key: any) =>
                          Option(
                            groups.id,
                            groups.name,
                            key,
                            handleAddGroup,
                            'group',
                            group,
                            challenge?.groupsIds
                          )
                        )}
                        {selectedAmbassadorsOptions.map(
                          (ambassador: any, key: any) =>
                            Option(
                              ambassador.id,
                              `${ambassador.name} ${ambassador.lastName}`,
                              key,
                              handleAddGroup,
                              'ambassadors',
                              ambassadors,
                              challenge?.ambassadorsIds
                            )
                        )}
                        {remainingGroupsOptions.map((groups: any, key: any) =>
                          Option(
                            groups.id,
                            groups.name,
                            key,
                            handleAddGroup,
                            'group',
                            group,
                            challenge?.groupsIds
                          )
                        )}
                        {remainingAmbassadorsOptions.map(
                          (ambassador: any, key: any) =>
                            Option(
                              ambassador.id,
                              `${ambassador.name} ${ambassador.lastName}`,
                              key,
                              handleAddGroup,
                              'ambassadors',
                              ambassadors,
                              challenge?.ambassadorsIds
                            )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </LabelForm>
              <LabelForm>
                <div>
                  <label>{t('challenge:social_networks')}</label>
                  <p>{t('challenge:suggestion_social_networks')}</p>
                </div>
                <div className="network">
                  <NetworkFlexContainer>
                    <Icon
                      size="small"
                      name="facebook"
                      color={Colors.facebook}
                    />
                    <Input
                      id="sna_facebook"
                      type="checkbox"
                      name="facebook"
                      ref={register}
                      checked={checked.facebook}
                      onChange={() => onSelectedSocialNetwork('facebook')}
                      disabled={isDisabled}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon size="small" name="twitter" color={Colors.twitter} />
                    <Input
                      id="sna_twitter"
                      type="checkbox"
                      name="twitter"
                      ref={register}
                      checked={checked.twitter}
                      onChange={() => onSelectedSocialNetwork('twitter')}
                      disabled={isDisabled}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon
                      size="small"
                      name="linkedin"
                      color={Colors.linkedin}
                    />
                    <Input
                      id="sna_linkedin"
                      type="checkbox"
                      name="linkedin"
                      ref={register}
                      checked={checked.linkedin}
                      onChange={() => onSelectedSocialNetwork('linkedin')}
                      disabled={isDisabled}
                    />
                  </NetworkFlexContainer>

                  <NetworkFlexContainer>
                    <Icon
                      size="small"
                      name="instagram"
                      color={Colors.instagram}
                    />
                    <Input
                      id="sna_instagram"
                      type="checkbox"
                      name="instagram"
                      ref={register}
                      checked={checked.instagram}
                      onChange={() => onSelectedSocialNetwork('instagram')}
                      disabled={isDisabled}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon size="small" name="tiktok" color={Colors.tiktok} />
                    <Input
                      id="sna_tiktok"
                      type="checkbox"
                      name="tiktok"
                      ref={register}
                      checked={checked.tiktok}
                      onChange={() => onSelectedSocialNetwork('tiktok')}
                      disabled={isDisabled}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon
                      size="small"
                      name="snapchat"
                      color={Colors.snapchat}
                    />
                    <Input
                      id="sna_snapchat"
                      type="checkbox"
                      name="snapchat"
                      ref={register}
                      checked={checked.snapchat}
                      onChange={() => onSelectedSocialNetwork('snapchat')}
                      disabled={isDisabled}
                    />
                  </NetworkFlexContainer>

                  <NetworkFlexContainer>
                    <Icon size="small" name="youtube" color={Colors.youtube} />
                    <Input
                      id="sna_youtube"
                      type="checkbox"
                      name="youtube"
                      ref={register}
                      checked={checked.youtube}
                      onChange={() => onSelectedSocialNetwork('youtube')}
                      disabled={isDisabled}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon size="small" name="threads" color={Colors.threads} />
                    <Input
                      id="sna_threads"
                      type="checkbox"
                      name="threads"
                      ref={register}
                      checked={checked.threads}
                      onChange={() => onSelectedSocialNetwork('threads')}
                      disabled={isDisabled}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon size="small" name="otros" color={Colors.otros} />
                    <Input
                      id="sna_others"
                      type="checkbox"
                      name="others"
                      ref={register}
                      checked={checked.others}
                      onChange={() => onSelectedSocialNetwork('others')}
                      disabled={isDisabled}
                    />
                  </NetworkFlexContainer>

                  {errorSocialNetwork && (
                    <ErrorSpan>{errorSocialNetwork}</ErrorSpan>
                  )}
                </div>
              </LabelForm>
              <LabelForm>
                <div>
                  <label>{t('challenge:format')}</label>
                  <p>{t('challenge:suggestion_format')}</p>
                </div>
                <div className="network">
                  <div className="network-wrapper">
                    <NetworkFlexContainer>
                      <label>{t('challenge:image')}</label>
                      <Input
                        id="sna_tiktok"
                        type="checkbox"
                        name="formatImage"
                        ref={register}
                        checked={formatType.formatImage}
                        onChange={() => onSelectedFormat('formatImage')}
                        disabled={isDisabled}
                      />
                    </NetworkFlexContainer>
                    <NetworkFlexContainer>
                      <label>{t('challenge:video')}</label>
                      <Input
                        id="sna_snapchat"
                        type="checkbox"
                        name="formatVideo"
                        ref={register}
                        checked={formatType.formatVideo}
                        onChange={() => onSelectedFormat('formatVideo')}
                        disabled={isDisabled}
                      />
                    </NetworkFlexContainer>
                  </div>
                  {errorFormat && <ErrorSpan>{errorFormat}</ErrorSpan>}
                </div>
              </LabelForm>
              {!isDisabled && (
                <MarginButton>
                  {!loader ? (
                    <Button
                      value={t('challenge:edit_challenge')}
                      variant="primary"
                    />
                  ) : (
                    <Spinner name="sign-up" />
                  )}
                </MarginButton>
              )}
            </div>
          </FormWrapper>
        </form>
      </SectionWrapper>
    </>
  );
};

export default EditChallengeForm;
