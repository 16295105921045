import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export const SectionForm = styled.form`
  margin: 0 20%;
  width: 70%;
  @media (max-width: ${Breakpoints.medium}) {
    margin: 0 0%;
    width: 100%;
  }
`;

export const ButtonStyled = styled.div`
  button {
    text-align: center;
    margin: auto;
    margin-top: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
`;

export const MarginUpload = styled.div`
  margin-left: 25%;
  margin-bottom: 10%;
  @media (max-width: ${Breakpoints.medium}) {
    width: 100%;
    margin-left: 0%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  @media (max-width: ${Breakpoints.medium}) {
    display: grid;
  }

  label {
    margin-top: 8px;
    padding: 10px;
    width: 10%;
    color: #4d4d4d;
    font-size: 1rem;
    font-weight: 600;
    @media (max-width: ${Breakpoints.medium}) {
      padding: 0px;
    }
  }

  input {
    margin-top: 15px;
    margin-left: 15%;
    margin-right: 20%;
    width: 60%;
    @media (max-width: ${Breakpoints.medium}) {
      display: grid;
      width: 100%;
      margin-left: 0%;
    }
  }

  .cutText {
    margin-top: 15px;
    margin-bottom: auto;
    width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  div.dropdown-filter-container {
    margin-top: 15px;
    margin-right: 20%;
  }

  div.dropdown-filter-content__control {
    width: 150px;
    border: 1px solid #979797;
    border-radius: 30px;
    background-color: transparent;
    box-shadow: none;
    height: 30px;

    &:hover,
    &:focus-within {
      border-color: ${Colors.primaryDarkBlue};
      cursor: pointer;
    }
  }

  div.dropdown-filter-content__single-value {
    color: #3e66fe;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
  }

  div.dropdown-filter-content__value-container {
    margin-top: -6px;
  }

  span.dropdown-filter-content__indicator-separator {
    display: none;
  }

  div.dropdown-filter-content__indicator {
    color: rgba(0, 0, 0, 0.54);
  }

  div.dropdown-filter-content__placeholder {
    padding-bottom: 15px;
    font-size: 15px;
    color: ${Colors.inactive};
  }

  div.dropdown-filter-content__menu {
    margin-top: 1px;
    width: 170px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    z-index: 5;
  }

  div.dropdown-filter-content__menu-list {
    padding-top: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  div.dropdown-filter-content__option {
    padding: 6px 8px;
    font-size: 14px;
  }

  div.dropdown-filter-content__option--is-focused {
    background: transparent;
  }

  div.dropdown-filter-content__option:hover,
  div.dropdown-filter-content__option--is-focused:hover,
  div.dropdown-filter-content__option--is-selected {
    background: rgba(0, 0, 0, 0.08);
    font-weight: 500;
    color: #3e66fe;
  }
`;

export const TextForm = styled.div`
  p {
    font-size: 12px;
    line-height: 1.42857;
    color: #333333;
    margin: 10px;
    margin-left: 25%;
    margin-right: 5%;
    width: 50%;
    @media (max-width: ${Breakpoints.medium}) {
      width: 100%;
      margin-left: 0%;
    }
  }
  a {
    color: #eb00ff;
    text-decoration: underline;
    cursor: pointer;
  }
`;
