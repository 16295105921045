import styled, { css } from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

export type StyledTagProps = {
  variant: string;
  network?: string;
};

const normal = css`
  border-bottom-left-radius: 16px;
  background: ${Colors.primaryDarkBlue};
  border-top-left-radius: 16px;
  color: ${Colors.normalWhite};
  position: absolute;
  font-weight: bold;
  padding: 4px 10px;
  margin-top: 20px;
  font-size: 12px;
  right: 0px;
  top: 0px;
`;

const invert = css`
  border-bottom-right-radius: 16px;
  background: ${Colors.primaryDarkBlue};
  border-top-right-radius: 16px;
  color: ${Colors.normalWhite};
  position: absolute;
  font-weight: bold;
  padding: 4px 10px;
  margin-top: 20px;
  font-size: 12px;
  right: inherit;
  top: 0px;
`;

const bottom = css<StyledTagProps>`
  height: 25px;
  width: 25px;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ network }) =>
    network &&
    css`
      background: ${NetworkColors[network]};
    `}
  svg {
    height: 15px;
    width: 15px;
    fill: #fff;
  }
`;

const number = css`
  background: ${Colors.primaryDarkBlue};
  border-top-left-radius: 16px;
  color: ${Colors.normalWhite};
  position: absolute;
  font-weight: bold;
  padding: 10px 15px;
  margin-top: -5px;
  margin-left: -5px;
  font-size: 16px;
  top: 0px;
`;

const name = css`
  display: inline-block;
  margin: 0 10px 10px 0;
  color: ${Colors.normalWhite};
  border-radius: 5px;
  padding: 8px 13px;
  font-weight: 400;
  font-size: 16px;

  &:nth-child(1n) {
    background: #fe135f;
  }

  &:nth-child(2n) {
    background: #5513fe;
  }

  &:nth-child(3n) {
    background: #009ef5;
  }
`;

const NetworkColors: { [key: string]: string } = {
  facebook: Colors.facebook,
  twitter: Colors.twitter,
  linkedin: Colors.linkedin,
  instagram: Colors.instagram,
  whatsapp: Colors.whatsapp,
  tiktok: Colors.tiktok,
  snapchat: Colors.snapchat,
  youtube: Colors.youtube,
  otros: Colors.otros
};

const network = css<StyledTagProps>`
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
  padding: 5px 10px;
  margin-top: 20px;
  left: 0;
  width: 20%;

  @media (min-width: ${Breakpoints.medium}) {
    padding: 6.5px 10px;
  }

  justify-content: flex-end;
  align-items: center;
  display: flex;

  svg {
    height: 22px;
    width: 22px;
    fill: #fff;
  }

  ${({ network }) =>
    network &&
    css`
      background: ${NetworkColors[network]};
    `}
`;

const Variant: { [key: string]: any } = {
  normal,
  number,
  name,
  network,
  invert,
  bottom
};

export const StyledTag = styled.div<StyledTagProps>`
  ${({ variant }) => Variant[variant]};
`;
