import Client from 'config/axios';

export const deactiveBonusByGroup = async (token: string, groupId: any) => {
  const { data } = await Client.post(
    `deactivate-allow-bonus-by-group/${groupId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return {
    data
  };
};
