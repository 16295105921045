import Client from 'config/axios';

export const getRanking = async (
  token: string,
  type?: string,
  month?: number,
  year?: number,
  group?: number,
  quarter?: number,
  name?: string,
  startDate?: any,
  finishDate?: any,
  points?: string
) => {
  let params;
  switch (type) {
    case 'month':
      params = {
        type: type,
        month: month,
        group: group,
        year: year,
        points: points
      };
      break;
    case 'quarter':
      params = {
        type: type,
        quarter: quarter,
        group: group,
        year: year,
        points: points
      };
      break;
    case 'year':
      params = {
        type: type,
        year: year,
        group: group,
        points: points
      };
      break;
    case 'custom':
      params = {
        type: type,
        group: group,
        startDate: startDate,
        finishDate: finishDate,
        points: points
      };
      break;
    default:
      params = {
        type: type,
        month: month,
        group: group,
        year: year,
        points: points
      };
      break;
  }
  return await Client.get('ranking', {
    params,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data)
    .catch(error => error);
};
