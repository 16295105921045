import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Colors } from 'styles/Constants';
import Icon, { IconProps } from './Icon';

export type RoundIconProps = IconProps & {
  background?: string;
};

const sizeStyles = (size: number): FlattenSimpleInterpolation => {
  return css`
    min-height: ${size}px;
    min-width: ${size}px;
    height: ${size}px;
    width: ${size}px;
  `;
};

const Sizes: Record<string, FlattenSimpleInterpolation> = {
  small: sizeStyles(22),
  medium: sizeStyles(35),
  big: sizeStyles(45)
};

export const StyledRoundSpan = styled.span<RoundIconProps>`
  box-shadow: 0 1px 6px 0 rgba(79, 79, 79, 0.38);
  background-color: ${({ background }) => background};
  justify-content: center;
  border-radius: 100px;
  align-items: center;
  display: flex;

  ${({ size }) => Sizes[size as string]};

  svg.icon {
    height: 55%;
    width: 55%;
  }
`;

const RoundIcon: React.FC<RoundIconProps> = ({
  background,
  size = 'medium',
  ...otherProps
}) => {
  return (
    <StyledRoundSpan
      size={size}
      className="icon"
      background={background}
      {...otherProps}
    >
      <Icon className="rounded-icon" {...otherProps} />
    </StyledRoundSpan>
  );
};

RoundIcon.defaultProps = {
  background: Colors.normalWhite
};

export default RoundIcon;
