import Client from 'config/axios';

export const approveBonus = async (
  token: string,
  id: any,
  message: any,
  points: any
) => {
  const body = {
    bonusId: id,
    message: message,
    points: points
  };
  const { data } = await Client.post(`approve-bonus`, body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
