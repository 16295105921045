import React, { Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Icon from 'components/icons/Icon';
import { useHistory } from 'react-router-dom';

interface AdministratorsDetailHeadProps {
  administrators: any;
}

const AdministratorsTable: React.FC<AdministratorsDetailHeadProps> = props => {
  const { administrators } = props;
  const { t } = useTranslation(['super_admin_administrator']);
  const history = useHistory();

  const go = (id: number) => {
    history.replace(`/administrators/updateAdmin/${id}`);
  };

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <TableContainer component={Paper} className="table_container">
        <Table
          sx={{ minWidth: 750 }}
          size="medium"
          aria-label="a dense table"
          className="table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" className="column">
                {t('super_admin_administrator:id')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('super_admin_administrator:username')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('super_admin_administrator:name')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('super_admin_administrator:email')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('super_admin_administrator:company')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {administrators?.map((admin: any) => {
              return (
                <TableRow
                  key={admin.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                  className="column-body"
                >
                  <TableCell align="center" component="th" scope="row">
                    {admin.id}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {admin.username}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {admin.name}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {admin.email}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {admin.company.name}
                  </TableCell>
                  <TableCell align="center">
                    <Icon
                      name="edit"
                      size="small"
                      onClick={() => go(admin.id)}
                      className="pointer"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Suspense>
  );
};

export default AdministratorsTable;
