import Client from 'config/axios';

export const enableCompany = async (token: string, id: any) => {
  const body = {};
  const { data } = await Client.post(`company/enable/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
