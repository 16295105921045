import Client from 'config/axios';

export const getExcelTemplate = async (token: string) => {
  const { data } = await Client.get('/group/excel', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
