import React, { Suspense } from 'react';
import { Instagram as LoadingState } from 'react-content-loader';
import { StyleTable } from '../../ChallengesPage/Styled';
import CampaignCard from '../CampaignCard';

export interface CampaignsListProps {
  id?: string;
  listCampaigns?: Array<any>;
}

const CampaignsList: React.FC<CampaignsListProps> = props => {
  const { listCampaigns = [] } = props;

  const filterCampaign = listCampaigns?.filter(
    (campaign: any) => campaign.status !== 'paused'
  );

  return (
    // <Suspense fallback={<LoadingState height="230px" width="100%" />}>
    <StyleTable>
      {filterCampaign.map((campaigns, index) => (
        <CampaignCard {...campaigns} key={index} />
      ))}
    </StyleTable>
    // </Suspense>
  );
};

export default CampaignsList;
