import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List as LoadingState } from 'react-content-loader';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { selectUser } from 'redux/slices/userSlice';
import { useFetch } from 'hooks/useFetch.hook';
import { getSecret2FA } from 'services/rest/2FA/getSecret2FA';
import {
  validate2FACode,
  TwoFactorData
} from 'services/rest/2FA/validate2FACode';
import { RequiredString } from 'helpers/yupHelper';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';
import { ErrorAlert } from 'components/Alert';
import { Wrapper, TextWrapper, List, Form, FormWrapper } from './Styled';

const TwoFA = () => {
  const { t } = useTranslation(['two_factor_authentication']);
  const { currentUser } = useSelector(selectUser);
  const history = useHistory();
  const { data, loading } = useFetch(getSecret2FA(currentUser?.token));
  const [loader, setLoader] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<string | null>(null);
  const twoFactorData = data as any;

  const userSchema = yup.object().shape({
    code: RequiredString()
      .min(6, t('code_length'))
      .max(6, t('validations:length_error', { max: 6 }))
  });

  const { register, handleSubmit, errors } = useForm<TwoFactorData>({
    resolver: yupResolver(userSchema)
  });

  const onSubmit = async (twoFactor: TwoFactorData) => {
    setLoader(true);
    twoFactor.secret2FA = twoFactorData?.secret2FA;
    const isValid = await validate2FACode(currentUser?.token, twoFactor);
    if (isValid) {
      history.replace({
        pathname: `/settings`,
        state: { from: t('two_factor_authentication:succes_activated') }
      });
    } else {
      setBackendError(t('two_factor_authentication:error_code_invalid'));
    }
    setLoader(false);
  };

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}
      <SectionWrapper>
        <NavigationLink text="back" direction="back" to="/settings" />
        <Wrapper>
          <h1>{t('title')}</h1>
          <TextWrapper>
            <span>{t('subtitle_message')}</span>
            <List>
              <li>{t('step_one')}</li>
              <li>{t('step_two')}</li>
            </List>
          </TextWrapper>
          <img alt="twoFactor" src={twoFactorData?.base64} />
          <TextWrapper>
            <span>
              {t('qr_key_text')}
              {twoFactorData?.secret2FA}
            </span>
          </TextWrapper>
          <List>
            <li>{t('step_three')}</li>
          </List>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormWrapper>
              <Input
                type="number"
                placeholder="XXXXXX"
                name="code"
                ref={register}
                error={errors.code?.message}
              />
              <Button value={t('verify')} variant={'primary'} loader={loader} />
            </FormWrapper>
          </Form>
        </Wrapper>
      </SectionWrapper>
    </Suspense>
  );
};

export default TwoFA;
