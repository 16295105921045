import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { createCountry } from 'services/rest/SuperAdmin/Country/createCountry';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { RequiredString, String } from 'helpers/yupHelper';
import CreateCountryForm from './CreateCountryForm';
import { max } from 'date-fns';

const CreateCountry: React.FC = () => {
  const { t } = useTranslation(['super_admin_country', 'validations']);
  const userSchema = yup.object().shape({
    name: RequiredString().max(50, t('validations:length_error', { max: 50 })),
    defaultTimeZone: RequiredString().max(
      100,
      t('validations:length_error', { max: 100 })
    ),
    dialCode: RequiredString().max(
      6,
      t('validations:length_error', { max: 6 })
    ),
    code: RequiredString().max(3, t('validations:length_error', { max: 3 }))
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(userSchema)
  });
  const { currentUser } = useSelector(selectUser);
  const [loader, setLoader] = useState(false);
  const onSubmit = async (CountryData: any) => {
    setLoader(true);
    const data = await createCountry(currentUser?.token, CountryData);
    if (data) {
      setLoader(false);
    }
  };
  return (
    <>
      <CreateCountryForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setValue={setValue}
        control={control}
        loader={loader}
      />
    </>
  );
};

export default CreateCountry;
