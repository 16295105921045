import Client from 'config/axios';

export const getSuggestedPosts = async (
  token: string,
  limit: number,
  page: number
) => {
  const { data } = await Client.get(
    `suggested_post/all?limit=${limit}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return {
    data
  };
};
