import Client from 'config/axios';

export const getCompanyDataById = async (token: string, companyId: number) => {
  return await Client.get(`company/${companyId}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data.data)
    .catch(error => ({
      errors: error?.response?.data?.data
    }));
};

export const getCompanyDataBySubdomain = async (subdomain: string) => {
  return await Client.get(`get-company-styles-by-subdomain/${subdomain}`, {
    headers: { 'Content-type': 'application/json' }
  })
    .then(response => response.data.data)
    .catch(error => error);
};
