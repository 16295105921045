import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import CallBack from 'pages/CallBack/CallBack';

const CallBackRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <CallBack />
      </Route>
    </Switch>
  );
};

export default CallBackRouter;
