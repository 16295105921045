import Client from 'config/axios';

export const getGroupById = async (
  token: string,
  id: any,
  searchWithAmbassadorspage: string
) => {
  const { data } = await Client.get(
    `group/${id}?searchWithAmbassadors=${searchWithAmbassadorspage}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return {
    data
  };
};
