import React, { useEffect, useRef, useState } from 'react';

import Button from 'components/buttons/Button';
import Icon from 'components/icons/Icon';
import EditorInput from 'components/inputs/EditorInput';
import EditorInputLinkedin from 'components/inputs/EditorInput/EditorInputLinkedin';
import Input from 'components/inputs/Input';
import TextArea from 'components/inputs/TextArea';
import UploadFileImageSimple from 'components/inputs/UploadFile/UploadFileImageSimple';
import UploadFileVideo from 'components/inputs/UploadFile/UploadFileVideo';
import UploadFileMultipleImage from 'components/inputs/UploadFile/UploadFileMultipleImage';
import NavigationLink from 'components/links/NavigationLink';
import Spinner from 'components/Spinner/Spinner';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { Controller, FieldErrors, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Colors } from 'styles/Constants';
import { facebookURLs } from 'Utils/FacebookURLs';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

import {
  ErrorSpan,
  FormTextArea,
  FormWrapper,
  LabelForm,
  MarginButton,
  NetworkFlexContainer,
  SocialNetworkContent
} from './Styled';
import { SuggestedPostData } from 'services/rest/SuggestedPost/createSuggestedPost';
import { useSelector } from 'react-redux';

const https = 'https://';
const www = '(?:www.)';
const fb = 'facebook.com';
const no_mentions = /^(?:(?!\B@).\n*)+$/;
const no_characters = /^(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'"ª|!· º\\,<.>/?]{5}).+/;
const no_links_mentions = /^(?!(https?:\/\/|ftp:\/\/|(?:www\.)?[a-zA-Z0-9.-]+\.(com|net|org|edu|gov|mil|aero|asia|biz|cat|coop|info|int|jobs|mobi|museum|name|pro|tel|travel|arpa|root|nato|xxx|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|ss|st|su|sv|sx|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)(\/[a-zA-Z0-9%_.~+-]*)*(\?[a-zA-Z0-9%_.~+=-]*)?(#[a-zA-Z0-9_.~+-]*)?)|(?:@[a-zA-Z0-9_]+|#\w+)).*$/;

export interface CreateSuggestedPostFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<SuggestedPostData>;
  register: UseFormMethods['register'];
  setValue: any;
  values?: any;
  handleChange?: any;
  handleBlur?: any;
  handleImage: any;
  control: any;
  handleTypeFile: any;
  errorSocialNetwork?: string;
  fileErrorTwitter?: string;
  fileErrorLinkedin?: string;
  fileErrorInstagram?: string;
  fileErrorTiktok?: string;
  fileErrorWhatsapp?: string;
  errorRequiredContent?: string;
  loader: any;
  contentLimits: any;
  invalidLimit: any;
  uploadedTwitter: boolean | null;
  uploadedLinkedin: boolean | null;
  uploadedInstagram: boolean | null;
  uploadedTiktok: boolean | null;
  uploadedWhatsapp: boolean | null;
}

const CreateSuggestedPostForm: React.FC<CreateSuggestedPostFormProps> = props => {
  const {
    errors,
    onSubmit,
    register,
    handleImage,
    handleTypeFile,
    control,
    errorSocialNetwork = '',
    fileErrorTwitter = '',
    fileErrorLinkedin = '',
    fileErrorInstagram = '',
    fileErrorTiktok = '',
    fileErrorWhatsapp = '',
    loader,
    contentLimits,
    invalidLimit,
    uploadedTwitter,
    uploadedLinkedin,
    uploadedInstagram,
    uploadedTiktok,
    uploadedWhatsapp
  } = props;
  const { t } = useTranslation(['suggested_post']);
  const [onShow, setShow] = useState(true);
  const refOne = useRef<HTMLDivElement>(null);
  const [onURL, setURL] = useState({ URL: '', isInputValid: true });
  const [validationInput, setValidationInput] = useState('');
  const [onLinkedin, setLinkedin] = useState({ URL: '', isInputValid: true });
  const [onTiktok, setTiktok] = useState({ URL: '', isInputValid: true });
  const [onTwitter, setTwitter] = useState({ URL: '', isInputValid: true });
  const { company } = useSelector(selectCompany);
  const [checked, setChecked] = useState({
    facebook: true,
    twitter: true,
    linkedin: true,
    instagram: true,
    tiktok: true,
    whatsapp: true
  });

  const [file, setFile] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    tiktok: false,
    whatsapp: false
  });

  const [type, setType] = useState({
    twitter: 'text',
    linkedin: 'text',
    instagram: 'text',
    tiktok: 'text',
    whatsapp: 'text'
  });

  useEffect(() => {
    handleTypeFile(type);
  }, [type]);

  useEffect(() => {
    document.addEventListener('click', closeOpenShow, true);
  }, []);

  useEffect(() => {
    const socialNetworkSelected = checked as any;
    const socialNetworks = {
      ...socialNetworkSelected,
      ['facebook']: company?.company_permission.allowFacebook
    };
    setChecked(socialNetworks);
  }, [company]);

  const closeOpenShow = (e: any) => {
    if (refOne.current && onShow && !refOne.current.contains(e.target)) {
      setShow(true);
    }
  };

  const onSelectedTypeFile = (socialNetwork: string) => {
    const socialNetworkSelected = file as any;
    const sns = socialNetworkSelected[socialNetwork];
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: !sns
    };
    setFile(socialNetworks);
  };

  const onSelectedType = (socialNetwork: string, typeContent: string) => {
    const socialNetworkSelected = type as any;
    const selectedSNS = socialNetworkSelected[socialNetwork];

    if (selectedSNS === typeContent) {
      const socialNetworks = {
        ...socialNetworkSelected,
        [socialNetwork]: 'text'
      };
      setType(socialNetworks);
    } else {
      const socialNetworks = {
        ...socialNetworkSelected,
        [socialNetwork]: typeContent
      };
      setType(socialNetworks);
    }
  };

  const onSelectedSocialNetwork = (socialNetwork: string) => {
    const socialNetworkSelected = checked as any;
    const sns = socialNetworkSelected[socialNetwork];
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: !sns
    };
    setChecked(socialNetworks);
  };

  const onChangeTypeContent = (content: string, type: string) => {
    onSelectedTypeFile(content);
    onSelectedType(content, type);
  };

  const regextValidation = (URL: string, test: any) => {
    const regex = new RegExp(test);
    return regex.test(URL);
  };

  const updateUrlVal = (event: any) => {
    setValidationInput('');
    const { value } = event.target;
    if (!regextValidation(value, https) || !value.startsWith(https)) {
      setValidationInput(t('https_errors'));
      return;
    }

    if (!regextValidation(value, www)) {
      setValidationInput(t('www_errors'));
      return;
    }

    if (!regextValidation(value, fb)) {
      setValidationInput(t('facebook_errors'));
      return;
    }

    if (value.length > 200) {
      setValidationInput(t('count_validation'));
      return;
    }

    const isInputValid = !value || facebookURLs(value);
    setValidationInput('');
    setURL({
      URL: value,
      isInputValid
    });
  };
  const updateUrlValLinkedin = (event: any) => {
    const isInputValid = !event || regextValidationLinkedin(event);

    setLinkedin({
      URL: event,
      isInputValid
    });
  };
  const updateUrlValTiktok = (event: any) => {
    const isInputValid = !event || regextValidationTiktok(event);

    setTiktok({
      URL: event,
      isInputValid
    });
  };
  const updateTwitterField = (event: any) => {
    const isInputValid = !event || regextValidationTwitter(event);
    setTwitter({
      URL: event,
      isInputValid
    });
  };
  const regextValidationLinkedin = (URL: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(URL);
  };

  const regextValidationTiktok = (URL: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(URL);
  };

  const regextValidationTwitter = (URL: string) => {
    const regex = new RegExp(no_characters);
    return regex.test(URL);
  };

  const regextValidationTiktokLinks = (URL: string) => {
    const regex = new RegExp(no_links_mentions);
    return regex.test(URL);
  };

  return (
    <>
      <SectionWrapper className="hide-on-mobile">
        <NavigationLink
          text={t('suggested_post:back')}
          direction="back"
          to="/suggested-posts"
        />
        <h1>{t('suggested_post:create_suggested_post')}</h1>
        <form onSubmit={onSubmit}>
          <FormWrapper>
            <div>
              <LabelForm>
                <div>
                  <label>{t('suggested_post:name_suggested_post')}</label>
                </div>
                <div>
                  <Input
                    type="text"
                    name="name"
                    ref={register}
                    error={errors.name?.message}
                    data-testid="name"
                    max={150}
                    maxLength={150}
                    style={{ textTransform: 'capitalize' }}
                  />
                </div>
              </LabelForm>
              <LabelForm>
                <div>
                  <label>{t('suggested_post:description')}</label>
                </div>
                <div>
                  <TextArea
                    name="description"
                    rows={8}
                    ref={register}
                    error={errors.name?.message}
                    maxLength={500}
                  />
                </div>
              </LabelForm>
              <LabelForm>
                <div>
                  <label>{t('suggested_post:social_networks_c')}</label>
                  <p>{t('suggested_post:suggestion_social_networks')}</p>
                </div>
                <div className="network">
                  <div className="network-wrapper">
                    <NetworkFlexContainer>
                      <Icon
                        size="small"
                        name="facebook"
                        color={Colors.facebook}
                      />
                      <Input
                        id="sna_facebook"
                        type="checkbox"
                        name="includesFacebook"
                        ref={register}
                        checked={checked.facebook}
                        onChange={() => onSelectedSocialNetwork('facebook')}
                      />
                    </NetworkFlexContainer>
                    <NetworkFlexContainer>
                      <Icon
                        size="small"
                        name="twitter"
                        color={Colors.twitter}
                      />
                      <Input
                        id="sna_twitter"
                        type="checkbox"
                        name="includesTwitter"
                        ref={register}
                        checked={checked.twitter}
                        onChange={() => onSelectedSocialNetwork('twitter')}
                      />
                    </NetworkFlexContainer>
                    <NetworkFlexContainer>
                      <Icon
                        size="small"
                        name="linkedin"
                        color={Colors.linkedin}
                      />
                      <Input
                        id="sna_linkedin"
                        type="checkbox"
                        name="includesLinkedin"
                        ref={register}
                        checked={checked.linkedin}
                        onChange={() => onSelectedSocialNetwork('linkedin')}
                      />
                    </NetworkFlexContainer>
                    <NetworkFlexContainer>
                      <Icon
                        size="small"
                        name="instagram"
                        color={Colors.instagram}
                      />
                      <Input
                        id="sna_instagram"
                        type="checkbox"
                        name="includesInstagram"
                        ref={register}
                        checked={checked.instagram}
                        onChange={() => onSelectedSocialNetwork('instagram')}
                      />
                    </NetworkFlexContainer>
                    <NetworkFlexContainer>
                      <Icon
                        size="small"
                        name="whatsapp"
                        color={Colors.whatsapp}
                      />
                      <Input
                        id="sna_whatsapp"
                        type="checkbox"
                        name="includesWhatsapp"
                        ref={register}
                        checked={checked.whatsapp}
                        onChange={() => onSelectedSocialNetwork('whatsapp')}
                      />
                    </NetworkFlexContainer>
                    <NetworkFlexContainer>
                      <Icon size="small" name="tiktok" color={Colors.tiktok} />
                      <Input
                        id="sna_tiktok"
                        type="checkbox"
                        name="includesTiktok"
                        ref={register}
                        checked={checked.tiktok}
                        onChange={() => onSelectedSocialNetwork('tiktok')}
                      />
                    </NetworkFlexContainer>
                  </div>
                  {errorSocialNetwork && (
                    <ErrorSpan>{errorSocialNetwork}</ErrorSpan>
                  )}
                </div>
              </LabelForm>
              <FormTextArea>
                <div className="marginLabel">
                  <label>{t('contents_sn')}</label>
                  <p>{t('suggestion_content')}</p>
                </div>
                <div>
                  {checked.facebook && (
                    <SocialNetworkContent>
                      <div>
                        <Icon
                          size="small"
                          name="facebook"
                          color={Colors.facebook}
                        />
                      </div>
                      <label>{t('suggested_post:url_post')}</label>
                      <Input
                        name="contentFacebook"
                        ref={register({
                          required: checked.facebook
                        })}
                        data-testid="name"
                        onChange={updateUrlVal}
                        error={
                          errors.contentFacebook?.type === 'required'
                            ? t('required_field')
                            : ''
                        }
                        type="text"
                        placeholder={t('suggested_post:place_holder_fb')}
                      />
                      {validationInput && (
                        <ErrorSpan>{validationInput}</ErrorSpan>
                      )}
                      {(!onURL.isInputValid ||
                        errors.contentFacebook?.type == 'pattern') && (
                        <ErrorSpan>{t('error_url')}</ErrorSpan>
                      )}
                    </SocialNetworkContent>
                  )}
                  {checked.twitter && (
                    <SocialNetworkContent className="with-file">
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="twitter"
                            color={Colors.twitter}
                          />
                        </div>
                        <label>{t('suggested_post:description_tweet')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentTwitter"
                            control={control}
                            rules={{
                              required: checked.twitter,
                              pattern: no_characters,
                              maxLength: contentLimits.twitter
                            }}
                            render={({ value, onChange, ref }) => (
                              <EditorInput
                                isTwitter={true}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.twitter}
                                content={value}
                                onChange={e => {
                                  onChange(e);
                                  updateTwitterField(e);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="styled_border">
                          <Icon
                            className={
                              type.twitter == 'image' || type.twitter == 'text'
                                ? 'icon-tw-active'
                                : 'icon-tw-noactive'
                            }
                            size="medium"
                            name="photo"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('twitter', 'image')
                            }
                          />
                          <Icon
                            className={
                              type.twitter == 'video' || type.twitter == 'text'
                                ? 'icon-tw-active'
                                : 'icon-tw-noactive'
                            }
                            size="medium"
                            name="video"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('twitter', 'video')
                            }
                          />
                        </div>
                        {errors.contentTwitter?.type === 'required' && (
                          <ErrorSpan>{t('required_field')}</ErrorSpan>
                        )}
                        {fileErrorTwitter && (
                          <ErrorSpan>{fileErrorTwitter}</ErrorSpan>
                        )}
                        {(errors.contentTwitter?.type === 'pattern' ||
                          !onTwitter.isInputValid) && (
                          <ErrorSpan>{t('characters')}</ErrorSpan>
                        )}
                        {invalidLimit.twitter && (
                          <ErrorSpan>{t('error_limit')}</ErrorSpan>
                        )}
                      </div>
                      <div className="marginFile">
                        {type.twitter === 'video' && (
                          <UploadFileVideo
                            icon="video"
                            name="contentPerSocialNetwork.twitter"
                            handleVideo={handleImage}
                            className="socialNetworkFile"
                            content="twitter"
                            ref={register}
                            infoBox={t('info_box_video_twitter')}
                            uploaded={uploadedTwitter}
                          />
                        )}
                        {type.twitter === 'image' && (
                          <UploadFileMultipleImage
                            uploaded={uploadedTwitter}
                            icon="photo"
                            name="contentPerSocialNetwork.twitter"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="twitter"
                            ref={register}
                            infoBox={t('info_box_photo_twitter')}
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                  {checked.linkedin && (
                    <SocialNetworkContent className="with-file">
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="linkedin"
                            color={Colors.linkedin}
                          />
                        </div>
                        <label>{t('description_post')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentLinkedin"
                            control={control}
                            rules={{
                              required: checked.linkedin,
                              pattern: no_mentions,
                              maxLength: contentLimits.linkedin
                            }}
                            render={({ value, onChange, ref }) => (
                              <EditorInputLinkedin
                                isTwitter={false}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.linkedin}
                                content={value}
                                onChange={e => {
                                  onChange(e);
                                  updateUrlValLinkedin(e);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="styled_border">
                          <Icon
                            className={
                              type.linkedin == 'image' ||
                              type.linkedin == 'text'
                                ? 'icon-ln-active'
                                : 'icon-ln-noactive'
                            }
                            size="medium"
                            name="photo"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('linkedin', 'image')
                            }
                          />
                          <Icon
                            className={
                              type.linkedin == 'video' ||
                              type.linkedin == 'text'
                                ? 'icon-ln-active'
                                : 'icon-ln-noactive'
                            }
                            size="medium"
                            name="video"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('linkedin', 'video')
                            }
                          />
                          <Icon
                            className={
                              type.linkedin == 'pdf' || type.linkedin == 'text'
                                ? 'icon-ln-active'
                                : 'icon-ln-noactive'
                            }
                            size="medium"
                            name="pdf"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('linkedin', 'pdf')
                            }
                          />
                        </div>
                        {errors.contentLinkedin?.type === 'required' && (
                          <ErrorSpan>{t('required_field')}</ErrorSpan>
                        )}
                        {fileErrorLinkedin && (
                          <ErrorSpan>{fileErrorLinkedin}</ErrorSpan>
                        )}
                        {(errors.contentLinkedin?.type === 'pattern' ||
                          !onLinkedin.isInputValid) && (
                          <ErrorSpan>{t('pattern')}</ErrorSpan>
                        )}
                        {invalidLimit.linkedin && (
                          <ErrorSpan>{t('error_limit')}</ErrorSpan>
                        )}
                      </div>
                      <div className="marginFile">
                        {type.linkedin == 'pdf' && (
                          <UploadFileImageSimple
                            icon="pdf"
                            name="contentPerSocialNetwork.linkedin"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="linkedin"
                            maxFileSize={10000}
                            ref={register}
                            infoBox={t('info_box_pdf_linkedin')}
                            allowedFileTypes="application/pdf"
                          />
                        )}
                        {type.linkedin == 'video' && (
                          <>
                            <UploadFileVideo
                              icon="video"
                              name="contentPerSocialNetwork.linkedin"
                              handleVideo={handleImage}
                              className="socialNetworkFile"
                              content="linkedin"
                              ref={register}
                              maxFileSize={200000}
                              maxFileDuration={600}
                              infoBox={t('info_box_video_linkedin')}
                              uploaded={uploadedLinkedin}
                            />
                          </>
                        )}
                        {type.linkedin == 'image' && (
                          <UploadFileMultipleImage
                            uploaded={uploadedLinkedin}
                            icon="photo"
                            name="contentPerSocialNetwork.linkedin"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="linkedin"
                            ref={register}
                            infoBox={t('info_box_photo_linkedin')}
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                  {checked.instagram && (
                    <SocialNetworkContent className="with-file">
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="instagram"
                            color={Colors.instagram}
                          />
                        </div>
                        <label>{t('suggested_post:description_post')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentInstagram"
                            control={control}
                            rules={{
                              maxLength: contentLimits.instagram
                            }}
                            render={({ value = '', onChange, ref }) => (
                              <EditorInput
                                isTwitter={false}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.instagram}
                                content={value}
                                onChange={e => {
                                  onChange(e);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="styled_border">
                          <Icon
                            className={
                              type.instagram == 'image' ||
                              type.instagram == 'text'
                                ? 'icon-ins-active'
                                : 'icon-ins-noactive'
                            }
                            size="medium"
                            name="photo"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('instagram', 'image')
                            }
                          />
                          <Icon
                            className={
                              type.instagram == 'video' ||
                              type.instagram == 'text'
                                ? 'icon-ins-active'
                                : 'icon-ins-noactive'
                            }
                            size="medium"
                            name="video"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('instagram', 'video')
                            }
                          />
                        </div>
                        {errors.contentInstagram?.type === 'required' && (
                          <ErrorSpan>{t('required_field')}</ErrorSpan>
                        )}
                        {fileErrorInstagram && (
                          <ErrorSpan>{fileErrorInstagram}</ErrorSpan>
                        )}
                        {invalidLimit.instagram && (
                          <ErrorSpan>{t('error_limit')}</ErrorSpan>
                        )}
                      </div>
                      <div className="marginFile">
                        {type.instagram == 'video' && (
                          <UploadFileVideo
                            icon="video"
                            name="contentPerSocialNetwork.instagram"
                            handleVideo={handleImage}
                            className="socialNetworkFile"
                            content="instagram"
                            maxFileSize={30000}
                            maxFileDuration={60}
                            ref={register}
                            infoBox={t('info_box_video_instagram')}
                            uploaded={uploadedInstagram}
                          />
                        )}
                        {type.instagram == 'image' && (
                          <UploadFileImageSimple
                            icon="photo"
                            name="contentPerSocialNetwork.instagram"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="instagram"
                            maxFileSize={5000}
                            ref={register}
                            infoBox={t('info_box_photo_instagram')}
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                  {checked.whatsapp && (
                    <SocialNetworkContent className="with-file">
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="whatsapp"
                            color={Colors.whatsapp}
                          />
                        </div>
                        <label>{t('suggested_post:description_wa')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentWhatsapp"
                            control={control}
                            rules={{
                              maxLength: contentLimits.whatsapp
                            }}
                            render={({ value = '', onChange, ref }) => (
                              <EditorInput
                                isTwitter={false}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.whatsapp}
                                content={value}
                                onChange={e => {
                                  onChange(e);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="styled_border">
                          <Icon
                            className={
                              type.whatsapp == 'image' ||
                              type.whatsapp == 'text'
                                ? 'icon-ins-active'
                                : 'icon-ins-noactive'
                            }
                            size="medium"
                            name="photo"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('whatsapp', 'image')
                            }
                          />
                          <Icon
                            className={
                              type.whatsapp == 'video' ||
                              type.whatsapp == 'text'
                                ? 'icon-ins-active'
                                : 'icon-ins-noactive'
                            }
                            size="medium"
                            name="video"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('whatsapp', 'video')
                            }
                          />
                        </div>
                        {errors.contentWhatsapp?.type === 'required' && (
                          <ErrorSpan>{t('required_field')}</ErrorSpan>
                        )}
                        {fileErrorWhatsapp && (
                          <ErrorSpan>{fileErrorWhatsapp}</ErrorSpan>
                        )}
                        {invalidLimit.whatsapp && (
                          <ErrorSpan>{t('error_limit')}</ErrorSpan>
                        )}
                      </div>
                      <div className="marginFile">
                        {type.whatsapp == 'video' && (
                          <UploadFileVideo
                            name="contentPerSocialNetwork.whatsapp"
                            handleVideo={handleImage}
                            className="socialNetworkFile"
                            content="whatsapp"
                            maxFileSize={30000}
                            maxFileDuration={60}
                            ref={register}
                            infoBox={t('info_box_video_whatsapp')}
                            uploaded={uploadedWhatsapp}
                          />
                        )}
                        {type.whatsapp == 'image' && (
                          <UploadFileImageSimple
                            name="contentPerSocialNetwork.whatsapp"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="whatsapp"
                            maxFileSize={5000}
                            ref={register}
                            infoBox={t('info_box_photo_whatsapp')}
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                  {checked.tiktok && (
                    <SocialNetworkContent className="with-file">
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="tiktok"
                            color={Colors.tiktok}
                          />
                        </div>
                        <label>{t('suggested_post:description_tk')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentTiktok"
                            control={control}
                            rules={{
                              required: checked.tiktok,
                              pattern: no_links_mentions,
                              maxLength: contentLimits.tiktok
                            }}
                            render={({ value, onChange, ref }) => (
                              <EditorInput
                                isTwitter={false}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.tiktok}
                                content={value}
                                onChange={e => {
                                  onChange(e);
                                  updateUrlValTiktok(e);
                                  // updateUrlValTiktokLinks(e);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="styled_border">
                          <Icon
                            className={
                              type.tiktok == 'video' || type.tiktok == 'text'
                                ? 'icon-ins-active'
                                : 'icon-ins-noactive'
                            }
                            size="medium"
                            name="video"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('tiktok', 'video')
                            }
                          />
                        </div>
                        {errors.contentTiktok?.type === 'required' && (
                          <ErrorSpan>{t('required_field')}</ErrorSpan>
                        )}
                        {fileErrorTiktok && (
                          <ErrorSpan>{fileErrorTiktok}</ErrorSpan>
                        )}
                        {(errors.contentTiktok?.type === 'pattern' ||
                          !onTiktok.isInputValid) && (
                          <ErrorSpan>{t('pattern_tiktok')}</ErrorSpan>
                        )}
                        {invalidLimit.tiktok && (
                          <ErrorSpan>{t('error_limit')}</ErrorSpan>
                        )}
                      </div>
                      <div className="marginFile">
                        {type.tiktok == 'video' && (
                          <UploadFileVideo
                            name="contentPerSocialNetwork.tiktok"
                            handleVideo={handleImage}
                            className="socialNetworkFile"
                            content="tiktok"
                            maxFileSize={30000}
                            maxFileDuration={300}
                            ref={register}
                            infoBox={t('info_box_video_tiktok')}
                            uploaded={uploadedTiktok}
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                </div>
              </FormTextArea>
              <MarginButton>
                {!loader ? (
                  <Button
                    value={t('suggested_post:create_suggested_post')}
                    variant="primary"
                  />
                ) : (
                  <Spinner name="sign-up" />
                )}
              </MarginButton>
            </div>
          </FormWrapper>
        </form>
      </SectionWrapper>
    </>
  );
};

export default CreateSuggestedPostForm;
