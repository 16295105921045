import Client from 'config/axios';

export interface AwardData {
  photoUrl: string;
  description: string;
  companyId: number;
  groupsIds: any;
  periodValue: number;
  periodType: string;
  startDate: any;
  finishDate: any;
}

export const updateAward = async (
  token: string,
  award: AwardData,
  awardId: any
) => {
  try {
    const { data } = await Client.post(`award/${awardId}`, award, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
