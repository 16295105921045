import styled, { css } from 'styled-components';

export interface StyledTooltipWrapperProps {
  visible: boolean;
}

export const TooltipMessage = styled.span`
  visibility: hidden;
  display: none;
  background-color: #1661ea;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 2;
  bottom: 100%;
  left: 0px;
  margin-left: 0px;
  transition: opacity 0.3s;
  margin-bottom: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #1661ea transparent transparent transparent;
  }

  &.warning {
    background: rgba(248, 252, 3, 0.6);
  }
`;

export const StyledTooltipWrapper = styled.div<StyledTooltipWrapperProps>`
  position: relative;
  cursor: pointer;

  &.ranking-tooltip-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${({ visible }) =>
    visible &&
    css`
      ${TooltipMessage} {
        visibility: visible;
        display: block;
      }
      ${TooltipMessage}.ranking-tooltip {
        visibility: visible;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 300px;
        left: inherit !important;
      }
    `}
`;
