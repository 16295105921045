import React from 'react';
import {
  GetSignedUrlS3,
  UploadSignedUrlToS3
} from 'services/rest/UploadImage/UploadImgToS3';

export async function awsUploadFileHelper(
  currentUser: string,
  file: any,
  isCropImage: boolean,
  folder: string,
  name: string,
  t: any,
  setError: any
) {
  let uploaded = false;
  const getUrl = await GetSignedUrlS3(currentUser, name, folder);
  if (getUrl?.status === 200) {
    const url = getUrl?.data?.data;
    const upload = await UploadSignedUrlToS3(
      url,
      isCropImage ? file : file['file'],
      isCropImage ? 'image/png' : file['file']['type']
    );
    if (upload?.status === 200) {
      uploaded = true;
    } else {
      setError(t('validations:file_error'));
      return;
    }
  } else {
    setError(t('validations:file_error'));
    return;
  }
  return uploaded;
}

export function setFileNameHelper(file: any, isEditMultiple: boolean) {
  let fileName = '';
  if (isEditMultiple) {
    if (file?.name.length > 0) {
      const getType = file['file']['type'].split('/');
      fileName = `${file['index']}.${getType[1]}`;
    } else {
      const getName = file.url.split('/');
      fileName = getName[5];
    }
  } else {
    if (file.name) {
      const getType = file['file']['type'].split('/');
      fileName = `${file['index']}.${getType[1]}`;
    }
  }
  return fileName;
}

export function setFilePdfHelper(namePDF: any) {
  let namePDFNormal = namePDF
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s.-]/g, '');

  namePDFNormal = namePDFNormal.replace(/\s+/g, '_').trim();

  const parts = namePDFNormal.split('.');
  if (parts.length > 1) {
    const extension = parts.pop()!;
    const name = parts.join('');

    const cleanedName = name
      .replace(/\s+/g, '')
      .toLowerCase()
      .replace(/^(.)/, (match: any) => match.toUpperCase());
    namePDFNormal = `${cleanedName}.${extension}`;
  }

  return namePDFNormal;
}
