import React, { SyntheticEvent } from 'react';
import { GridWrapper, MarginText } from './Styled';
import DefaultImage from 'images/empty_states/empty_state_search.png';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BoxActionChallenges } from 'components/boxes/PostPointsBox/StyledPostPointsBox';
import Button from 'components/buttons/Button';

export interface ChallengeAmbassadorCardProps {
  key: number;
  challenge: any;
  timeLine: any;
}

const ChallengeAmbassadorCard: React.FC<ChallengeAmbassadorCardProps> = props => {
  const { key, challenge, timeLine } = props;
  const { t } = useTranslation(['campaign_card', 'challenge']);
  const history = useHistory();
  const socialNetworks = [
    'facebook',
    'twitter',
    'linkedin',
    'instagram',
    'tiktok',
    'snapchat',
    'youtube',
    'threads',
    'others'
  ];

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = DefaultImage;
  };

  return (
    <>
      <GridWrapper key={key} className="hide-on-mobile hoverable small">
        <div className="content-box-header content-img">
          <img
            alt={challenge.name}
            className="image-label"
            onError={evt => addDefaultPicture(evt)}
            src={challenge.photo || DefaultImage}
          />
        </div>
        <MarginText className="m-auto">
          <p className="time-line">
            {timeLine(challenge.startDate, challenge.finishDate)}
          </p>
          <div>
            <h2>{challenge.name}</h2>
          </div>
          <div>
            <span className="subtitle">{`${t('challenge:rules')}: `}</span>
            <span>{`${t(`${challenge.content}`)}`}</span>
          </div>
          <p className="social-networks">
            <span className="subtitle">{`${t(
              'challenge:social_networks'
            )}: `}</span>
            {socialNetworks.map(network => {
              return (
                challenge[network] && (
                  <SocialMediaIcon
                    name={network === 'others' ? 'otros' : network}
                    size="small"
                    shape="square"
                  />
                )
              );
            })}
            <div>
              <span className="format subtitle">
                {`${t('challenge:format')}: `}
              </span>
              <span>
                {challenge.format === 'video, image'
                  ? `${t('challenge:image')} ${t('challenge:and')} ${t(
                      'challenge:video'
                    )}`
                  : `${t(`challenge:${challenge.format}`)}`}
              </span>
            </div>
          </p>
        </MarginText>
        <BoxActionChallenges>
          <Button
            value={t('challenge:participate')}
            variant="primary"
            onClick={() =>
              history.replace({
                pathname: `/challenges/detail/${challenge.id}`
              })
            }
            as="a"
            size="medium"
          />
        </BoxActionChallenges>
      </GridWrapper>
    </>
  );
};

export default ChallengeAmbassadorCard;
