import React from 'react';

import Icon from 'components/icons/Icon';
import IconWithStatus from 'components/icons/IconWithStatus';
import { PossibleStatus } from 'components/icons/StatusIcon';
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  InstagramIcon,
  TiktokIcon
} from 'components/icons/Catalogue';

import {
  DropdownContentList,
  DropdownContentItem,
  DropdownContainer,
  DropdownElement
} from 'components/dropdowns/StyledDropdown';
import { Colors } from 'styles/Constants';

export interface TestComponentProps {
  invalidToken: boolean;
  network: string;
  status: PossibleStatus;
  className?: string;
  items: any;
}

const socialIcon = (network: string) => {
  switch (network) {
    case 'facebook':
      return <FacebookIcon shape="square" />;
    case 'twitter':
      return <TwitterIcon shape="square" />;
    case 'linkedin':
      return <LinkedinIcon shape="square" />;
    case 'instagram':
      return <InstagramIcon shape="square" />;
    case 'tiktok':
      return <TiktokIcon shape="square" />;
    default:
      return null;
  }
};

const SocialComponent: React.FC<TestComponentProps> = props => {
  const { invalidToken, network, status, className, items } = props;
  const actions = items(invalidToken, network);

  return (
    <>
      <DropdownContainer className={className}>
        <DropdownElement>
          <IconWithStatus status={status}>{socialIcon(network)}</IconWithStatus>
          <Icon name="arrow-dropdown" color={Colors.normalBlack} />
        </DropdownElement>

        <DropdownContentList>
          {actions.map((item: any, index: number) => (
            <DropdownContentItem key={index}>
              <button onClick={() => item.onClick(network)}>{item.text}</button>
            </DropdownContentItem>
          ))}
        </DropdownContentList>
      </DropdownContainer>
    </>
  );
};

export default SocialComponent;
