import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { FieldPolicy } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';

interface Edge {
  node: {
    __ref: string;
  };
}

//eslint-disable-next-line
type KeySpecifier = (string | any[])[]

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_HOSTNAME}/graphql`,
  fetch
});

const authLink = setContext((_, { headers }) => {
  const authHeaders = localStorage.getItem('ironmanAuth');
  const parsedHeaders = authHeaders ? JSON.parse(authHeaders) : {};

  return {
    headers: {
      ...headers,
      ...parsedHeaders
    }
  };
});

const fixedRelayPagination = (keyArgs: KeySpecifier): FieldPolicy => {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      if (!existing || !args || args.after === '') {
        return incoming;
      }

      const allEdges = [...existing.edges, ...incoming.edges].reduce(
        (acc: Array<Edge>, edge: Edge) => {
          const index = acc.findIndex(
            item => item.node.__ref === edge.node.__ref
          );

          if (index <= -1) acc.push(edge);

          return acc;
        },
        []
      );

      return {
        ...existing,
        pageInfo: incoming.pageInfo,
        edges: allEdges
      };
    }
  };
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        employeeRanking: relayStylePagination()
      }
    },
    Employee: {
      fields: {
        campaigns: fixedRelayPagination(['filters', ['status']])
      }
    }
  },
  possibleTypes: {
    Points: [
      'FacebookPoints',
      'TwitterPoints',
      'LinkedinPoints',
      'InstagramPoints'
    ]
  }
});

const Client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
});

export default Client;
