import Client from 'config/axios';

export const getLinkedinPost = async (token: string, link: string) => {
  const body = {
    url: link
  };
  try {
    const { data } = await Client.post(`campaign/linkedin/urnli`, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return data;
  } catch (response) {
    const data = {
      url: '',
      urnLi: ''
    };
    return { data };
  }
};
