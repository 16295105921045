import React, { SyntheticEvent } from 'react';
import { StyledLabelBox, HeaderLabelBox, BodyLabelBox } from './Styled';
import DefaultImage from 'images/empty_states/empty_state_search.png';

export interface LabelBoxProps {
  image: string;
  labels: Array<string>;
  content: string;
  className?: string;
}

const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
  evt.currentTarget.src = DefaultImage;
};

const LabelBox: React.FC<LabelBoxProps> = props => {
  const { image, labels, content, className } = props;
  return (
    <StyledLabelBox className={className}>
      <HeaderLabelBox className="header-card-container">
        <img
          alt="UserImage"
          onError={evt => addDefaultPicture(evt)}
          src={image || DefaultImage}
        />
        <div className="tags">
          {labels.map((label, index) => (
            <span key={index}>{label}</span>
          ))}
        </div>
      </HeaderLabelBox>
      <BodyLabelBox>
        <p>{content}</p>
      </BodyLabelBox>
    </StyledLabelBox>
  );
};

export default LabelBox;
