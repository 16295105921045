import React, { Suspense, useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { useTranslation } from 'react-i18next';
import { MarginTitlePage } from './Styled';
import { getChallenges } from 'services/rest/Challenge/getChallenges';
import AdminChallenges from './AdminChallenges';
import { useHistory, useLocation } from 'react-router-dom';
import { SuccessAlert } from 'components/Alert';
import moment from 'moment';
import 'moment/locale/es';
import Button from 'components/buttons/Button';
import AmbassadorChallenges from './AmbassadorChallenges';

const timeLine = (startDate: any, finishDate: any) => {
  startDate = moment(startDate);
  const startMonth = startDate.format('MMM');
  const startDay = startDate.format('D');
  finishDate = moment(finishDate);
  const finishMonth = finishDate.format('MMM');
  const finishDay = finishDate.format('D');
  return `${startMonth} ${startDay} - ${finishMonth} ${finishDay}`;
};

const Challenges = () => {
  const { currentUser } = useSelector(selectUser);
  const [challenges, setChallenges] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(['challenge']);
  const location = useLocation();
  const history = useHistory();
  const { state } = location as any;
  const [backendSuccess, setBackendSuccess] = useState<any | null>(null);
  const [changeChallenge, setChangeChallenge] = useState<boolean | null>(false);

  const showAlertSuccessChallengeCreated = async () => {
    if (typeof state === 'string') {
      setBackendSuccess(state);
    }
  };

  const getData = async () => {
    const { data } = await getChallenges(currentUser?.token, 500, 1);
    if (data) {
      currentUser.role === 'admin'
        ? setChallenges(data.data.docs)
        : setChallenges(data.data);
    }
    showAlertSuccessChallengeCreated();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [changeChallenge]);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        {challenges.length > 0 ? (
          <SectionWrapper>
            <MarginTitlePage>
              <h1>{t('challenge:challenges')}</h1>
              {currentUser.role === 'admin' && (
                <Button
                  value={t('challenge:create_challenge')}
                  variant="primary"
                  onClick={() =>
                    history.replace({ pathname: `/challenges/new` })
                  }
                />
              )}
            </MarginTitlePage>

            {currentUser.role === 'admin' ? (
              <AdminChallenges
                challenges={challenges}
                timeLine={timeLine}
                setLoading={setLoading}
                changeChallenge={changeChallenge}
                setChangeChallenge={setChangeChallenge}
              />
            ) : (
              <AmbassadorChallenges
                challenges={challenges}
                timeLine={timeLine}
              />
            )}
          </SectionWrapper>
        ) : (
          <SectionWrapper>
            <MarginTitlePage>
              <h1>{t('challenge:challenges')}</h1>
              {currentUser.role === 'admin' && (
                <Button
                  value={t('challenge:create_challenge')}
                  variant="primary"
                  onClick={() =>
                    history.replace({ pathname: `/challenges/new` })
                  }
                />
              )}
            </MarginTitlePage>

            {currentUser.role === 'admin' ? (
              <AdminChallenges
                challenges={[]}
                timeLine={[]}
                setLoading={false}
                changeChallenge={[]}
                setChangeChallenge={[]}
              />
            ) : (
              <AmbassadorChallenges challenges={[]} timeLine={[]} />
            )}
          </SectionWrapper>
        )}
      </Suspense>
    </>
  );
};

export default Challenges;
