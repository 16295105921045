import styled, { css } from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import { Table, Th, Tr, Td } from 'components/Table';
import { StyledTooltipWrapper } from 'components/TooltipWrapper/StyledTooltipWrapper';

export { Table, Th };

const Padding60 = css`
  @media (min-width: ${Breakpoints.small}) {
    padding-right: 60px;
  }
`;

export const Row = styled(Tr)`
  .responseHead {
    display: grid;
    background: ${Colors.normalWhite};
    margin-bottom: 20px;
  }
  &.unsubscribe {
    background-color: rgb(252 248 238 / 1);
    td {
      background: inherit;
      border: inherit;
      &:before {
        background: rgb(252 248 238 / 1);
        width: 10px;
        left: -10px;
      }
      &:nth-child(1):before {
        content: none;
      }
    }
  }
  &:nth-child(1) {
    svg.trophy {
      fill: ${Colors.gold};
      stroke: ${Colors.gold};
    }
  }

  &:nth-child(2) {
    svg.trophy {
      fill: ${Colors.silver};
      stroke: ${Colors.silver};
    }
  }

  &:nth-child(3) {
    svg.trophy {
      fill: ${Colors.bronze};
      stroke: ${Colors.bronze};
    }
  }

  ${({ activeRow }) =>
    activeRow &&
    css`
      ${PositionColumn} {
        p {
          font-weight: 700;
          color: #ffffff;
        }
      }

      ${StyledTooltipWrapper} {
        svg[name='warning'],
        svg[name='warning']:hover {
          stroke: #ffffff;
          fill: #ffffff;
        }
      }
    `}

  @media only screen and (max-width: ${Breakpoints.medium}) {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
  }
`;

export const PositionColumn = styled(Td)`
  display: flex;
  div.id {
    align-items: center;
  }

  img.user-photo {
    border-radius: 100%;
    margin: 0 0 0 25px;
    height: 50px;
    width: 50px;
  }

  p {
    color: ${Colors.primaryDarkBlue};
    font-weight: 700;
    margin: 1px 0 0 0;

    @media (min-width: ${Breakpoints.medium}) {
      font-size: 1.5rem;
    }
  }

  svg[name='warning'] {
    stroke: ${Colors.secondaryDarkGrey};
    fill: ${Colors.secondaryDarkGrey};

    &:hover {
      stroke: #fcba03;
      fill: #fcba03;
    }
  }
`;

export const TrophyColumn = styled(Td)`
  span.trophy {
    box-shadow: none;
  }

  svg.trophy {
    stroke-width: 20px;
    stroke: #d4d4d4;
    fill: #d4d4d4;
  }

  ${Padding60};
`;

export const Column = styled(Td)`
  .center {
    text-align: center;
  }
  @media (max-width: ${Breakpoints.medium}) {
    text-align: center;
  }

  p {
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 14px;
    color: #4d4d4d;
    border: none;
  }

  img {
    height: 22px;
    width: 22px;
  }
`;

export const UserNameHeader = styled(Th)`
  .textColumn {
    columns: 2;
  }
`;

export const PointsHeader = styled(Th)`
  @media (min-width: ${Breakpoints.small}) {
    padding: 0px;
  }
`;

export const SocialInfoStyled = styled.div`
  display: flex;

  img {
    height: 20px;
    width: 20px;
  }

  .totalPoints {
    display: grid;
  }
`;

export const Status = styled.div`
  float: right;
  margin-left: -10px;
  z-index: 1;
`;
