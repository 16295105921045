import Client from 'config/axios';

export const getCountries = async (token: string) => {
  return await Client.get('countries', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data.data.docs)
    .catch(error => error);
};
