import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import CreateCompanyForm from './CreateCompanyForm';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFetch } from 'hooks/useFetch.hook';
import { getCountries } from 'services/rest/SuperAdmin/Country/getCountries';
import { getCurrencies } from 'services/rest/SuperAdmin/Currency/getCurrencies';
import { selectUser } from 'redux/slices/userSlice';
import { createCompany } from 'services/rest/SuperAdmin/Company/createCompany';
import { yupResolver } from '@hookform/resolvers';
import { RequiredString } from 'helpers/yupHelper';
import { companyFileName } from 'helpers/companyFileName';
import { awsUploadFileHelper } from 'helpers/awsFileHelper';
import { getUrlS3 } from 'constants/setup.constants';
import { ErrorAlert } from 'components/Alert';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const CreateCompany: React.FC = () => {
  const { t } = useTranslation([
    'Settings',
    'validations',
    'super_admin_company'
  ]);
  const companySchema = yup.object().shape({
    name: RequiredString().max(
      100,
      t('validations:length_error', { max: 100 })
    ),
    subdomain: RequiredString().max(
      50,
      t('validations:length_error', { max: 50 })
    ),
    language: RequiredString(),
    country: RequiredString(),
    currency: RequiredString(),
    ambassadorsLimit: RequiredString(),
    licenseDate: yup
      .date()
      .typeError(t('super_admin_company:form:validations:required'))
      .required(t('super_admin_company:form:validations:required'))
      .min(
        moment(new Date()).format('YYYY-MM-DD'),
        t('super_admin_company:license_date_error')
      )
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(companySchema)
  });
  const [loader, setLoader] = useState(false);
  const { currentUser } = useSelector(selectUser);
  const countries = useFetch(getCountries(currentUser?.token));
  const currencies = useFetch(getCurrencies(currentUser?.token));
  const [selectCountry, setSelectCountry] = useState<any>();
  const [selectCurrency, setSelectCurrency] = useState<any>();
  const [selectLanguage, setSelectLanguage] = useState<any>();
  const [error, setError] = useState<string>('');
  const [backgroundImage, setBackgroundImage] = useState<Array<any>>([]);
  const [logoImage, setLogoImage] = useState<Array<any>>([]);
  const [emailLogoImage, setEmailLogoImage] = useState<Array<any>>([]);
  const [tosPdf, setTosPdf] = useState<Array<any>>([]);
  const [fileError, setFileError] = useState<boolean>(true);
  const history = useHistory();

  const handleImage = (select: Array<any>, content: string) => {
    if (select.length > 0) {
      switch (content) {
        case 'backgroundImage':
          setBackgroundImage(select);
          setFileError(false);
          break;
        case 'logo':
          setLogoImage(select);
          setFileError(false);
          break;
        case 'emailLogo':
          setEmailLogoImage(select);
          setFileError(false);
          break;
        case 'tosUrl':
          setTosPdf(select);
          setFileError(false);
          break;
      }
    } else {
      setFileError(true);
    }
  };

  const onSubmit = async (companyData: any) => {
    setLoader(true);

    if (
      fileError ||
      backgroundImage.length == 0 ||
      logoImage.length == 0 ||
      emailLogoImage.length == 0 ||
      tosPdf.length == 0
    ) {
      setError(t('validations:file_required'));
      setLoader(false);
      return;
    }
    if (backgroundImage.length > 0) {
      const backgroundName = companyFileName(
        backgroundImage,
        companyData?.subdomain,
        'background'
      );
      await awsUploadFileHelper(
        currentUser?.token,
        backgroundImage[0],
        'COMPANY',
        backgroundName,
        false,
        setError,
        t('validations:file_error'),
        companyData?.subdomain
      );
      companyData.backgroundImage = getUrlS3(
        'companies',
        backgroundName,
        companyData?.subdomain
      );
    }
    if (logoImage.length > 0) {
      await awsUploadFileHelper(
        currentUser?.token,
        logoImage[0],
        'COMPANY',
        `${companyData?.subdomain}_logo.svg`,
        false,
        setError,
        t('validations:file_error'),
        companyData?.subdomain
      );
      companyData.logo = getUrlS3(
        'companies',
        `${companyData?.subdomain}_logo.svg`,
        companyData?.subdomain
      );
    }
    if (emailLogoImage.length > 0) {
      await awsUploadFileHelper(
        currentUser?.token,
        emailLogoImage[0],
        'COMPANY',
        `${companyData?.subdomain}_logo.png`,
        false,
        setError,
        t('validations:file_error'),
        companyData?.subdomain
      );
      companyData.emailLogo = getUrlS3(
        'companies',
        `${companyData?.subdomain}_logo.png`,
        companyData?.subdomain
      );
    }
    if (tosPdf.length > 0) {
      await awsUploadFileHelper(
        currentUser?.token,
        tosPdf[0],
        'LEGAL',
        `legal_${companyData?.subdomain}.pdf`,
        false,
        setError,
        t('validations:file_error'),
        companyData?.subdomain
      );
      companyData.TosUrl = `legal_${companyData?.subdomain}.pdf`;
    }
    companyData.role = currentUser?.role;
    companyData.facebookCredentials = null;
    companyData.twitterCredentials = null;
    companyData.linkedinCredentials = null;
    companyData.countryId = companyData.country;
    companyData.currencyId = companyData.currency;

    const { data, errors } = await createCompany(
      currentUser?.token,
      companyData
    );
    if (data) {
      setLoader(false);
      history.push('/companies', t('super_admin_company:create_success'));
    }
    if (errors) {
      setError(errors?.data);
      setLoader(false);
    }
  };
  return (
    <>
      {error && (
        <ErrorAlert
          text={error}
          allowClose={true}
          onClose={() => setError('')}
        />
      )}
      <CreateCompanyForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setValue={setValue}
        control={control}
        loader={loader}
        countries={countries?.data}
        currencies={currencies?.data}
        selectCountry={selectCountry}
        setSelectCountry={setSelectCountry}
        selectCurrency={selectCurrency}
        setSelectCurrency={setSelectCurrency}
        selectLanguage={selectLanguage}
        setSelectLanguage={setSelectLanguage}
        handleImage={handleImage}
      />
    </>
  );
};

export default CreateCompany;
