import Client from 'config/axios';

export const deleteAward = async (token: string, awardId: number) => {
  try {
    const { data } = await Client.delete(`award/${awardId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return { data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
