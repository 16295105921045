import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export const MarginUploadFile = styled.div`
  text-align: center;
  background-color: #f2f2f2 !important;
  font-family: 'Montserrat' !important;
  border-radius: 25px !important;
  border: 2px dashed #979797 !important;
  height: 200px !important;
  width: 250px !important;
  padding: 5px !important;

  &.error-file {
    border-color: #ff303f !important;
  }

  span {
    position: relative;
    bottom: 150px;
  }

  @media (max-width: ${Breakpoints.small}) {
    margin-left: 4%;
    margin-right: 20%;
    margin-top: 5%;
    width: 250px !important;
  }

  input {
    width: 250px;
    height: 200px;
    opacity: 0 !important;
    position: relative;
    bottom: 150px;
  }

  svg {
    text-align: center;
    margin: 10px auto;
    display: flex;
  }
  h3 {
    font-size: 1rem;
    margin-left: 12%;
    margin-right: 12%;
    font-weight: bold;
  }

  p {
    font-size: 13px !important;
    color: ${Colors.primaryGray} !important;
    text-align: center;
  }
`;

export const StyledImage = styled.div`
  img {
    border-radius: 10px;
    object-fit: cover;
    height: 200px;
    width: 200px;
  }
`;
