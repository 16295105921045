import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { selectUser } from 'redux/slices/userSlice';

import { SuccessAlert } from 'components/Alert';

import AdminSettings from './AdminSettings';
import AmbassadorSettings from './AmbassadorSettings';

const Settings = () => {
  const { currentUser } = useSelector(selectUser);
  const location = useLocation();
  const { state } = location as any;
  const [successMessage, setSuccessMessage] = useState<any>(state?.from);
  return (
    <>
      {currentUser?.role === 'ambassador' ? (
        <AmbassadorSettings />
      ) : (
        <>
          {successMessage && (
            <SuccessAlert
              text={successMessage}
              allowClose={true}
              onClose={() => setSuccessMessage(null)}
            />
          )}
          <AdminSettings />
        </>
      )}
    </>
  );
};

export default Settings;
