import Client from 'config/axios';

export const getAmbassadorById = async (token: string, id: string) => {
  const { data } = await Client.get(`ambassador/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
