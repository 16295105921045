import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';
import BackgroundResponsive from 'images/backgrounds/background_onboarding_responsive.png';

export const Background = styled.div`
  background-image: url(${BackgroundResponsive});
  justify-content center;
  background-size: cover;
  flex-direction: column;
  display: flex;

  @media (min-width: ${Breakpoints.medium}) {
    min-height: 100vh;
  }
  
`;

export const FormWrapper = styled.div`
  background: ${Colors.normalWhite};
  background-color: #fff;
  border-radius: 15px 15px 0px 0px;
  padding: 80px 45px;
  margin: 51vw 0 0 0;
  position: relative;
  display: block;
  max-width: 100%;

  @media (min-width: ${Breakpoints.medium}) {
    box-shadow: 0 10px 19px 0 rgba(161, 161, 161, 0.5);
    background-color: #fff;
    border-radius: 15px 15px 0px 0px;
    margin: 20px 30%;
    grid-column: 2;
  }
  @media screen and (max-width: ${Breakpoints.large}) {
    box-shadow: 0 0px 50px 0 rgba(161, 161, 161, 0.5);
    background-color: #fff;
    border-radius: 40px 40px 0px 0px;
  }
}
`;

export const FormColor = styled.div`
  background: #f2f2f2;
  background-color: #fff;
  border-radius: 0px 0px 15px 15px;
  position: relative;
  max-width: 100%;
  
  @media (min-width: ${Breakpoints.medium}) {
    box-shadow: 0 10px 19px 0 rgba(161, 161, 161, 0.5);
    background: #f2f2f2;
    border-radius: 0px 0px 15px 15px;
    padding: 40px 80px;
    max-width: 40%;
    margin: auto 30% 7% 30%;
    grid-column: 2;
    
    button {
      padding: 10px 118px;
    }
  }
  @media screen and (max-width: ${Breakpoints.large}) {
    background: #f2f2f2;
    margin: -50px auto 15%;
    border-radius: 0px;
    
    button {
      padding: 10px 145px;
    }
  }
}
`;

export const DivColor = styled.div`
  background: #f2f2f2;
  background-color: #fff;
`;

export const MarginBox = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const MarginBotton = styled.div`
  float: right;
  margin: -50px auto;
  color: ${Colors.primaryDarkBlue};
  display: flex;
  p {
    font-weight: bold;
    font-size: 14px;
  }
  @media screen and (max-width: ${Breakpoints.large}) {
    margin: -60px -30px;
  }
`;

export const TextTitle = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 10px 0px;

  p {
    font-weight: bold;
    font-size: 14px;
    color: ${Colors.primaryDarkBlue};
    margin: 0;
  }
`;

export const MessageText = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 !important;

  p {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: ${Colors.normalBlack};
    margin: 5px;
  }
`;

export const QuestionText = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 !important;

  p {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: ${Colors.normalBlack};
    margin: 0;
  }
`;

export const ArgumentText = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 10px;

  p {
    font-weight: border;
    font-size: 14px;
    text-align: center;
    color: ${Colors.normalBlack};
    margin: 0;
  }
`;

export const CardNetworkStyled = styled.div`
  
  margin: 20px 50px;
  float: right;
  align-content: center;
  align-items: center;

  p {
    display: grid;
    text-transform: capitalize;
    font-weight: bold;
    color: ${Colors.secondaryNormalGrey};
    font-size: 12px;
    text-align: center;
  }

  button {
    background-color: transparent;
    color: ${Colors.primaryDarkBlue}!important;
    border: 1px solid ${Colors.normalBlack};
    border-radius: 20px;
    padding: 5px 13px;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    margin: 10px 40px 20px 40px;
    padding: 12px;
  }
}
`;

export const Success = styled.div`
  position: absolute;
  right: 0;
`;

/* border-radius: 15px 15px 15px 15px; */
export const CircleIcons = styled.div`
  backgrounds: ${Colors.normalBlack};
  display: grid;
  place-content: center;
  height: 50px;
  width: 50px;
  border-radius: 40px 40px 40px 40px;
  margin: auto;
  position: relative;

  @media (min-width: ${Breakpoints.medium}) {
    background: ${Colors.normalBlack};
  }
  @media screen and (max-width: ${Breakpoints.large}) {
    background: ${Colors.normalBlack};
  }
  &.twitter-active {
    background: ${Colors.twitter};
  }
  &.facebook-active {
    background: ${Colors.facebook};
  }
  &.instagram-active {
    background: ${Colors.instagram};
  }
  &.linkedin-active {
    background: ${Colors.linkedin};
  }
`;

export const ButtonStyled = styled.div`
  button {
    text-align: center;
    margin: auto;
    margin-top: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
`;
