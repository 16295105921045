import React, { Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import GeneralDataBox from 'components/boxes/GeneralDataBox';
import Icon from 'components/icons/Icon';

import iconFlagCheck from 'images/icons/flag_check.svg';
import iconFlag from 'images/icons/flag.svg';

import { getCompanyMetrics } from 'services/rest/CompanyMetrics/CompanyMetrics';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { useFetch } from 'hooks/useFetch.hook';

import { nFormatter } from 'helpers/formatHelper';

const CampaignsSectionAdmin = () => {
  const { t } = useTranslation(['campaign_section']);
  const { currentUser } = useSelector(selectUser);
  const { token } = currentUser;
  const { data, loading } = useFetch(
    getCompanyMetrics({ token, typeFilter: 'all' })
  );
  const companyMetrics = data as any;

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const itemsFirstRow = [
    {
      icon: <img alt="iconFlagCheck" src={iconFlagCheck} />,
      value: nFormatter(companyMetrics?.activeCampaigns),
      text: t('campaign_section:campaigns_active')
    },
    {
      icon: <img alt="iconFlag" src={iconFlag} />,
      value: nFormatter(companyMetrics?.totalCampaigns),
      text: t('campaign_section:campaigns_created')
    },
    {
      icon: <Icon name="report" size="big" />,
      value: Math.ceil(companyMetrics?.averageParticipationPerCampaign),
      text: t('campaign_section:average_content')
    }
  ];
  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <GeneralDataBox items={itemsFirstRow} />
    </Suspense>
  );
};

export default CampaignsSectionAdmin;
