// eslint-disable-next-line @typescript-eslint/no-var-requires
const excel4node = require('excel4node');

const BOLD = {
  font: {
    bold: true
  }
};

export const createGenericTableReport = async (
  title: string,
  headerTable: string[],
  jsonData: any[]
) => {
  const wb = new excel4node.Workbook(title);
  const ws = wb.addWorksheet('Page 1');

  headerTable.map((field: string, index: number) => {
    ws.cell(1, index + 1, 1, index + 1, true)
      .string(field)
      .style(BOLD);
  });

  jsonData.map((data: any, row: number) => {
    Object.keys(data).map((key: string, columm: number) => {
      ws.cell(row + 2, columm + 1, row + 2, columm + 1, true).string(data[key]);
    });
  });

  const buffer = await wb.writeToBuffer();
  return buffer.toString('base64');
};
