import React, { Fragment, SyntheticEvent, useState } from 'react';

import {
  PositionColumn,
  Column,
  UserNameHeader,
  Row,
  Table,
  Th,
  SocialInfoStyled,
  Status
} from './StyledResumenTable';

import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import StatusIcon from 'components/icons/StatusIcon';
import EmployeeAvatar from 'images/avatars/employee.png';
import iconEyes from 'images/icons/eyes.svg';
import iconNoAction from 'images/icons/no_actions.svg';
import iconStar from 'images/icons/star.svg';
import eyeStrikethroughRed from 'images/icons/eyes_strikethrough.red.svg';
import programmingPost from 'images/icons/clock-programming.svg';
import Icon from 'components/icons/Icon';
import { useTranslation } from 'react-i18next';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';

export type Role = 'employee' | 'admin';

interface Employee {
  employeeId: number;
  position: number;
  points: number;
  photo: string;
  name: string;
  lastName: string;
}

export type Employees = Array<Employee>;

export interface RankingTableProps {
  currentEmployeeId?: number;
  employees: Employees;
  role: Role;
  badPracticesOnClickFunction?: any;
  includesFacebook?: boolean;
  includesTwitter?: boolean;
  includesLinkedin?: boolean;
  includesInstagram?: boolean;
  includesWhatsapp?: boolean;
  includesTiktok?: boolean;
}

const postURL = (socialNetwork: string, uid: string, twitterUserName: any) => {
  let url;
  switch (socialNetwork) {
    case 'facebook':
      url = `https://facebook.com/${uid}`;
      break;
    case 'twitter':
      url = `https://twitter.com/${twitterUserName}/status/${uid}`;
      break;
    case 'linkedin':
      url = `https://linkedin.com/feed/update/${uid}`;
      break;
    case 'instagram':
      url = uid;
      break;
    case 'tiktok':
      url = `https://tiktok.com/@tiktok/video/${uid}`;
      break;
    default:
      url = uid;
      break;
  }
  return url;
};

const social_info = (
  participations: any,
  socialNetwork: any,
  twitterUserName?: any
) => {
  return (
    <SocialInfoStyled className="contentPoints">
      {participations.length > 0 ? (
        <>
          {participations[0]?.networks[socialNetwork] ? (
            <>
              {participations[0]?.networks[socialNetwork]?.status ===
              'deleted' ? (
                <img alt="deletedIcon" src={eyeStrikethroughRed} />
              ) : (
                <>
                  {participations[0]?.networks[socialNetwork]?.status ===
                  'accepted' ? (
                    <img alt="acceptedIcon" src={iconNoAction} />
                  ) : (
                    <>
                      {participations[0]?.networks[socialNetwork]?.status ===
                      'programmed' ? (
                        <img alt="programmedIcon" src={programmingPost} />
                      ) : (
                        <>
                          {socialNetwork === 'whatsapp' ? (
                            <Fragment>
                              <img alt="iconEyes" src={iconEyes} />
                              <span>
                                <b>
                                  {participations[0]?.networks[socialNetwork]
                                    ?.totalPoints
                                    ? participations[0]?.networks[socialNetwork]
                                        ?.totalPoints
                                    : 0}
                                </b>
                                <img
                                  alt="iconStar"
                                  className="points"
                                  src={iconStar}
                                />
                              </span>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <a
                                href={postURL(
                                  socialNetwork,
                                  participations[0]?.networks[socialNetwork]
                                    ?.uid,
                                  twitterUserName
                                )}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img alt="iconEyes" src={iconEyes} />
                              </a>
                              <span>
                                <b>
                                  {participations[0]?.networks[socialNetwork]
                                    ?.totalPoints
                                    ? participations[0]?.networks[socialNetwork]
                                        ?.totalPoints
                                    : 0}
                                </b>
                                <img
                                  alt="iconStar"
                                  className="points"
                                  src={iconStar}
                                />
                              </span>
                            </Fragment>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <img alt="iconNoAction" src={iconNoAction} />
          )}
        </>
      ) : (
        <img alt="iconNoAction" src={iconNoAction} />
      )}
    </SocialInfoStyled>
  );
};

const total_points = (total_points: any) => {
  return (
    <SocialInfoStyled>
      <div className="totalPoints">
        <b>{total_points || 0}</b>
        <img alt="points" className="points" src={iconStar} />
      </div>
    </SocialInfoStyled>
  );
};

const ResumenTable: React.FC<RankingTableProps> = props => {
  const {
    employees,
    currentEmployeeId,
    includesFacebook = false,
    includesTwitter = false,
    includesLinkedin = false,
    includesInstagram = false,
    includesWhatsapp = false,
    includesTiktok = false
  } = props;

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  const [visible, setVisible] = useState({
    warningScope: false
  });
  const { t } = useTranslation([
    'admin_campaign_details',
    'campaigns_page',
    'group_page'
  ]);

  const changeTooltip = (tooltipType: string, state: boolean) => {
    const tooltipTypes = {
      ...visible,
      [tooltipType]: state
    };
    setVisible(tooltipTypes);
  };

  return (
    <Table className="notranslate">
      <thead>
        <Row>
          <Th />
          <UserNameHeader>{t('group_page:name')}</UserNameHeader>
          {includesFacebook && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'facebook'} size="medium" />
            </UserNameHeader>
          )}
          {includesTwitter && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'twitter'} size="medium" />
            </UserNameHeader>
          )}
          {includesLinkedin && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'linkedin'} size="medium" />
            </UserNameHeader>
          )}
          {includesInstagram && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'instagram'} size="medium" />
            </UserNameHeader>
          )}
          {includesWhatsapp && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'whatsapp'} size="medium" />
            </UserNameHeader>
          )}
          {includesTiktok && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'tiktok'} size="medium" />
            </UserNameHeader>
          )}
          <UserNameHeader className="center">
            {t('campaigns_page:total_points')}
          </UserNameHeader>
        </Row>
      </thead>
      <tbody>
        {employees.map((employee: any, index) => {
          const isCurrent = currentEmployeeId === employee.employeeId;
          const showBadPractices: boolean =
            props.role == 'admin' &&
            employee.participations[0]?.networks?.facebook
              ?.suspiciousBadPractice &&
            employee.participations[0]?.networks?.facebook
              ?.badPracticeDiscarded == false;
          return (
            <Row key={index} activeRow={isCurrent}>
              <PositionColumn
                collapsed
                style={{ columns: showBadPractices ? 2 : 1 }}
              >
                <div className="id">
                  <img
                    src={employee.photo || EmployeeAvatar}
                    className="user-photo"
                    alt="User"
                    onError={evt => addDefaultPicture(evt)}
                  />
                </div>
                {employee.registered === true && (
                  <Status>
                    <StatusIcon variant={'verified'} />
                  </Status>
                )}
                {employee.deleted == true && (
                  <Status>
                    <StatusIcon variant={'danger'} />
                  </Status>
                )}

                {showBadPractices && (
                  <div style={{ display: 'inline-flex' }}>
                    <div className="centro">
                      <StyledTooltipWrapper
                        visible={visible?.warningScope}
                        onMouseOver={() => changeTooltip('warningScope', true)}
                        onMouseOut={() => changeTooltip('warningScope', false)}
                      >
                        <Icon name={'warning'} size="medium"></Icon>
                        <TooltipMessage>
                          {t('admin_campaign_details:warning_bad_practices')}
                        </TooltipMessage>
                      </StyledTooltipWrapper>
                      <div>
                        <Icon
                          onClick={() => {
                            if (props.badPracticesOnClickFunction) {
                              props.badPracticesOnClickFunction(
                                employee.participations[0].id
                              );
                            }
                          }}
                          name={'error-signal'}
                          size="medium"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </PositionColumn>
              <Column>
                {employee.name} {employee.lastName}
              </Column>
              {includesFacebook && (
                <Column className="center">
                  {social_info(employee.participations, 'facebook')}
                </Column>
              )}
              {includesTwitter && (
                <Column className="center">
                  {social_info(
                    employee.participations,
                    'twitter',
                    employee.social_network?.networks?.twitter?.userName
                  )}
                </Column>
              )}
              {includesLinkedin && (
                <Column className="center">
                  {social_info(employee.participations, 'linkedin')}
                </Column>
              )}
              {includesInstagram && (
                <Column className="center">
                  {social_info(employee.participations, 'instagram')}
                </Column>
              )}
              {includesWhatsapp && (
                <Column className="center">
                  {social_info(employee.participations, 'whatsapp')}
                </Column>
              )}
              {includesTiktok && (
                <Column className="center">
                  {social_info(employee.participations, 'tiktok')}
                </Column>
              )}
              <Column className="center">
                {total_points(employee.participations[0]?.totalPoints)}
              </Column>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ResumenTable;
