import axios from 'axios';
import { store } from 'redux/store';
import { expiredSession } from 'redux/slices/userSlice';

const axiosClient = () =>
  axios.create({
    baseURL: process.env.REACT_APP_HOSTNAME,
    timeout: 30000,
    headers: {
      'Content-type':
        'application/json; application/x-www-form-urlencoded; charset=UTF-8'
    }
  });

const post = async (url: string, data: any, config?: any) => {
  try {
    const resp = await axiosClient().post(url, data, config);
    return resp;
  } catch (e: any) {
    isCredentialsExpired(e);
    throw e;
  }
};

const get = async (url: string, config: any) => {
  try {
    return await axiosClient().get(url, config);
  } catch (e: any) {
    isCredentialsExpired(e);
    throw e;
  }
};

const deleteMethod = async (url: string, config?: any) => {
  try {
    return axiosClient().delete(url, config);
  } catch (e: any) {
    isCredentialsExpired(e);
    throw e;
  }
};

const put = async (url: string) => axiosClient().put(url);

const isCredentialsExpired = (e: any) => {
  if (e.toString() === 'Error: Request failed with status code 401') {
    localStorage.clear();
    store.dispatch(expiredSession());
  }
};

const Client = {
  get,
  post,
  delete: deleteMethod,
  put
};

export default Client;
