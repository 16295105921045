import styled from 'styled-components';

export const StyledSpinner = styled.div`
  p {
    font-size: 0.875rem;
    text-align: center;
    margin: 0 0 5px 0;
    font-weight: 500;
  }
  .container-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid #3498db;
      width: 50px;
      height: 50px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;

      &.small {
        width: 30px;
        height: 30px;
      }

      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`;
