import React, { useEffect, useState } from 'react';
import PostCard from './PostCard';
import Tag from 'components/Tag';
import {
  VideoHeaderWrapper,
  FacebookWrapper,
  FacebookContents,
  EmbedLkHeaderWrapper
} from './Style';

import { SocialNetworkCampaign } from 'types/appTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUser,
  selectUserInfo,
  setUserInfo
} from 'redux/slices/userSlice';
import { getInfoUser } from 'services/rest/logout';
import Icon from 'components/icons/Icon';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

export type { PostCardProps } from './PostCard';
export * from './mobileCard';

const ImageHeader: React.FC<SocialNetworkCampaign> = ({ provider }) => (
  <div>
    <Tag network={provider} variant="network" />
  </div>
);

const VideoHeader: React.FC<SocialNetworkCampaign> = props => {
  const { provider, videoFileUrl } = props;

  return (
    <VideoHeaderWrapper>
      <Tag network={provider} variant="network" />
      <video controls src={videoFileUrl}></video>
    </VideoHeaderWrapper>
  );
};

const EmbedLkHeader: React.FC<SocialNetworkCampaign> = props => {
  const { provider, contentType, pdfUrl, urnLi } = props;

  const open = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const url =
    contentType == 'share'
      ? `https://www.linkedin.com/embed/feed/update/${urnLi}`
      : pdfUrl;

  return (
    <EmbedLkHeaderWrapper>
      <Tag network={provider} variant="network" />
      <Icon
        className="open"
        size="small"
        name="eye"
        onClick={() => open(url)}
        color="#0077b5"
      />
      {contentType == 'pdf' ? (
        <object
          data={`${url}#toolbar=0&navpanes=0`}
          type="application/pdf"
          width="100%"
          height="300px"
        ></object>
      ) : (
        <object data={`${url}`} width="100%" height="300px"></object>
      )}
    </EmbedLkHeaderWrapper>
  );
};

const FacebookIframe = ({ url }: { url: string }) => {
  return (
    <FacebookWrapper className="fb-post" data-width="auto">
      <iframe
        title="FacebookIframe"
        src={url}
        width="500"
        height="450"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </FacebookWrapper>
  );
};

const CustomFacebookView = ({
  name,
  photo,
  data
}: {
  name: string;
  photo: string;
  data: any;
}) => {
  return (
    <FacebookContents>
      {data.map((post: any, index: number) => (
        <a
          href={post?.permalinkUrl}
          target="_blank"
          rel="noreferrer"
          key={index}
        >
          <div className="fb-post">
            <div className="header">
              <img alt="userPhoto" className="img-user" src={photo}></img>
              <div className="texts">
                <p className="username">{name}</p>
                <p className="message">
                  {post?.message && post?.message.length > 50
                    ? `${post?.message.slice(0, 50)}...`
                    : post?.message}
                </p>
              </div>
            </div>
            <img alt="imgPost" className="img-post" src={post?.url}></img>
          </div>
        </a>
      ))}
    </FacebookContents>
  );
};

const FacebookHeader: React.FC<SocialNetworkCampaign> = ({
  provider,
  content,
  userPosts = undefined
}) => {
  const { company } = useSelector(selectCompany);
  const { infoUser } = useSelector(selectUserInfo);
  const { currentUser } = useSelector(selectUser);
  const [userData, setUserData] = useState<any>(infoUser);
  const dispatch = useDispatch();

  const iframeLink = [
    `https://www.facebook.com/plugins/post.php?href=${content}`
  ];

  const getUserData = async () => {
    const { data } = await getInfoUser(
      currentUser?.user?.id,
      currentUser?.token
    );
    if (data) {
      setUserData(data);
      dispatch(setUserInfo(data));
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <Tag network={provider} variant="network" />
      {!company?.company_permission?.allowTest || userPosts === undefined ? (
        <div className="fb-iframes">
          {iframeLink.map((link: string, index: number) => (
            <FacebookIframe url={link} key={index}></FacebookIframe>
          ))}
        </div>
      ) : (
        <CustomFacebookView
          name={userPosts.username}
          photo={userData.photo}
          data={userPosts.posts}
        ></CustomFacebookView>
      )}
    </div>
  );
};

export const ImageCard: React.FC<SocialNetworkCampaign> = snc => (
  <PostCard
    getRankingData={snc}
    socialNetworkCampaign={snc}
    header={<ImageHeader {...snc} />}
    campaign={snc.campaign}
  />
);

export const VideoCard: React.FC<SocialNetworkCampaign> = snc => (
  <PostCard
    getRankingData={snc}
    socialNetworkCampaign={snc}
    header={<VideoHeader {...snc} />}
    campaign={snc.campaign}
  />
);

export const EmbedLkCard: React.FC<SocialNetworkCampaign> = snc => (
  <PostCard
    getRankingData={snc}
    socialNetworkCampaign={snc}
    header={<EmbedLkHeader {...snc} />}
    campaign={snc.campaign}
  />
);

export const FacebookCard: React.FC<SocialNetworkCampaign> = snc => {
  return (
    <PostCard
      getRankingData={snc}
      socialNetworkCampaign={snc}
      header={<FacebookHeader {...snc} />}
      campaign={snc.campaign}
    />
  );
};
