import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { RequiredString, String } from 'helpers/yupHelper';
import UpdateAdminForm from './UpdateAdminForm';
import { useHistory, useParams } from 'react-router-dom';
import { getCompanies } from 'services/rest/SuperAdmin/Company/getCompanies';
import { getAdministratorById } from 'services/rest/SuperAdmin/Administrator/getAdministratorById';
import { AdminData, updateAdmin } from 'services/rest/Admin/updateAdmin';
import { ErrorAlert } from 'components/Alert';

const UpdateAdmin: React.FC = () => {
  const { t } = useTranslation(['super_admin_administrator', 'validations']);
  const userSchema = yup.object().shape({
    name: RequiredString().max(70, t('validations:length_error', { max: 70 })),
    email: RequiredString().max(
      100,
      t('validations:length_error', { max: 100 })
    ),
    password: String()
      .nullable()
      .transform((o, c) => (o === '' ? null : c))
      .min(5, 'login_form.password_min_length')
      .max(40, 'login_form.password_max_length')
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(userSchema)
  });
  const { currentUser } = useSelector(selectUser);
  const { token, role } = currentUser;
  const [companies, setCompanies] = useState<Array<any>>([]);
  const [loader, setLoader] = useState(false);
  const { adminId } = useParams<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [admin, setAdmin] = useState<any>();
  const [error, setError] = useState<string>('');
  const history = useHistory();

  const onSubmit = async (adminData: AdminData) => {
    adminData.role = role;
    setLoader(true);
    const { data, errors } = await updateAdmin(adminId, token, adminData);
    if (data) {
      setLoader(false);
      if (data?.errors) {
        setError(data.errors?.data);
      } else {
        history.push(
          '/administrators',
          t('super_admin_administrator:update_success')
        );
      }
    }
    if (errors) {
      setError(errors?.data);
      setLoader(false);
    }
  };

  const getAdminData = async () => {
    const { data: adminData } = await getAdministratorById(token, adminId);
    const { data: companiesData } = await getCompanies(token, 500, 1);

    if (adminData && companiesData) {
      setAdmin(adminData);
      setCompanies(companiesData);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdminData();
  }, []);
  return (
    <>
      {error && (
        <ErrorAlert
          text={error}
          allowClose={true}
          onClose={() => setError('')}
        />
      )}
      <UpdateAdminForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        admin={admin}
        errors={errors}
        setValue={setValue}
        control={control}
        loader={loader}
        companies={companies}
        loading={loading}
      />
    </>
  );
};

export default UpdateAdmin;
