import React, { Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import iconPerson from 'images/icons/person.svg';

import GeneralDataBox from 'components/boxes/GeneralDataBox';
import Icon from 'components/icons/Icon';

import { getCompanyMetrics } from 'services/rest/CompanyMetrics/CompanyMetrics';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { nFormatter } from 'helpers/formatHelper';
import { useFetch } from 'hooks/useFetch.hook';

const GroupSection = () => {
  const { t } = useTranslation(['group_section']);
  const { currentUser } = useSelector(selectUser);
  const { token } = currentUser;
  const { data, loading } = useFetch(
    getCompanyMetrics({ token, typeFilter: 'all' })
  );
  const companyMetrics = data as any;

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const itemsFirstRow = [
    {
      icon: <Icon name="group" size="big" />,
      value: nFormatter(companyMetrics?.totalGroups),
      text: t('group_section:total_groups')
    },
    {
      icon: <img alt="iconPerson" src={iconPerson} />,
      value: nFormatter(companyMetrics?.totalAmbassadors),
      text: t('group_section:total_ambassadors')
    },
    {
      icon: <Icon name="report" size="big" />,
      value: nFormatter(companyMetrics?.ambassadorsParticipating),
      text: t('group_section:ambassadors_generating_content')
    }
  ];
  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <GeneralDataBox items={itemsFirstRow} />
    </Suspense>
  );
};
export default GroupSection;
