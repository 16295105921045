import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const BadgetSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const IconSection = styled.div`
  img {
    width: 30px;
  }
`;
export const TextSection = styled.div`
  h3 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #4d4d4d;
  }
  p {
    text-align: center;
    font-size: 14px;
  }
`;
export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    font-size: 15px;
    margin: 10px;
  }
`;

export const FormSection = styled.div``;
export const Form = styled.form``;
export const FormWrapper = styled.div``;
export const InputWrapper = styled.div`
  display: grid;
  align-items: baseline;
  grid-template-columns: repeat(12, 1fr);

  .checkbox-parent {
    position: relative;
  }

  .bonus-checkbox {
    display: flex;
    align-items: center;
  }

  .checkbox-child {
    float: left;
  }

  label {
    color: #4d4d4d;
    font-size: 1rem;
    font-weight: 600;
    grid-column: span 3;
  }

  input {
    margin: 10px;
  }

  .w-100 {
    width: 100%;
    grid-column: span 9;
  }
`;
