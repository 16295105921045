import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TablePagination from '@mui/material/TablePagination';
import { EmptyCard } from './Styled';
import EmptyCampaign from 'images/empty_states/empty_campaigns.png';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import {
  FilterChallenges,
  HeardMargin,
  StyleTable,
  TextStyled
} from '../Styled';
import Input from 'components/inputs/Input';
import ChallengeAmbassadorCard from './ChallengeAmbassadorCard';
import iconEye from 'images/icons/eyes.svg';
import ChallengeCard from './ChallengeCard';
import { IChallenge } from 'redux/slices/Challenges/Challenges.interface';
import Carousel from 'components/carousel/Carousel';
import VerticalBox from 'components/boxes/VerticalBox';

interface AmbassadorChallengesProps {
  challenges: any;
  timeLine: any;
}

const AmbassadorChallenges: React.FC<AmbassadorChallengesProps> = props => {
  const { challenges, timeLine } = props;
  const { t } = useTranslation([
    'campaign_card',
    'challenge',
    'campaign_section'
  ]);
  const statusOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: t('challenge:pending'), value: 'pending' },
    { label: t('challenge:activated'), value: 'activated' },
    { label: t('challenge:completed'), value: 'completed' }
  ];
  const socialOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Twitter', value: 'twitter' },
    { label: 'Linkedin', value: 'linkedin' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Tiktok', value: 'tiktok' },
    { label: 'Snapchat', value: 'snapchat' },
    { label: 'Youtube', value: 'youtube' },
    { label: 'Threads', value: 'threads' },
    { label: t('campaign_section:others'), value: 'others' }
  ];
  const [filterText, setFilterText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedSocial, setSelectedSocial] = useState(socialOptions[0]);
  const [filterOption, setFilterOption] = useState(challenges);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const getFilterSocial = (filter: any, value: string) => {
    return filter.filter((challenge: any) => {
      return challenge[value];
    });
  };

  const getFilterStatus = (filter: any, value: string) => {
    return filter.filter((challenge: any) => challenge.status === value);
  };

  const selectStatusOption = (event: any) => {
    let filter = challenges;
    const filterStatus = event.value.toLowerCase();

    if (filterStatus !== 'all') {
      filter = getFilterStatus(filter, filterStatus);
    }
    if (selectedSocial.value.toLowerCase() !== 'all') {
      filter = getFilterSocial(filter, selectedSocial.value.toLowerCase());
    }
    setFilterOption(filter);
    setSelectedStatus(event);
  };

  const selectSocialOption = (event: any) => {
    let filter = challenges;
    const filterSocial = event.value.toLowerCase();

    if (filterSocial !== 'all') {
      filter = getFilterSocial(filter, filterSocial);
    }
    if (selectedStatus.value.toLowerCase() !== 'all') {
      filter = getFilterStatus(filter, selectedStatus.value.toLowerCase());
    }
    setFilterOption(filter);
    setSelectedSocial(event);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const newChallenges = challenges.filter((data: any) => {
    const challenge = data;
    const challengeName = challenge.name.toLowerCase();
    const challengeContent = challenge.content.toLowerCase();
    const result =
      challengeName.indexOf(filterText.toLowerCase()) != -1 ||
      challengeContent.indexOf(filterText.toLowerCase()) != -1;

    return result;
  });

  const currentChallenges = filterText ? newChallenges : filterOption;
  const currentPath = window.location.pathname;

  const challengeCards = () => {
    const activatedPendingChallenges = currentChallenges?.filter(
      (challenge: any) =>
        challenge.status === 'activated' || challenge.status === 'pending'
    );

    const sortByStatus = activatedPendingChallenges.sort((a: any, b: any) => {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });

    if (sortByStatus?.length > 0) {
      return sortByStatus.map((challenge: IChallenge, index: number) => (
        <ChallengeCard {...challenge} key={index} />
      ));
    }

    return [
      <EmptyCard key={1}>
        <VerticalBox
          className="empty_state"
          header={<img alt="emptyIcon" src={EmptyCampaign} />}
        >
          <h2>{t('campaign_section:empty_state_subtitle')}</h2>
          <p>{t('campaign_section:empty_state_challenge_description')}</p>
        </VerticalBox>
      </EmptyCard>
    ];
  };

  return (
    <StyleTable>
      <FilterChallenges>
        {currentPath != '/dashboard' ? (
          <>
            <Input
              type="search"
              placeholder={t('challenge:challenge_filter')}
              value={filterText}
              onChange={e => {
                setFilterText(e.target.value);
              }}
            />
            <span>{t('campaign_card:filter')}</span>
            <DropdownSelect
              options={statusOptions}
              value={selectedStatus}
              onSelect={selectStatusOption}
            />
            <DropdownSelect
              options={socialOptions}
              value={selectedSocial}
              onSelect={selectSocialOption}
            />
          </>
        ) : (
          <>
            <span>{t('campaign_card:filter')}</span>
            <DropdownSelect
              options={socialOptions}
              value={selectedSocial}
              onSelect={selectSocialOption}
            />
          </>
        )}
      </FilterChallenges>
      {currentPath != '/dashboard' ? (
        currentChallenges.length > 0 ? (
          <>
            <HeardMargin>
              <div className="option">
                <img alt="eyeIcon" src={iconEye} />
                <TextStyled>{t('challenge:view_challenge')}</TextStyled>
              </div>
            </HeardMargin>
            {(rowsPerPage > 0
              ? currentChallenges.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : currentChallenges
            ).map((challenge: any, index: number) => {
              const row = challenge as any;
              return (
                <ChallengeAmbassadorCard
                  key={index}
                  challenge={row}
                  timeLine={timeLine}
                />
              );
            })}

            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={currentChallenges.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <Carousel
            items={challengeCards()}
            startIndex={0}
            className="campaign-ambassador"
          />
        )
      ) : (
        <Carousel
          items={challengeCards()}
          startIndex={0}
          className="campaign-ambassador"
        />
      )}
    </StyleTable>
  );
};

export default AmbassadorChallenges;
