import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledCalendarInput, {
  StyledCalendarInputProps
} from './StyledCalendarInput';

import { AvailableLocales, formatDateTime } from 'helpers/dateHelpers';

interface CalendarInputProps extends StyledCalendarInputProps {
  locale: AvailableLocales;
  dates: Date[];
  onClick: (event: React.MouseEvent) => void;
  disabled?: boolean;
}

const CalendarInput: React.FC<CalendarInputProps> = props => {
  const { locale, dates, onClick, disabled, ...otherProps } = props;
  const formatWeekDay = formatDateTime(locale, 'weekday');
  const formatMonth = formatDateTime(locale, 'month');
  const { t } = useTranslation(['calendar']);

  const customLabel = (date: Date) =>
    `${formatWeekDay(date, 'long')}, ${date.getDate()} ${formatMonth(
      date,
      'short'
    )}`;

  const customInputLabel = dates[0]
    ? `${customLabel(dates[0])} - ${customLabel(dates[1])}`
    : `${t('calendar:begin_date')} - ${t('calendar:end_date')}`;

  return (
    <StyledCalendarInput {...otherProps} onClick={onClick} disabled={disabled}>
      <label>{customInputLabel}</label>
    </StyledCalendarInput>
  );
};

export default CalendarInput;
