import styled from 'styled-components';
import BaseBox from 'components/boxes/BaseBox';
import { Breakpoints, Colors } from 'styles/Constants';
import { StyledNetworkParticipationBox } from 'components/boxes/NetworkParticipationBox';
import StyledCalendarInput from 'components/Calendar/CalendarInput/StyledCalendarInput';
import CalendarWrapper from 'components/Calendar/StyledCalendar';

export const Card = styled.div`
  margin: 0 5px 5px;

  p {
    width: auto;
    height: 80px;
  }
`;

export const EmptyCard = styled.div`
  margin: 0 10px 10px;
  width: 100%;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.buttonCreateBonus {
    justify-content: space-between;
  }

  ${StyledCalendarInput} {
    border: none;
    border-bottom: 0.85px solid ${Colors.inactive};
    border-radius: 0;
    max-width: 300px;

    &:hover,
    &:focus-within {
      border-bottom: 0.85px solid ${Colors.primaryDarkBlue};
    }

    label {
      color: ${Colors.primaryDarkBlue};
      text-transform: uppercase;
    }
  }

  ${CalendarWrapper} {
    padding: 0 10px 12px;
  }
`;

export const MarginImg = styled.div`
  img {
    width: 80%;
    display: block;
    margin: 0px auto;
    min-width: 350px;
    min-height: 350px;
    max-width: 350px;
    max-height: 350px;
    object-fit: contain;
  }
`;

export const MobileWrapper = styled.div`
  ${BaseBox};

  text-align: left;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;

  > div {
    border-bottom: 2px solid #d8d8d8;
    margin-bottom: 20px;

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;

      p,
      svg {
        margin-bottom: 0;
      }
    }
  }

  ${StyledNetworkParticipationBox} {
    padding-bottom: 15px;

    .button-collection {
      justify-content: flex-start;
    }

    h3 {
      text-align: left;
      width: 100%;
    }

    > * {
      margin-left: 0;
    }
  }
`;

export const SocialButtton = styled.button`
  background: none;
  border: none;
  zoom: 150%;
  filter: grayscale(1);
  cursor: pointer;
  &.selected {
    filter: grayscale(0);
    zoom: 200%;
  }
`;

export const PostDetailWrapper = styled.div`
  p.small-paragraph {
    font-size: 0.875rem;
    text-align: center;
    margin: 0 0 5px 0;
    font-weight: 500;
  }

  a {
    width: 90%;
  }
`;
