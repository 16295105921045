export const nFormatter = (num: any) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'Q' }
  ];
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    }) as any;

  const numberFormat = (num / item?.value)
    .toFixed(3)
    .replace(regex, '$1') as any;

  return item
    ? numberFormat.match(/^-?\d+(?:\.\d{0,2})?/)[0] + item.symbol
    : '0';
};
