import React from 'react';

import DescriptionBox from 'components/boxes/DescriptionBox';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import { useTranslation } from 'react-i18next';

const FrequentQuestions = () => {
  const { t } = useTranslation(['faq']);
  const questionsData = t('faq:questions', {
    returnObjects: true,
    defaultValue: []
  });

  return (
    <SectionWrapper>
      <NavigationLink text={t('faq:back')} direction="back" to="/dashboard" />
      <h1 className="section-title">{t('faq:title')}</h1>
      {Array.isArray(questionsData) &&
        questionsData.map((element, index) => {
          return (
            <DescriptionBox
              title={element['question']}
              textDescription={element['answer']}
              dataOneList={element['data_one_list']}
              dataTwoList={element['data_two_list']}
              dataThreeList={element['data_three_list']}
              dataFourthList={element['data_fourth_list']}
              dataFifthList={element['data_fifth_list']}
              toggle={true}
              key={index}
            />
          );
        })}
    </SectionWrapper>
  );
};

export default FrequentQuestions;
