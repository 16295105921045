import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

import Header from 'images/backgrounds/header.png';
import HeaderSecondary from 'images/backgrounds/header_secondary.png';

import BodyWrapper from 'pages/Layout/StyledLayout';

export const UserHeader = styled.header`
  margin-bottom: 30px;

  @media (min-width: ${Breakpoints.large}) {
    margin-bottom: 60px;
    padding: 0 60px;
    display: flex;
  }

  @media (min-width: ${Breakpoints.xLarge}) {
    padding: 0 130px;
  }
`;

export const UserInfo = styled.div`
  background-image: linear-gradient(132deg, #3e65fe, #3ebbfe);
  color: ${Colors.normalWhite};

  align-items: center;
  display: flex;
  grid-template-columns: repeat(12, 1fr);
  padding: 20px;

  @media (min-width: ${Breakpoints.large}) {
    display: grid;
  }

  ${BodyWrapper} & {
    @media (max-width: ${Breakpoints.medium}) {
      padding-top: 80px;
    }
  }

  img {
    border: 10px solid #fff;
    background-color: #fff;
    border-radius: 100px;
    height: 70px;
    width: 70px;

    @media (min-width: ${Breakpoints.large}) {
      margin-left: 10px;
      height: 140px;
      width: 140px;
    }
  }

  div.texts {
    padding-left: 25px;

    p {
      font-weight: 400;
    }
  }

  h1 {
    max-width: 220px;
    font-weight: bold;
    font-size: 20px;
    margin: 0;

    span {
      font-weight: 400;
    }

    @media (min-width: ${Breakpoints.large}) {
      max-width: 160px;
      font-size: 25px;
    }
  }

  p {
    @media (min-width: ${Breakpoints.large}) {
      display: none;
    }
  }

  @media (min-width: ${Breakpoints.large}) {
    background-image: url(${Header});
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    width: 40%;
  }
`;

export const AdminInfo = styled.div`
  background-image: linear-gradient(215deg, #3e65fe, #3ebbfe);
  color: ${Colors.normalWhite};

  align-items: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 20px;

  ${BodyWrapper} & {
    @media (max-width: ${Breakpoints.medium}) {
      padding-top: 80px;
    }
  }

  img {
    border: 10px solid #fff;
    background-color: #fff;
    border-radius: 100px;
    height: 70px;
    width: 70px;

    @media (min-width: ${Breakpoints.large}) {
      margin-left: 10px;
      height: 140px;
      width: 140px;
    }
  }

  div.texts {
    padding-left: 25px;

    p {
      font-weight: 400;
    }
  }

  h1 {
    max-width: 220px;
    font-weight: bold;
    font-size: 20px;
    margin: 0;

    span {
      font-weight: 400;
    }

    @media (min-width: ${Breakpoints.large}) {
      max-width: 160px;
      font-size: 25px;
    }
  }

  p {
    @media (min-width: ${Breakpoints.large}) {
      display: none;
    }
  }

  button {
    margin-left: 30%;
    width: 30%;
    border: 1px solid #d113fe;
    background-color: #d113fe;
  }

  @media (min-width: ${Breakpoints.large}) {
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    width: 100%;
  }
`;

export const UserDataContainer = styled.div`
  grid-column: span 2;
  display: flex;
  align-items: center;
`;

export const AdminButton = styled.div`
  grid-column: span 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-left: 0;
  }
`;

export const UserDescription = styled.div`
  @media (min-width: ${Breakpoints.large}) {
    background-image: url(${HeaderSecondary});
    background-position: 0% 50%;
    background-repeat: no-repeat;
    width: calc(60% + 40px);
    background-size: cover;
    margin-left: -40px;
    padding: 12px;

    .data-items {
      margin-left: 10%;
      width: 90%;
    }
  }

  h2 {
    color: ${Colors.primaryDarkBlue};
    font-weight: 600;
    font-size: 12px;
    margin: 0;

    @media (min-width: ${Breakpoints.large}) {
      font-size: 20px;
    }
  }

  p {
    color: ${Colors.normalBlack};
    font-weight: 600;
    font-size: 38px;
    margin: 0;
  }

  div.data {
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 20px;
    display: none;

    @media (min-width: ${Breakpoints.large}) {
      display: flex;
    }
  }
`;

export const NetworksCard = styled.div`
  background-color: #f4f9ff;
  justify-content: center;
  align-items: center;
  display: flex;

  > *,
  > h2 {
    margin: 10px;
  }

  @media (min-width: ${Breakpoints.large}) {
    background-color: transparent;
  }

  @media (max-width: ${Breakpoints.medium}) {
    display: grid;
    grid-template-columns: repeat(4, 20%);

    h2 {
      grid-column: span 4;
    }
  }

  .connect-account {
    padding-bottom: 5px;
  }
`;

export const ButtonMargin = styled.div`
  button {
    border: 1px solid #d113fe;
    background-color: #d113fe;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 2px;
    width: 60%;
    margin-bottom: 10%;
  }
`;
