import React, { useEffect, useState, Suspense } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Facebook as LoadingState } from 'react-content-loader';

import { selectUser } from 'redux/slices/userSlice';

import EditAwardForm, { userSchema } from './EditAwardForm';

import { awsUploadFileHelper } from 'helpers/awsFileHelper';
import { generateRandAlphaNumStr } from 'Utils/StringRandom';
import { getUrlS3 } from 'constants/setup.constants';

import { ErrorAlert } from 'components/Alert';
import { updateAward, AwardData } from 'services/rest/Award/updateAward';
import { getAwardById } from 'services/rest/Award/getAwardById';
import { IAward } from 'redux/slices/Award/Award.interface';

interface AwardParams {
  awardId: string;
}

const EditAwardPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['awards_section', 'validations']);

  const { register, handleSubmit, errors } = useForm<AwardData>({
    resolver: yupResolver(userSchema)
  });

  const { awardId } = useParams<AwardParams>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [backendError, setBackendError] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState<Array<any>>([]);
  const [groupsIds, setGroupsIds] = useState([]);
  const [awardData, setAwardData] = useState<IAward>();
  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [editImage, setEditImage] = useState(false);

  const onSubmit = async (awardData: AwardData) => {
    setLoader(true);
    const award = awardData as any;
    const { startDate, finishDate } = setDates(award);
    if (editImage) {
      const namePhoto = generateRandAlphaNumStr();
      await awsUploadFileHelper(
        currentUser?.token,
        image,
        'AWARD',
        namePhoto,
        true,
        setBackendError,
        t('validations:file_error')
      );
      award.photoUrl = getUrlS3('awards', namePhoto);
    }
    award.companyId = currentUser.companyId;
    award.groupsIds = groupsIds;
    award.startDate = startDate;
    award.finishDate = finishDate;
    const { data, errors } = await updateAward(
      currentUser?.token,
      award,
      awardId
    );
    if (data) {
      setLoader(false);
      history.push('/admin/award', t('awards_section:edited_award'));
    }
    if (errors) {
      setBackendError(errors.data);
      setLoader(false);
    }
  };

  const setDates = (award: any) => {
    const dates = {} as any;
    const quarters = [
      [1, 3],
      [4, 6],
      [7, 9],
      [10, 12]
    ];
    switch (award.periodType) {
      case 'month':
        dates.startDate = new Date(
          new Date().getFullYear(),
          award.periodValue - 1
        );
        dates.finishDate = new Date(
          new Date().getFullYear(),
          award.periodValue,
          0
        );
        return dates;
      case 'quarter':
        dates.startDate = new Date(
          new Date().getFullYear(),
          quarters[award.periodValue - 1][0]
        );
        dates.finishDate = new Date(
          new Date().getFullYear(),
          quarters[award.periodValue - 1][1],
          0
        );
        return dates;
      case 'year':
        dates.startDate = new Date(award.periodValue, 0);
        dates.finishDate = new Date(award.periodValue, 12, 0);
        return dates;
      case 'custom':
        dates.startDate = startDate;
        dates.finishDate = endDate;
        return dates;
      default:
        return dates;
    }
  };

  const getData = async () => {
    await getAwardById(currentUser?.token, Number(awardId))
      .then(result => {
        setAwardData(result.data.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  const handleImagen = (select: Array<any>) => {
    if (select.length > 0) {
      setImage(select);
      setEditImage(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <EditAwardForm
          awardData={awardData}
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={errors}
          handleImage={handleImagen}
          setGroupsIds={setGroupsIds}
          loader={loader}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
        />
      </Suspense>
    </>
  );
};

export default EditAwardPage;
