import styled from 'styled-components';

export const FooterTable = styled.div`
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    background: #185adb;
    border: none;
    color: #ffffff;
    margin: 5px;
    padding: 5px 20px;
    border-radius: 20px;
    cursor: pointer;
  }
`;
