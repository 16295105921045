import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AwardPageAdmin from 'pages/AwardPageAdmin';
import NewAwardPage from 'pages/AwardPageAdmin/NewAwardPage';

const AwardAdminRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/newAward`}>
        <NewAwardPage />
      </Route>
      <Route path={`${match.path}`}>
        <AwardPageAdmin />
      </Route>
    </Switch>
  );
};

export default AwardAdminRouter;
