import { IPerformanceSection } from './PerformanceSection.interface';

export const initialState: IPerformanceSection = {
  ranking: { position: 0, totalPoints: 0 },
  postsByNetwork: { facebook: 0, linkedin: 0, twitter: 0, instagram: 0 },
  potentialReach: 0,
  totalPublishedPosts: 0,
  totalParticipatedCampaigns: 0,
  totalInvitedCampaigns: 0,
  monthlyRanking: { position: 0, totalPoints: 0 }
};
