import styled from 'styled-components';

import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export const GridWrapper = styled.div`
  @media only screen and (max-width: 900px) {
    width: 120%;
  }

  ${BaseBox};

  @media (min-width: ${Breakpoints.medium}) {
    height: 150px;
  }

  .content-img {
    width: 150px;
    height: 150px;
    min-height: inherit !important;
    display: flex !important;
    align-items: center !important;
  }

  img {
    width: 100%;
    height: inherit;
    object-fit: contain;
  }

  display: grid;
  grid-template-columns: 200px 10fr 2fr;

  .time-line {
    margin: 0 0 5px 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 14px;
    color: ${Colors.primaryNormalPurple};
  }
`;

export const MarginText = styled.div`
  padding: 5px;
  font-weight: 500;
  font-size: 14px;

  &.m-auto{
    margin: auto 0px;
  }

  div {
    h2 {
      font-weight: bold;
      position: relative;
      font-size: 18px;
      margin: 0;
    }
  }
  .overflow-elipsis{
    height: 100%;
    h2{
      height: 50px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
`;

export const DivIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    width: 25px;
    margin: 5px;
  }
`;
