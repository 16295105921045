import styled from 'styled-components';
import { Colors } from 'styles/Constants';

export const StyledErrorReport = styled.div`
  label {
    display: flex;
    color: #4d4d4d;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
  .grid-block {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    p {
      font-size: 85%;
    }
    .button-download-container {
      display: flex;
      align-items: center;
      a {
        border: 1px solid #d113fe;
        background-color: #d113fe;
        color: #ffffff;
        text-decoration: none;
        font-weight: 700;
        outline: none;
        padding: 6px 40px;
        border-radius: 30px;
      }
    }
  }
`;
