import React, { useEffect, useState } from 'react';

import Icon from 'components/icons/Icon';
import Input from 'components/inputs/Input';
import add_blue from 'images/icons/add_blue.svg';
import { useTranslation } from 'react-i18next';
import { generateRandAlphaNumStr } from 'Utils/StringRandom';
import LinearProgress from 'components/LinearProgress/LinearProgress';

import {
  ErrorSpan,
  MarginOption,
  MarginPhoto,
  MarginPhotos,
  MarginUploadFile,
  StatusSuccess
} from './StyleUploadFile';
import StatusIcon from 'components/icons/StatusIcon';

interface IUploadFileProps {
  icon?: string;
  name?: string;
  handleImage?: any;
  id?: string;
  className?: string;
  content?: string;
  allowedFileTypes?: any;
  imagesArray?: Array<any>;
  maxNumberOfFiles?: number;
  maxFileSize?: number;
  ref?: any;
  infoBox?: string;
  disabled?: boolean;
  uploaded?: boolean | null;
}

const UploadFileMultipleImage: React.FC<IUploadFileProps> = props => {
  const typeFile = 'image/png, image/jpg, image/jpeg';
  const {
    icon,
    name,
    handleImage,
    id,
    className,
    content,
    allowedFileTypes = typeFile,
    imagesArray = [],
    maxNumberOfFiles = 3,
    maxFileSize = 20000,
    ref,
    infoBox,
    disabled = false,
    uploaded
  } = props;

  const { t } = useTranslation(['campaigns_page']);
  const [images, setImages] = useState<Array<any>>([]);
  const [addImages, setAddImages] = useState(false);
  const [errorSize, setErrorSize] = useState<boolean>(false);
  const [errorMB, setErrorMB] = useState<boolean>(false);
  const [errorFileType, setErrorFileType] = useState<boolean>(false);

  const photos = imagesArray?.map(image => {
    return {
      index: generateRandAlphaNumStr(),
      name: '',
      url: image
    };
  });

  useEffect(() => {
    if (photos?.length > 0) {
      setImages(photos);
    }
  }, []);

  const changeInput = (e: any) => {
    setErrorSize(false);
    setErrorMB(false);
    setErrorFileType(false);
    const sizekiloBytes = parseInt(e?.currentTarget?.files[0]?.size) / 1024;
    const sizeImage = parseInt(sizekiloBytes.toString());

    if (!typeFile.includes(e?.target?.files[0]?.type)) {
      setErrorFileType(true);
      return;
    }

    if (sizeImage > maxFileSize) {
      setErrorMB(true);
      return;
    }

    const newImgsToState = readmultifiles(e);
    const newImgsState = [...images, ...newImgsToState];
    setImages(newImgsState);
    setAddImages(false);
    handleImage(newImgsState, content);
  };

  function readmultifiles(e: any) {
    const files = e.currentTarget.files;
    const arrayImages: any = [];

    Object.keys(files).forEach(i => {
      const file = files[i];
      const url = URL.createObjectURL(file);

      arrayImages.push({
        index: generateRandAlphaNumStr(),
        name: file.name,
        url: url,
        file
      });
    });

    return arrayImages;
  }

  const clear = () => {
    setImages([]);
    handleImage([], content);
  };

  const onImgLoad = ({ target: img }: any, index: any) => {
    const { naturalHeight, naturalWidth } = img;
    if (naturalWidth > 1920 && naturalHeight > 1920) {
      deleteImg(index);
      clear();
      setErrorSize(true);
    }
  };

  const deleteImg = (index: any) => {
    const newImgs = images.filter((image: any) => image.index !== index);
    setImages(newImgs);
    handleImage(newImgs, content);
  };

  const size = maxFileSize / 1000;

  return (
    <>
      {images.length === 0 || addImages ? (
        <MarginUploadFile id={id} className={className}>
          <>
            <Icon name={icon ?? 'file'} size="big" color="#979797" />
            <h3>{t('attach_image')}</h3>
            <p>{infoBox}</p>
            <Input
              name={name}
              type="file"
              accept={allowedFileTypes}
              onChange={changeInput}
              ref={ref}
            />
          </>
        </MarginUploadFile>
      ) : (
        <></>
      )}
      {images.length > 0 && !addImages ? (
        <MarginUploadFile className={className}>
          {!disabled && (
            <MarginOption>
              <p onClick={clear}>Cancelar</p>
              {images.length >= maxNumberOfFiles ? (
                <></>
              ) : (
                <img
                  alt="addIcon"
                  src={add_blue}
                  onClick={() => {
                    setAddImages(true);
                  }}
                />
              )}
            </MarginOption>
          )}
          <MarginPhotos>
            {React.Children.toArray(
              images.map(image => (
                <>
                  <MarginPhoto>
                    <img
                      alt="icon"
                      src={image.url}
                      onLoad={e => onImgLoad(e, image.index)}
                    />
                    {!disabled && (
                      <Icon
                        size="small"
                        name="close-rounded"
                        onClick={() => deleteImg(image.index)}
                        color="rgba(0,0,0,0.54)"
                      />
                    )}
                  </MarginPhoto>
                  {uploaded === true && (
                    <StatusSuccess>
                      <StatusIcon variant={'success'} />
                    </StatusSuccess>
                  )}
                </>
              ))
            )}
          </MarginPhotos>
          {uploaded === false && <LinearProgress />}
        </MarginUploadFile>
      ) : (
        <></>
      )}
      {errorSize && <ErrorSpan>{t('error_resolution_image')}</ErrorSpan>}
      {errorMB && <ErrorSpan>{t('error_weight_image', { size })}</ErrorSpan>}
      {errorFileType && <ErrorSpan>{t('error_type_file_image')}</ErrorSpan>}
    </>
  );
};

export default UploadFileMultipleImage;
