import Client from 'config/axios';

export interface CompanyData {
  name: string;
  subdomain: string;
  language: string;
  country: string;
  currency: string;
  ambassadorsLimit: number;
  licenseDate: any;
}

export const createCompany = async (token: string, company: CompanyData) => {
  try {
    const { data } = await Client.post('company', company, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
