import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Facebook as LoadingState } from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OptionTypeBase } from 'react-select';

import { StyledAwardsSection } from './Styled';
import LabelBox from 'components/boxes/LabelBox';
import Carousel from 'components/carousel';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import NavigationLink from 'components/links/NavigationLink';
import add_blue from 'images/icons/add_blue.svg';
import ButtonCreate from 'components/buttons/ButtonCreate';
import { getAward } from 'services/rest/Award/Award';
import { selectUser } from 'redux/slices/userSlice';
import {
  selectAwards,
  setAwardsList,
  setLoading
} from 'redux/slices/Award/AwardSlice';
import { IAward } from 'redux/slices/Award/Award.interface';
import { groupNameByObj } from 'helpers/groupsHelper';
import { getAwardsList } from 'services/rest/Award/getAwardsList';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';
import moment from 'moment';

const AwardsSection = () => {
  const { t } = useTranslation(['dates', 'awards_section']);
  const { currentUser } = useSelector(selectUser);
  const history = useHistory();

  const { awards = [], loading } = useSelector(selectAwards);

  const dispatch = useDispatch();
  const periodTypes = [
    { label: t('dates:period_type.month'), value: 'month' },
    { label: t('dates:period_type.quarter'), value: 'quarter' },
    { label: t('dates:period_type.year'), value: 'year' },
    { label: t('dates:period_type.custom'), value: 'custom' }
  ];

  const [periodType, setPeriodType] = useState<OptionTypeBase>(periodTypes[0]);

  const getData = async () => {
    if (currentUser.role === 'admin') {
      await getAwardsList(currentUser?.token, 100, 1, periodType.value)
        .then(response => {
          dispatch(setAwardsList(response.data.data.docs));
          dispatch(setLoading(false));
        })
        .catch(error => {
          dispatch(error);
          dispatch(setLoading(false));
        });
    } else {
      await getAward(currentUser?.token, periodType.value)
        .then(response => {
          dispatch(setAwardsList(response.data));
          dispatch(setLoading(false));
        })
        .catch(error => {
          dispatch(error);
          dispatch(setLoading(false));
        });
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
  }, [periodType]);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const timeLine = (startDate: any, finishDate: any) => {
    startDate = moment(startDate);
    finishDate = moment(finishDate);
    const startMonth = startDate.format('MMM');
    const startDay = startDate.format('D');
    const finishMonth = finishDate.format('MMM');
    const finishDay = finishDate.format('D');
    return `${startMonth} ${startDay} - ${finishMonth} ${finishDay}`;
  };

  const awardsCards = awards.map((award: IAward, index: number) => {
    const translationPage = t(`dates:${award.periodType}`, {
      returnObjects: true
    });

    let periodValue = '';
    if (award.periodType === 'year') {
      periodValue = award.periodValue;
    } else if (award.periodType === 'custom') {
      periodValue = timeLine(award.startDate, award.finishDate);
    } else {
      periodValue = translationPage[parseInt(award.periodValue) - 1];
    }

    const groupName = groupNameByObj(award.groups);

    return (
      <LabelBox
        image={award.photoUrl}
        content={award.description}
        labels={[periodValue, groupName]}
        key={index}
      />
    );
  });

  return (
    <>
      {awards.length > 0 ? (
        <SectionWrapper>
          <h1 className="section-title">{t('employee_dashboard:awards')}</h1>
          {currentUser.role === 'admin' && (
            <ButtonCreate
              onClick={() => history.replace('/admin/award/newAward')}
              color="#004fea"
              value={t('awards_section:create_award')}
              href={add_blue}
            />
          )}
          <StyledAwardsSection>
            <DropdownSelect
              onSelect={option => setPeriodType(option)}
              value={periodType}
              defaultValue={periodTypes}
              options={periodTypes}
            />
            <Carousel
              items={awardsCards}
              startIndex={0}
              className="width-auto"
            />
          </StyledAwardsSection>
          {currentUser.role === 'admin' && (
            <NavigationLink
              text={t('awards_section:see_all')}
              direction={'forward'}
              to={'admin/award'}
            />
          )}
        </SectionWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default AwardsSection;
