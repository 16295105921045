import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Facebook as LoadingState } from 'react-content-loader';
import i18n from 'i18next';

import EmployeeCard from './EmployeeCard';
import { formatDateTime } from 'helpers/dateHelpers';

import {
  selectLoading,
  selectUserInfo,
  selectUser,
  setUserInfo
} from 'redux/slices/userSlice';
import { networkUser } from 'Utils/Networks';
import { selectPerformance } from 'redux/slices/PerformanceSection/PerformanceSectionSlice';
import { NetworkProps } from './NetworksList';

import { getInfoUser } from 'services/rest/logout';

const ConnectedEmployeeCard = () => {
  const { infoUser } = useSelector(selectUserInfo);
  const [userData, setUserData] = useState<any>(infoUser);
  const { currentUser } = useSelector(selectUser);
  const dataUser = useSelector(selectLoading);
  const { loading, performanceSection } = useSelector(selectPerformance);
  const dispatch = useDispatch();

  const getUserData = async () => {
    const { data } = await getInfoUser(
      currentUser?.user?.id,
      currentUser?.token
    );
    if (data) {
      setUserData(data);
      dispatch(setUserInfo(data));
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const networks: Array<NetworkProps> = networkUser(
    infoUser.linkedFacebook,
    infoUser.linkedTwitter,
    infoUser.linkedLinkedin,
    infoUser.linkedInstagram,
    infoUser.linkedTiktok,
    infoUser.social_network?.networks?.facebook?.invalidToken,
    infoUser.social_network?.networks?.twitter?.invalidToken,
    infoUser.social_network?.networks?.linkedin?.invalidToken,
    infoUser.social_network?.networks?.instagram?.invalidToken,
    infoUser.social_network?.networks?.tiktok?.invalidToken
  );

  const formatMonth = formatDateTime(i18n.language, 'month');
  const currentMonth = formatMonth(new Date(), 'long');

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <EmployeeCard
        userName={userData.name}
        userPhoto={userData.photo}
        networks={networks}
        currentMonth={currentMonth}
        points={
          performanceSection.monthlyRanking.totalPoints != null
            ? performanceSection.monthlyRanking.totalPoints
            : 0
        }
        ranking={
          performanceSection.monthlyRanking.position != null
            ? performanceSection.monthlyRanking.position
            : 0
        }
      />
    </Suspense>
  );
};

export default ConnectedEmployeeCard;
