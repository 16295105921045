import Client from 'config/axios';

export const deleteSuggestedPost = async (
  token: string,
  suggestedPostId: number
) => {
  try {
    const { data } = await Client.delete(
      `suggested_post/delete/${suggestedPostId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return { data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.data };
  }
};
