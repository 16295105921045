import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';
import { SectionForm, InputWrapper, ButtonStyled } from '../../Styled';
import Spinner from 'components/Spinner/Spinner';
import { formatSelectOptions } from 'helpers/selectFormatHelper';
import { AdministratorData } from 'services/rest/SuperAdmin/Administrator/createAdministrator';
import { List as LoadingState } from 'react-content-loader';
import ReactSelect from 'react-select';

export interface AdminFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<AdministratorData>;
  register: UseFormMethods['register'];
  admin: any;
  setValue: any;
  control: any;
  loader: boolean;
  companies: any;
  loading: any;
}

const AdminForm: React.FC<AdminFormProps> = props => {
  const {
    onSubmit,
    errors,
    register,
    admin,
    loader,
    companies,
    loading
  } = props;
  const { t } = useTranslation(['super_admin_administrator']);
  const formatCompanies = formatSelectOptions(companies);
  const defaultCompany = formatCompanies.filter((company: any) => {
    return company.value === admin.companyId;
  });

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }
  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <SectionWrapper>
        <NavigationLink
          text={t('super_admin_administrator:form:back')}
          direction="back"
          to={'/administrators'}
        />
        <h1>{t('super_admin_administrator:edit_admin')}</h1>
        <form onSubmit={onSubmit}>
          <SectionForm>
            <InputWrapper className="disabled-field">
              <label>{t('super_admin_administrator:form:company')}</label>
              <div className="w-100">
                <ReactSelect
                  options={formatCompanies}
                  defaultValue={defaultCompany}
                  className="dropdown-filter-container m-l-0"
                  classNamePrefix="dropdown-filter-content"
                  isDisabled={true}
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <label>{t('super_admin_administrator:form:name')}</label>
              <div className="w-100">
                <Input
                  name="name"
                  type="text"
                  placeholder={t('super_admin_administrator:form:name')}
                  defaultValue={admin?.name}
                  ref={register}
                  error={errors.name?.message}
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <label>{t('super_admin_administrator:form:email')}</label>
              <div className="w-100">
                <Input
                  name="email"
                  type="text"
                  placeholder={t('super_admin_administrator:form:email')}
                  defaultValue={admin?.email}
                  ref={register}
                  error={errors.email?.message}
                />
              </div>
            </InputWrapper>
            <InputWrapper className="disabled-field">
              <label>{t('super_admin_administrator:form:username')}</label>
              <div className="w-100">
                <Input
                  name="username"
                  type="text"
                  placeholder={t('super_admin_administrator:form:username')}
                  defaultValue={admin?.username}
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <label>{t('super_admin_administrator:form:password')}</label>
              <div className="w-100">
                <Input
                  name="password"
                  type="password"
                  placeholder={t('super_admin_administrator:form:password')}
                  ref={register}
                  error={errors.password?.message}
                />
              </div>
            </InputWrapper>
          </SectionForm>
          <ButtonStyled>
            {!loader ? (
              <Button
                value={t('super_admin_administrator:update')}
                variant="primary"
              />
            ) : (
              <Spinner name="sign-up" />
            )}
          </ButtonStyled>
        </form>
      </SectionWrapper>
    </Suspense>
  );
};

export default AdminForm;
