import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { List as LoadingState } from 'react-content-loader';
import { UseFormMethods, FieldErrors } from 'react-hook-form';
import ReactSelect from 'react-select';
import { Company } from 'services/rest/Company/updateCompany';
import { formatSelectOptions } from 'helpers/selectFormatHelper';
import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';
import Spinner from 'components/Spinner/Spinner';
import UploadFileImageSimple from 'components/inputs/UploadFile/UploadFileImageSimple';
import {
  ButtonStyled,
  InputWrapper,
  SectionForm,
  FileWrapper,
  FileSection
} from './Styled';

export interface EditCompanyFormProps {
  company: any;
  loading: boolean;
  loader: boolean;
  errors: FieldErrors<Company>;
  handleSubmit: (event: React.FormEvent) => void;
  register: UseFormMethods['register'];
  handleImage: any;
  setCurrency: any;
  setLanguage: any;
}

const EditCompanyForm: React.FC<EditCompanyFormProps> = props => {
  const {
    loader,
    loading,
    handleSubmit,
    register,
    company,
    errors,
    handleImage,
    setCurrency,
    setLanguage
  } = props;
  const { t } = useTranslation('update_company');

  const languages = [
    { label: t('spanish'), value: 'es' },
    { label: t('english'), value: 'en' },
    { label: t('portuguese'), value: 'pt' }
  ];

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <form onSubmit={handleSubmit} className="flex-wrapper">
        <SectionForm>
          <InputWrapper>
            <label>{t('name')}</label>
            <div className="w-100">
              <Input
                type="text"
                placeholder={t('name')}
                name="name"
                ref={register}
                defaultValue={company?.name}
                error={errors.name?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('currency')}</label>
            <div className="w-100">
              <ReactSelect
                options={formatSelectOptions(company?.currencies)}
                defaultValue={
                  formatSelectOptions(company?.currencies)[
                    company?.currencyId - 1
                  ]
                }
                className="dropdown-filter-container m-l-0"
                classNamePrefix="dropdown-filter-content"
                onChange={e => setCurrency(e?.value)}
                isDisabled={true}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('language')}</label>
            <div className="w-100">
              <ReactSelect
                options={languages}
                defaultValue={
                  languages.filter((lang: any) => {
                    return lang.value == company?.language;
                  })[0]
                }
                className="dropdown-filter-container m-l-0"
                classNamePrefix="dropdown-filter-content"
                onChange={e => setLanguage(e?.value)}
              />
            </div>
          </InputWrapper>
          <FileSection>
            <InputWrapper className="inputFile">
              <label>{t('background')}</label>
              <FileWrapper>
                <UploadFileImageSimple
                  name="backgroundImage"
                  content="backgroundImage"
                  maxFileSize={5000}
                  ref={register}
                  infoBox={t('info_box_background')}
                  imagesArray={[company?.backgroundImage]}
                  handleImage={handleImage}
                  allowedFileTypes="image/jpeg"
                />
              </FileWrapper>
            </InputWrapper>
            <InputWrapper className="inputFile">
              <label>{t('logo')}</label>
              <FileWrapper>
                <UploadFileImageSimple
                  name="logo"
                  content="logo"
                  maxFileSize={5000}
                  ref={register}
                  infoBox={t('info_box_logo')}
                  imagesArray={[company?.logo]}
                  handleImage={handleImage}
                  allowedFileTypes="image/svg+xml"
                />
              </FileWrapper>
            </InputWrapper>
            <InputWrapper className="inputFile">
              <label>{t('emailLogo')}</label>
              <FileWrapper>
                <UploadFileImageSimple
                  name="emailLogo"
                  content="emailLogo"
                  maxFileSize={5000}
                  ref={register}
                  infoBox={t('info_box_emailLogo')}
                  imagesArray={[company?.emailLogo]}
                  handleImage={handleImage}
                  allowedFileTypes="image/png"
                />
              </FileWrapper>
            </InputWrapper>
            <InputWrapper className="inputFile">
              <label>{t('tos_file')}</label>
              <FileWrapper>
                <UploadFileImageSimple
                  name="tosUrl"
                  content="tosUrl"
                  maxFileSize={5000}
                  ref={register}
                  infoBox={t('info_box_tosUrl')}
                  imagesArray={[company?.TosUrl]}
                  handleImage={handleImage}
                  allowedFileTypes="application/pdf"
                />
              </FileWrapper>
            </InputWrapper>
          </FileSection>
        </SectionForm>
        <ButtonStyled>
          {!loader ? (
            <Button value={t('update')} variant="primary" />
          ) : (
            <Spinner name="sign-up" />
          )}
        </ButtonStyled>
      </form>
    </Suspense>
  );
};
export default EditCompanyForm;
