import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods } from 'react-hook-form';
import Input from 'components/inputs/Input';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { InputWrapper, EmailPermissions } from './Styled';

export interface GeneralAccordionProps {
  permissions: any;
  handleOnClick: any;
  register: UseFormMethods['register'];
}

const GeneralAccordion: React.FC<GeneralAccordionProps> = props => {
  const { permissions, handleOnClick, register } = props;
  const { t } = useTranslation('change_permissions');

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h1>{t('general_permissions')}</h1>
      </AccordionSummary>
      <AccordionDetails>
        <EmailPermissions>
          <InputWrapper>
            <label>{t('allowShowAwards')}</label>
            <div className="checkbox-parent">
              <Input
                id="allowShowAwards"
                type="checkbox"
                name="allowShowAwards"
                className="swipe-checkbox"
                checked={permissions?.allowShowAwards}
                ref={register}
                onClick={handleOnClick}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowShowRanking')}</label>
            <div className="checkbox-parent">
              <Input
                id="allowShowRanking"
                type="checkbox"
                name="allowShowRanking"
                className="swipe-checkbox"
                checked={permissions?.allowShowRanking}
                ref={register}
                onClick={handleOnClick}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowPendingCampaign')}</label>
            <div className="checkbox-parent">
              <Input
                id="allowPendingCampaign"
                type="checkbox"
                name="allowPendingCampaign"
                className="swipe-checkbox"
                checked={permissions?.allowPendingCampaign}
                ref={register}
                onClick={handleOnClick}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowContentParticipation')}</label>
            <div className="checkbox-parent">
              <Input
                id="allowContentParticipation"
                type="checkbox"
                name="allowContentParticipation"
                className="swipe-checkbox"
                checked={permissions?.allowContentParticipation}
                ref={register}
                onClick={handleOnClick}
              />
            </div>
          </InputWrapper>
        </EmailPermissions>
      </AccordionDetails>
    </Accordion>
  );
};
export default GeneralAccordion;
