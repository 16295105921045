import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { createCountry } from 'services/rest/SuperAdmin/Country/createCountry';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { RequiredString, String } from 'helpers/yupHelper';
import UpdateCountryForm from './UpdateCountryForm';
import { max } from 'date-fns';
import { useParams } from 'react-router-dom';
import { getCountries } from 'services/rest/SuperAdmin/Country/getCountries';

const UpdateCountry: React.FC = () => {
  const { t } = useTranslation(['super_admin_country', 'validations']);
  const userSchema = yup.object().shape({
    name: RequiredString().max(50, t('validations:length_error', { max: 50 })),
    defaultTimeZone: RequiredString().max(
      100,
      t('validations:length_error', { max: 100 })
    ),
    dialCode: RequiredString().max(
      6,
      t('validations:length_error', { max: 6 })
    ),
    code: RequiredString().max(3, t('validations:length_error', { max: 3 }))
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(userSchema)
  });
  const { currentUser } = useSelector(selectUser);
  const [loader, setLoader] = useState(false);
  const onSubmit = async (CountryData: any) => {
    setLoader(true);
    const data = await createCountry(currentUser?.token, CountryData);
    if (data) {
      setLoader(false);
    }
  };
  const { token, role } = currentUser;
  const { countryId } = useParams<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [countries, setCountry] = useState<any>();

  const getcountryData = async () => {
    const country = await getCountries(token);
    const currentCountry = country.filter((item: any) => {
      return item.id == countryId;
    });
    setCountry(currentCountry[0]);
    setLoading(false);
  };

  useEffect(() => {
    getcountryData();
  }, []);

  return (
    <>
      <UpdateCountryForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setValue={setValue}
        countries={countries}
        control={control}
        loader={loader}
      />
    </>
  );
};

export default UpdateCountry;
