import Client from 'config/axios';

export const getInfoUser = async (id: number, token: string, role: string) => {
  let url;
  switch (role) {
    case 'admin':
      url = 'administrator';
      break;
    case 'super':
      url = 'superadmin';
      break;
    default:
      url = role;
      break;
  }
  return await Client.get(`${url}/${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data.data)
    .catch(error => error);
};
