import styled, { css } from 'styled-components';
import { Colors } from 'styles/Constants';

export interface EditorWrapperProps {
  disabled?: boolean;
}

export const EditorWrapper = styled.div<EditorWrapperProps>`
  border: 1px solid #e2e3e5;
  padding: 6px 12px 30px;
  border-radius: 4px;
  position: relative;
  min-height: 80px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  width: auto;
  height: auto;
  line-height: 1.7;
  min-height: 200px;
  min-width: 80%;

  .public-DraftEditorPlaceholder-inner {
    color: #7b7878;
  }

  .DraftEditor-editorContainer,
  .DraftEditor-root,
  .public-DraftEditor-content {
    min-height: 200px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: #f5f6f8;
    `};
`;

export const StyledCounter = styled.span`
  position: absolute;
  font-size: 15px;
  right: 5px;
  bottom: 5px;

  &.max-chars-error {
    color: red;
  }
`;

export const Hashtag = styled.span`
  color: ${Colors.primaryDarkBlue};
  font-weight: bold;
`;

export const Url = styled.a`
  color: ${Colors.primaryDarkBlue};
  font-weight: bold;
`;

export const Mention = styled(Hashtag)`
  direction: ltr;
  unicode-bidi: bidi-override;
`;
