import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import { CancelIcon } from 'components/icons/Catalogue';
import { StyledAlert, StyledClose } from './StyledAlert';

export interface AlertProps {
  type?: string;
  allowClose?: boolean;
  autoClose?: boolean;
  onClose?: () => void;
  className?: string;
}

const Alert: React.FC<AlertProps> = props => {
  const [showAlert, setShowAlert] = useState(true);
  const {
    children,
    type,
    allowClose,
    autoClose = true,
    onClose,
    className
  } = props;

  useEffect(() => {
    if (autoClose) {
      const countdown = setTimeout(() => closeAlert(), 10000);
      return () => clearTimeout(countdown);
    }
  });

  const closeAlert = () => {
    setShowAlert(false);
    onClose && onClose();
  };

  if (!showAlert) return null;

  const alertRoot = document.getElementById('alert-root');

  return ReactDOM.createPortal(
    <StyledAlert className={`${cx('alert', type)} ${className}`}>
      {allowClose && (
        <StyledClose onClick={closeAlert}>
          <CancelIcon className={'cancel'} color={'#fff'} />
        </StyledClose>
      )}
      {children}
    </StyledAlert>,
    alertRoot as Element
  );
};

Alert.defaultProps = {
  allowClose: false,
  autoClose: true,
  className: ''
};

export default Alert;
