import { createSlice } from '@reduxjs/toolkit';

const setInvalidToken = (state: any, action: { payload: any }) => {
  return {
    ...state,
    invalidToken: action.payload
  };
};

const InvalidTokenSlice = createSlice({
  name: 'invalidToken',
  initialState: {
    invalidToken: ''
  },
  reducers: {
    setInvalidTokenCurrent: setInvalidToken
  }
});

export const selectInvalidToken = (state: {
  invalidToken: { invalidToken: string };
}) => state.invalidToken;

export const { setInvalidTokenCurrent } = InvalidTokenSlice.actions;

export default InvalidTokenSlice.reducer;
