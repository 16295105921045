import styled from 'styled-components';

export const ContentEditorWrapper = styled.div`
  .Calendar-time {
    margin-top: 5%;

    .react-datetime-picker__wrapper {
      border: 1px solid rgb(151, 151, 151);
      width: 350px;
      border-radius: 10px;
      padding: 10px;
      cursor: pointer;
    }

    .react-datetime-picker__inputGroup {
      color: rgb(77, 77, 77);
      font-size: 1rem;
      font-weight: 600;
      margin: 0px;
    }

    .react-calendar__navigation__label__labelText {
      font-weight: 600;
      font-size: 12px;
      text-transform: capitalize;
      color: rgb(5, 85, 156);
    }

    .react-calendar__month-view__weekdays
      .react-calendar__month-view__weekdays__weekday
      abbr {
      text-decoration: none;
      font-weight: 500 !important;
      font-size: 12px;
      text-transform: uppercase;
      color: rgb(5, 85, 156);
      font-weight: 500;
    }

    .react-calendar__tile {
    }

    .react-calendar__tile--now {
      background: #3d91ff;
    }

    .react-calendar__tile react-calendar__month-view__days__day {
      color: #1d2429;
    }

    .react-calendar__tile:disabled {
      background: rgb(255, 255, 255);
      cursor: default;
    }
  }
`;

export const TextPlain = styled.div`
  text-align: left;

  p {
    text-align: justify;
    font-weight: 500;
    line-height: 1.5;
    font-size: 14px;
  }

  button {
    margin-top: 20px;
    width: 100%;
  }

  .programmerButton {
    background: #b721ff;
    border: 1px solid #b721ff;
    background-image: linear-gradient(136deg, #770cab, #b721ff);
    color: ;
  }

  .rejectParticipation {
    width: 100%;
    text-align: center;
    margin-top: 2%;

    span {
      cursor: pointer;
      color: #05559c;
      fill: #05559c;
      height: 16px;
      width: 16px;
    }
  }
`;
