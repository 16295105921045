import styled from 'styled-components';
import BaseInput from 'components/inputs/BaseInput';

import Search from 'images/icons/search.svg';
import person from 'images/icons/person.svg';
import iconCheck from 'images/icons/check.svg';
import iconCheckInactive from 'images/icons/checkbox_inactive_label.svg';
import SearchBlue from 'images/icons/search-blue.svg';

const StyledInput = styled(BaseInput)`
  height: 40px;

  &[type='group'] {
    background-image: url(${person});
    background-repeat: no-repeat;
    background-position: 12px 50%;
    background-size: 20px;
    padding: 5px 15px 5px 40px;
    cursor: pointer;
    height: 32px;
  }

  &[type='search'] {
    background-image: url(${Search});
    background-repeat: no-repeat;
    background-position: 12px 50%;
    background-size: 20px;
    padding: 5px 15px 5px 40px;
    cursor: pointer;
    height: 32px;

    &:focus {
      background-image: url(${SearchBlue});
      border: 1px solid $primary-dark-blue;
    }

    &:hover {
      background-image: url(${SearchBlue});
    }
  }
  &.swipe-checkbox{
    width: 5%;
    appearance: none;
    visibility: visible;
    background: #d8d8d8;
    position: relative;
    border-radius: 3em;
    cursor: pointer;
    outline: none;
    border: none;
    height: 20px;
    width: 43px;
  }
  &.swipe-checkbox:checked {
    background: #7ed321;
  }
  &.swipe-checkbox:after {
    left: calc(100% - 1.5em);
    background: #a5adba url(${iconCheckInactive});
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
    position: absolute;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    content: '';
    top: -1px;
    left: -5px;
  }
  &.swipe-checkbox:checked:after {
    left: calc(100% - 1.5em);
    background: #92e239 url(${iconCheck});
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
    transition: all 0.2s ease-in-out;
    transform: scale(0.92);
    position: absolute;
    border-radius: 50%;
    height: 22px;
    width: 22px;
  }
}
`;

export default StyledInput;
