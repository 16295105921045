import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  PositionColumn,
  TrophyColumn,
  Column,
  UserNameHeader,
  PointsHeader,
  Row,
  Table,
  Th
} from './StyledRankingTable';
import { TrophyIcon } from 'components/icons/Catalogue';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';

import EmployeeAvatar from 'images/avatars/employee.png';
import { IRanking } from 'redux/slices/Ranking/Ranking.interface';
import { Role } from 'pages/DashboardPage/RankingSection/RankingSection';

interface Employee {
  employeeId: number;
  position: number;
  totalPoints: number;
  bonusPoints: number;
  photo: string;
  fullName: string;
  badPractices: boolean;
}

export type Employees = Array<IRanking>;
export type EmployeesAdmin = Array<Employee>;

export interface RankingTableProps {
  currentEmployeeId?: number;
  employees: Employees;
  role: Role;
  userData: any;
}

const getBadPracticeMessage = (
  t: TFunction,
  isCurrentUser: boolean,
  role: Role
): string => {
  if (role === 'admin') return t('ranking_table:admin_bad_practices');
  if (isCurrentUser) return t('ranking_table:own_bad_practices');

  return t('ranking_table:employee_bad_practices');
};

const RankingTable: React.FC<RankingTableProps> = props => {
  const { employees, currentEmployeeId, role, userData } = props;
  const { t } = useTranslation(['ranking_table']);
  const [visible, setVisible] = useState<any>(
    employees?.map(() => {
      return {
        status: false
      };
    })
  );

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  const showTooltip = (index: any, status: any) => {
    const tooltipTypes = {
      ...visible,
      [index]: { status: status }
    };
    setVisible(tooltipTypes);
  };

  return (
    <Table className="notranslate">
      <thead>
        <Row>
          <Th />
          <UserNameHeader>{t('ranking_table:employee')}</UserNameHeader>
          <PointsHeader>{t('ranking_table:points')}</PointsHeader>
          <Th />
        </Row>
      </thead>
      <tbody>
        {employees.map((employee, index) => {
          const isCurrent = currentEmployeeId === employee?.ambassadorId;
          const message = getBadPracticeMessage(t, isCurrent, role);
          return (
            <Row key={index} activeRow={role !== 'admin' && isCurrent}>
              <PositionColumn collapsed>
                <div className="id">
                  <p>{`${employee.position}.`}</p>
                  <img
                    src={employee.photo || EmployeeAvatar}
                    className="user-photo"
                    alt="User"
                    onError={evt => addDefaultPicture(evt)}
                  />
                </div>
              </PositionColumn>
              <Column>{employee.ambassador}</Column>
              <Column className="no-padding">
                <StyledTooltipWrapper
                  visible={visible[index]?.status}
                  onMouseOver={() => showTooltip(index, true)}
                  onMouseOut={() => showTooltip(index, false)}
                  className="ranking-tooltip-wrapper"
                >
                  {employee.totalPoints}
                  {(isCurrent &&
                    employee.bonusPoints > 0 &&
                    userData?.allowBonus) ||
                  (role == 'admin' &&
                    employee.bonusPoints > 0 &&
                    employee?.allowBonus) ? (
                    <TooltipMessage className="ranking-tooltip">
                      <span>
                        {t('ranking_table:campaign_points')}
                        {employee.campaignPoints}
                      </span>
                      <span>
                        {t('ranking_table:bonus_points')}
                        {employee.bonusPoints}
                      </span>
                    </TooltipMessage>
                  ) : (
                    <></>
                  )}
                </StyledTooltipWrapper>
              </Column>
              <TrophyColumn>
                <TrophyIcon
                  className={`trophy ${
                    employee.position == 1
                      ? 'gold'
                      : employee.position == 2
                      ? 'silver'
                      : employee.position == 3
                      ? 'bronze'
                      : ''
                  }`}
                />
              </TrophyColumn>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};

export default RankingTable;
