import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import Input from 'components/inputs/Input';

import Button from 'components/buttons/Button';

import { SectionForm, InputWrapper, ButtonStyled } from '../../Styled';

import { CurrencyData } from 'services/rest/SuperAdmin/Currency/createCurrency';

import { RequiredString } from 'helpers/yupHelper';
import Spinner from 'components/Spinner/Spinner';

export interface CurrencyFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<CurrencyData>;
  register: UseFormMethods['register'];
  setValue: any;
  control: any;
  loader: boolean;
}

export const userSchema = yup.object().shape({
  name: RequiredString()
});

const CurrencyForm: React.FC<CurrencyFormProps> = props => {
  const { onSubmit, errors, register, loader } = props;
  const { t } = useTranslation(['super_admin_currency']);
  return (
    <SectionWrapper>
      <NavigationLink
        text={t('super_admin_currency:form:back')}
        direction="back"
        to={'/currencies'}
      />
      <h1>{t('super_admin_currency:create_currency')}</h1>
      <form onSubmit={onSubmit}>
        <SectionForm>
          <InputWrapper>
            <label>{t('super_admin_currency:name')}</label>
            <div className="w-100">
              <Input
                name="name"
                type="text"
                placeholder={t('super_admin_currency:name')}
                ref={register}
                error={errors.name?.message}
                data-testid="name"
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_currency:code')}</label>
            <div className="w-100">
              <Input
                name="code"
                type="text"
                placeholder={t('super_admin_currency:code')}
                ref={register}
                error={errors.code?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_currency:sign')}</label>
            <div className="w-100">
              <Input
                name="sign"
                type="text"
                placeholder={t('super_admin_currency:sign')}
                ref={register}
                error={errors.sign?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_currency:value')}</label>
            <div className="w-100">
              <Input
                name="value"
                type="number"
                placeholder={t('super_admin_currency:value')}
                ref={register}
                error={errors.value?.message}
              />
            </div>
          </InputWrapper>
        </SectionForm>
        <ButtonStyled>
          {!loader ? (
            <Button
              value={t('super_admin_currency:create_currency')}
              variant="primary"
            />
          ) : (
            <Spinner name="sign-up" />
          )}
        </ButtonStyled>
      </form>
    </SectionWrapper>
  );
};

export default CurrencyForm;
