import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
`;
export const TextWrapper = styled.div`
  padding: 0 80px;
  span {
    font-size: 15px;
    font-weight: 500;
  }
`;
export const List = styled.ul`
  li {
    font-size: 15px;
    font-weight: bold;
  }
`;
export const Form = styled.form``;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  input {
    width: 210px;
  }
  button {
    font-size: 15px;
    margin: 20px;
  }
`;
