import React from 'react';
import { Colors } from 'styles/Constants';
import Button from 'components/buttons/Button';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'components/progress_bar/ProgressBar';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';

import StyledNetworkParticipationBox, {
  StyledButtonCollection
} from './StyledNetworkParticipationBox';

export interface NetworkProps {
  network: string;
  percentage: string;
  linked: boolean;
}

export interface NetworkParticipationBoxProps {
  items: Array<NetworkProps>;
  onConnect: (network: string) => void;
  topText?: boolean;
  msgTopText?: string;
  columns?: number;
  detailCampaign?: boolean;
}

const NetworkParticipationBox: React.FC<NetworkParticipationBoxProps> = props => {
  const { t } = useTranslation(['network_participation']);
  const { items, onConnect, topText, detailCampaign = false } = props;
  const unLinkedNetworks = items.filter(item => item.linked === false);

  return (
    <StyledNetworkParticipationBox {...props}>
      {topText && (
        <h3>
          {props.msgTopText ??
            t('network_participation:contents_by_social_network')}
        </h3>
      )}

      <div style={{ columns: `${props.columns ?? 1}` }}>
        {items.map(({ network, percentage, linked }, index) => {
          return (
            <ProgressBar
              key={index}
              withNumber={true}
              color={Colors[network]}
              percentage={
                percentage !== undefined && percentage !== null
                  ? percentage
                  : '0'
              }
              icon={
                <SocialMediaIcon
                  name={network === 'others' ? 'otros' : network}
                  size="small"
                />
              }
              disabled={!linked}
              detailCampaign={detailCampaign}
            />
          );
        })}
      </div>

      {!topText && (
        <h3>
          {props.msgTopText ??
            t('network_participation:contents_by_social_network')}
        </h3>
      )}

      <StyledButtonCollection className="button-collection">
        {!detailCampaign &&
          unLinkedNetworks.map(({ network }, index) => (
            <div
              className={network}
              key={index}
              onClick={() => onConnect(network)}
            >
              <Button
                size="small"
                icon={network}
                variant={network}
                value={t('network_participation:link_account')}
              />
            </div>
          ))}
      </StyledButtonCollection>
    </StyledNetworkParticipationBox>
  );
};

export default NetworkParticipationBox;
