import Client from 'config/axios';

export interface Permissions {
  id: number;
  companyId: number;
  allowEmailNotifications: boolean;
  allowWelcomeEmail: boolean;
  allowCampaignEmail: boolean;
  allowCampaignAwardsEmail: boolean;
  allowChallengeEmail: boolean;
  allowAwardsEmail: boolean;
  allowBonusAdminEmail: boolean;
  allowRejectedBonusEmail: boolean;
  allowApprovedBonusEmail: boolean;
  allowPushNotification: boolean;
  allowCampaignPush: boolean;
  allowAwardsPush: boolean;
  allowApprovedBonusPush: boolean;
  allowRejectedBonusPush: boolean;
  allowCampaignReminderPush: boolean;
  allowShowAwards: boolean;
  allowShowRanking: boolean;
  allowShowBonus: boolean;
  allowPendingCampaign: boolean;
  allowContentParticipation: boolean;
  allowChallengePush: boolean;
  allowChallengeReminderPush: boolean;
}

export const changePermissions = async (
  token: string,
  permissions: Permissions
) => {
  try {
    const { data } = await Client.post(
      `change-permissions/${permissions?.id}`,
      permissions,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return { data: data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.data };
  }
};
