import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonDownload,
  FiltersForm,
  FirstFilters,
  SecondFilters
} from './Styled';
import Input from 'components/inputs/Input';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import Calendar from 'components/Calendar';
import useIsAdmin from 'hooks/useIsAdmin.hook';
import Button from 'components/buttons/Button';
import { getCurrentCompany } from 'helpers/companyHelper';

export interface PlainFilters {
  periodType: string;
  periodValue: string;
  groupName: string;
  name: string;
  year?: number;
  startDate?: any;
  finishDate?: any;
  points: string;
}

export interface RankingFiltersProps {
  userData: any;
  role: string;
  name: string;
  setName: any;
  periodTypeOptions: any;
  periodType: any;
  setPeriodType: any;
  periodValue: any;
  setPeriodValue: any;
  months: any;
  quarters: any;
  years: any;
  year: any;
  setYear: any;
  currentMonth: number;
  groupsOptions: any;
  setSelectedGroup: any;
  points: any;
  setPoints: any;
  pointsOptions: any;
  onChangeFilters: any;
  onDownloadReport?: any;
}

const RankingFilters: React.FC<RankingFiltersProps> = props => {
  const {
    userData,
    role,
    name,
    setName,
    periodType,
    setPeriodType,
    periodTypeOptions,
    periodValue,
    setPeriodValue,
    months,
    quarters,
    years,
    year,
    setYear,
    currentMonth,
    groupsOptions,
    setSelectedGroup,
    points,
    setPoints,
    pointsOptions,
    onChangeFilters
  } = props;

  const { t } = useTranslation(['dates', 'ranking_filters']);
  const company = getCurrentCompany();
  const [selectedPeriodOptions, setSelectedPeriodOptions] = useState<any>(
    months
  );

  const [activeYear, setActiveYear] = useState(false);

  const [filters, setFilters] = useState({
    periodType: periodType.value,
    periodValue: periodValue.value,
    groupName: groupsOptions.length > 0 && groupsOptions[0].value,
    name: name,
    year: year.value,
    startDate: '',
    finishDate: '',
    points: points.value
  });

  const [groupValue, setGroupValue] = useState<any>(null);

  useEffect(() => {
    !groupValue ? setGroupValue(groupsOptions[0]) : groupValue;
  }, [groupsOptions]);

  const onChangePeriodType = (event: any) => {
    setPeriodType(event);
    switch (event.value) {
      case 'month':
        setActiveYear(false);
        setSelectedPeriodOptions(months);
        setPeriodValue(months[currentMonth - 1]);
        onChangeFilter({
          periodType: 'month',
          periodValue: months[currentMonth - 1].value,
          groupName: groupsOptions[0].value,
          year: year.value,
          points: points.value
        });
        break;
      case 'quarter':
        setActiveYear(false);
        setSelectedPeriodOptions(quarters);
        setPeriodValue(quarters[0]);
        onChangeFilter({
          periodType: 'quarter',
          periodValue: quarters[0].value,
          groupName: groupsOptions[0].value,
          year: year.value,
          points: points.value
        });
        break;
      case 'year':
        setActiveYear(true);
        onChangeFilter({
          periodType: 'year',
          periodValue: years[0].value,
          groupName: groupsOptions[0].value,
          year: year.value,
          points: points.value
        });
        break;
      case 'custom':
        setActiveYear(false);
        setSelectedPeriodOptions(event);
        setPeriodValue(event);
        break;
      default:
        break;
    }
  };

  const onSelectCustomFilter = (startDate: Date, endDate: Date) => {
    const sDate = new Date(startDate).toISOString();
    const fDate = new Date(endDate).toISOString();
    onChangeFilter({
      periodType: 'custom',
      periodValue: months[currentMonth - 1].value,
      groupName: groupsOptions.length > 0 && groupsOptions[0].value,
      startDate: sDate,
      finishDate: fDate,
      points: points.value
    });
  };

  const onChangeFilter = (newFilters: any) => {
    setFilters(newFilters);
    onChangeFilters(newFilters);
  };

  const rootName = 'ranking_general';
  const extension = 'xlsx';
  const documentName = `${company}_${rootName}.${extension}`;

  return (
    <FiltersForm className="notranslate">
      <FirstFilters>
        <Input
          placeholder={t('ranking_filters:search_employees')}
          type="search"
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
        />
        <span className="filter-by-label">
          {t('ranking_filters:filter_by')}
        </span>
        {(role == 'admin' ||
          (role == 'ambassador' && userData?.allowBonus)) && (
          <DropdownSelect
            onSelect={e => {
              const points = e.value;
              onChangeFilter({ ...filters, points });
              setPoints(e);
            }}
            value={points}
            defaultValue={points}
            options={pointsOptions}
          />
        )}
        <DropdownSelect
          onSelect={e => {
            const groupName = e.value;
            onChangeFilter({ ...filters, groupName });
            setSelectedGroup(e);
            setGroupValue(e);
          }}
          value={groupValue}
          defaultValue={groupValue}
          options={groupsOptions}
        />
      </FirstFilters>
      <SecondFilters>
        <DropdownSelect
          onSelect={onChangePeriodType}
          value={periodType}
          defaultValue={periodTypeOptions[0]}
          options={periodTypeOptions}
        />
        {periodValue.value == 'custom' && !activeYear && (
          <Calendar onChange={onSelectCustomFilter} />
        )}
        {periodValue.value !== 'custom' && !activeYear && (
          <DropdownSelect
            onSelect={e => {
              const periodValue = e.value;
              onChangeFilter({ ...filters, periodValue });
              setPeriodValue(e);
            }}
            value={periodValue}
            defaultValue={periodValue}
            options={selectedPeriodOptions}
          />
        )}
        {periodValue.value !== 'custom' && (
          <DropdownSelect
            onSelect={e => {
              const year = e.value;
              onChangeFilter({ ...filters, year });
              setYear(e);
            }}
            value={year}
            defaultValue={year}
            options={years}
          />
        )}
        {useIsAdmin() && (
          <ButtonDownload>
            <a
              onClick={() => {
                props.onDownloadReport(documentName);
              }}
              rel="noreferrer"
              role="button"
            >
              <Button size="medium" icon="download" />
            </a>
          </ButtonDownload>
        )}
      </SecondFilters>
    </FiltersForm>
  );
};

export default RankingFilters;
