import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import RegisterForm, { userSchema } from './RegisterForm';
import { ErrorAlert } from 'components/Alert';
import { Register, RegisterData, Login } from 'services/rest/authentication';
import { login, setUserInfo } from 'redux/slices/userSlice';
import { getCurrentCompany } from 'helpers/companyHelper';
import { getCompanyDataById } from 'services/rest/Company/GetCompanyData';
import { setCompanyData } from 'redux/slices/Company/CompanySlice';

const RegisterPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const subdomain = getCurrentCompany();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterData>({
    resolver: yupResolver(userSchema)
  });

  const [backendError, setBackendError] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async ({
    email,
    password,
    confirmPassword
  }: RegisterData) => {
    let registerData;
    if (password === confirmPassword) {
      setLoader(true);
      registerData = await Register({ email, password, confirmPassword });
    }
    const result = registerData as any;
    if (result) {
      if (result.user) {
        dispatch(register());
        const { user, errors } = await Login({ email, password, subdomain });
        if (user) {
          dispatch(login(user));

          const data = await getCompanyDataById(
            user?.token,
            user?.user?.companyId
          );
          if (data) {
            dispatch(setCompanyData(data));
            history.replace('/social_network');
          } else {
            setBackendError('Error');
          }
          setLoader(false);
        }
        if (errors) {
          const error = errors as any;
          setBackendError(error[0]);
          setLoader(false);
        }
      } else {
        setBackendError(result.data.data);
        setLoader(false);
      }
    }
  };

  return (
    <>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      <RegisterForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        loader={loader}
        showPassword={showPassword}
        onClickEye={() => {
          setShowPassword(!showPassword);
        }}
      />
    </>
  );
};

export default RegisterPage;
