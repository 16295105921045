import {
  configureStore,
  combineReducers,
  getDefaultMiddleware
} from '@reduxjs/toolkit';

import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import userReducer from './slices/userSlice';
import employeeReducer from './slices/employeeSlice';
import campaignsSlice from './slices/Campaigns/CampaignsSlice';
import PerformanceSectionReducer from './slices/PerformanceSection/PerformanceSectionSlice';
import AwardReducer from './slices/Award/AwardSlice';
import RankingReducer from './slices/Ranking/RankingSlice';
import CompanySlice from './slices/Company/CompanySlice';
import CompanyMetrics from './slices/CompanyMetrics/CompanyMetricsSlice';
import RouterSlice from './slices/Router/RouterSlice';
import GroupAmbassadorSlice from './slices/GroupAmbassador/GroupAmbassadorSlice';
import InvalidTokenSlice from './slices/InvalidToken/InvalidTokenSlice';
import CompanyStyleSlice from './slices/CompanyStyle/CompanyStyleSlice';

const reducers = combineReducers({
  user: userReducer,
  employee: employeeReducer,
  campaigns: campaignsSlice,
  performanceSection: PerformanceSectionReducer,
  awards: AwardReducer,
  ranking: RankingReducer,
  company: CompanySlice,
  companyMetrics: CompanyMetrics,
  router: RouterSlice,
  groupsAmbassador: GroupAmbassadorSlice,
  invalidToken: InvalidTokenSlice,
  companyStyle: CompanyStyleSlice
});

const persitConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persitConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});

const persistor = persistStore(store);

export { store, persistor };
