import Client from 'config/axios';

export const getBonus = async (token: string, limit: number, page: number) => {
  const { data } = await Client.get(`bonus?limit=${limit}&page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
