import Client from 'config/axios';

export const getCurrencies = async (token: string) => {
  return await Client.get('currencies', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data.data.docs)
    .catch(error => error);
};
