import React, { Fragment, Suspense, SyntheticEvent, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import {
  Filter,
  HeaderSection,
  HeardMargin,
  SocialInfoStyled,
  TextStyled
} from './Styled';
import Input from 'components/inputs/Input';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import Icon from 'components/icons/Icon';
import iconEyes from 'images/icons/eyes.svg';
import iconEyesTransparent from 'images/icons/eyes_transparent.svg';
import iconNoAction from 'images/icons/no_actions.svg';
import iconStar from 'images/icons/star.svg';
import EmployeeAvatar from 'images/avatars/employee.png';

interface DetailChallengeTableProps {
  contentList: any;
  socialNetworks: any;
}

const DetailChallengeTable: React.FC<DetailChallengeTableProps> = props => {
  const { contentList, socialNetworks } = props;

  const { t } = useTranslation(['challenge']);
  const [filterText, setFilterText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const newChallenges = contentList.filter((data: any) => {
    const challenge = data;
    const challengeName = challenge.ambassadorName.toLowerCase();
    const result = challengeName.indexOf(filterText.toLowerCase()) != -1;

    return result;
  });

  const currentChallenges = filterText ? newChallenges : contentList;

  const go_to = (url: string): void => {
    const http = 'http://';
    const https = 'https://';
    url = url.includes(http) || url.includes(https) ? url : https + url;
    window.open(url, '_blank');
  };

  const verifyContent = (list: any, network: string) => {
    let url = '';
    let status = '';
    let points = 0;
    list.contents.filter((content: any) => {
      if (content.socialNetwork === network) {
        url = content.url;
        status = content.status;
        points = content.points;
      }
    });
    if (status === 'approved') {
      return (
        <SocialInfoStyled className="contentPoints">
          <Fragment>
            <img
              alt="eyeIcon"
              className="pointer"
              onClick={() => go_to(url)}
              src={iconEyes}
            />
            <span>
              <b>{points}</b>
              <img alt="iconStar" className="points" src={iconStar} />
            </span>
          </Fragment>
        </SocialInfoStyled>
      );
    } else {
      if (status === 'rejected') {
        return <Icon name="close-rounded" color="red" size="small" />;
      }
      if (url === '') {
        return <img alt="iconNoAction" src={iconNoAction} />;
      } else {
        return (
          <img
            alt="iconEyesTransparent"
            className="pointer"
            onClick={() => go_to(url)}
            src={iconEyesTransparent}
          />
        );
      }
    }
  };

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <h1>{t('challenge:summary')}</h1>
      <HeaderSection>
        <Filter>
          <Input
            type="search"
            placeholder={t('challenge:search')}
            value={filterText}
            onChange={e => {
              setFilterText(e.target.value);
            }}
          />
        </Filter>
      </HeaderSection>
      <HeardMargin>
        <div className="option">
          <img alt="iconEyes" src={iconEyes} />
          <TextStyled>{t('challenge:view_content')}</TextStyled>
        </div>
        <div className="option">
          <Icon name="close-rounded" color="red" size="small" />
          <TextStyled>{t('challenge:content_reject')}</TextStyled>
        </div>
        <div className="option">
          <img alt="iconEyesTransparent" src={iconEyesTransparent} />
          <TextStyled>{t('challenge:view_content_pending')}</TextStyled>
        </div>
        <div className="option">
          <img alt="iconNoAction" src={iconNoAction} />
          <TextStyled>{t('challenge:without_actions')}</TextStyled>
        </div>
      </HeardMargin>
      <TableContainer component={Paper} className="table_container">
        <Table
          sx={{ minWidth: 750 }}
          size="medium"
          aria-label="a dense table"
          className="table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" className="column"></TableCell>
              <TableCell align="center" className="column">
                {t('challenge:name_challenge')}
              </TableCell>
              {socialNetworks.facebook && (
                <TableCell align="center" className="column">
                  <div className="sn-icon">
                    <SocialMediaIcon name={'facebook'} size="medium" />
                  </div>
                </TableCell>
              )}
              {socialNetworks.twitter && (
                <TableCell align="center" className="column">
                  <div className="sn-icon">
                    <SocialMediaIcon name={'twitter'} size="medium" />
                  </div>
                </TableCell>
              )}
              {socialNetworks.linkedin && (
                <TableCell align="center" className="column">
                  <div className="sn-icon">
                    <SocialMediaIcon name={'linkedin'} size="medium" />
                  </div>
                </TableCell>
              )}
              {socialNetworks.instagram && (
                <TableCell align="center" className="column">
                  <div className="sn-icon">
                    <SocialMediaIcon name={'instagram'} size="medium" />
                  </div>
                </TableCell>
              )}
              {socialNetworks.tiktok && (
                <TableCell align="center" className="column">
                  <div className="sn-icon">
                    <SocialMediaIcon name={'tiktok'} size="medium" />
                  </div>
                </TableCell>
              )}
              {socialNetworks.snapchat && (
                <TableCell align="center" className="column">
                  <div className="sn-icon">
                    <SocialMediaIcon name={'snapchat'} size="medium" />
                  </div>
                </TableCell>
              )}
              {socialNetworks.youtube && (
                <TableCell align="center" className="column">
                  <div className="sn-icon">
                    <SocialMediaIcon name={'youtube'} size="medium" />
                  </div>
                </TableCell>
              )}
              {socialNetworks.threads && (
                <TableCell align="center" className="column">
                  <div className="sn-icon">
                    <SocialMediaIcon name={'threads'} size="medium" />
                  </div>
                </TableCell>
              )}
              {socialNetworks.otros && (
                <TableCell align="center" className="column">
                  <div className="sn-icon">
                    <SocialMediaIcon name={'otros'} size="medium" />
                  </div>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? currentChallenges.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : currentChallenges
            ).map((content: any, index: number) => {
              return (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                  className={`column-body`}
                >
                  <TableCell align="center" component="th" scope="row">
                    <img
                      src={content.ambassadorPhoto || EmployeeAvatar}
                      className="user-photo"
                      alt="UserPhoto"
                      onError={evt => addDefaultPicture(evt)}
                    />
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {content.ambassadorName}
                  </TableCell>
                  {socialNetworks.facebook && (
                    <TableCell align="center" component="th" scope="row">
                      {verifyContent(content, 'facebook')}
                    </TableCell>
                  )}
                  {socialNetworks.twitter && (
                    <TableCell align="center" component="th" scope="row">
                      {verifyContent(content, 'twitter')}
                    </TableCell>
                  )}
                  {socialNetworks.linkedin && (
                    <TableCell align="center" component="th" scope="row">
                      {verifyContent(content, 'linkedin')}
                    </TableCell>
                  )}
                  {socialNetworks.instagram && (
                    <TableCell align="center" component="th" scope="row">
                      {verifyContent(content, 'instagram')}
                    </TableCell>
                  )}
                  {socialNetworks.tiktok && (
                    <TableCell align="center" component="th" scope="row">
                      {verifyContent(content, 'tiktok')}
                    </TableCell>
                  )}
                  {socialNetworks.snapchat && (
                    <TableCell align="center" component="th" scope="row">
                      {verifyContent(content, 'snapchat')}
                    </TableCell>
                  )}
                  {socialNetworks.youtube && (
                    <TableCell align="center" component="th" scope="row">
                      {verifyContent(content, 'youtube')}
                    </TableCell>
                  )}
                  {socialNetworks.threads && (
                    <TableCell align="center" component="th" scope="row">
                      {verifyContent(content, 'threads')}
                    </TableCell>
                  )}
                  {socialNetworks.otros && (
                    <TableCell align="center" component="th" scope="row">
                      {verifyContent(content, 'otros')}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500]}
        component="div"
        count={currentChallenges.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Suspense>
  );
};

export default DetailChallengeTable;
