import React from 'react';
import { useTranslation } from 'react-i18next';

import Carousel from 'components/carousel';
import LabelBox from 'components/boxes/LabelBox';
import { StyledAwardsSection } from 'pages/DashboardPage/AwardsSection/Styled';
import { IAward } from 'redux/slices/Award/Award.interface';

export interface AwardsCarouselProps {
  awards: Array<IAward>;
}

const AwardsCarousel: React.FC<AwardsCarouselProps> = props => {
  const { awards } = props;
  const { t } = useTranslation(['dates', 'awards_section']);

  if (awards.length === 0) {
    return null;
  }

  const groupsName = (award: IAward): string => {
    return award.groups?.name != null ? award.groups?.name : '';
  };

  const awardsCards = awards.map((award, index) => {
    const translationPage = t(`dates:${award.periodType}`, {
      returnObjects: true
    });

    const periodValue =
      award.periodType === 'year'
        ? award.periodValue
        : translationPage[parseInt(award.periodValue) - 1];

    const groupName =
      groupsName(award) === 'ALL'
        ? t('awards_section:group_all')
        : groupsName(award);

    return (
      <LabelBox
        image={award.photoUrl}
        content={award.description}
        labels={[periodValue, groupName]}
        key={index}
      />
    );
  });

  return (
    <StyledAwardsSection>
      <Carousel items={awardsCards} startIndex={0} paddingRight={50} />
    </StyledAwardsSection>
  );
};

export default AwardsCarousel;
