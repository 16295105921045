import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export const StyledGeneralDataBox = styled.div`
  ${BaseBox};

  text-align: center;
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const StyledGeneralBox = styled.div`
  text-align: center;
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const StyledDataBoxItem = styled.div`
  position: relative;

  &:before {
    content: '';
    background-color: #d8d8d8;

    position: absolute;
    height: calc(100% + 40px);
    width: 2px;
    right: 0px;
    top: -20px;
    border-radius: 10px;

    @media (min-width: ${Breakpoints.medium}) {
      width: 4px;
    }
  }

  svg {
    margin-right: 10px;
  }

  img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  h3 {
    font-family: inherit;
    color: #333333;
  }

  &:last-of-type {
    &:before {
      background-color: transparent;
    }
  }
`;
