import Client from 'config/axios';

export const getReport = async (
  token: string,
  id: string,
  reportType: string,
  sendEmail: any,
  month?: string,
  year?: string
) => {
  let params;
  switch (reportType) {
    case 'PREVIEW_EMPLOYEE':
      params = {
        reportType: reportType,
        sendEmail: sendEmail
      };
      break;
    case 'CHALLENGE':
      params = {
        reportType: reportType,
        sendEmail: sendEmail,
        challengeId: id
      };
      break;
    case 'CAMPAIGN':
      params = {
        reportType: reportType,
        campaignId: id
      };
      break;
    case 'PARTICIPATIONS_BY_CAMPAIGN':
      params = {
        reportType: reportType,
        campaignId: id
      };
      break;
    case 'PARTICIPATIONS_BY_DATE':
      params = {
        reportType: reportType,
        month: month,
        year: year
      };
      break;
    default:
      params = {
        reportType: reportType
      };
      break;
  }

  const { data } = await Client.get(`report`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
