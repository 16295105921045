import { getCurrentCompanyStyles } from 'helpers/companyHelper';

const companyStyles = getCurrentCompanyStyles();

const Breakpoints = {
  small: '481px',
  medium: '641px',
  xmedium: '878px',
  xxmedium: '980px',
  cLarge: '1024px',
  large: '1025px',
  xLarge: '1200px',
  cxLarge: '1350px',
  xxLarge: '1441px',
  xxxLarge: '1921px'
};

const PrimaryColors = {
  primaryNormalBlue: '#3EABFE',
  primaryNormalPurple: '#b721ff',
  primaryDarkBlue: companyStyles.btnBgColor,
  primaryLightGray: '#979797',
  primaryGray: '#4D4D4D'
};

const SecondaryColors = {
  secondaryNormalYellow: '#FEEA3E',
  secondaryNormalRed: '#ff6266',
  secondaryNormalPink: '#d113fe',
  secondaryLightBlue: '#f1f8ff',
  secondaryLightGray: '#f5f6f8',
  secondaryDarkGrey: '#4a4a4a',
  secondaryDarkPink: '#bd10e0',
  secondaryDarkBlue: '#42526e',
  secondaryNormalGrey: '#4d4d4d',
  secondaryPurple: '#004fea',
  secondarySuccess: '#7ed321'
};

const MessageColors = {
  messageInfo: '#3ea8fe',
  messageError: '#ec5b54',
  messageSuccess: '#7cba53',
  inputError: '#ff303f',

  normalBlack: '#4D4D4D',
  normalWhite: '#FFFFFF',
  normalGreyLight: '#F4F4F4'
};

const SocialMediaColors = {
  facebook: '#3B5998',
  twitter: '#000000',
  linkedin: '#0077b5',
  instagram: '#df3a7b',
  whatsapp: '#25d366',
  tiktok: '#000000',
  snapchat: '#f3ff00',
  youtube: '#ff0000',
  threads: '#000000',
  otros: '#05559C'
};

const IconColors = {
  success: '#7ed321',
  alert: '#ff991f',
  danger: '#d62d2d',
  inactive: '#a5adba',
  verified: '#05559C'
};

const RankingColors = {
  gold: '#f8e71c',
  silver: '#757575',
  bronze: '#ffb953'
};

const Colors: { [key: string]: string } = {
  ...PrimaryColors,
  ...SecondaryColors,
  ...MessageColors,
  ...SocialMediaColors,
  ...IconColors,
  ...RankingColors
};

export { Breakpoints, Colors };
