import React from 'react';
import { useTranslation } from 'react-i18next';

import Advocates from 'images/logos/advocates_logo.svg';
import Contract from 'images/backgrounds/contract.jpg';

import Button from 'components/buttons/Button';

import { Container, Logo, InfoBox, Text, Banner } from './Styled';

const NotFoundPage = () => {
  const { t } = useTranslation('company');
  return (
    <Container>
      <Logo>
        <img alt="Logo" src={Advocates} />
      </Logo>
      <InfoBox>
        <Text>
          <h1>{t('page_not_found')}</h1>
          <Button
            value={t('go_back')}
            variant="primary"
            onClick={() => {
              window.location.assign('/');
            }}
          />
        </Text>
        <Banner>
          <img alt="Background" src={Contract} />
        </Banner>
      </InfoBox>
    </Container>
  );
};

export default NotFoundPage;
