import Client from 'config/axios';

export interface AdminData {
  name: string;
  password: string;
  email: string;
  phone: string;
  allow2FA: boolean;
  role: string;
  username: string;
  companyId: string;
  allowContentsEmail: boolean;
}

export const updateAdmin = async (
  id: number,
  token: string,
  adminData: AdminData
) => {
  try {
    const { data } = await Client.post(`administrator/${id}`, adminData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.data };
  }
};
