import React from 'react';
import {
  GetSignedUrlS3,
  UploadSignedUrlToS3
} from 'services/rest/UploadImage/UploadImgToS3';

export async function awsUploadVideoHelper(
  currentUser: string,
  video: any,
  folder: string,
  name: string,
  t: any,
  setError: any
) {
  let uploaded = false;
  const getUrl = await GetSignedUrlS3(currentUser, name, folder);
  if (getUrl?.status === 200) {
    const url = getUrl?.data?.data;
    const upload = await UploadSignedUrlToS3(
      url,
      video?.file,
      video?.file?.type
    );
    if (upload?.status === 200) {
      uploaded = true;
    } else {
      setError(t('validations:file_error'));
      return;
    }
  } else {
    setError(t('validations:file_error'));
    return;
  }
  return uploaded;
}
