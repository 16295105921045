import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import { Igroup } from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';
import { Points, SocialNetworkCampaign } from 'types/appTypes';

export const networkCampaign = (
  campaign: ICampaigns,
  contentType: string,
  content: string,
  picturesUrls: Array<string> = [],
  videoFileUrl: string,
  pdfUrl: string,
  urnLi: string,
  provider: string,
  points: Points,
  totalPoints: number,
  detailPoints: Points,
  linked: boolean,
  invalidToken: boolean,
  status: string,
  networkFlagParticipation: boolean,
  uid: string
): SocialNetworkCampaign => {
  return {
    contentType: contentType,
    content: content,
    picturesUrls: picturesUrls,
    videoFileUrl: videoFileUrl,
    pdfUrl: pdfUrl,
    urnLi: urnLi,
    provider: provider,
    points: points,
    userPosts: provider === 'facebook' ? campaign.userPosts : [],
    employeePost: {
      id: 1,
      actableType: '',
      status: status,
      content: content,
      totalPoints: totalPoints,
      detailPoints: detailPoints,
      networkFlagParticipation: networkFlagParticipation,
      realPostUrl: uid,
      socialNetworkAccount: {
        id: 1,
        linked: linked,
        invalidToken: invalidToken || false,
        provider: provider
      }
    }
  };
};

export const networkCampaignNull = (): SocialNetworkCampaign => {
  return {
    contentType: '',
    content: '',
    picturesUrls: [],
    videoFileUrl: '',
    pdfUrl: '',
    urnLi: '',
    provider: '',
    points: {} as Points,
    employeePost: {
      id: 1,
      actableType: '',
      status: '',
      content: '',
      totalPoints: 0,
      detailPoints: {} as Points,
      networkFlagParticipation: false,
      realPostUrl: '',
      socialNetworkAccount: {
        id: 1,
        linked: true,
        invalidToken: true,
        provider: 'true'
      }
    }
  };
};

export const groups = (select: Array<Igroup>) => {
  const data = select.map(select => {
    const body = { id: select.id, name: select.name };
    return body;
  });
  return data;
};
