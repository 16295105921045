import styled from 'styled-components';
import { BaseStyles } from 'components/inputs/BaseInput';

export const MarginTextArea = styled.div``;

const StyledTextArea = styled.textarea`
  ${BaseStyles};

  border-radius: 10px;
  line-height: 1.7;
  resize: none;
`;

export default StyledTextArea;
