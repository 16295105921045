import React from 'react';
import StyledVerticalWrapper from './Styled';
import Label from 'components/Label';

export interface VerticalWrapperProps {
  label: string;
}

const VerticalWrapper: React.FC<VerticalWrapperProps> = props => {
  const { label, children, ...otherProps } = props;

  return (
    <StyledVerticalWrapper {...otherProps}>
      <Label text={label} />
      {children}
    </StyledVerticalWrapper>
  );
};

export default VerticalWrapper;
