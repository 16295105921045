import Client from 'config/axios';

export const getCampaignDataById = async (
  token: string,
  id: any,
  ranking: boolean,
  withParticipation: boolean
) => {
  return await Client.get(
    `campaign/${id}?ranking=${ranking}&withParticipation=${withParticipation}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
    .then(response => response.data)
    .catch(error => error);
};
