import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';
import TextArea from 'components/inputs/TextArea';
import UploadFileImageSimple from 'components/inputs/UploadFile/UploadFileImageSimple';
import {
  SectionForm,
  InputWrapper,
  FileSection,
  FileWrapper,
  FlexWrapper,
  ButtonStyled
} from '../../Styled';
import { List as LoadingState } from 'react-content-loader';
import { formatSelectOptions } from 'helpers/selectFormatHelper';
import { CompanyData } from 'services/rest/SuperAdmin/Company/createCompany';
import Spinner from 'components/Spinner/Spinner';
import ReactSelect from 'react-select';

export interface UpdateCompanyFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<CompanyData>;
  register: UseFormMethods['register'];
  company: any;
  loading: any;
  setValue: any;
  control: any;
  loader: boolean;
  countries: any;
  currencies: any;
  handleImage: any;
}

const UpdateCompanyForm: React.FC<UpdateCompanyFormProps> = props => {
  const {
    onSubmit,
    errors,
    register,
    company,
    loading,
    setValue,
    loader,
    countries,
    currencies,
    handleImage
  } = props;
  const { t } = useTranslation(['super_admin_company']);
  const languages = [
    { label: t('super_admin_company:form:spanish'), value: 'es' },
    { label: t('super_admin_company:form:english'), value: 'en' },
    { label: t('super_admin_company:form:portuguese'), value: 'pt' }
  ];

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }
  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <SectionWrapper>
        <NavigationLink
          text={t('super_admin_company:form:back')}
          direction="back"
          to={'/companies'}
        />
        <FlexWrapper>
          <h1>{t('super_admin_company:edit_company')}</h1>
        </FlexWrapper>
        <form onSubmit={onSubmit}>
          <SectionForm>
            <InputWrapper>
              <label>{t('super_admin_company:form:name')}</label>
              <div className="w-100">
                <Input
                  name="name"
                  type="text"
                  placeholder={t('super_admin_company:form:name')}
                  defaultValue={company?.name}
                  ref={register}
                  error={errors.name?.message}
                />
              </div>
            </InputWrapper>
            <InputWrapper className="disabled-field">
              <label>{t('super_admin_company:form:subdomain')}</label>
              <div className="w-100">
                <Input
                  name="subdomain"
                  type="text"
                  placeholder={t('super_admin_company:form:subdomain')}
                  defaultValue={company?.subdomain}
                  error={errors.name?.message}
                />
              </div>
            </InputWrapper>
            <InputWrapper className="disabled-field">
              <label>{t('super_admin_company:form:language')}</label>
              <div className="w-100">
                <ReactSelect
                  options={languages}
                  defaultValue={
                    languages.filter((lang: any) => {
                      return lang.value == company?.language;
                    })[0]
                  }
                  className="dropdown-filter-container m-l-0"
                  classNamePrefix="dropdown-filter-content"
                  isDisabled={true}
                />
              </div>
            </InputWrapper>
            <InputWrapper className="disabled-field">
              <label>{t('super_admin_company:form:country')}</label>
              <div className="w-100">
                <ReactSelect
                  options={formatSelectOptions(countries)}
                  defaultValue={
                    formatSelectOptions(countries)[company?.countryId - 1]
                  }
                  className="dropdown-filter-container m-l-0"
                  classNamePrefix="dropdown-filter-content"
                  isDisabled={true}
                />
              </div>
            </InputWrapper>
            <InputWrapper className="disabled-field">
              <label>{t('super_admin_company:form:currency')}</label>
              <div className="w-100">
                <ReactSelect
                  options={formatSelectOptions(currencies)}
                  defaultValue={
                    formatSelectOptions(currencies)[company?.currencyId - 1]
                  }
                  className="dropdown-filter-container m-l-0"
                  classNamePrefix="dropdown-filter-content"
                  isDisabled={true}
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <label>{t('super_admin_company:form:ambassadors_limit')}</label>
              <div className="w-100">
                <Input
                  name="ambassadorsLimit"
                  type="number"
                  placeholder={t('super_admin_company:form:ambassadors_limit')}
                  defaultValue={company?.ambassadorsLimit}
                  ref={register}
                  error={errors.ambassadorsLimit?.message}
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <label>{t('super_admin_company:form:license_date')}</label>
              <div className="w-100">
                <Input
                  name="licenseDate"
                  type="date"
                  defaultValue={company?.licenseDate}
                  ref={register}
                  error={errors.licenseDate?.message}
                />
              </div>
            </InputWrapper>

            <FileSection>
              <InputWrapper className="inputFile">
                <label>{t('super_admin_company:form:backgroundImage')}</label>
                <FileWrapper>
                  <UploadFileImageSimple
                    name="backgroundImage"
                    handleImage={handleImage}
                    content="backgroundImage"
                    maxFileSize={1000}
                    infoBox={t('super_admin_company:form:info_box_file')}
                    imagesArray={[company?.backgroundImage]}
                    allowedFileTypes="image/jpeg"
                  />
                </FileWrapper>
              </InputWrapper>
              <InputWrapper className="inputFile">
                <label>{t('super_admin_company:form:profileLogo')}</label>
                <FileWrapper>
                  <UploadFileImageSimple
                    name="logo"
                    handleImage={handleImage}
                    className="socialNetworkFile"
                    content="logo"
                    maxFileSize={1000}
                    infoBox={t('super_admin_company:form:info_box_file')}
                    imagesArray={[company?.logo]}
                    allowedFileTypes="image/svg+xml"
                  />
                </FileWrapper>
              </InputWrapper>
              <InputWrapper className="inputFile">
                <label>{t('super_admin_company:form:emailLogo')}</label>
                <FileWrapper>
                  <UploadFileImageSimple
                    name="emailLogo"
                    handleImage={handleImage}
                    className="socialNetworkFile"
                    content="emailLogo"
                    maxFileSize={1000}
                    infoBox={t('super_admin_company:form:info_box_file')}
                    imagesArray={[company?.emailLogo]}
                    allowedFileTypes="image/png"
                  />
                </FileWrapper>
              </InputWrapper>
              <InputWrapper className="inputFile">
                <label>{t('super_admin_company:form:tosUrl')}</label>
                <FileWrapper>
                  <UploadFileImageSimple
                    name="tosUrl"
                    handleImage={handleImage}
                    className="socialNetworkFile"
                    content="tosUrl"
                    maxFileSize={1000}
                    infoBox={t('super_admin_company:form:info_box_file')}
                    imagesArray={[company?.TosUrl]}
                    allowedFileTypes="application/pdf"
                  />
                </FileWrapper>
              </InputWrapper>
            </FileSection>
            <ButtonStyled>
              {!loader ? (
                <Button
                  value={t('super_admin_company:update')}
                  variant="primary"
                />
              ) : (
                <Spinner name="sign-up" />
              )}
            </ButtonStyled>
          </SectionForm>
        </form>
      </SectionWrapper>
    </Suspense>
  );
};

export default UpdateCompanyForm;
