import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AcceptPostRequest } from 'services/rest/post';
import { FormWrapper, InputsWrapper, LabelForm, MarginButton } from './Styled';
import Button from 'components/buttons/Button';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { usePostActions } from '../PostActions';

type SelectOption = {
  value: string;
  label: string;
};

type FormState = {
  description: any;
  privacyLevel: any;
  checkboxes: {
    comment: any;
    duet: any;
    stitch: any;
  };
};

const options: SelectOption[] = [
  { value: 'PUBLIC_TO_EVERYONE', label: 'Everyone' },
  { value: 'MUTUAL_FOLLOW_FRIENDS', label: 'Friends' },
  { value: 'SELF_ONLY', label: 'Only me' }
];

interface IProps {
  campaign?: ICampaigns;
  postId: number;
  setPublicationLoader?: any;
  setShowButton?: any;
  getRankingData?: any;
}

const ContentTiktok: React.FC<IProps> = ({
  campaign,
  postId,
  setPublicationLoader,
  setShowButton,
  getRankingData
}) => {
  const [formState, setFormState] = useState<FormState>({
    description: '',
    privacyLevel: options[0].value,
    checkboxes: {
      comment: campaign?.creatorTiktok.comment_disabled,
      duet: campaign?.creatorTiktok.duet_disabled,
      stitch: campaign?.creatorTiktok.stitch_disabled
    }
  });
  const { t } = useTranslation(['campaigns_page']);
  const [loader, setLoader] = useState(false);
  const { onAcceptPost, onErrorPost } = usePostActions();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setFormState(prevState => ({
        ...prevState,
        checkboxes: {
          ...prevState.checkboxes,
          [name]: checked
        }
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormState(prevState => ({
      ...prevState,
      privacyLevel: event.target.value
    }));
  };

  const { currentUser } = useSelector(selectUser);

  const handleSubmit = async (event: React.FormEvent) => {
    setLoader(true);
    event.preventDefault();
    const { data, errors } = await AcceptPostRequest(
      Number(campaign?.id),
      '',
      'tiktok',
      currentUser?.token,
      {
        title: formState.description,
        privacy_level: formState.privacyLevel,
        disable_duet: formState.checkboxes.duet,
        disable_comment: formState.checkboxes.comment,
        disable_stitch: formState.checkboxes.stitch
      },
      false,
      ''
    );
    if (data) {
      onAcceptPost(postId, data.data);
      setLoader(false);
      setPublicationLoader(false);
      setShowButton(data.data);
      getRankingData();
    }
    if (errors) {
      onErrorPost(t('settings:error_admin'));
      if (errors && errors.data) {
        onErrorPost(errors.data);
      }
      setLoader(false);
      setPublicationLoader(false);
      setShowButton('pending');
    }
  };

  return (
    <>
      <h1 className="section-title">Upload to Tiktok</h1>
      <div>
        <img src={campaign?.creatorTiktok.creator_avatar_url} alt="" />
        <h3>{campaign?.creatorTiktok.creator_username}</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <InputsWrapper>
            <LabelForm>
              <div className="label">
                <label>Title:</label>
              </div>
              <div>
                <input
                  type="text"
                  name="description"
                  value={formState.description}
                  onChange={handleInputChange}
                />
              </div>
            </LabelForm>
          </InputsWrapper>
          <InputsWrapper>
            <LabelForm>
              <div className="label">
                <label>Who can view this video:</label>
              </div>
              <div>
                <select
                  value={formState.privacyLevel}
                  onChange={handleSelectChange}
                  className="shareoptions"
                >
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </LabelForm>
          </InputsWrapper>
          <InputsWrapper>
            <LabelForm>
              <div className="label">
                <label>Allow users to:</label>
              </div>
              <div className="checkboxes">
                <label>
                  <input
                    type="checkbox"
                    name="comment"
                    checked={formState.checkboxes.comment}
                    onChange={handleInputChange}
                  />
                  Comment
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    name="duet"
                    checked={formState.checkboxes.duet}
                    onChange={handleInputChange}
                  />
                  Duet
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    name="stitch"
                    checked={formState.checkboxes.stitch}
                    onChange={handleInputChange}
                  />
                  Stitch
                </label>
              </div>
            </LabelForm>
          </InputsWrapper>
          <MarginButton>
            <Button value={'Upload'} variant="primary" loader={loader} />
          </MarginButton>
        </FormWrapper>
      </form>
    </>
  );
};

export default ContentTiktok;
