import React, { Suspense, useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { useTranslation } from 'react-i18next';
import { MarginTitlePage } from './Styled';
import AdminSuggestedPosts from './AdminSuggestedPosts';
import { useHistory, useLocation } from 'react-router-dom';
import { SuccessAlert } from 'components/Alert';
import Button from 'components/buttons/Button';
import AmbassadorSuggestedPosts from './AmbassadorSuggestedPosts';
import { getSuggestedPosts } from 'services/rest/SuggestedPost/getSuggestedPosts';

const SuggestedPosts = () => {
  const { currentUser } = useSelector(selectUser);
  const [suggestedPosts, setSuggestedPosts] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(['suggested_post']);
  const location = useLocation();
  const history = useHistory();
  const { state } = location as any;
  const [changeSuggestedPost, setChangeSuggestedPost] = useState<
    boolean | null
  >(false);
  const [backendSuccess, setBackendSuccess] = useState<any | null>(null);

  const showAlertSuccessChallengeCreated = async () => {
    if (typeof state === 'string') {
      setBackendSuccess(state);
    }
  };

  const getData = async () => {
    const { data } = await getSuggestedPosts(currentUser?.token, 500, 1);
    if (data) {
      setSuggestedPosts(data.data.docs);
    }
    showAlertSuccessChallengeCreated();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [changeSuggestedPost]);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <SectionWrapper>
          <NavigationLink
            text={t('suggested_post:back')}
            direction="back"
            to="/dashboard"
          />
          <MarginTitlePage>
            <h1>{t('suggested_post:suggested_posts')}</h1>
            {currentUser.role === 'ambassador' && (
              <Button
                value={t('suggested_post:create_suggested_post')}
                variant="primary"
                onClick={() =>
                  history.replace({ pathname: `/suggested-posts/new` })
                }
              />
            )}
          </MarginTitlePage>
          {currentUser.role === 'admin' ? (
            <AdminSuggestedPosts
              suggestedPosts={suggestedPosts}
              setLoading={setLoading}
              changeSuggestedPost={changeSuggestedPost}
              setChangeSuggestedPost={setChangeSuggestedPost}
            />
          ) : (
            <AmbassadorSuggestedPosts
              suggestedPosts={suggestedPosts}
              setLoading={setLoading}
              changeSuggestedPost={changeSuggestedPost}
              setChangeSuggestedPost={setChangeSuggestedPost}
            />
          )}
        </SectionWrapper>
      </Suspense>
    </>
  );
};

export default SuggestedPosts;
