import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import Button from 'components/buttons/Button';
import {
  DivIcon,
  GridWrapper,
  MarginText
} from '../../ChallengesPage/AmbassadorChallenges/Styled';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import DefaultImage from 'images/empty_states/empty_state_search.png';
import Tag from 'components/Tag/Tag';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';

const CallToAction: React.FC<ICampaigns> = props => {
  const { id, status } = props;

  const { t } = useTranslation(['campaign_card', 'challenge']);
  const history = useHistory();

  if (status == 'pending') {
    return (
      <Button
        value={t('campaign_section:coming_soon')}
        disabled={true}
        size="medium"
        variant="primary"
      />
    );
  }

  return (
    <Button
      value={
        status == 'completed'
          ? t('campaign_section:see_results')
          : t('campaign_section:participate')
      }
      size="medium"
      variant="primary"
      onClick={() =>
        history.replace({ pathname: `/campaigns/${id}`, search: `?All` })
      }
    />
  );
};

const CampaignCard: React.FC<ICampaigns> = props => {
  const {
    id,
    photo,
    startDate,
    status,
    finishDate,
    name,
    includesFacebook,
    includesTwitter,
    includesLinkedin,
    includesInstagram,
    includesTiktok,
    includesWhatsapp
  } = props;

  const history = useHistory();
  const timeLine = (startDate: any, finishDate: any) => {
    startDate = moment(startDate);
    finishDate = moment(finishDate);
    const startMonth = startDate.format('MMM');
    const startDay = startDate.format('D');
    const finishMonth = finishDate.format('MMM');
    const finishDay = finishDate.format('D');
    return `${startMonth} ${startDay} - ${finishMonth} ${finishDay}`;
  };

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = DefaultImage;
  };

  return (
    <GridWrapper className="hide-on-mobile activated-campaign hoverable small">
      {status === 'pending' && <Tag value="Proximamente" variant="invert" />}
      <div
        onClick={() => history.replace({ pathname: `/campaigns/${id}` })}
        className="content-box-header content-img"
      >
        <img
          src={photo || DefaultImage}
          onError={evt => addDefaultPicture(evt)}
          alt="new"
          className="image-label"
        />
      </div>

      <MarginText
        className="m-auto"
        onClick={() => history.replace({ pathname: `/campaigns/${id}` })}
      >
        <p className="time-line">{timeLine(startDate, finishDate)}</p>
        <div>
          <h2>{name}</h2>
        </div>
        <p
          className="social-networks"
          onClick={() => history.replace({ pathname: `/campaigns/${id}` })}
        >
          {includesFacebook && (
            <SocialMediaIcon size="small" shape="square" name="facebook" />
          )}
          {includesTwitter && (
            <SocialMediaIcon size="small" shape="square" name="twitter" />
          )}
          {includesLinkedin && (
            <SocialMediaIcon size="small" shape="square" name="linkedin" />
          )}
          {includesInstagram && (
            <SocialMediaIcon size="small" shape="square" name="instagram" />
          )}
          {includesWhatsapp && (
            <SocialMediaIcon size="small" shape="square" name="whatsapp" />
          )}
          {includesTiktok && (
            <SocialMediaIcon size="small" shape="square" name="tiktok" />
          )}
        </p>
      </MarginText>
      <DivIcon>
        <CallToAction {...props} />
      </DivIcon>
    </GridWrapper>
  );
};

export default CampaignCard;
