import Client from 'config/axios';

export interface Ambassador {
  name: string;
  lastName: string;
  language: string;
  email: string;
  phone: string;
  code: string;
  allowBonus: boolean;
  allowSuggestedPost: boolean;
  companyId: number;
  groups: any;
  groupsIds: [];
}

export const createAmbassador = async (
  token: string,
  ambassador: Ambassador
) => {
  try {
    const { data } = await Client.post('ambassador', ambassador, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (err) {
    if (err.response) {
      return { errors: err.response.data };
    } else {
      return { errors: err.message };
    }
  }
};
