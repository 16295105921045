import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import useAdmin from 'hooks/useIsAdmin.hook';
import CreateChallengePage from 'pages/ChallengesPage/CreateChallengesPage';
import Challenges from 'pages/ChallengesPage';
import EditChallengePage from 'pages/ChallengesPage/EditChallengesPage/EditChallengePage';
import DetailChallengesPage from 'pages/ChallengesPage/DetailChallengesPage/DetailChallengePage';

const ChallengesRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/new`}>
        {useAdmin() ? (
          <CreateChallengePage />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </Route>

      <Route exact path={`${match.path}/detail/:challengeId`}>
        <DetailChallengesPage />
      </Route>

      <Route exact path={`${match.path}/:challengeId/:action`}>
        <EditChallengePage />
      </Route>

      <Route exact path={`${match.path}`}>
        <Challenges />
      </Route>
    </Switch>
  );
};

export default ChallengesRouter;
