import React, { useEffect, useState, Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import EmployeesTable from 'components/GroupTable';
import Input from 'components/inputs/Input';
import { Section_body } from './Styled';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { GroupEmployees } from 'components/GroupTable/GroupTable';
import { getGroupById } from 'services/rest/Group/getGroupById';
import { getGroupDataById } from 'services/rest/Group/getGroupDataById';
import TableFooter from 'components/TableFooter';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';

interface GroupParams {
  groupId: string;
}

interface GroupDetailHeadProps {
  setBackendError?: any;
  setBackendSuccess?: any;
  bonusState: any;
}

const GroupDetailBody: React.FC<GroupDetailHeadProps> = props => {
  const { setBackendError, setBackendSuccess, bonusState } = props;
  const { t } = useTranslation(['group_page']);
  const { groupId } = useParams<GroupParams>();
  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState<GroupEmployees>([]);
  const [employees, setEmployees] = useState<any>([]);
  const [groupData, setGroupData] = useState<any>([]);
  const [textFilter, setTextFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const getData = async () => {
    const { data } = await getGroupById(currentUser?.token, groupId, 'true');
    const result = await getGroupDataById(currentUser?.token, groupId);
    setGroup(data.data.ambassadors);
    setEmployees(data.data.ambassadors);
    setGroupData(result?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [bonusState]);

  const statusOptions = [
    { label: t('group_page:status_all'), value: 'all' },
    { label: t('group_page:status_registered'), value: 'registered' },
    { label: t('group_page:status_unregistered'), value: 'unregistered' },
    { label: t('group_page:status_with_bonus'), value: 'withBonus' },
    { label: t('group_page:status_without_bonus'), value: 'withoutBonus' }
  ];

  const [selectedStatus, setStatus] = useState(statusOptions[0]);

  const new_employees = employees?.filter((data: any) => {
    const userData = data as any;
    const userName = userData.name.toLowerCase();
    const usenameRemove = userName
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const userLastName = userData.lastName.toLowerCase();
    const usenLastNameRemove = userLastName
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const nameComplete = userName + ' ' + userLastName;
    const nameRemoveComplete = usenameRemove + ' ' + usenLastNameRemove;
    const userEmail = userData.email.toLowerCase();
    const result =
      nameComplete.indexOf(textFilter.toLowerCase()) != -1 ||
      nameRemoveComplete.indexOf(textFilter.toLowerCase()) != -1 ||
      userName.indexOf(textFilter.toLowerCase()) != -1 ||
      usenameRemove.indexOf(textFilter.toLowerCase()) != -1 ||
      userLastName.indexOf(textFilter.toLowerCase()) != -1 ||
      usenLastNameRemove.indexOf(textFilter.toLowerCase()) != -1 ||
      userEmail.indexOf(textFilter.toLowerCase()) != -1;
    return result;
  });

  const currentEmployees = textFilter
    ? new_employees
    : employees?.slice(indexOfFirstPost, indexOfLastPost);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const selectOption = (event: any) => {
    let filterGroup = group;
    switch (event.value) {
      case 'registered':
        filterGroup = group?.filter((employee: any) => employee?.registered);
        break;
      case 'unregistered':
        filterGroup = group?.filter((employee: any) => !employee?.registered);
        break;
      case 'subscribed':
        filterGroup = group?.filter((employee: any) => !employee?.unsubscribe);
        break;
      case 'unsubscribed':
        filterGroup = group?.filter((employee: any) => employee?.unsubscribe);
        break;
      case 'withBonus':
        filterGroup = group?.filter((employee: any) => employee?.allowBonus);
        break;
      case 'withoutBonus':
        filterGroup = group?.filter((employee: any) => !employee?.allowBonus);
        break;
      default:
        filterGroup = group;
        break;
    }
    setStatus(event);
    setEmployees(filterGroup);
  };

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <Section_body>
        <Input
          type="search"
          placeholder={t('search_name')}
          value={textFilter}
          onChange={event => {
            setTextFilter(event.target.value);
          }}
        />
        <span>{t('filter')}</span>
        <DropdownSelect
          options={statusOptions}
          value={selectedStatus}
          onSelect={selectOption}
        />
      </Section_body>
      <EmployeesTable employees={currentEmployees} isAll={groupData?.isAll} />
      <TableFooter
        postsPerPage={postsPerPage}
        totalPosts={textFilter ? new_employees : employees?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </Suspense>
  );
};

export default GroupDetailBody;
