import { Colors, Breakpoints } from 'styles/Constants';
import styled from 'styled-components';
import {
  ContentVerticalBox,
  HeaderVerticalBox,
  BodyVerticalBox
} from 'components/boxes/VerticalBox/StyledVerticalBox';

export const HeaderLabelBox = styled(HeaderVerticalBox)`
  position: relative;

  div.tags {
    position: absolute;
    margin-left: 10px;
    margin-top: 20px;
    display: flex;
    bottom: 6px;
  }

  img {
    height: 250px;
    object-fit: cover;
    width: 250px;

    @media (min-width: ${Breakpoints.large}) {
      height: 250px;
    }
  }

  span {
    background: ${Colors.primaryNormalPurple};
    color: ${Colors.normalWhite};
    border-radius: 5px 5px 0 0;
    margin-right: 15px;
    padding: 4px 10px;
    font-weight: 500;
    line-height: 1.5;
    font-size: 12px;
  }
`;

export const BodyLabelBox = styled(BodyVerticalBox)`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0 15px;
`;

export const StyledLabelBox = styled(ContentVerticalBox)`
  box-shadow: 0 5px 13px 0 rgba(171, 169, 169, 0.38);
  border-radius: 5px;
  max-width: 500px;
  width: 60%;

  &.width-auto {
    width: auto !important;
    .header-card-container {
      img {
        width: 250px;
        height: 250px;
        line-height: 250px;
      }
    }
  }

  p {
    white-space: pre-line;
    margin: 30px 0 25px 0;
    font-weight: 500;
    height: 63px;
    line-height: 1.5;
    font-size: 14px;
    color: ${Colors.secondaryDarkGrey};
    text-align: start !important;
  }
`;
