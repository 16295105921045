import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Facebook as LoadingState } from 'react-content-loader';

import Input from 'components/inputs/Input';
import TableFooter from 'components/TableFooter';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';
import { Ranking, RankingScrollContainer } from './Styled';
import CampaignDetailTable from 'components/CampaignDetailTable';

export interface CampaignDetailRankingProps {
  ranking: any;
  getRankingData: any;
  currentUser: any;
}

const CampaignDetailRanking: React.FC<CampaignDetailRankingProps> = props => {
  const { ranking, getRankingData, currentUser } = props;
  const { t } = useTranslation(['dates', 'ranking_filters']);
  const [name, setName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const newAmbassadors = ranking?.filter((data: any) => {
    const userData = data as any;
    const result =
      userData.name.toLowerCase().indexOf(name.toLowerCase()) != -1;
    return result;
  });

  const currentRanking = name
    ? newAmbassadors
    : newAmbassadors?.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <Ranking>
        <Input
          placeholder={t('ranking_filters:search_employees')}
          type="search"
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <RankingScrollContainer id="scroll_container">
          {ranking.length > 0 && (
            <>
              <CampaignDetailTable
                employees={currentRanking}
                currentEmployeeId={currentUser.user.id}
              />
              <TableFooter
                postsPerPage={postsPerPage}
                totalPosts={ranking?.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </>
          )}
        </RankingScrollContainer>
      </Ranking>
    </Suspense>
  );
};

export default CampaignDetailRanking;
