import React, { useEffect, useState } from 'react';

import Button from 'components/buttons/Button';
import Input from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import TextArea from 'components/inputs/TextArea';
import NavigationLink from 'components/links/NavigationLink';
import { RequiredString } from 'helpers/yupHelper';
import moment from 'moment';
import {
  ButtonStyled,
  InputWrapper,
  SectionForm,
  CalendarSection
} from 'pages/AwardPageAdmin/NewAwardPage/Styled';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { AwardData } from 'services/rest/Award/createAward';
import { getGroupsList } from 'services/rest/Group/getGroupsList';
import * as yup from 'yup';
import UploadFileCropper from 'components/inputs/UploadFileCropper';
import Calendar from 'components/Calendar';

export interface CreateAwardFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<AwardData>;
  register: UseFormMethods['register'];
  handleImage: any;
  setGroupsIds: any;
  loader: any;
  setStartDate: any;
  setEndDate: any;
}

export const userSchema = yup.object().shape({
  groupsIds: RequiredString(),
  description: RequiredString().max(1000)
});

const CreateAwardForm: React.FC<CreateAwardFormProps> = props => {
  const {
    errors,
    onSubmit,
    register,
    handleImage,
    setGroupsIds,
    loader,
    setStartDate,
    setEndDate
  } = props;
  const { t } = useTranslation(['dates', 'awards_section', 'group_page']);
  const { currentUser } = useSelector(selectUser);
  const [groups, setGroups] = useState([]);
  const [filter, setFilter] = useState('month');
  const [value, setValue] = useState('');
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [checked, setChecked] = useState({
    month: true,
    quarter: false,
    year: false,
    custom: false
  });
  const getData = async () => {
    const { data } = await getGroupsList(currentUser?.token, 100, 1);
    setGroups(data.data.docs);
  };

  useEffect(() => {
    getData();
  }, []);

  const year = [
    { text: new Date().getFullYear(), value: new Date().getFullYear() },
    { text: new Date().getFullYear() + 1, value: new Date().getFullYear() + 1 },
    { text: new Date().getFullYear() + 2, value: new Date().getFullYear() + 2 },
    { text: new Date().getFullYear() + 3, value: new Date().getFullYear() + 3 }
  ];

  const selectGroups = groups.map((group: any) => {
    return { text: group.name, value: group.id };
  });

  selectGroups.sort((a, b) => {
    return a.value - b.value;
  });

  const currentDate = moment();

  const monthsData = t('dates:month', {
    returnObjects: true,
    defaultValue: []
  });

  const months = Array.isArray(monthsData)
    ? monthsData.map((month: any, index: any) => ({
        text: month,
        value: moment().month(index).format('M')
      }))
    : [];

  const availableMonths = months.splice(
    (currentDate.format('M') as any) - 1,
    12
  );

  const datesQuarterData = t('dates:quarter', {
    returnObjects: true,
    defaultValue: []
  });

  const datesQuarter = Array.isArray(datesQuarterData)
    ? datesQuarterData.map((quarter, index) => ({
        text: quarter,
        value: index + 1
      }))
    : [];

  const slicePosition = Math.floor((currentDate.format('M') as any) / 3);

  const quarter = datesQuarter.splice(slicePosition - 4, datesQuarter.length);

  const onSelectedPeriodType = (periodType: string) => {
    switch (periodType) {
      case 'month':
        setChecked({
          month: true,
          quarter: false,
          year: false,
          custom: false
        });
        break;
      case 'quarter':
        setChecked({
          month: false,
          quarter: true,
          year: false,
          custom: false
        });
        break;
      case 'year':
        setChecked({
          month: false,
          quarter: false,
          year: true,
          custom: false
        });
        break;
      case 'custom':
        setChecked({
          month: false,
          quarter: false,
          year: false,
          custom: true
        });
        break;
      default:
        break;
    }
    setFilter(periodType);
    setSelectedValue('');
    setSelectedYear('');
  };

  const PeriodType = ({ options, setState, state, name }: any): JSX.Element => {
    return (
      <Select
        options={options}
        value={state}
        onSelect={e => e}
        placeholder={t('awards_section:select_an_option')}
        name={name}
        data-testid={name}
        register={register}
        onChange={e => {
          setState(e.target.value);
        }}
        error={errors.periodValue?.message}
        required={true}
      />
    );
  };

  const onSelectGroup = (event: any) => {
    const groupId = [];
    if (event.target.options[event.target.selectedIndex].text == 'ALL') {
      selectGroups.flatMap((group: any) => {
        groupId.push(group.value);
      });
    } else {
      groupId.push(event.target.value);
    }
    setGroupsIds(groupId);
    setSelectedGroup(event.target.value);
  };

  const onSelectCalendar = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <SectionWrapper>
      <NavigationLink
        text={t('group_page:back')}
        direction="back"
        to={'/admin/award'}
      />
      <h1>{t('awards_section:create_award')}</h1>
      <form onSubmit={onSubmit}>
        <SectionForm>
          <InputWrapper>
            <label>{t('Imagen')}</label>
            <div className="marginUpload">
              <UploadFileCropper
                name="photoUrl"
                handleImage={handleImage}
                maxFileSize={2000}
                ref={register}
                infoBox={t('awards_section:file_info_box')}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('group_page:groups')}</label>
            <div className="marginUpload">
              <Select
                options={selectGroups}
                value={selectedGroup}
                name="groupsIds"
                data-testid="groupsIds"
                placeholder={t('awards_section:select_an_option')}
                register={register}
                onChange={onSelectGroup}
                error={errors.groupsIds?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('awards_section:period')}</label>
            <div className="inputRadio">
              <div>
                <Input
                  type="radio"
                  checked={checked.month}
                  id="month"
                  name="month"
                  onChange={() => onSelectedPeriodType('month')}
                />
                <label>{t('dates:period_type.month')}</label>
              </div>
              <div>
                <Input
                  type="radio"
                  checked={checked.quarter}
                  id="quarter"
                  name="quarter"
                  onChange={() => onSelectedPeriodType('quarter')}
                />
                <label>{t('dates:period_type.quarter')}</label>
              </div>
              <div>
                <Input
                  type="radio"
                  checked={checked.year}
                  id="year"
                  name="year"
                  onChange={() => onSelectedPeriodType('year')}
                />
                <label>{t('dates:period_type.year')}</label>
              </div>
              <div>
                <Input
                  type="radio"
                  checked={checked.custom}
                  id="custom"
                  name="custom"
                  onChange={() => onSelectedPeriodType('custom')}
                />
                <label>{t('dates:period_type.custom')}</label>
              </div>
            </div>
          </InputWrapper>
          <Input
            type="text"
            value={filter}
            name="periodType"
            ref={register}
            error={errors.periodType?.message}
            data-testid="periodType"
            style={{ display: 'none' }}
          />
          <InputWrapper>
            <label>{t('awards_section:period_selection')}</label>
            <div className="marginUpload">
              {checked.month && (
                <>
                  <PeriodType
                    options={availableMonths}
                    setState={setSelectedValue}
                    state={selectedValue}
                    name="periodValue"
                  />
                  <PeriodType
                    options={year}
                    setState={setSelectedYear}
                    state={selectedYear}
                    name="periodYear"
                  />
                </>
              )}
              {checked.quarter && (
                <>
                  <PeriodType
                    options={quarter}
                    setState={setSelectedValue}
                    state={selectedValue}
                    name="periodValue"
                  />
                  <PeriodType
                    options={year}
                    setState={setSelectedYear}
                    state={selectedYear}
                    name="periodYear"
                  />
                </>
              )}
              {checked.year && (
                <PeriodType
                  options={year}
                  setState={setSelectedValue}
                  state={selectedValue}
                  name="periodValue"
                />
              )}
              {checked.custom && (
                <CalendarSection className="notranslate">
                  <Calendar
                    onChange={onSelectCalendar}
                    startDate={new Date()}
                    defaultEndDate={new Date()}
                    withinSameMonth={false}
                  />
                </CalendarSection>
              )}
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('awards_section:award')}</label>
            <TextArea
              name="description"
              ref={register}
              rows={8}
              value={value}
              error={errors.description?.message}
              onChange={(event: any) =>
                setValue((event.target as HTMLTextAreaElement).value)
              }
              data-testid="description"
            />
          </InputWrapper>
        </SectionForm>
        <ButtonStyled>
          <Button
            value={t('awards_section:create_award')}
            variant="primary"
            loader={loader}
          />
        </ButtonStyled>
      </form>
    </SectionWrapper>
  );
};
export default CreateAwardForm;
