import Client from 'config/axios';

export const RejectPostRequest = async (id: number) => {
  const { data } = await Client.put(`/v1/employees/posts/${id}/reject`);
  return { data };
};

export const AcceptPostRequest = async (
  id: number,
  newContent: string,
  provider: string,
  token: string,
  tiktok: any = '',
  programmerPost: boolean,
  programmedDate: any = ''
) => {
  try {
    const content = {
      content: newContent,
      provider: provider,
      tiktok,
      programmed: programmerPost,
      programmedDate: programmedDate
    };
    const { data } = await Client.post(`publish-participation/${id}`, content, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (response) {
    const result = response as any;
    return { errors: result.data };
  }
};

export const discardBadPracticesParticipation = async (
  token: string,
  participationId: number,
  provider: string
) => {
  return await Client.post(
    `discard-bad-practices-participation`,
    { participationId, provider },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
    .then(response => response)
    .catch(error => error);
};

export const verifyTokenSocialNetwork = async (
  provider: string,
  token: string
) => {
  try {
    const { data } = await Client.get(
      `verify-token-social-network/${provider}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return { data: data };
  } catch (response) {
    const result = response as any;
    return { errors: result.data };
  }
};
