import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';
import { StyledNetworkParticipationBox } from 'components/boxes/NetworkParticipationBox';

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 50px;

  @media (min-width: ${Breakpoints.medium}) {
    grid-column-gap: 20px;
  }

  > div {
    grid-column: span 3;
  }

   {
    > div:nth-of-type(-n + 4) {
      grid-column: span 3;
      margin-bottom: 20px;
    }
    > div:nth-of-type(n + 5) {
      grid-column: span 3;
    }
    > div:nth-of-type(n + 6) {
      grid-column: span 3;
    }
    > div:nth-of-type(n + 7) {
      grid-column: span 6;
    }
    .single-data-box {
      display: grid;
      margin-bottom: 0px;
      height: 100%;
    }
    .currency {
      color: #004fea;
      margin-top: -25px;
      font-weight: 700;
    }
  }

  svg[name='flag'] {
    stroke-width: 30px;
    stroke: ${Colors.primaryDarkBlue};
  }
`;
