import React from 'react';

import { ButtonDiv } from './StyledButton';

export interface ButtonProps {
  value: string;
  onClick?: () => void;
  color: string;
  href?: string;
}

const ButtonCreate: React.FC<ButtonProps> = props => {
  const { value, onClick, color, href } = props;
  return (
    <ButtonDiv onClick={onClick} color={color}>
      <p>{value}</p>
      <img alt="icon" src={href} />
    </ButtonDiv>
  );
};

export default ButtonCreate;
