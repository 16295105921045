import styled, { css } from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export interface StyledHorizontalBoxProps {
  size?: string;
  type?: string;
  backgroundImage?: string;
  fallbackBackground?: string;
  hoverable?: boolean;
  backgroundBox?: string;
  disabled?: boolean;
}

export const StyledHorizontalBox = styled.div<StyledHorizontalBoxProps>`
  ${BaseBox};

  align-items: center;

  display: grid;

  ${({ hoverable }) =>
    hoverable === true &&
    css`
      &:focus,
      &:hover {
        box-shadow: 0px 4px 10px 0 ${Colors.primaryDarkBlue};
      }
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      grid-template-columns: 200px 1fr;
      height: 200px;
    `}

  ${({ type }) =>
    type === 'socialNetwork'
      ? css`
          @media (min-width: ${Breakpoints.xxLarge}) {
            grid-template-columns: 450px 1fr;
            min-height: 450px;
          }
          @media (max-width: ${Breakpoints.xxLarge}) {
            grid-template-columns: 300px 1fr;
            min-height: 300px;
          }
          @media (max-width: ${Breakpoints.large}) {
            grid-template-columns: 20% 2fr;
            align-items: center;
            height: 250px;
          }
        `
      : css`
          @media (max-width: ${Breakpoints.xLarge}) {
            grid-template-columns: 2fr 2fr;
          }
          grid-template-columns: 2fr 1fr;
          min-height: 450px;
          .fb-iframes {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
        `}

    ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${({ backgroundBox }) =>
    backgroundBox === 'blue-gradient' &&
    css`
      background-image: linear-gradient(215deg, #3e65fe, #3ebbfe);
      text-align: left;
      box-shadow: none;
      color: #fff;
    `}
`;

export const HeaderHorizontalBox = styled.div<StyledHorizontalBoxProps>`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  height: 100%;
  width: 100%;

  &.center-box {
    display: flex;
    align-items: center;
  }

  @media (min-width: ${Breakpoints.large}) {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    background-position: 50%;
  }
  @media (max-width: ${Breakpoints.large}) {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    background-position: 50%;
  }

  p {
    font-size: 13px;
    padding: 20px;
    font-weight: 500;
    line-height: 1.5;
    color: #333333;
  }

  img {
    margin: 15px 30px;
    padding: 10px;
    height: 80px;
    width: 80px;

    @media (min-width: ${Breakpoints.medium}) {
      margin: 15px auto;
      height: 142px;
      width: 142px;
    }
  }

  ${({ backgroundImage, fallbackBackground }) =>
    (backgroundImage || fallbackBackground) &&
    css`
      background-image: url(${backgroundImage}), url(${fallbackBackground});

      p {
        color: ${Colors.normalWhite};
      }
    `}
`;
