import React from 'react';
import {
  GetSignedUrlS3,
  UploadSignedUrlToS3
} from 'services/rest/UploadImage/UploadImgToS3';

export function awsUploadFileHelper(
  currentUser: string,
  file: any,
  folder: string,
  name: string,
  isCrop: boolean,
  setError?: any,
  message?: string,
  subdomain?: string
) {
  return GetSignedUrlS3(currentUser, name, folder, subdomain)
    .then(result => {
      const url = result.data.data;
      UploadSignedUrlToS3(
        url,
        isCrop ? file : file['file'],
        isCrop ? 'image/png' : file['file']['type']
      )
        .then(result => console.log('result ', name, ': ', result))
        .catch(error => {
          setError(message);
        });
    })
    .catch(error => {
      setError(message);
    });
}

export function setFileNameHelper(file: any, isEditMultiple: boolean) {
  let fileName = '';
  if (isEditMultiple) {
    if (file?.name.length > 0) {
      const getType = file['file']['type'].split('/');
      fileName = `${file['index']}.${getType[1]}`;
    } else {
      const getName = file.url.split('/');
      fileName = getName[5];
    }
  } else {
    if (file.name) {
      const getType = file['file']['type'].split('/');
      fileName = `${file['index']}.${getType[1]}`;
    }
  }
  return fileName;
}
