import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import CampaignsAdminCard from './CampaignsAdminCard';

import iconEditOutline from 'images/icons/edit-outline.svg';

import { MarginAdminPage } from '../styled';
import NavigationLink from 'components/links/NavigationLink';

export interface AllCampaignsStatusProps {
  campaigns: any;
  title: string;
}

const AllCampaignsStatus: React.FC<AllCampaignsStatusProps> = props => {
  const { campaigns = [], title } = props;
  const { t } = useTranslation(['admin_dashboard', 'campaign_section']);

  return (
    <SectionWrapper>
      {campaigns?.length > 0 ? (
        <>
          <MarginAdminPage>
            <h2>{title}</h2>
          </MarginAdminPage>
          {campaigns.slice(0, 3).map((campaign: any, index: any) => {
            return (
              <CampaignsAdminCard
                key={index}
                id={campaign.id}
                url={campaign.photo}
                startDate={campaign.startDate}
                finishDate={campaign.finishDate}
                description={campaign.name}
                active={campaign.ambassadorsParticipating}
                publicacion={campaign.totalParticipation}
                iconEdit={iconEditOutline}
                status={campaign.status}
                includesFacebook={campaign.includesFacebook}
                includesTwitter={campaign.includesTwitter}
                includesLinkedin={campaign.includesLinkedin}
                includesInstagram={campaign.includesInstagram}
                includesWhatsapp={campaign.includesWhatsapp}
                includesTiktok={campaign.includesTiktok}
                includesStatus={false}
              />
            );
          })}
          <NavigationLink
            text={t('admin_dashboard:see_campaigns')}
            direction={'forward'}
            to={'/campaigns/admin/campaignsAll'}
          />
        </>
      ) : (
        <></>
      )}
    </SectionWrapper>
  );
};

export default AllCampaignsStatus;
