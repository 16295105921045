import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { Location } from 'history';
import { isLoggedIn } from 'services/rest/authentication';

import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';

import Layout from 'pages/Layout';

interface PrivateRouteProps extends RouteProps {
  children: JSX.Element;
}

interface PrivatePageProps {
  location: Location;
  children: JSX.Element;
}

const PrivatePage = (props: PrivatePageProps) => {
  const { location, children } = props;
  const { currentUser } = useSelector(selectUser);

  if (isLoggedIn()) {
    return <Layout pathname={location.pathname}>{children}</Layout>;
  }

  return (
    <Redirect
      to={{
        pathname: '/sign_in',
        state: { from: currentUser === 'expired' ? 'token_expired' : location }
      }}
    />
  );
};

const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <PrivatePage location={location}>{children}</PrivatePage>
      )}
    />
  );
};

export default PrivateRoute;
