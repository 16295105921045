import Client from 'config/axios';

export const getAwardsList = async (
  token: string,
  limit: number,
  page: number,
  type?: string
) => {
  const paramType = type ? `type=${type}` : '';
  const { data } = await Client.get(
    `awards?limit=${limit}&page=${page}&${paramType}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return {
    data
  };
};
