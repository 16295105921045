import Client from 'config/axios';

export const deleteGroup = async (
  token: string,
  groupId: number,
  removeFromGroupAll: any
) => {
  try {
    const { data } = await Client.delete(
      `group/${groupId}?removeFromGroupAll=${removeFromGroupAll}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return { data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.response.response.data };
  }
};
