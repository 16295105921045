import Client from 'config/axios';

export interface AwardData {
  photoUrl: string;
  description: string;
  companyId: number;
  groupsIds: any;
  periodValue: number;
  periodType: string;
  periodYear: string;
  startDate: any;
  finishDate: any;
}

export const createAward = async (token: string, award: AwardData) => {
  try {
    const { data } = await Client.post('award', award, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
