import styled from 'styled-components';
import { StyledLabelBox } from 'components/boxes/LabelBox/Styled';

export const StyledAwardsSection = styled.div`
  ${StyledLabelBox} {
    box-shadow: 0 5px 13px 0 rgba(171, 169, 169, 0.38);
    border-radius: 5px;
    max-width: 100%;
    width: 100%;
  }

  .margin {
    display: flex;
  }

  div.tags {
    bottom: 4px;
  }

  .alice-carousel__stage {
    .alice-carousel__stage-item:only-child {
      max-width: 600px;
    }
  }
`;
