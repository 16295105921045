import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import backgroundPurpure from 'images/backgrounds/background_reports_plataform.png';
import backgroundResports from 'images/backgrounds/background_reports_blue_advocates.png';

export const SectionCard = styled.div`
  box-shadow: 0 1px 6px 0 rgba(79, 79, 79, 0.38);
  transition: all 0.5s ease-out 0s;
  background: ${Colors.normalWhite};
  display: inline-block;
  margin-bottom: 20px;
  border-radius: 10px;

  h3 {
    font-size: 0.6875rem;
    font-weight: bold;
    margin: 0;

    width: 80%;
    display: block;
    margin: 0 auto;

    @media (min-width: ${Breakpoints.medium}) {
      font-size: 1rem;
      width: auto;
    }
  }

  h4 {
    font-weight: bold;
    min-height: 30px;
    font-size: 14px;

    @media (min-width: ${Breakpoints.medium}) {
      margin: 0px 10px;
    }
  }

  h3 + * {
    margin-top: 15px;
  }

  h3.small-description {
    @media (min-width: ${Breakpoints.medium}) {
      font-size: 0.625rem;
    }
  }

  // Hoverable Box
  &.hoverable {
    &:focus,
    &:hover {
      box-shadow: 0 4px 10px 0 ${Colors.primaryDarkBlue};
    }
  }

  &.no-shadow {
    box-shadow: none;
    grid-column-gap: 35px;
  }
  display: grid;
  grid-template-columns: 30% 1fr;
`;

export const ContentBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  padding: 20px;
`;

export const MarginDescription = styled.div`
  grid-column: span 9;
  line-height: 1.2;
  text-align: justify;
  color: #363636;
  p {
    margin: 0 0 5px 0;
    font-weight: 300;
    line-height: 1.5;
    font-size: 14px;
    p {
      font-weight: 600 !important;
    }
  }
`;

export const BackgroundImg = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  min-height: 150px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  &.purpure-bck {
    background-image: url(${backgroundPurpure});
  }
  &.blue-bck {
    background-image: url(${backgroundResports});
  }
  h3 {
    margin-left: 30px;
    font-size: 1rem;
    width: auto;
    font-weight: bold;
    display: block;
    color: white;
    line-height: 1.2;
    strong {
      display: block;
      font-size: 30px;
    }
  }
`;

export const MarginButtonDownload = styled.div`
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  grid-column: span 3;
  .iconDownload {
    background-color: #004fea;
    box-shadow: 0 1px 6px 0 rgb(79 79 79 / 38%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    min-height: 35px;
    min-width: 35px;
    height: 76px;
    width: 76px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    width: auto;
    margin: 0;
    margin-left: 10px;
  }

  .inputEmail {
    width: 55%;
    margin-left: 10px;
  }
`;

export const infoContent = styled.div`
  margin-top: 10px;
`;

export const TextCheckbox = styled.p`
  margin-top: 15px;
  font-size: 1rem;
  font-weight: 600 !important;
  margin-left: 0px !important;
  @media (max-width: ${Breakpoints.medium}) {
    width: 100%;
  }
`;

export const FiltersForm = styled.div`
  text-align: center;
  display: block;
  flex-wrap: wrap;

  @media (min-width: ${Breakpoints.medium}) {
    align-items: center;
    margin: 20px 0;
    display: flex;
  }

  input {
    @media (min-width: ${Breakpoints.medium}) {
      margin-right: 10px;
      max-width: 300px;
    }
  }

  div.dropdown-filter-content__control {
    @media (max-width: ${Breakpoints.medium}) {
      width: 100% !important;
    }
  }

  div.dropdown-filter-content__menu {
    @media (max-width: ${Breakpoints.medium}) {
      width: 95% !important;
    }
  }

  div.dropdown-filter-container {
    @media (max-width: ${Breakpoints.medium}) {
      margin-bottom: 15px;
    }
  }
`;
