import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import StyledDropdownSearchable from './StyledDropdownSearchable';
import FieldError from 'components/inputs/FieldError';

interface Option {
  [value: string]: any;
  label: string;
}

interface DropdownProps {
  placeHolder: string;
  options: Option[];
  isMulti?: boolean;
  isSearchable?: boolean;
  onChange: (selectedValue: Option[] | Option | null) => void;
  error?: string;
  name: string;
}

const Icon: React.FC = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon: React.FC = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

const DropdownSearchable: React.FC<DropdownProps> = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  error,
  onChange
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Option[] | Option | null>(
    isMulti ? [] : null
  );
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSearchValue('');
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  const handleInputClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || (isMulti && selectedValue.length === 0)) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          {selectedValue.map((option: any) => (
            <div key={option.value} className="dropdown-tag-item">
              {option.label}
              <span
                onClick={e => onTagRemove(e, option)}
                className="dropdown-tag-close"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return (selectedValue as Option).label;
  };

  const removeOption = (option: Option) => {
    return (selectedValue as Option[]).filter(o => o.value !== option.value);
  };

  const onTagRemove = (
    e: React.MouseEvent<HTMLSpanElement>,
    option: Option
  ) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option: Option) => {
    let newValue: Option[] | Option;
    if (isMulti) {
      if (
        (selectedValue as Option[]).findIndex(o => o.value === option.value) >=
        0
      ) {
        newValue = removeOption(option);
      } else {
        newValue = [...(selectedValue as Option[]), option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const isSelected = (option: Option) => {
    if (isMulti) {
      return (
        (selectedValue as Option[]).filter(o => o.value === option.value)
          .length > 0
      );
    }

    if (!selectedValue) {
      return false;
    }

    return (selectedValue as Option).value === option.value;
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      option =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <StyledDropdownSearchable>
      <div className="dropdown-container">
        <div
          ref={inputRef}
          onClick={handleInputClick}
          className={cx('dropdown-input', { 'field-error': Boolean(error) })}
        >
          <div className="dropdown-selected-value">{getDisplay()}</div>
          <div className="dropdown-tools">
            <div className="dropdown-tool">
              <Icon />
            </div>
          </div>
        </div>
        {showMenu && (
          <div className="dropdown-menu">
            {isSearchable && (
              <div className="search-box">
                <input
                  onChange={onSearch}
                  value={searchValue}
                  ref={searchRef}
                />
              </div>
            )}
            {getOptions().map(option => (
              <div
                onClick={() => onItemClick(option)}
                key={option.value}
                className={`dropdown-item ${
                  isSelected(option) ? 'selected' : ''
                }`}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      {error && <FieldError text={error} />}
    </StyledDropdownSearchable>
  );
};

export default DropdownSearchable;
