import React from 'react';
import HorizontalBox from 'components/boxes/HorizontalBox';
import PointsTable from './PointsTable';
import { usePostActions } from '../PostActions';
import EmptyCampaign from 'images/empty_states/empty_campaigns.png';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import { SocialNetworkCampaign } from 'types/appTypes';
import { useSelector } from 'react-redux';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

export interface PostCardProps {
  socialNetworkCampaign: SocialNetworkCampaign;
  header: JSX.Element;
  getRankingData?: any;
  campaign?: ICampaigns;
}

const ShowPostDetail = (props: SocialNetworkCampaign) => {
  const {
    provider,
    points: sncPoints,
    employeePost,
    content,
    getRankingData,
    campaign
  } = props;

  const { campaignStatus } = usePostActions();

  return (
    <PointsTable
      sncPoints={sncPoints}
      provider={provider}
      post={employeePost}
      originalContent={content}
      campaignStatus={campaignStatus as string}
      getRankingData={getRankingData}
      campaign={campaign}
    />
  );
};

const PostCard: React.FC<PostCardProps> = props => {
  const {
    socialNetworkCampaign,
    socialNetworkCampaign: { picturesUrls },
    header,
    campaign
  } = props;
  const { company } = useSelector(selectCompany);
  return (
    <HorizontalBox
      backgroundImage={
        socialNetworkCampaign.contentType === 'text'
          ? EmptyCampaign
          : picturesUrls[0]
      }
      header={header}
      size="normal"
      type={
        socialNetworkCampaign.provider === 'facebook' &&
        company?.company_permission?.allowTest
          ? 'fb-iframes'
          : 'socialNetwork'
      }
      className={
        ['video', 'pdf'].includes(socialNetworkCampaign?.contentType)
          ? 'center-box'
          : 'img-box'
      }
    >
      {ShowPostDetail({ ...socialNetworkCampaign, campaign })}
    </HorizontalBox>
  );
};

export default PostCard;
