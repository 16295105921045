import React, { TextareaHTMLAttributes, DetailedHTMLProps } from 'react';
import cx from 'classnames';

import StyledTextArea from './StyledTextArea';
import FieldError from 'components/inputs/FieldError';

export type DefaultProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  HTMLTextAreaElement;

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  rows: number;
  error?: string;
}

const TextArea = React.forwardRef<DefaultProps, TextAreaProps>(
  ({ rows, error, ...otherProps }, ref) => (
    <>
      <div>
        <StyledTextArea
          rows={rows}
          ref={ref}
          className={cx({ 'field-error': Boolean(error) })}
          {...otherProps}
        />
        {error && <FieldError text={error} />}
      </div>
    </>
  )
);

TextArea.defaultProps = {
  rows: 8
};

TextArea.displayName = 'TextArea';

export default TextArea;
