import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { Instagram as LoadingState } from 'react-content-loader';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

import { Ambassador } from 'services/rest/Ambassador/createAmbassador';

import Input from 'components/inputs/Input';
import Phone from 'components/inputs/Phone';
import Button from 'components/buttons/Button';
import Spinner from 'components/Spinner/Spinner';

import { ButtonStyled, InputWrapper, SectionForm } from '../../Styled';

import 'react-phone-number-input/style.css';

export interface AmbassadorProfileFormProps {
  ambassador: any;
  loading: boolean;
  loader: boolean;
  company: any;
  phone: string;
  setPhone: any;
  setLanguage: any;
  language: any;
  errors: FieldErrors<Ambassador>;
  handleSubmit: (event: React.FormEvent) => void;
  register: UseFormMethods['register'];
}

const AmbassadorProfileForm: React.FC<AmbassadorProfileFormProps> = props => {
  const {
    ambassador,
    loading,
    loader,
    company,
    phone,
    setPhone,
    setLanguage,
    language,
    errors,
    handleSubmit,
    register
  } = props;

  const { t } = useTranslation([
    'group_page',
    'profile',
    'register_form',
    'validations'
  ]);

  const languages = [
    { label: t('spanish'), value: 'es' },
    { label: t('english'), value: 'en' },
    { label: t('portuguese'), value: 'pt' }
  ];

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const defaultSelection = languages.find(({ value }) => value === language);

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <form onSubmit={handleSubmit}>
        <SectionForm>
          <InputWrapper>
            <label>{t('name')}</label>
            <div className="w-100">
              <Input
                type="text"
                placeholder={t('name')}
                name="name"
                defaultValue={ambassador?.name}
                ref={register}
                error={errors.name?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('last_name')}</label>
            <div className="w-100">
              <Input
                type="text"
                name="lastName"
                placeholder={t('last_name')}
                defaultValue={ambassador?.lastName}
                ref={register}
                error={errors.lastName?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('mobile')}</label>
            <div className="w-100">
              <Phone
                defaultCountry={company?.countryCode}
                name="phone"
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('language')}</label>
            <div className="w-100">
              <ReactSelect
                className="dropdown-filter-container m-l-0"
                classNamePrefix="dropdown-filter-content"
                options={languages}
                defaultValue={defaultSelection}
                onChange={e => setLanguage(e?.value)}
              />
            </div>
          </InputWrapper>
        </SectionForm>
        <ButtonStyled>
          {!loader ? (
            <Button value={t('save')} variant="primary" />
          ) : (
            <Spinner name="sign-up" />
          )}
        </ButtonStyled>
      </form>
    </Suspense>
  );
};
export default AmbassadorProfileForm;
