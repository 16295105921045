import React from 'react';
import StyledMetricBox, { ValueContainer } from './StyledMetricBox';
import PlainNumber from 'components/numbers/PlainNumber';
import Icon from 'components/icons/Icon';

export interface MetricBoxProps {
  iconName: string;
  value: string;
  text: string;
  topText?: boolean;
}

const MetricBox: React.FC<MetricBoxProps> = props => {
  const { iconName, value, text, topText } = props;
  return (
    <StyledMetricBox>
      {topText && <h3>{text}</h3>}

      <Icon name={iconName} size="big" />
      <ValueContainer>
        <PlainNumber value={value} />
      </ValueContainer>

      {!topText && <h3>{text}</h3>}
    </StyledMetricBox>
  );
};

export default MetricBox;
