import React, { Suspense, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Icon from 'components/icons/Icon';
import { selectUser } from 'redux/slices/userSlice';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { enableCompany } from 'services/rest/SuperAdmin/Company/enableCompany';
import { disableCompany } from 'services/rest/SuperAdmin/Company/disableCompany';
import { TablePagination } from '@mui/material';
import {
  ButtonSection,
  Filter,
  HeaderSection,
  HeardMargin,
  TextStyled
} from '../Styled';
import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';
import moment from 'moment';
import 'moment/locale/es';

interface CompaniesProps {
  companies: any;
  changeCompany: any;
  setChangeCompany: any;
  setLoading: any;
}

const CompaniesTable: React.FC<CompaniesProps> = props => {
  const { companies, changeCompany, setChangeCompany, setLoading } = props;
  const { t } = useTranslation(['super_admin_company']);
  const { currentUser } = useSelector(selectUser);
  const history = useHistory();
  const [filterText, setFilterText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  moment().locale('es');

  const go = (id: number) => {
    history.replace(`/company/updateCompany/${id}`);
  };

  const onSubmit = async (id: number, deleted: boolean) => {
    setLoading(true);
    if (deleted) {
      const { data } = await enableCompany(currentUser?.token, id);
      if (data.data) {
        setChangeCompany(!changeCompany);
      }
    } else {
      const { data } = await disableCompany(currentUser?.token, id);
      if (data.data) {
        setChangeCompany(!changeCompany);
      }
    }
  };

  const actionsAlert = (id: number, deleted: boolean) => {
    const text = deleted
      ? t('super_admin_company:activate_question')
      : t('super_admin_company:deactivate_question');
    Swal.fire({
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('super_admin_company:yes'),
      cancelButtonText: t('super_admin_company:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(id, deleted);
      }
    });
  };

  const timeLine = (licenseDate: any) => {
    licenseDate = moment(licenseDate);
    const licenseMonth = licenseDate.format('MMMM');
    const licenseDay = licenseDate.format('D');
    const year = licenseDate.format('YYYY');
    return `${licenseMonth} ${licenseDay} ${year}`;
  };

  const calculateDays = (license: any) => {
    const oneDay = 1000 * 60 * 60 * 24; //Get 1 day in milliseconds
    const licenseDate = new Date(license).getTime();
    const currentDate = new Date().getTime();
    const difference = licenseDate - currentDate;
    const days = Math.round(difference / oneDay);
    return days < 30 ? 'red-bg' : days < 60 ? 'yellow-bg' : '';
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const newCompanies = companies.filter((data: any) => {
    const company = data as any;
    const companyName = company.name.toLowerCase();
    const companySubdomain = company.subdomain.toLowerCase();
    const result =
      companyName.indexOf(filterText.toLowerCase()) != -1 ||
      companySubdomain.indexOf(filterText.toLowerCase()) != -1;

    return result;
  });

  const currentCompanies = filterText ? newCompanies : companies;

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <HeaderSection>
        <Filter>
          <Input
            type="search"
            placeholder={t('super_admin_company:search')}
            value={filterText}
            onChange={e => {
              setFilterText(e.target.value);
            }}
          />
        </Filter>
        <ButtonSection>
          <Button
            value={t('super_admin_company:create_company')}
            variant="primary"
            onClick={() => history.replace({ pathname: `/company/new` })}
          />
        </ButtonSection>
      </HeaderSection>
      <HeardMargin>
        <div className="option">
          <Icon name="edit" size="small" />
          <TextStyled>{t('super_admin_company:edit')}</TextStyled>
        </div>
        <div className="option">
          <Icon name="check-rounded" color="#7ed321" size="small" />
          <TextStyled>{t('super_admin_company:activate')}</TextStyled>
        </div>
        <div className="option">
          <Icon name="close-rounded" color="red" size="small" />
          <TextStyled>{t('super_admin_company:deactivate')}</TextStyled>
        </div>
      </HeardMargin>
      <TableContainer component={Paper} className="table_container">
        <Table
          sx={{ minWidth: 750 }}
          size="medium"
          aria-label="a dense table"
          className="table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" className="column">
                {t('super_admin_company:id')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('super_admin_company:name')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('super_admin_company:subdomain')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('super_admin_company:language')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('super_admin_company:url_page')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('super_admin_company:license_date')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? currentCompanies.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : currentCompanies
            ).map((company: any) => {
              return (
                <TableRow
                  key={company.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                  className={`column-body ${calculateDays(
                    company.licenseDate
                  )}`}
                >
                  <TableCell align="center" component="th" scope="row">
                    {company.id}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {company.name}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {company.subdomain}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {company.language}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {`https://${company.subdomain}.advocatespro.com`}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    className="text-cap"
                  >
                    {timeLine(company.licenseDate)}
                  </TableCell>
                  <TableCell align="center">
                    {company.deleted ? (
                      <Icon
                        name="check-rounded"
                        color="#7ed321"
                        size="small"
                        onClick={() =>
                          actionsAlert(company.id, company.deleted)
                        }
                        className="pointer"
                      />
                    ) : (
                      <Icon
                        name="close-rounded"
                        color="red"
                        size="small"
                        onClick={() =>
                          actionsAlert(company.id, company.deleted)
                        }
                        className="pointer"
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Icon
                      name="edit"
                      size="small"
                      onClick={() => go(company.id)}
                      className="pointer"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500]}
        component="div"
        count={currentCompanies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Suspense>
  );
};

export default CompaniesTable;
