import styled, { css } from 'styled-components';
import { Colors } from 'styles/Constants';

import DropdownDisable from 'images/icons/dropdown-disable.svg';
import DropdownActive from 'images/icons/dropdown-active.svg';
import ChevronThinDown from 'images/icons/chevron-thin-down.svg';

export const StyledSelect = styled.div`
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url(/static/media/dropdown-disable.4dd4bae1.svg);
    -webkit-transition: all 0.1s ease-out 0s;
    -webkit-transition: all 0.1s ease-out 0s;
    transition: all 0.1s ease-out 0s;
    background-repeat: no-repeat;
    background-position: 90% 50%;
    background-size: 22px;
    padding-right: 50px;
    height: 40px;
    width: 300px;
    -webkit-transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    border: 1px solid #979797;
    font-size: 0.8125rem;
    color: #4d4d4d;
    border-radius: 32px;
    padding: 10px 15px;
    font-weight: 400;
    display: block;
    outline: none;
    &.field-error {
      border: 1px solid ${Colors.inputError};
      color: ${Colors.inputError};
    }
  }
`;
