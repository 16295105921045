import Client from 'config/axios';

export const deleteAmbassador = async (
  token: string,
  ambassadorId: any,
  groupId: any,
  removePermanent: any
) => {
  try {
    const url = `remove-ambassadors-to-groups?ambassadorsIds=[${ambassadorId}]&groupsIds=[${groupId}]&isRemovePermanent=${removePermanent}`;
    const { data } = await Client.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return { data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
