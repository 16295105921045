import Client from 'config/axios';

export interface Data {
  currentPassword: string;
}

export const validatePassword = async (
  id: number,
  token: string,
  body: Data
): Promise<boolean> => {
  const { data } = await Client.post(`validate-password/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return data?.data;
};
