import styled from 'styled-components';

export const VideoHeaderWrapper = styled.div`
  position: relative;

  video {
    margin-left: 25%;
    width: 60%;
    border-radius: 10px;
    margin-top: -50px;
  }
`;

export const EmbedLkHeaderWrapper = styled.div`
  position: relative;
  width: 100%;

  .open {
    position: relative;
    display: flex;
    margin: 10px;
    cursor: pointer;
  }

  object {
    border-top-left-radius: 10px;
    margin-top: -85px;
    width: 95%;
  }
`;

export const FacebookWrapper = styled.div`
  line-height: 0.7;
  iframe {
    width: 100%;
    width="500"
    height="274"
    border:none;overflow:hidden
    scrolling="no"
    frameborder="0"
  }
`;

export const FacebookContents = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  width: 100%;

  a {
    text-decoration: none;
  }
  img,
  p {
    margin: 0;
    padding: 0;
  }
  .fb-post {
    background-color: #ffffff;
  }
  .header {
    display: flex;
    min-height: 80px;
    padding-top: 10px;
    .img-user {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .texts {
      margin: 0 0 0 10px;
    }
    .username {
      font-size: 15px;
      color: #000000;
      font-weight: 600;
    }
    .message {
      color: #000000;
    }
  }
  .img-post {
    width: 100%;
    height: 300px;
  }
`;

export const PostDetailWrapper = styled.div`
  p.small-paragraph {
    font-size: 0.875rem;
    text-align: center;
    margin: 0 0 5px 0;
    font-weight: 500;
  }

  a {
    width: 90%;
  }
`;

export const RejectPostWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 6px;

  &:hover:before {
    transform: translateY(-14px);
  }

  &.is-open {
    &:before,
    svg {
      display: none;
    }
  }
`;

export const divButton = styled.button`
  width: 50%;
  margin: auto;
`;
export const ButtonsParticipations = styled.button`
  a {
    margin: 3%;
  }

  .programmerButton {
    background: #b721ff;
    border: 1px solid #b721ff;
    background-image: linear-gradient(136deg, #770cab, #b721ff);
    color: ;
  }
  background-color: white;
  border: 0px;
`;
