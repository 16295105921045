import styled from 'styled-components';

export const Section_header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;

  .marginUpdate {
    display: flex;
    margin-bottom: 20px;
    input {
      height: 32px;
    }
    button {
      border-radius: 20px;
      font-size: 0.6875rem;
      padding: 6px 15px;
      margin-left: 10px;
    }
  }

  .actionsButtons {
    width: 100%;
    button {
      margin: 5px;
    }
  }
`;
