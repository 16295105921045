import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Colors, Breakpoints } from 'styles/Constants';

export interface StyledButtonProps {
  as?: any;
  color?: string;
  disabled?: boolean;
  icon?: string;
  size: string;
  variant: string;
}

export interface StyledButtonCreate {
  color?: string;
  icon?: string;
}

const SocialNetworks = ['facebook', 'twitter', 'linkedin', 'instagram'];

export const Variants: Record<string, string> = {
  default: 'gray',
  primary: Colors.primaryDarkBlue,
  secondary: Colors.secondaryNormalPink,
  tertiary: Colors.primaryNormalBlue,
  quaternary: Colors.secondaryLightGray,
  success: Colors.secondarySuccess,
  warning: Colors.secondaryNormalRed,
  facebook: Colors.facebook,
  twitter: Colors.twitter,
  linkedin: Colors.linkedin,
  instagram: Colors.instagram,
  tiktok: Colors.tiktok
};

const ButtonBase = css`
  transition: all 0.5s ease-out 0s;
  color: ${Colors.normalWhite};
  text-decoration: none;
  display: inline-block;
  border-radius: 30px;
  padding: 10px 30px;
  text-align: center;
  line-height: 1.6;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover,
  &:active {
    box-shadow: 0 5px 12px 0 rgba(44, 62, 80, 0.2);
    color: ${Colors.normalWhite};
    text-decoration: none;
  }

  @media (min-width: ${Breakpoints.medium}) {
    font-size: 1.125rem;
    padding: 10px 50px;
  }

  svg {
    vertical-align: text-top;
    margin-right: 5px;
  }
`;

const small = css`
  border-radius: 6px;
  font-size: 0.6875rem;
  padding: 4px 5px;

  @media (min-width: ${Breakpoints.medium}) {
    font-size: 0.6875rem;
    padding: 4px 5px;
  }

  svg {
    height: 13px;
    width: 13px;
  }
`;

const medium = css`
  font-size: 0.875rem;
  padding: 6px 40px;

  @media (min-width: ${Breakpoints.medium}) {
    font-size: 0.875rem;
    padding: 6px 40px;
  }
`;

const regularSize = css``;

const Sizes: Record<string, FlattenSimpleInterpolation> = {
  small,
  medium,
  regular: regularSize
};

const colorBase = (color: string): FlattenSimpleInterpolation => {
  return css`
    background: ${color};
    border: 1px solid ${color};
  `;
};

const StyledButton = styled.button<StyledButtonProps>`
  ${ButtonBase};
  /* Background and border set with variant */
  ${({ variant }) => colorBase(Variants[variant])};
  /* Change size based on prop */
  ${({ size }) => Sizes[size]};

  /* Change icon color if it's social media */
  ${({ variant, icon }) =>
    SocialNetworks.includes(variant) &&
    icon &&
    css`
      svg {
        fill: #fff;
      }
    `}

  ${({ variant }) =>
    variant === 'tertiary' &&
    css`
      background-image: linear-gradient(136deg, #3e65fe, #3ebbfe);
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      ${colorBase('#e0e0e0')};
      color: ${Colors.secondaryDarkBlue};
      pointer-events: none;
      opacity: 0.7;

      &:focus,
      &:hover,
      &:active {
        color: ${Colors.secondaryDarkBlue};
      }
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
      &:focus,
      &:hover,
      &:active {
        color: ${color};
      }
    `}
`;

StyledButton.defaultProps = {
  variant: 'default'
};

export const ButtonDiv = styled.div<StyledButtonCreate>`
  display: flex;
  justify-content: flex-end;

  p {
    color: ${({ color }) => color};
    margin-top: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }

  img {
    margin-top: -12px;
    width: 60px;
  }
`;

export default StyledButton;
