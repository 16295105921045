import React from 'react';

import StyledButton, { StyledButtonProps } from './StyledButton';
import Icon from 'components/icons/Icon';
import Spinner from 'components/Spinner/Spinner';
import { Colors } from 'styles/Constants';

export interface ButtonProps extends StyledButtonProps {
  value?: any;
  onClick?: () => void;
  className?: string;
  href?: string;
  target?: string;
  color?: string;
  loader?: boolean;
}

const Button = (buttonProps: ButtonProps) => {
  const {
    value,
    icon,
    className,
    color = Colors.normalWhite,
    loader,
    ...otherProps
  } = buttonProps;

  return (
    <>
      {!loader ? (
        <StyledButton className={className} icon={icon} {...otherProps}>
          {icon && <Icon name={icon} color={color} />}
          {value}
        </StyledButton>
      ) : (
        <Spinner name="sign-up" />
      )}
    </>
  );
};

Button.defaultProps = {
  variant: 'default',
  size: 'regular',
  disabled: false,
  loader: false
};

export default Button;
