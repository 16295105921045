import styled from 'styled-components';
import { Colors } from 'styles/Constants';

export const StyledForm = styled.form`
  margin-bottom: 0;
  display: grid;

  .confirm-password {
    margin-bottom: 0px !important;
  }
  button {
    margin-top: 20px;
    font-size: 13px;
  }
`;

export const PoweredByWrapper = styled.div`
  grid-template-columns: 45% 55%;
  align-items: center;
  margin-bottom: 15px;
  display: grid;

  p {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: ${Colors.secondaryNormalGrey};
    margin: 0;
  }

  img {
    max-width: 100%;
  }
`;

export const NoAccountSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 !important;
  margin: 20px 54px;

  p {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    color: ${Colors.secondaryNormalGrey};
    margin: 0;
  }

  a.register {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
    color: #009ef5;
  }
`;

export const ResetText = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 20px 54px;

  p {
    font-weight: bold;
    font-size: 14px;
    color: ${Colors.primaryDarkBlue};
    margin: 0;
  }
  span {
    text-align: center;
    color: ${Colors.secondaryNormalGrey};
    font-weight: 600;
    font-size: 13px;
  }
`;
