import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Breakpoints, Colors } from 'styles/Constants';
import PlayStore from 'images/logos/play_store.png';
import AppStore from 'images/logos/app_store.png';
import PhoneImage from 'images/backgrounds/phone.png';
import BackgroundDownload from 'images/backgrounds/background_download.png';

const MobileDownloadApp = styled.div`
  background: url(${BackgroundDownload});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${Breakpoints.large}) {
    background-size: 100% 80%;
    padding-bottom: 10px;
  }
`;

const Phone = styled.div`
  justify-self: center;
  align-self: center;

  img {
    width: 125px;
    margin: 10px;

    @media (min-width: ${Breakpoints.large}) {
      width: 200px;
      margin: 20px;
    }
  }
`;

const DownloadInstructions = styled.div`
  padding: 20px 5px;

  @media (min-width: ${Breakpoints.large}) {
    padding: 40px 10px;
  }

  h2 {
    color: ${Colors.normalWhite};
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.1;

    @media (min-width: ${Breakpoints.large}) {
      font-size: 1.8rem;
    }
  }

  p {
    color: ${Colors.normalWhite};
    font-size: 0.85rem;
    font-weight: 500;

    @media (min-width: ${Breakpoints.large}) {
      font-size: 0.875rem;
    }
  }
`;

const Stores = styled.div`
  justify-items: center;
  padding: 20px 0;

  @media (min-width: ${Breakpoints.large}) {
    padding: 0;
  }

  img {
    width: 150px;

    @media (min-width: ${Breakpoints.large}) {
      &.app-store {
        margin-left: 150px;
      }

      &.google-play {
        margin-right: 150px;
      }
    }
  }
`;

export interface MobileCardProps {
  provider: string;
}

export const MobileCard: React.FC<MobileCardProps> = props => {
  const { provider } = props;
  const { t } = useTranslation(['mobile_card']);

  return (
    <MobileDownloadApp>
      <div className="grid-block">
        <Phone className="span-sm-5 span-md-4">
          <img src={PhoneImage} alt="Phone" />
        </Phone>

        <DownloadInstructions className="span-sm-7 span-md-8">
          <h2>{t('download_app', { provider })}</h2>
          <p>{t('download_instructions', { provider })}</p>
        </DownloadInstructions>
      </div>

      <Stores className="hide-on-mobile grid-block">
        <div className="span-sm-6">
          <img className="app-store" src={AppStore} alt="App store" />
        </div>
        <div className="span-sm-6">
          <img className="google-play" src={PlayStore} alt="Google play" />
        </div>
      </Stores>

      <Stores className="show-on-mobile grid-block">
        <div className="span-sm-6">
          <a href="https://apple.co/35pF0vF" target="_blank" rel="noreferrer">
            <img className="app-store" src={AppStore} alt="App store" />
          </a>
        </div>

        <div className="span-sm-6">
          <a href="https://bit.ly/3eGlnCA" target="_blank" rel="noreferrer">
            <img className="google-play" src={PlayStore} alt="Google play" />
          </a>
        </div>
      </Stores>
    </MobileDownloadApp>
  );
};
