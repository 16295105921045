import React, { useEffect, useState, Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { RequiredString } from 'helpers/yupHelper';

import Button from 'components/buttons/Button';
import { Section_header } from './Styled';
import NavigationLink from 'components/links/NavigationLink';

import Input from 'components/inputs/Input';
import Icon from 'components/icons/Icon';
import { Colors } from 'styles/Constants';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getGroupById } from 'services/rest/Group/getGroupById';
import { GroupCard } from 'pages/GroupAdminPage/GroupAdminCard/GroupAdminCard';
import { updateGroup } from 'services/rest/Group/updateGroup';
import { activeBonusByGroup } from 'services/rest/Bonus/activeBonusByGroup';
import { deactiveBonusByGroup } from 'services/rest/Bonus/deactiveBonusByGroup';
import { ErrorAlert, SuccessAlert } from 'components/Alert';

interface GroupParams {
  groupId: string;
}
interface GroupDate {
  name: string;
}

interface GroupDetailHeadProps {
  setBackendError: any;
  setBonusState: any;
}

const GroupDetailHead: React.FC<GroupDetailHeadProps> = props => {
  const { setBackendError, setBonusState } = props;
  const history = useHistory();
  const [value, setValue] = useState(true);
  const { t } = useTranslation(['group_page', 'validations']);
  const { groupId } = useParams<GroupParams>();
  const { state } = location as any;
  const [backendSuccess, setBackendSuccess] = useState<string | null>(state);
  const [backendErrorBonus, setBackendErrorBonus] = useState<string | null>(
    null
  );

  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState<GroupCard>();

  const getData = async () => {
    const { data } = await getGroupById(currentUser?.token, groupId, 'false');
    setGroup(data.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const showAlert = async (action: string) => {
    Swal.fire({
      text: t(`confirm_${action}_bonus`),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('yes'),
      cancelButtonText: t('not'),
      reverseButtons: true
    }).then(async result => {
      if (result.isConfirmed) {
        action === 'enable' ? await activeBonus() : await desactiveBonus();
      }
    });
  };

  const activeBonus = async () => {
    const data = await activeBonusByGroup(currentUser?.token, groupId);
    data.data
      ? setBackendSuccess(t('activated_bonus'))
      : setBackendErrorBonus(t('update_ambassador_error'));
    setBonusState(true);
  };

  const desactiveBonus = async () => {
    const data = await deactiveBonusByGroup(currentUser?.token, groupId);
    data.data
      ? setBackendSuccess(t('desactivated_bonus'))
      : setBackendErrorBonus(t('update_ambassador_error'));
    setBonusState(false);
  };

  const goNewEmployee = () => {
    history.replace({ pathname: `/admin/group/newEmployee/${groupId}` });
  };

  const goEmployee = () => {
    history.replace({
      pathname: `/admin/group/newEmployee/${groupId}/${group?.name}`
    });
  };

  const userSchema = yup.object().shape({
    name: RequiredString().max(30, t('validations:length_error', { max: 30 }))
  });

  const { register, handleSubmit, errors } = useForm<GroupDate>({
    resolver: yupResolver(userSchema)
  });

  const showUpdateName = () => {
    setValue(false);
  };

  const updateName = async (name: GroupDate) => {
    if (name?.name?.length) {
      const { data, errors } = await updateGroup(
        currentUser?.token,
        groupId,
        name
      );
      if (data) {
        window.location.reload();
      } else {
        setBackendError(errors.data);
      }
    } else {
      setBackendError('El campo es requerido');
    }
  };

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}
      {backendErrorBonus && (
        <ErrorAlert
          text={t('update_ambassador_error')}
          allowClose={true}
          onClose={() => setBackendErrorBonus(null)}
        />
      )}
      <NavigationLink text={t('back')} direction="back" to="/admin/group" />
      <Section_header>
        {value ? (
          <h1>
            {group?.name}
            {group?.isAll === false && (
              <Icon
                name="edit"
                color={Colors.primaryDarkBlue}
                size="medium"
                onClick={showUpdateName}
              />
            )}
          </h1>
        ) : (
          <form onSubmit={handleSubmit(updateName)}>
            <div className="marginUpdate">
              <Input
                type="text"
                placeholder={t('newName')}
                name="name"
                ref={register}
                error={errors.name?.message}
                defaultValue={group?.name}
              />
              <Button value={t('update')} variant={'primary'} />
            </div>
          </form>
        )}
        <div className="actionsButtons">
          <Button
            value={t('add_employee')}
            variant={'primary'}
            onClick={goNewEmployee}
          />
          <Button
            value={t('bulk_upload')}
            variant={'primary'}
            onClick={goEmployee}
          />
          <Button
            value={t('enable_bonus')}
            variant={'success'}
            onClick={() => showAlert('enable')}
          />
          <Button
            value={t('disabled_bonus')}
            variant={'default'}
            onClick={() => showAlert('disabled')}
          />
        </div>
      </Section_header>
    </Suspense>
  );
};
export default GroupDetailHead;
