import Client from 'config/axios';

export const getAdministratorById = async (token: string, adminId: number) => {
  try {
    const { data } = await Client.get(`administrator/${adminId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data.data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
