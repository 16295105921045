import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';

export const FacebookShareWrapper = styled.div`
  text-align: left;
  padding: 20px 0;

  @media (min-width: ${Breakpoints.medium}) {
    padding: 20px 30px;
  }

  h2 {
    position: relative;
    font-weight: 500;
    font-size: 18px;
  }

  img.fb-share-instructions {
    width: 100%;
  }
`;

export const AdviceBox = styled.div`
  border-radius: 6px;
  margin: 10px 0;
  display: block;

  ol {
    padding: 0 16px;
  }

  li {
    font-weight: 500;
    line-height: 1.5;
    font-size: 13px;

    &.share strong {
      color: #1877f2;
    }

    &.finish strong {
      color: #ff6266;
    }

    span.public-img {
      display: flex;
      align-items: center;

      img {
        width: 100px;
      }
    }
  }
`;

export const ShareActionsWrapper = styled.div`
  margin: 20px 0;
  text-align: center;
  width: 100%;

  button,
  a {
    width: 100%;
    margin-bottom: 20px;
    text-transform: capitalize;

    svg {
      height: 20px;
      width: 20px;
    }

    &.finish {
      border: 1px solid #ff6266;
      background: #ff6266;
    }
  }
`;
