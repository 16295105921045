import React from 'react';

import Icon from 'components/icons/Icon';
import {
  StyledPoints,
  PointsGrid,
  PointsData,
  Table,
  TotalPoints,
  BoxAction,
  BoxIframes
} from './StyledPostPointsBox';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

export interface DetailPoints {
  action: string;
  actionPoints: number;
  earnedPoints: number;
}

export interface PostPointsBoxProps {
  points: Array<DetailPoints>;
  totalPoints: number;
  provider?: string;
}

const PostPointsBox: React.FC<PostPointsBoxProps> = props => {
  const { points, totalPoints, provider, children } = props;
  const { t } = useTranslation(['points_table']);
  const { company } = useSelector(selectCompany);

  return (
    <StyledPoints>
      {provider === 'facebook' && company?.company_permission?.allowTest ? (
        <></>
      ) : (
        <PointsGrid>
          <h2>
            {t('points_table:scoring')}
            <Icon name="star" color="#000" />
          </h2>
          <PointsData>
            <Table>
              <tr>
                <th></th>
                <th></th>
                <th>{t('points_table:won')}</th>
              </tr>
              {points.map((point, index) => (
                <tr key={index}>
                  <td>{point.action ? point.action : '0'}</td>
                  <td>{point.actionPoints ? point.actionPoints : '0'}</td>
                  <td>{point.earnedPoints ? point.earnedPoints : '0'}</td>
                </tr>
              ))}
            </Table>
            <TotalPoints>
              <p>{totalPoints ? totalPoints : '0'}</p>
              <h3>{t('points_table:total_points_post')}</h3>
            </TotalPoints>
          </PointsData>
        </PointsGrid>
      )}
      {provider === 'facebook' && company?.company_permission?.allowTest ? (
        <BoxIframes>{children}</BoxIframes>
      ) : (
        <BoxAction>{children}</BoxAction>
      )}
    </StyledPoints>
  );
};

export default PostPointsBox;
