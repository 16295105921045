import React, { useRef, useState } from 'react';

import {
  CompositeDecorator,
  ContentState,
  Editor,
  EditorState
} from 'draft-js';
import 'draft-js/dist/Draft.css';

import Counter from './Counter';
import { TwitterDecorator, LinkedinDecorator } from './strategies';
import { EditorWrapper } from './Styled';

export interface EditorInputProps {
  className?: string;
  decorator?: CompositeDecorator;
  placeholder?: string;
  content?: string;
  onChange?: (content: string) => void;
  maxCharacters?: number;
  ref?: any;
  disabled?: boolean;
  isTwitter: boolean;
}

const getInitialEditorState = (
  decorator: CompositeDecorator,
  content?: string
) => {
  if (content && content.trim() !== '') {
    const contentState = ContentState.createFromText(content);

    return EditorState.createWithContent(contentState, decorator);
  }

  return EditorState.createEmpty(decorator);
};

const EditorInput: React.FC<EditorInputProps> = props => {
  const {
    placeholder,
    content,
    onChange,
    maxCharacters,
    className,
    ref,
    disabled,
    isTwitter
  } = props;

  const decorator = isTwitter ? TwitterDecorator() : LinkedinDecorator();

  const [editorState, setEditorState] = useState(() =>
    getInitialEditorState(decorator, content)
  );

  const editor = useRef<Editor | null>(null);

  const focusEditor = () => {
    editor.current && editor.current.focus();
  };

  const onEditorChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    onChange && onChange(newEditorState.getCurrentContent().getPlainText(''));
  };

  const text = editorState.getCurrentContent().getPlainText('');

  return (
    <EditorWrapper
      className={className}
      onClick={focusEditor}
      disabled={disabled}
    >
      <Editor
        ref={ref}
        editorState={editorState}
        onChange={onEditorChange}
        placeholder={placeholder}
      />
      {maxCharacters && (
        <Counter
          isTwitter={isTwitter}
          maxCharacters={maxCharacters}
          text={text}
        />
      )}
    </EditorWrapper>
  );
};

export default EditorInput;
