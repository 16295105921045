import Client from 'config/axios';

export const getCompanies = async (
  token: string,
  limit: number,
  page: number
) => {
  try {
    const { data } = await Client.get(`companies?limit=${limit}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data.data.docs };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
