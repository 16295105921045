import React, { useEffect, useState } from 'react';

import Icon from 'components/icons/Icon';
import Input from 'components/inputs/Input';
import { useTranslation } from 'react-i18next';
import { generateRandAlphaNumStr } from 'Utils/StringRandom';
import { ErrorSpan, MarginPreview, MarginUploadFile } from './StyleUploadFile';

interface IUploadFileImageSimpleProps {
  icon?: string;
  name?: string;
  handleImage?: any;
  id?: string;
  className?: string;
  content?: string;
  allowedFileTypes?: any;
  imagesArray?: Array<any>;
  maxNumberOfFiles?: number;
  maxFileSize?: number;
  ref?: any;
  infoBox?: string;
  disabled?: boolean;
  openNewTab?: boolean;
}

const UploadFileImageSimple: React.FC<IUploadFileImageSimpleProps> = props => {
  const typeFile = 'image/png, image/jpg, image/jpeg';
  const {
    icon,
    name,
    handleImage,
    id,
    className,
    content,
    allowedFileTypes = typeFile,
    imagesArray = [],
    maxNumberOfFiles = 1,
    maxFileSize = 20000,
    ref,
    infoBox,
    disabled = false,
    openNewTab = false
  } = props;

  const { t } = useTranslation(['campaigns_page']);
  const [images, setImages] = useState<Array<any>>([]);
  const [errorSize, setErrorSize] = useState<boolean>(false);
  const [errorMB, setErrorMB] = useState<boolean>(false);
  const [errorFileType, setErrorFileType] = useState<boolean>(false);

  const photo = imagesArray?.map(image => {
    return {
      index: generateRandAlphaNumStr(),
      name: '',
      url: image
    };
  });

  useEffect(() => {
    if (photo?.length > 0) {
      setImages(photo);
    }
  }, []);

  const changeInput = (e: any) => {
    setErrorSize(false);
    setErrorMB(false);
    setErrorFileType(false);
    const sizekiloBytes = parseInt(e?.currentTarget?.files[0]?.size) / 1024;
    const sizeImage = parseInt(sizekiloBytes.toString());

    if (!allowedFileTypes.includes(e?.target?.files[0]?.type)) {
      setErrorFileType(true);
      return;
    }

    if (sizeImage > maxFileSize) {
      setErrorMB(true);
      return;
    }

    const newImgsToState = readmultifiles(e);
    const newImgsState = [...images, ...newImgsToState];
    setImages(newImgsState);
    handleImage(newImgsState, content);
  };

  function readmultifiles(e: any) {
    const files = e.currentTarget.files;
    const arrayImages: any = [];

    Object.keys(files).forEach(i => {
      const file = files[i];
      const url = URL.createObjectURL(file);

      arrayImages.push({
        index: generateRandAlphaNumStr(),
        name: file.name,
        url: url,
        file
      });
    });

    return arrayImages;
  }

  const onImgLoad = ({ target: img }: any, index: any) => {
    const { naturalHeight, naturalWidth } = img;
    if (naturalWidth > 1920 && naturalHeight > 1920) {
      deleteImg(index);
      setErrorSize(true);
    }
  };

  const deleteImg = (index: any) => {
    const newImgs = images.filter((image: any) => image.index !== index);
    handleImage(newImgs, content);
    setImages(newImgs);
  };

  const open = (pdfUrl: string) => {
    window.open(pdfUrl, '_blank', 'noreferrer');
  };

  const size = maxFileSize / 1000;

  return (
    <>
      {images.length === 0 || images.length < maxNumberOfFiles ? (
        <MarginUploadFile id={id} className={className}>
          <>
            <Icon name={icon ?? 'file'} size="big" color="#979797" />
            <h3>
              {allowedFileTypes === 'application/pdf'
                ? t('attach_pdf')
                : t('attach_image')}
            </h3>
            <p>{infoBox}</p>
            <Input
              name={name}
              type="file"
              accept={allowedFileTypes}
              onChange={changeInput}
              ref={ref}
            />
          </>
        </MarginUploadFile>
      ) : (
        <></>
      )}
      <MarginPreview>
        {React.Children.toArray(
          images.map(image => (
            <>
              {allowedFileTypes === 'application/pdf' ? (
                <object
                  data={image.url}
                  type="application/pdf"
                  width="200px"
                  height="200px"
                ></object>
              ) : (
                <img
                  alt="icon"
                  src={image.url}
                  onLoad={(e: any) => onImgLoad(e, image.index)}
                />
              )}
              <div className="icons">
                {!disabled && (
                  <Icon
                    size="small"
                    name="close-rounded"
                    onClick={() => deleteImg(image.index)}
                    color="rgba(0,0,0,0.54)"
                  />
                )}
                {openNewTab && (
                  <Icon
                    size="small"
                    name="eye"
                    onClick={() => open(image.url)}
                    color="rgba(0,0,0,0.54)"
                  />
                )}
              </div>
            </>
          ))
        )}
      </MarginPreview>
      {errorSize && <ErrorSpan>{t('error_resolution_image')}</ErrorSpan>}
      {errorMB && <ErrorSpan>{t('error_weight_image', { size })}</ErrorSpan>}
      {errorFileType && <ErrorSpan>{t('error_type_file_image')}</ErrorSpan>}
    </>
  );
};

export default UploadFileImageSimple;
