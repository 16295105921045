import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { RequiredString } from 'helpers/yupHelper';
import CreateAdminForm from './CreateAdminForm';
import { getCompanies } from 'services/rest/SuperAdmin/Company/getCompanies';
import { useHistory } from 'react-router-dom';
import {
  AdministratorData,
  createAdministrator
} from 'services/rest/SuperAdmin/Administrator/createAdministrator';
import { ErrorAlert } from 'components/Alert';

const CreateAdmin: React.FC = () => {
  const { t } = useTranslation(['super_admin_administrator', 'validations']);
  const userSchema = yup.object().shape({
    companyId: RequiredString(),
    name: RequiredString().max(70, t('validations:length_error', { max: 70 })),
    email: RequiredString().max(
      100,
      t('validations:length_error', { max: 100 })
    ),
    username: RequiredString().max(
      100,
      t('validations:length_error', { max: 100 })
    ),
    password: RequiredString()
      .min(5, 'login_form.password_min_length')
      .max(40, 'login_form.password_max_length')
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    errors,
    setError: setErrors
  } = useForm({
    resolver: yupResolver(userSchema)
  });
  const { currentUser } = useSelector(selectUser);
  const [companies, setcompanies] = useState<any | null>(null);
  const [selectCompany, setSelectCompany] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState<string>('');
  const history = useHistory();

  const onSubmit = async (adminData: AdministratorData) => {
    setLoader(true);
    adminData.role = currentUser?.role;
    const { data, errors } = await createAdministrator(
      currentUser?.token,
      adminData
    );
    if (data) {
      setLoader(false);
      if (data?.errors) {
        setError(data.errors?.data);
      } else {
        history.push(
          '/administrators',
          t('super_admin_administrator:create_success')
        );
      }
    }
    if (errors) {
      setError(errors?.data);
      setLoader(false);
    }
  };

  const getData = async () => {
    const { data } = await getCompanies(currentUser?.token, 500, 1);
    setcompanies(data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {error && (
        <ErrorAlert
          text={error}
          allowClose={true}
          onClose={() => setError('')}
        />
      )}
      <CreateAdminForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setErrors={setErrors}
        setValue={setValue}
        control={control}
        loader={loader}
        companies={companies}
        selectCompany={selectCompany}
        setSelectCompany={setSelectCompany}
      />
    </>
  );
};

export default CreateAdmin;
