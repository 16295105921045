import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export const SectionForm = styled.div`
  @media (max-width: ${Breakpoints.medium}) {
    width: 100%;
  }
  margin-top: 30px;
  h1 {
    font-size: 18px;
  }
`;

export const ButtonStyled = styled.div`
  button {
    text-align: center;
    margin: auto;
    margin-top: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
`;

export const InputWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
  @media (max-width: ${Breakpoints.medium}) {
    display: grid;
  }

  .checkbox-parent {
    position: relative;
    &.disabled {
      input {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .bonus-checkbox {
    display: flex;
    align-items: center;
  }

  .checkbox-child {
    float: left;
  }

  label {
    color: #4d4d4d;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 10px;
    grid-column: span 9;
  }

  input {
    margin: 10px 0;
    @media (max-width: ${Breakpoints.medium}) {
      display: grid;
      width: 100%;
      margin-left: 0%;
    }
  }

  .checkbox {
    display: flex;
    width: 100%;
    margin: 10px 0 0px 10px;
    @media (max-width: ${Breakpoints.medium}) {
      width: 50%;
      margin-top: 0px;
      margin-right: 1px;
    }
  }

  div.dropdown-filter-container {
    margin-top: 15px;
    margin-left: 6%;
    margin-right: 20%;
    @media (max-width: ${Breakpoints.medium}) {
      display: grid;
      width: 100%;
      margin-left: 0%;
    }
  }

  div.dropdown-filter-content__control {
    width: 150px;
    border: 1px solid #979797;
    border-radius: 30px;
    background-color: transparent;
    box-shadow: none;
    height: 30px;

    &:hover,
    &:focus-within {
      border-color: ${Colors.primaryDarkBlue};
      cursor: pointer;
    }
  }

  div.dropdown-filter-content__single-value {
    color: #3e66fe;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
  }

  div.dropdown-filter-content__value-container {
    margin-top: -6px;
  }

  span.dropdown-filter-content__indicator-separator {
    display: none;
  }

  div.dropdown-filter-content__indicator {
    color: rgba(0, 0, 0, 0.54);
  }

  div.dropdown-filter-content__placeholder {
    padding-bottom: 15px;
    font-size: 15px;
    color: ${Colors.inactive};
  }

  div.dropdown-filter-content__menu {
    margin-top: 1px;
    width: 170px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    z-index: 5;
  }

  div.dropdown-filter-content__menu-list {
    padding-top: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  div.dropdown-filter-content__option {
    padding: 6px 8px;
    font-size: 14px;
  }

  div.dropdown-filter-content__option--is-focused {
    background: transparent;
  }

  div.dropdown-filter-content__option:hover,
  div.dropdown-filter-content__option--is-focused:hover,
  div.dropdown-filter-content__option--is-selected {
    background: rgba(0, 0, 0, 0.08);
    font-weight: 500;
    color: #3e66fe;
  }

  .m-l-0 {
    margin-left: 0px !important;
  }

  .w-100 {
    width: 100%;
    grid-column: span 10;
  }
`;

export const EmailPermissions = styled.div`
  grid-column: span 6;
`;
export const PushNotificationPermissions = styled.div`
  grid-column: span 6;
`;
