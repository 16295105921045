import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

export const StyledPoints = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
`;

export const PointsGrid = styled.div`
  position: relative;
  grid-column: span 12;

  h2 {
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
  }

  svg.icon {
    height: 15px;
    width: 15px;
  }

  @media (min-width: ${Breakpoints.medium}) {
    grid-column: span 6;

    &:after {
      content: '';
      background-color: #d8d8d8;
      border-radius: 32px;
      position: absolute;
      right: -5px;
      height: 80%;
      width: 4px;
      top: 10%;
    }
  }
`;

export const PointsData = styled.div`
  display: flex;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 215px;
  margin: 0;

  th {
    text-align: center;
    font-size: 12px;
    color: #a5adba;
    padding: 5px;
  }

  td {
    position: relative;
    box-shadow: none;
    font-size: 14px;
    padding: 5px;
    white-space: nowrap;
    width: auto;
    font-weight: 500;

    &:before {
      content: none;
    }

    &:first-child {
      border-bottom: 1px solid #f2f2f2;

      @media (max-width: ${Breakpoints.medium}) {
        padding-left: 0;
      }
    }

    &:nth-child(2) {
      padding: 5px 20px 5px 5px;
    }

    &:last-child {
      color: ${Colors.primaryNormalPurple};
      padding: 0 20px;
      text-align: center;

      &:before {
        content: "";
        background: ${Colors.primaryLightGray};
        position: absolute;
        height: 60%;
        width: 1px;
        top: 20%;
        left: 0;
      }
    }
`;

export const TotalPoints = styled.div`
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  max-width: 110px;

  p {
    color: ${Colors.primaryNormalPurple};
    font-weight: bold;
    font-size: 38px;
    margin: 0;
    @media (min-width: ${Breakpoints.medium}) {
      font-size: 48px;
    }
  }

  h3 {
    color: ${Colors.primaryNormalPurple};
    font-weight: 500;
    font-size: 11px;
    width: 100%;
    @media (min-width: ${Breakpoints.medium}) {
      font-size: 13px;
    }
  }
`;

export const BoxAction = styled.div`
  grid-column: span 12;
  text-align: center;
  padding: 20px 0;
  p.error-message-challenge {
    color: red;
    font-weight: bold;
  }
  @media (min-width: ${Breakpoints.medium}) {
    padding: 5px 30px;
    grid-column: span 6;
  }
`;

export const BoxActionChallenges = styled.div`
  grid-column: span 1;
  text-align: center;
  padding: 10px 0;

  @media (min-width: ${Breakpoints.medium}) {
    display: flex;
    font-size: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const BoxIframes = styled.div`
  grid-column: span 12;
  text-align: center;
  padding: 20px 0;
`;
