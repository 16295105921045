import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods, FieldErrors } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';

import { ForgotPasswordData } from 'services/rest/authentication';

import {
  StyledForm,
  PoweredByWrapper,
  PasswordText,
  MessageText,
  NoAccountSection,
  LogoWrapper
} from './Styled';
import { InputWrapper } from 'components/inputs/VerticalWrapper';
import Button from 'components/buttons/Button';
import { BrowsersFooter } from 'components/Footer';
import AdvocatesProLogo from 'images/logos/advocates_logo.svg';
import ThreeDots from 'components/loaders/ThreeDots';
import { RequiredString } from 'helpers/yupHelper';
import { useSelector } from 'react-redux';
import { selectCompanyStyle } from 'redux/slices/CompanyStyle/CompanyStyleSlice';

export interface ForgotPasswordFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<ForgotPasswordData>;
  register: UseFormMethods['register'];
  loader: any;
}

export const userSchema = yup.object().shape({
  email: RequiredString().email('email')
});

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = props => {
  const { errors, onSubmit, register, loader } = props;
  const { t } = useTranslation(['forgot_Password_form']);

  const { companyStyle, loading } = useSelector(selectCompanyStyle);
  return (
    <>
      <NavLink to="/" className="logo">
        <LogoWrapper>
          {loading ? (
            <ThreeDots />
          ) : (
            <>
              <img
                className="hide-on-mobile"
                src={companyStyle?.logo}
                alt="Home"
              />
              <img
                className="show-on-mobile mobile-logo"
                src={companyStyle?.logo}
                alt="Home"
              />
            </>
          )}
        </LogoWrapper>
      </NavLink>

      <PasswordText>
        <p>{t('forgot_Password_form:question')}</p>
      </PasswordText>

      <MessageText>
        <p>{t('forgot_Password_form:message')}</p>
      </MessageText>

      <StyledForm onSubmit={onSubmit}>
        <InputWrapper
          label={t('forgot_Password_form:email')}
          name="email"
          ref={register}
          type="email"
          error={errors.email?.message}
          data-testid="email"
        />
        <Button
          value={t('forgot_Password_form:send-link')}
          variant="primary"
          loader={loader}
        />
      </StyledForm>

      <NoAccountSection>
        <p>{t('forgot_Password_form:have_account')}</p>
        <NavLink to="/sign_in" className="register">
          {t('forgot_Password_form:sign_in')}
        </NavLink>
      </NoAccountSection>

      <PoweredByWrapper>
        <p>Powered By</p>
        <img src={AdvocatesProLogo} alt="AdvocatesPro" />
      </PoweredByWrapper>

      <BrowsersFooter />
    </>
  );
};

export default ForgotPasswordForm;
