import Client from 'config/axios';

export interface PasswordData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  role: string;
}

export const changePassword = async (
  token: string,
  passwords: PasswordData,
  userId: any
) => {
  try {
    const { data } = await Client.post(`change-password/${userId}`, passwords, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
