import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import UpdateCompanyForm from './UpdateCompanyForm';
import { getCountries } from 'services/rest/SuperAdmin/Country/getCountries';
import { getCurrencies } from 'services/rest/SuperAdmin/Currency/getCurrencies';
import { selectUser } from 'redux/slices/userSlice';
import { getCompanyDataById } from 'services/rest/Company/GetCompanyData';
import { useHistory, useParams } from 'react-router-dom';
import { RequiredString } from 'helpers/yupHelper';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import { companyFileName } from 'helpers/companyFileName';
import { awsUploadFileHelper } from 'helpers/awsFileHelper';
import { getUrlS3 } from 'constants/setup.constants';
import { ErrorAlert, SuccessAlert } from 'components/Alert';
import { updateCompany } from 'services/rest/Company/updateCompany';
import moment from 'moment';

const UpdateCompany: React.FC = () => {
  const [company, setCompany] = useState<any>();
  const { t } = useTranslation(['validations', 'super_admin_company']);
  const companySchema = yup.object().shape({
    name: RequiredString().max(
      100,
      t('validations:length_error', { max: 100 })
    ),
    ambassadorsLimit: yup
      .number()
      .typeError(t('super_admin_company:form:validations:required'))
      .required(t('super_admin_company:form:validations:required'))
      .min(
        company?.ambassadorsLimit,
        t('super_admin_company:ambassadors_limit_error')
      ),
    licenseDate: yup
      .date()
      .typeError(t('super_admin_company:form:validations:required'))
      .required(t('super_admin_company:form:validations:required'))
      .min(
        moment(company?.licenseDate).format('YYYY-MM-DD'),
        t('super_admin_company:license_date_error')
      )
  });
  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(companySchema)
  });
  const [loader, setLoader] = useState(false);
  const { currentUser } = useSelector(selectUser);
  const [countries, setCountries] = useState<Array<any>>([]);
  const [currencies, setCurrencies] = useState<Array<any>>([]);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [backgroundImage, setBackgroundImage] = useState<Array<any>>([]);
  const [logoImage, setLogoImage] = useState<Array<any>>([]);
  const [emailLogoImage, setEmailLogoImage] = useState<Array<any>>([]);
  const [tosPdf, setTosPdf] = useState<Array<any>>([]);
  const [fileError, setFileError] = useState<boolean>(false);
  const { companyId } = useParams<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  const handleImage = (select: Array<any>, content: string) => {
    if (select.length > 0) {
      switch (content) {
        case 'backgroundImage':
          setBackgroundImage(select);
          setFileError(false);
          break;
        case 'logo':
          setLogoImage(select);
          setFileError(false);
          break;
        case 'emailLogo':
          setEmailLogoImage(select);
          setFileError(false);
          break;
        case 'tosUrl':
          setTosPdf(select);
          setFileError(false);
          break;
      }
    } else {
      setFileError(true);
    }
  };

  const getCompanyData = async () => {
    const data = await getCompanyDataById(currentUser?.token, companyId);
    if (data.errors) {
      setError(data.errors);
    }

    const currenciesData = await getCurrencies(currentUser?.token);
    const countriesData = await getCountries(currentUser?.token);

    if (data) {
      data.licenseDate = moment(data.licenseDate).format('YYYY-MM-DD');
      setCompany(data);
      setCurrencies(currenciesData);
      setCountries(countriesData);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  const onSubmit = async (companyData: any) => {
    setLoader(true);

    if (fileError) {
      setError(t('validations:file_required'));
      setLoader(false);
      return;
    }
    if (backgroundImage.length > 0) {
      const backgroundName = companyFileName(
        backgroundImage,
        company?.subdomain,
        'background'
      );
      await awsUploadFileHelper(
        currentUser?.token,
        backgroundImage[0],
        'COMPANY',
        backgroundName,
        false,
        setError,
        t('validations:file_error'),
        company?.subdomain
      );
      companyData.backgroundImage = getUrlS3(
        'companies',
        backgroundName,
        company?.subdomain
      );
    }
    if (logoImage.length > 0) {
      await awsUploadFileHelper(
        currentUser?.token,
        logoImage[0],
        'COMPANY',
        `${company?.subdomain}_logo.svg`,
        false,
        setError,
        t('validations:file_error'),
        company?.subdomain
      );
      companyData.logo = getUrlS3(
        'companies',
        `${company?.subdomain}_logo.svg`,
        company?.subdomain
      );
    }
    if (emailLogoImage.length > 0) {
      await awsUploadFileHelper(
        currentUser?.token,
        emailLogoImage[0],
        'COMPANY',
        `${company?.subdomain}_logo.png`,
        false,
        setError,
        t('validations:file_error'),
        company?.subdomain
      );
      companyData.emailLogo = getUrlS3(
        'companies',
        `${company?.subdomain}_logo.png`,
        company?.subdomain
      );
    }
    if (tosPdf.length > 0) {
      await awsUploadFileHelper(
        currentUser?.token,
        tosPdf[0],
        'LEGAL',
        `legal_${company?.subdomain}.pdf`,
        false,
        setError,
        t('validations:file_error'),
        company?.subdomain
      );
      companyData.TosUrl = `legal_${company?.subdomain}.pdf`;
    }
    companyData.id = company?.id;
    companyData.role = currentUser?.role;

    const { data, errors } = await updateCompany(
      currentUser?.token,
      companyData
    );
    if (data) {
      setLoader(false);
      history.push('/companies', t('super_admin_company:update_success'));
    }
    if (errors) {
      setError(errors?.data);
      setLoader(false);
    }
  };
  return (
    <>
      {error && (
        <ErrorAlert
          text={error}
          allowClose={true}
          onClose={() => setError('')}
        />
      )}
      {success && (
        <SuccessAlert
          text={success}
          allowClose={true}
          onClose={() => setSuccess('')}
        />
      )}
      <UpdateCompanyForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        company={company}
        loading={loading}
        errors={errors}
        setValue={setValue}
        control={control}
        loader={loader}
        countries={countries}
        currencies={currencies}
        handleImage={handleImage}
      />
    </>
  );
};

export default UpdateCompany;
