import React from 'react';
import { useTranslation } from 'react-i18next';
import { OptionTypeBase } from 'react-select';
import { useHistory } from 'react-router-dom';
import { Award } from 'types/appTypes';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import {
  AwardsContainer,
  MarginAdminPage,
  SectionCreater,
  SectionFilter
} from 'pages/AwardPageAdmin/StyledAwardPageAdmin';
import iconEditOutline from 'images/icons/edit-outline.svg';
import empty_prizes from 'images/empty_states/empty_prizes.png';
import Button from 'components/buttons/Button';
import { groupNameByObj } from 'helpers/groupsHelper';
import AwardSingleCard from '../AwardSingleCard';
export interface AwardsListWebProps {
  awards: Array<Award>;
  periodTypes: Array<OptionTypeBase>;
  selectedPeriodType: OptionTypeBase | any;
  onSelect: (value: OptionTypeBase) => void;
}

const AwardsListWebAdmin: React.FC<AwardsListWebProps> = props => {
  const { awards, periodTypes, selectedPeriodType, onSelect } = props;
  const { t } = useTranslation(['dates', 'awards_section', 'ranking_filters']);
  const history = useHistory();

  const getPeriod = (periodType: string, periodValue: string) => {
    const translationPage = t(`dates:${periodType}`, {
      returnObjects: true
    });

    return periodType === 'year'
      ? periodValue
      : translationPage[parseInt(periodValue) - 1];
  };

  const activeAwards = awards.filter(award => {
    const data = award as any;
    return data.status !== 'completed';
  });

  const finishedAwards = awards.filter(award => {
    const data = award as any;
    return data.status === 'completed';
  });

  if (awards.length === 0) {
    return (
      <>
        <SectionFilter>
          <div className="margin">
            <span>{t('ranking_filters:filter_by')}</span>
            <DropdownSelect
              onSelect={onSelect}
              value={selectedPeriodType}
              defaultValue={periodTypes[0]}
              options={periodTypes}
            />
          </div>
        </SectionFilter>
        <SectionCreater>
          <img alt="EmptyIcon" src={empty_prizes} />
          <div>
            <h2>{t('awards_section:new_award')}</h2>
            <p>{t('awards_section:encourage_employees')}</p>
          </div>
          <Button
            value={t('awards_section:create_award')}
            onClick={() => history.replace('/admin/award/newAward')}
          />
        </SectionCreater>
      </>
    );
  }

  return (
    <div className="hide-on-mobile">
      <SectionFilter>
        <div className="margin">
          <span>{t('ranking_filters:filter_by')}</span>
          <DropdownSelect
            onSelect={onSelect}
            value={selectedPeriodType}
            defaultValue={periodTypes[0]}
            options={periodTypes}
          />
        </div>
      </SectionFilter>

      {activeAwards.length > 0 && (
        <AwardsContainer>
          <MarginAdminPage>
            <h2>{t('awards_section:active')}</h2>
          </MarginAdminPage>
          {activeAwards.map((award, index) => {
            return (
              <AwardSingleCard
                key={index}
                id={award.id}
                photoUrl={award.photoUrl}
                group={groupNameByObj(award.groups)}
                description={award.description}
                periodValue={getPeriod(award.periodType, award.periodValue)}
                iconEdit={iconEditOutline}
              />
            );
          })}
        </AwardsContainer>
      )}

      {finishedAwards.length > 0 && (
        <AwardsContainer>
          <MarginAdminPage>
            <h2>{t('awards_section:finished')}</h2>
          </MarginAdminPage>
          {finishedAwards.map((award, index) => {
            return (
              <AwardSingleCard
                key={index}
                id={award.id}
                photoUrl={award.photoUrl}
                group={groupNameByObj(award.groups)}
                description={award.description}
                periodValue={getPeriod(award.periodType, award.periodValue)}
                iconEdit={iconEditOutline}
              />
            );
          })}
        </AwardsContainer>
      )}
    </div>
  );
};

export default AwardsListWebAdmin;
