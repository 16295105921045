import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';

import ForgotPasswordForm, { userSchema } from './ForgotPasswordForm';
import { ErrorAlert, SuccessAlert } from 'components/Alert';
import {
  ForgotPassword,
  ForgotPasswordData
} from 'services/rest/authentication';
// import { login } from 'redux/slices/userSlice';

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation(['forgot_Password_form']);

  const { register, handleSubmit, errors } = useForm<ForgotPasswordData>({
    resolver: yupResolver(userSchema)
  });

  const [backendError, setBackendError] = useState<string | null>(null);
  const [backendSuccess, setBackendSuccess] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);

  const onSubmit = async ({ email }: ForgotPasswordData) => {
    setLoader(true);
    const ForgotPasswordData = await ForgotPassword({ email });
    const result = ForgotPasswordData as any;
    if (result) {
      if (result.user) {
        setBackendSuccess(t('forgot_Password_form:email_sent'));
        setLoader(false);
      } else {
        setBackendError(result.data.data);
        setLoader(false);
      }
    }
  };

  return (
    <>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}

      <ForgotPasswordForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        loader={loader}
      />
    </>
  );
};

export default ForgotPasswordPage;
