import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

const StyledMetricBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 10px;
  }

  p {
    color: ${Colors.primaryDarkBlue};
    margin-bottom: 0px;
    font-weight: 700;
    line-height: 1;
  }

  h3 {
    width: auto;
    display: block;
    margin: 0 auto;
    color: #333333;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
    font-family: inherit;

    @media (max-width: ${Breakpoints.medium}) {
      grid-column: span 12;
      font-size: 0.6875rem;
      text-align: left;
      width: 100%;
    }
  }

  svg {
    margin: 0 auto 15px;

    @media (max-width: ${Breakpoints.medium}) {
      margin: 0 0 20px;
    }
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
`;

export default StyledMetricBox;
