import React, { useEffect, useState, Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import AwardsListWeb from './AwardsListAdmin/AwardsListWebAdmin';
import SectionWrapper from 'pages/Layout/SectionWrapper';

import { Section_header } from './StyledAwardPageAdmin';
import Button from 'components/buttons/Button';
import { getAwardsList } from 'services/rest/Award/getAwardsList';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { Award } from 'types/appTypes';
import { SuccessAlert } from 'components/Alert';
import { OptionTypeBase } from 'react-select';

const AwardPageAdmin = () => {
  const { t } = useTranslation(['dates', 'awards_section', 'ranking_filters']);
  const history = useHistory();
  const location = useLocation();
  const { state } = location as any;
  const periodTypes = [
    { label: t('dates:period_type.month'), value: 'month' },
    { label: t('dates:period_type.quarter'), value: 'quarter' },
    { label: t('dates:period_type.year'), value: 'year' },
    { label: t('dates:period_type.custom'), value: 'custom' }
  ];
  const { currentUser } = useSelector(selectUser);
  const [awards, setAwards] = useState<Award[]>([]);
  const [filterOption, setfilterOption] = useState<Award[]>([]);
  const [loading, setLoading] = useState(true);
  const [backendSuccess, setBackendSuccess] = useState<string | null>(state);
  const options = [
    { label: t('dates:period_type.month'), value: 'month' },
    { label: t('dates:period_type.quarter'), value: 'quarter' },
    { label: t('dates:period_type.year'), value: 'year' },
    { label: t('dates:period_type.custom'), value: 'custom' }
  ];

  const [periodType, setPeriodType] = useState<OptionTypeBase>(options[0]);

  const getData = async () => {
    const { data } = await getAwardsList(
      currentUser?.token,
      100,
      1,
      periodType?.value
    );
    setAwards(data.data.docs);
    const filter = data?.data?.docs?.filter(
      (award: any) => award.periodType == periodType?.value
    );
    setfilterOption(filter);
    showAlertSuccessAwardCreated();
    setLoading(false);
  };

  const showAlertSuccessAwardCreated = () => {
    const queryParams = new URLSearchParams(location.search);
    const groupCreated = queryParams.get('backend_success_award_created');
    if (groupCreated != null && groupCreated == 'true') {
      setBackendSuccess(t('awards_section:created_award'));
    }
  };

  useEffect(() => {
    getData();
  }, [periodType]);

  const selectOption = (event: any) => {
    let filter = awards;
    filter = awards.filter((award: any) => award.periodType == event.value);
    const selectedOption = options.filter(
      (option: any) => option.value == event.value
    );
    setfilterOption(filter);
    setPeriodType(selectedOption[0]);
  };

  const currentAwards = filterOption;

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}
      <Suspense fallback={<LoadingState height="650px" width="100%" />}>
        <SectionWrapper>
          <Section_header>
            <>
              <h1 className="section-title">{t('awards_section:awards')}</h1>
              {awards.length > 0 && (
                <Button
                  value={t('awards_section:create_award')}
                  className="hide-on-mobile"
                  onClick={() => history.replace('/admin/award/newAward')}
                />
              )}
            </>
          </Section_header>
          <AwardsListWeb
            awards={currentAwards}
            periodTypes={periodTypes}
            selectedPeriodType={periodType}
            onSelect={selectOption}
          />
        </SectionWrapper>
      </Suspense>
    </>
  );
};

export default AwardPageAdmin;
