import Client from 'config/axios';

export const createLog = async (body: any) => {
  try {
    const { data } = await Client.post('report-log', body);
    return {
      data
    };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
