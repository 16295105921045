import Client from 'config/axios';

export const awardCampaignBonusValidate = async (token: string, id: any) => {
  return await Client.get(`award-campaign-bonus-validate/${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data.data)
    .catch(error => error);
};
