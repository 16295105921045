import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import LoginForm, { userSchema } from './LoginForm';
import Login2FAForm, { userSchema as user2FAsSchema } from './Login2FAForm';
import { ErrorAlert } from 'components/Alert';
import { Login, LoginData, Login2FAData } from 'services/rest/authentication';
import { login, setUserInfo } from 'redux/slices/userSlice';
import { getInfoUser } from 'services/rest/User/getInfoUser';
import { getCurrentCompany } from 'helpers/companyHelper';
import { getCompanyDataById } from 'services/rest/Company/GetCompanyData';
import { setCompanyData } from 'redux/slices/Company/CompanySlice';
import { useTranslation } from 'react-i18next';
import { validate2FACode } from 'services/rest/2FA/validate2FACode';
import { selectCompanyStyle } from 'redux/slices/CompanyStyle/CompanyStyleSlice';
const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subdomain = getCurrentCompany();
  const { t } = useTranslation(['login_form']);
  const location = useLocation();
  const { companyStyle } = useSelector(selectCompanyStyle);
  const { register, handleSubmit, errors } = useForm<LoginData>({
    resolver: yupResolver(userSchema)
  });
  const data2FA = useForm<Login2FAData>({
    resolver: yupResolver(user2FAsSchema)
  });
  const [backendError, setBackendError] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const [allow2FA, setAllow2FA] = useState(false);
  const [reCaptcha, setReCaptchaToken] = useState<string | null>(null);
  const [loginData2FATemporal, setLoginData2FATemporal] = useState({
    user: null,
    dataResult: null
  });
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    showAlertTokenExpired();
    const openApp = () => {
      const deepLinkUrl = `https://advocatespro.page.link/?link=https://${subdomain}.advocatespro.com&apn=com.advocatespro.test&isi=1539150527&ibi=com.advocatespro.app`;
      window.location.href = deepLinkUrl;
    };
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    if (isMobileDevice) {
      openApp();
    }
    LoginAuto();
  }, []);
  const showAlertTokenExpired = () => {
    const { state } = location as any;
    if (state?.from === 'token_expired') {
      setBackendError(t('login_form:token_expired'));
    }
  };
  const onSubmit2FA = async ({ code2FA }: Login2FAData) => {
    setLoader(true);
    if (data2FA.errors && Object.keys(data2FA.errors).length > 0) {
      setBackendError(data2FA.errors as any);
      setLoader(false);
    }
    if (code2FA) {
      const code: any = code2FA;
      const { token } = loginData2FATemporal.user as any;
      const isValid = await validate2FACode(token, { code });
      if (isValid == false) {
        setBackendError('Codigo de verificación incorrecto');
        setLoader(false);
      }
      if (isValid) {
        await saveUserDataAndContinue(
          loginData2FATemporal.user,
          loginData2FATemporal.dataResult
        );
      }
    }
  };

  const LoginAuto = async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const emailQuery = queryParameters.get('email') ?? '';
    const passwordQuery = queryParameters.get('password') ?? '';

    if (emailQuery && passwordQuery) {
      const { user, errors } = await Login({
        email: emailQuery,
        password: passwordQuery,
        subdomain
      });
      if (user) {
        const data = await getInfoUser(user?.user?.id, user?.token, user?.role);
        if (data) {
          if (user?.role === 'admin' && data.allow2FA) {
            setAllow2FA(true);
            setLoader(false);
            setLoginData2FATemporal({
              user,
              dataResult: data
            });
            return;
          }
          await saveUserDataAndContinue(user, data);
        }
      }
      if (errors) {
        setBackendError(errors);
        setLoader(false);
      }
    }
  };

  const saveUserDataAndContinue = async (user: any, dataResult: any) => {
    dispatch(login(user));
    dispatch(setUserInfo(dataResult));
    const data = await getCompanyDataById(user?.token, user?.user?.companyId);
    if (data) {
      dispatch(setCompanyData(data));
      history.replace('/');
    } else {
      setBackendError('Error');
    }
    setLoader(false);
  };
  const onSubmit = async ({ email, password }: LoginData) => {
    setLoader(true);
    if (companyStyle?.allowRecaptcha && !reCaptcha) {
      setBackendError('El campo ReCAPTCHA es requerido');
      setLoader(false);
      return;
    }
    const { user, errors } = await Login({
      email,
      password,
      subdomain
    });
    if (user) {
      const data = await getInfoUser(user?.user?.id, user?.token, user?.role);
      if (data) {
        if (user?.role === 'admin' && data.allow2FA) {
          setAllow2FA(true);
          setLoader(false);
          setLoginData2FATemporal({
            user,
            dataResult: data
          });
          return;
        }
        await saveUserDataAndContinue(user, data);
      }
    }
    if (errors) {
      setBackendError(errors);
      setLoader(false);
    }
  };
  return (
    <>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => {
            setBackendError(null);
          }}
        />
      )}
      {!allow2FA && (
        <LoginForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={errors}
          loader={loader}
          setReCaptchaToken={setReCaptchaToken}
          showPassword={showPassword}
          onClickEye={() => {
            setShowPassword(!showPassword);
          }}
          reCaptcha={reCaptcha}
        />
      )}
      {allow2FA && (
        <Login2FAForm
          onSubmit={data2FA.handleSubmit(onSubmit2FA)}
          register={data2FA.register}
          errors={data2FA.errors}
          loader={loader}
        ></Login2FAForm>
      )}
    </>
  );
};
export default LoginPage;
