import styled, { css } from 'styled-components';
import { Colors } from 'styles/Constants';
export interface StyledTooltipWrapperProps {
  visible: boolean;
}

export const FilterCampaigns = styled.div`
  display: flex;

  button {
    border-radius: 20px;
    font-size: 0.6875rem;
    padding: 6px 15px;
    height: 35px;
    margin-right: 20px;
  }

  input {
    width: 30%;
    margin-right: 20px;
  }

  span {
    margin-top: 7px;
  }
`;

export const StyleTable = styled.div`
  .column {
    background-color: ${Colors.normalWhite};
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: ${Colors.secondaryNormalGrey};
    border: none;
    font-weight: bold;
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 20px;
    font-size: 1rem;
    color: ${Colors.secondaryNormalGrey};
    margin-bottom: 10px;
    max-width: 100%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .table_container {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .column-body {
    box-shadow: 0 1px 6px 0 rgb(79 79 79 / 38%);
    transition: all 0.5s ease-out 0s;
    background: ${Colors.normalWhite};
    margin-bottom: 20px;
    border-radius: 10px;
    z-index: 2;
    height: 70px;

    &.pending-bg {
      background-color: #c7e6eb;
    }

    th {
      font-family: 'Montserrat', sans-serif;
      font-size: 1.125rem;
      padding: 16px;
      border-collapse: separate;
      border-spacing: 0 20px;
      font-size: 1rem;
      color: ${Colors.secondaryNormalGrey};
      border-bottom: none;
    }

    img {
      height: 20px;
      width: 20px;
    }

    svg {
      height: 22px;
      width: 22px;
    }

    img.user-photo {
      border-radius: 100%;
      margin: 0 10px;
      height: 50px;
      width: 50px;
    }
  }

  .name {
    font-weight: 700;
    text-align: left;
  }

  .social-network {
    margin: auto;
  }

  .date {
    text-transform: capitalize;
    font-weight: 700;
  }

  .pointer {
    cursor: pointer;
  }
`;

export const HeardMargin = styled.div`
  display: flex;
  align-items: center;

  .option {
    display: flex;
    margin-right: 10px;
    img {
      margin-top: 13px;
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }
    svg {
      margin-top: 13px;
      margin-right: 10px;
      height: 22px;
      width: 22px;
    }
  }
`;

export const TextStyled = styled.p`
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: ${Colors.secondaryNormalGrey};
`;

export const MarginAmbassadorPage = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 60%;
    font-size: 1.125rem;
    height: 20%;
    margin-bottom: 30px;
    border: 1px solid #d113fe;
    background-color: #d113fe;
  }
`;

export const SocialButtton = styled.button`
  background: none;
  border: none;
  zoom: 150%;
  filter: grayscale(1);
  cursor: pointer;
  &.selected {
    filter: grayscale(0);
    zoom: 200%;
  }
`;
export const PostDetailWrapper = styled.div`
  p.small-paragraph {
    font-size: 0.875rem;
    text-align: center;
    margin: 0 0 5px 0;
    font-weight: 500;
  }

  a {
    width: 90%;
  }
`;

export const TooltipMessage = styled.span`
  visibility: hidden;
  display: none;
  background-color: #1661ea;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 2;
  bottom: 100%;
  left: 0px;
  margin-left: 0px;
  transition: opacity 0.3s;
  margin-bottom: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 20%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #1661ea transparent transparent transparent;
  }
`;

export const StyledTooltipWrapper = styled.div<StyledTooltipWrapperProps>`
  position: relative;
  cursor: pointer;

  ${({ visible }) =>
    visible &&
    css`
      ${TooltipMessage} {
        visibility: visible;
        display: block;
      }
      ${TooltipMessage}.ranking-tooltip {
        visibility: visible;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        min-width: 300px;
        left: inherit !important;
      }
    `}
`;

export const SocialInfoStyled = styled.div`
  &.total-points {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    height: 20px;
    width: 20px;
    &.points {
      height: 15px;
      width: 15px;
      margin-left: 5px;
    }
  }

  b {
    font-size: 16px;
    line-height: 23px;
    margin: 7px 0 0 7px;
  }
`;
