import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './CompanyStyle.state';
import { ICompanyStyle } from './CompanyStyle.interface';

const setCompanyLoading = (state: any, action: { payload: any }) => {
  return {
    ...state,
    loading: action.payload
  };
};

const setCompany = (state: any, action: { payload: ICompanyStyle }) => {
  return {
    ...state,
    companyStyle: action.payload
  };
};

export const selectCompanyStyle = (state: { companyStyle: any }) =>
  state.companyStyle;

const CompanySlice = createSlice({
  name: 'companyStyleSlice',
  initialState: {
    loading: false,
    companyStyle: initialState
  },
  reducers: {
    setLoading: setCompanyLoading,
    setDataStyle: setCompany
  }
});

export const { setLoading, setDataStyle } = CompanySlice.actions;

export default CompanySlice.reducer;
