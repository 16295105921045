import React from 'react';
import { useTranslation } from 'react-i18next';
import EmployeeAvatar from 'images/avatars/employee.png';

import {
  UserHeader,
  UserInfo,
  UserDescription,
  NetworksCard
} from './StyledEmployeeCard';

import NetworksList, { NetworksListProps } from './NetworksList';

export interface EmployeeCardProps extends NetworksListProps {
  userPhoto: string;
  userName: string;
  points: number;
  ranking: number;
  currentMonth: string;
}

const EmployeeCard: React.FC<EmployeeCardProps> = props => {
  const {
    userPhoto,
    userName,
    ranking,
    points,
    currentMonth,
    networks
  } = props;

  const { t } = useTranslation(['employee_card']);

  return (
    <UserHeader>
      <UserInfo>
        <img src={userPhoto || EmployeeAvatar} alt="userPhoto" />
        <div className="texts">
          <h1>{t('employee_card:hello', { userName })}</h1>
          <p>
            {t('employee_card:your_ranking', { currentMonth, ranking, points })}
          </p>
        </div>
      </UserInfo>

      <UserDescription>
        <div className="data-items">
          <div className="data">
            <div className="item ranking">
              <h2>{t('employee_card:ranking_title', { currentMonth })}</h2>
              <p>{ranking}</p>
            </div>

            <div className="item points">
              <h2>{t('employee_card:points_title')}</h2>
              <p>{points}</p>
            </div>
          </div>

          <NetworksCard>
            <h2>{t('employee_card:connected_networks')}</h2>
            <NetworksList networks={networks} />
          </NetworksCard>
        </div>
      </UserDescription>
    </UserHeader>
  );
};

export default EmployeeCard;
