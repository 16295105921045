import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { List as LoadingState } from 'react-content-loader';
import { useHistory, useLocation } from 'react-router-dom';
import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { Filter, StyleTable, HeaderSection, ButtonSection } from '../Styled';
import { selectUser } from 'redux/slices/userSlice';
import { getAdministrators } from 'services/rest/SuperAdmin/Administrator/getAdministrators';
import AdministratorsTable from './AdministratorsTable';
import TableFooter from 'components/TableFooter';
import { SuccessAlert } from 'components/Alert';

const Administrator = () => {
  const { t } = useTranslation(['super_admin_administrator']);
  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [administrators, setAdministrators] = useState<any | null>(null);

  const [filterText, setFilterText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const location = useLocation();
  const { state } = location as any;
  const [backendSuccess, setBackendSuccess] = useState<any | null>(null);

  const showAlertSuccessCompanyCreated = async () => {
    if (typeof state === 'string') {
      setBackendSuccess(state);
    }
  };

  const getData = async () => {
    const { data } = await getAdministrators(currentUser?.token, 500, 1);
    setAdministrators(data);
    showAlertSuccessCompanyCreated();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const filteredAdministrators = administrators?.filter((data: any) => {
    const admin = data as any;
    const adminUsername = admin.username.toLowerCase();
    const adminCompany = admin.company.name.toLowerCase();
    const result =
      adminUsername.indexOf(filterText.toLowerCase()) != -1 ||
      adminCompany.indexOf(filterText.toLowerCase()) != -1;
    return result;
  });

  const currentAdministrators = filterText
    ? filteredAdministrators
    : administrators?.slice(indexOfFirstPost, indexOfLastPost);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}

      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <SectionWrapper>
          <StyleTable>
            <HeaderSection>
              <Filter>
                <Input
                  type="search"
                  placeholder={t('super_admin_administrator:search')}
                  value={filterText}
                  onChange={e => {
                    setFilterText(e.target.value);
                  }}
                />
              </Filter>
              <ButtonSection>
                <Button
                  value={t('super_admin_administrator:create_admin')}
                  variant="primary"
                  onClick={() =>
                    history.replace({ pathname: `/administrators/new` })
                  }
                />
              </ButtonSection>
            </HeaderSection>
            <AdministratorsTable administrators={currentAdministrators} />
            <TableFooter
              postsPerPage={postsPerPage}
              totalPosts={
                filterText ? currentAdministrators : administrators?.length
              }
              paginate={paginate}
              currentPage={currentPage}
            />
          </StyleTable>
        </SectionWrapper>
      </Suspense>
    </>
  );
};

export default Administrator;
