import React, { SelectHTMLAttributes, DetailedHTMLProps } from 'react';
import cx from 'classnames';

import { StyledSelect } from './StyledSelect';
import FieldError from 'components/inputs/FieldError';

interface Option {
  text: string;
  value: string;
}

export type Options = Array<Option>;

export type DefaultProps = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> &
  HTMLSelectElement;

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  error?: string;
  options?: Options;
  register?: any;
  variant?: string;
  value?: any;
}

const Select = React.forwardRef<DefaultProps, SelectProps>(
  (
    {
      value,
      register,
      options,
      placeholder,
      onChange,
      error,
      name,
      ...otherProps
    },
    ref
  ) => (
    <>
      <StyledSelect>
        <select
          name={name}
          data-testid={name}
          value={value}
          onChange={onChange}
          ref={register}
          className={cx({ 'field-error': Boolean(error) })}
          {...otherProps}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options &&
            options.map(({ text, value }, index) => (
              <option key={index} value={value}>
                {text}
              </option>
            ))}
        </select>
      </StyledSelect>

      {error && <FieldError text={error} />}
    </>
  )
);

Select.defaultProps = {
  variant: 'normal',
  options: []
};

Select.displayName = 'Select';

export default Select;
