import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods, FieldErrors } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { LoginData } from 'services/rest/authentication';

import {
  StyledForm,
  PoweredByWrapper,
  NoAccountSection,
  LogoWrapper
} from './Styled';
import { InputWrapper } from 'components/inputs/VerticalWrapper';
import Button from 'components/buttons/Button';
import { BrowsersFooter } from 'components/Footer';
import AdvocatesProLogo from 'images/logos/advocates_logo.svg';
import ThreeDots from 'components/loaders/ThreeDots';
import { RequiredString } from 'helpers/yupHelper';
import { useSelector } from 'react-redux';
import { selectCompanyStyle } from 'redux/slices/CompanyStyle/CompanyStyleSlice';

export interface LoginFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<LoginData>;
  register: UseFormMethods['register'];
  loader: boolean;
  setReCaptchaToken?: any;
  showPassword?: boolean;
  onClickEye?: any;
  reCaptcha: null | string;
}

export const userSchema = yup.object().shape({
  email: RequiredString(),
  password: RequiredString()
    .min(5, 'login_form.password_min_length')
    .max(40, 'login_form.password_max_length')
});

const LoginForm: React.FC<LoginFormProps> = props => {
  const {
    errors,
    onSubmit,
    register,
    loader,
    setReCaptchaToken,
    showPassword,
    onClickEye,
    reCaptcha
  } = props;
  const { t } = useTranslation(['login_form']);

  const { companyStyle, loading } = useSelector(selectCompanyStyle);

  return (
    <>
      <NavLink to="/" className="logo">
        <LogoWrapper>
          {loading ? (
            <ThreeDots />
          ) : (
            <>
              <img
                className="hide-on-mobile"
                src={companyStyle?.logo}
                alt="Home"
              />
              <img
                className="show-on-mobile mobile-logo"
                src={companyStyle?.logo}
                alt="Home"
              />
            </>
          )}
        </LogoWrapper>
      </NavLink>

      <StyledForm onSubmit={onSubmit}>
        <InputWrapper
          label={t('login_form:email')}
          name="email"
          ref={register}
          type="text"
          error={errors.email?.message}
          data-testid="email"
        />

        <InputWrapper
          label={t('login_form:password')}
          name="password"
          type="password"
          ref={register}
          error={errors.password?.message}
          data-testid="password"
          onClickEye={onClickEye}
          showPassword={showPassword}
        />

        {companyStyle?.allowRecaptcha && (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
          >
            <GoogleReCaptcha
              onVerify={token => {
                if (!reCaptcha) setReCaptchaToken(token);
              }}
            />
          </GoogleReCaptchaProvider>
        )}

        <NavLink to="/forgot_password" className="forgot-password">
          {t('login_form:forgot_password')}
        </NavLink>

        <Button
          value={t('login_form:sign_in')}
          variant="primary"
          loader={loader}
        />
      </StyledForm>

      <NoAccountSection>
        <p>{t('login_form:no_account')}</p>
        <NavLink to="sign_up" className="register">
          {t('login_form:register')}
        </NavLink>
      </NoAccountSection>

      <PoweredByWrapper>
        <p>Powered By</p>
        <img src={AdvocatesProLogo} alt="AdvocatesPro" />
      </PoweredByWrapper>

      <BrowsersFooter />
    </>
  );
};

export default LoginForm;
