import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';

export const Section_body = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  display: -webkit-flex;
  @media (max-width: ${Breakpoints.xxmedium}) {
    display: grid;
    button {
      width: 150px;
    }
  }
  input {
    width: 30%;
    margin-right: 2%;
    @media (max-width: ${Breakpoints.small}) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;
