import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TablePagination from '@mui/material/TablePagination';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import { FilterChallenges, StyleTable } from '../Styled';
import Input from 'components/inputs/Input';
import MetricsChallengesSectionAdmin from './MetricsChallengesSectionAdmin';
import ChallengeAdminCard from './ChallengeAdminCard';
interface AdminChallengesProps {
  challenges: any;
  timeLine: any;
  setLoading: any;
  changeChallenge: any;
  setChangeChallenge: any;
}
import AllChallengesStatus from './AllChallengesStatus';

const AdminChallenges: React.FC<AdminChallengesProps> = props => {
  const {
    challenges,
    timeLine,
    setLoading,
    changeChallenge,
    setChangeChallenge
  } = props;
  const { t } = useTranslation([
    'campaign_card',
    'challenge',
    'campaign_section'
  ]);
  const statusOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: t('challenge:pending'), value: 'pending' },
    { label: t('challenge:activated'), value: 'activated' },
    { label: t('challenge:completed'), value: 'completed' }
  ];
  const socialOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Twitter', value: 'twitter' },
    { label: 'Linkedin', value: 'linkedin' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Tiktok', value: 'tiktok' },
    { label: 'Snapchat', value: 'snapchat' },
    { label: 'Youtube', value: 'youtube' },
    { label: 'Threads', value: 'threads' },
    { label: t('campaign_section:others'), value: 'others' }
  ];
  const [filterText, setFilterText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedSocial, setSelectedSocial] = useState(socialOptions[0]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const getFilterSocial = (filter: any, value: string) => {
    return filter.filter((challenge: any) => challenge[value]);
  };

  const selectStatusOption = (event: any) => {
    setSelectedStatus(event);
    setPage(0);
  };

  const selectSocialOption = (event: any) => {
    setSelectedSocial(event);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const newChallenges = challenges.filter((data: any) => {
    const challenge = data as any;
    const challengeName = challenge.name.toLowerCase();
    const challengeContent = challenge.content.toLowerCase();
    const result =
      challengeName.indexOf(filterText.toLowerCase()) != -1 ||
      challengeContent.indexOf(filterText.toLowerCase()) != -1;
    return result;
  });

  const newChallengeStatus =
    selectedStatus.value !== 'ALL'
      ? newChallenges.filter(
          (challeng: any) => challeng.status === selectedStatus.value
        )
      : newChallenges;

  const newChallengesSocial =
    selectedSocial.value !== 'ALL'
      ? getFilterSocial(newChallengeStatus, selectedSocial.value)
      : newChallengeStatus;

  const currentChallenges = newChallengesSocial;

  const activatedChallenges = challenges.filter(
    (challenge: any) => challenge.status === 'activated'
  );

  const pendingChallenges = challenges.filter(
    (challenge: any) => challenge.status === 'pending'
  );

  const completedChallenges = challenges.filter(
    (challenge: any) => challenge.status === 'completed'
  );
  return (
    <>
      <MetricsChallengesSectionAdmin />
      <>
        <StyleTable>
          <h1>{t('challenge:challenge_list')}</h1>
          <FilterChallenges>
            <Input
              type="search"
              placeholder={t('challenge:challenge_filter')}
              value={filterText}
              onChange={e => {
                setFilterText(e.target.value);
              }}
            />
            <span>{t('campaign_card:filter')}</span>
            <DropdownSelect
              options={statusOptions}
              value={selectedStatus}
              onSelect={selectStatusOption}
            />
            <DropdownSelect
              options={socialOptions}
              value={selectedSocial}
              onSelect={selectSocialOption}
            />
          </FilterChallenges>

          {selectedStatus.value === 'ALL' &&
          selectedSocial.value === 'ALL' &&
          filterText === '' ? (
            <>
              {activatedChallenges?.length > 0 && (
                <AllChallengesStatus
                  title={t('challenge:activated')}
                  challenges={activatedChallenges}
                  timeLine={timeLine}
                  setLoading={setLoading}
                  changeChallenge={changeChallenge}
                  setChangeChallenge={setChangeChallenge}
                />
              )}
              {pendingChallenges?.length > 0 && (
                <AllChallengesStatus
                  title={t('challenge:pending')}
                  challenges={pendingChallenges}
                  timeLine={timeLine}
                  setLoading={setLoading}
                  changeChallenge={changeChallenge}
                  setChangeChallenge={setChangeChallenge}
                />
              )}
              {completedChallenges?.length > 0 && (
                <AllChallengesStatus
                  title={t('challenge:completed')}
                  challenges={completedChallenges}
                  timeLine={timeLine}
                  setLoading={setLoading}
                  changeChallenge={changeChallenge}
                  setChangeChallenge={setChangeChallenge}
                />
              )}
            </>
          ) : (
            <>
              {(rowsPerPage > 0
                ? currentChallenges.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : currentChallenges
              ).map((challenge: any, index: number) => {
                const row = challenge as any;
                return (
                  <ChallengeAdminCard
                    key={index}
                    challenge={row}
                    timeLine={timeLine}
                    setLoading={setLoading}
                    changeChallenge={changeChallenge}
                    setChangeChallenge={setChangeChallenge}
                  />
                );
              })}
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 500]}
                component="div"
                count={currentChallenges.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </StyleTable>
      </>
    </>
  );
};

export default AdminChallenges;
