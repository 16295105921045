import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods } from 'react-hook-form';
import Input from 'components/inputs/Input';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { InputWrapper, PushNotificationPermissions } from './Styled';

export interface AppAccordionProps {
  permissions: any;
  handleOnClick: any;
  register: UseFormMethods['register'];
}

const AppAccordion: React.FC<AppAccordionProps> = props => {
  const { permissions, handleOnClick, register } = props;
  const { t } = useTranslation('change_permissions');

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <h1>{t('push_notification_permissions')}</h1>
      </AccordionSummary>
      <AccordionDetails>
        <PushNotificationPermissions>
          <InputWrapper>
            <label>{t('allowPushNotification')}</label>
            <div className="checkbox-parent">
              <Input
                id="allowPushNotification"
                type="checkbox"
                name="allowPushNotification"
                className="swipe-checkbox"
                checked={permissions?.allowPushNotification}
                ref={register}
                onClick={handleOnClick}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowCampaignPush')}</label>
            <div
              className={
                !permissions?.allowPushNotification
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowCampaignPush"
                type="checkbox"
                name="allowCampaignPush"
                className="swipe-checkbox"
                checked={permissions?.allowCampaignPush}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowPushNotification}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowAwardsPush')}</label>
            <div
              className={
                !permissions?.allowPushNotification
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowAwardsPush"
                type="checkbox"
                name="allowAwardsPush"
                className="swipe-checkbox"
                checked={permissions?.allowAwardsPush}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowPushNotification}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowApprovedBonusPush')}</label>
            <div
              className={
                !permissions?.allowPushNotification
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowApprovedBonusPush"
                type="checkbox"
                name="allowApprovedBonusPush"
                className="swipe-checkbox"
                checked={permissions?.allowApprovedBonusPush}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowPushNotification}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowRejectedBonusPush')}</label>
            <div
              className={
                !permissions?.allowPushNotification
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowRejectedBonusPush"
                type="checkbox"
                name="allowRejectedBonusPush"
                className="swipe-checkbox"
                checked={permissions?.allowRejectedBonusPush}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowPushNotification}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowCampaignReminderPush')}</label>
            <div
              className={
                !permissions?.allowPushNotification
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowCampaignReminderPush"
                type="checkbox"
                name="allowCampaignReminderPush"
                className="swipe-checkbox"
                checked={permissions?.allowCampaignReminderPush}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowPushNotification}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowChallengePush')}</label>
            <div
              className={
                !permissions?.allowPushNotification
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowChallengePush"
                type="checkbox"
                name="allowChallengePush"
                className="swipe-checkbox"
                checked={permissions?.allowChallengePush}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowPushNotification}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowChallengeReminderPush')}</label>
            <div
              className={
                !permissions?.allowPushNotification
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowChallengeReminderPush"
                type="checkbox"
                name="allowChallengeReminderPush"
                className="swipe-checkbox"
                checked={permissions?.allowChallengeReminderPush}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowPushNotification}
              />
            </div>
          </InputWrapper>
        </PushNotificationPermissions>
      </AccordionDetails>
    </Accordion>
  );
};
export default AppAccordion;
