import React from 'react';
import Alert, { AlertProps } from './Alert';

export type { AlertProps } from './Alert';

export interface DetailedAlertProps extends AlertProps {
  text: string;
  textOnClick?: string;
  onClick?: () => void;
}

export const ErrorAlert = (props: DetailedAlertProps) => {
  const { text, textOnClick, onClick, ...otherProps } = props;

  return (
    <Alert {...otherProps} type="error">
      <p>
        {text}
        {textOnClick != undefined && onClick != undefined && (
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onClick!();
            }}
          >
            {textOnClick}
          </a>
        )}
      </p>
    </Alert>
  );
};

export const SuccessAlert = (props: DetailedAlertProps) => {
  const { text, ...otherProps } = props;

  return (
    <Alert {...otherProps} type="success">
      <p>{text}</p>
    </Alert>
  );
};

export default Alert;
