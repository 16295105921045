import React, { Suspense } from 'react';
import {
  OnboardingWrapper,
  OnboardingMobileBackground,
  FormWrapper
} from './Styled';

import { getCurrentCompany } from 'helpers/companyHelper';
import ThreeDots from 'components/loaders/ThreeDots';
import { useSelector } from 'react-redux';
import { selectCompanyStyle } from 'redux/slices/CompanyStyle/CompanyStyleSlice';

export interface OnboardingLayoutProps {
  className?: string;
}

const OnboardingLayout: React.FC<OnboardingLayoutProps> = props => {
  const { className, children } = props;
  const { companyStyle, loading } = useSelector(selectCompanyStyle);
  const subdomain = getCurrentCompany();

  if (loading) {
    return <ThreeDots />;
  }

  return (
    <>
      <Suspense fallback={<ThreeDots />}>
        <OnboardingMobileBackground
          brandBackground={companyStyle.backgroundImage}
          className="onboarding-layout-mobile"
          id={subdomain}
          backgroundStyles={companyStyle.backgroundStyles}
          responsiveStyle={companyStyle.responsiveStyle}
        ></OnboardingMobileBackground>

        <OnboardingWrapper
          brandBackground={companyStyle.backgroundImage}
          className="onboarding-layout"
          id={subdomain}
          backgroundStyles={companyStyle.backgroundStyles}
          responsiveStyle={companyStyle.responsiveStyle}
        >
          <FormWrapper className={className}>{children}</FormWrapper>
        </OnboardingWrapper>
      </Suspense>
    </>
  );
};

export default OnboardingLayout;
