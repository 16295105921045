import React, { useEffect, useState, Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import Button from 'components/buttons/Button';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import { FilterCampaigns, FilterCampaignsMobile, StyleTable } from '../styled';
import SectionWrapper from 'pages/Layout/SectionWrapper';

import Input from 'components/inputs/Input';
import NavigationLink from 'components/links/NavigationLink';

import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getCompanyCampaigns } from 'services/rest/Campaign/getCompanyCampaigns';
import iconEditOutline from 'images/icons/edit-outline.svg';
import CampaignsAdminCard from '../CampaignsAdminCard';

const CampaignsAllAdmin = () => {
  const { t } = useTranslation(['campaign_card', 'campaigns_page']);
  const statusOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: t('campaign_card:paused'), value: 'paused' },
    { label: t('campaign_card:actived'), value: 'activated' },
    { label: t('campaign_card:pending'), value: 'pending' },
    { label: t('campaign_card:completed'), value: 'completed' }
  ];

  const { currentUser } = useSelector(selectUser);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [filterOption, setfilterOption] = useState(campaigns);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedStatus, setStatus] = useState(statusOptions[0]);
  const getData = async () => {
    const { data } = await getCompanyCampaigns(currentUser?.token, 500, 1);
    setCampaigns(data.data.docs);
    setfilterOption(data.data.docs);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleFilterText = (event: any) => {
    setFilterText(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectOption = (event: any) => {
    let filter = campaigns;
    if (event.value !== 'ALL') {
      filter = campaigns.filter(
        (campaign: any) => campaign.status === event.value
      );
    }
    setfilterOption(filter);
    setStatus(event);
    return filter;
  };

  const new_campaigns = campaigns.filter(campaign => {
    const data = campaign as any;
    const result =
      data.name.toLowerCase().indexOf(filterText.toLowerCase()) != -1;
    return result;
  });

  const filterList =
    selectedStatus.value !== 'ALL'
      ? new_campaigns.filter(
          (campaign: any) => campaign.status === selectedStatus.value
        )
      : new_campaigns;

  const currentCampaigns = filterText ? filterList : filterOption;

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <SectionWrapper>
          <NavigationLink
            text={t('campaigns_page:back')}
            direction="back"
            to="/admin/campaigns"
          />
          <StyleTable>
            <h1>{t('campaign_card:list_of_campaigns')}</h1>
            <FilterCampaigns className="hide-on-mobile">
              <Input
                type="search"
                placeholder={t('campaign_card:search_campaign')}
                value={filterText}
                onChange={handleFilterText}
              />
              <span>{t('campaign_card:filter')}</span>
              <DropdownSelect
                options={statusOptions}
                value={selectedStatus}
                onSelect={selectOption}
              />
            </FilterCampaigns>
            <FilterCampaignsMobile className="show-on-mobile">
              <Input
                type="search"
                placeholder={t('campaign_card:search_campaign')}
                className="show-on-mobile"
              />
              <Button
                value={'Buscar'}
                variant="primary"
                className="show-on-mobile"
              />
              <span className="show-on-mobile">
                {t('campaign_card:filter')}
              </span>
              <DropdownSelect
                className="show-on-mobile"
                options={statusOptions}
                value={selectedStatus}
              />
            </FilterCampaignsMobile>

            <TableContainer component={Paper} className="table_container">
              <Table
                sx={{ minWidth: 1000 }}
                size="medium"
                aria-label="a dense table"
                className="table"
              >
                <TableBody>
                  {(rowsPerPage > 0
                    ? currentCampaigns.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : currentCampaigns
                  ).map((campaign: any, index: any) => {
                    return (
                      <CampaignsAdminCard
                        key={index}
                        id={campaign.id}
                        url={campaign.photo}
                        startDate={campaign.startDate}
                        finishDate={campaign.finishDate}
                        description={campaign.name}
                        active={campaign.ambassadorsParticipating}
                        publicacion={campaign.totalParticipation}
                        iconEdit={iconEditOutline}
                        status={campaign.status}
                        includesFacebook={campaign.includesFacebook}
                        includesTwitter={campaign.includesTwitter}
                        includesLinkedin={campaign.includesLinkedin}
                        includesInstagram={campaign.includesInstagram}
                        includesWhatsapp={campaign.includesWhatsapp}
                        includesTiktok={campaign.includesTiktok}
                        includesStatus={true}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={campaigns.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyleTable>
        </SectionWrapper>
      </Suspense>
    </>
  );
};

export default CampaignsAllAdmin;
