import Client from 'config/axios';

export const getChallengeById = async (
  token: string,
  id: number,
  withBonus: boolean
) => {
  const { data } = await Client.get(`challenge/${id}?withBonus=${withBonus}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
