import Client from 'config/axios';

export const getOrganizationsLinkedin = async (
  token: string,
  search: string
) => {
  const { data } = await Client.get(`/campaign/linkedin/search/${search}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data: data.data
  };
};
