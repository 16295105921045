import React, { Suspense, useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import { GridWrapper, MobileWrapper } from './Styled';
import Icon from 'components/icons/Icon';
import iconGraph from 'images/icons/graph.svg';
import GeneralDataBox from 'components/boxes/GeneralDataBox';
import SingleDataBox from 'components/boxes/SingleDataBox';
import MetricBox from 'components/boxes/MetricBox';
import MetricImgBox from 'components/boxes/MetricBoxImg';
import NetworkParticipationBox from 'components/boxes/NetworkParticipationBox';
import { getAmbassadorPerformance } from 'services/rest/PerformanceSection/PerformanceSection';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectUserInfo } from 'redux/slices/userSlice';
import {
  selectPerformance,
  setLoading,
  setPerformance
} from 'redux/slices/PerformanceSection/PerformanceSectionSlice';
import { nFormatter } from 'helpers/formatHelper';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';

const PerformanceSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['performance_section', 'admin_dashboard']);
  const { currentUser } = useSelector(selectUser);
  const { infoUser } = useSelector(selectUserInfo);
  const { loading, performanceSection } = useSelector(selectPerformance);
  const [visible, setVisible] = useState({
    potentialReach: false,
    estimatedScope: false
  });

  const getData = async () => {
    const { data } = await getAmbassadorPerformance(currentUser?.token);
    dispatch(setPerformance(data.data));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
  }, []);

  const changeTooltip = (tooltipType: string, state: boolean) => {
    const tooltipTypes = {
      ...visible,
      [tooltipType]: state
    };
    setVisible(tooltipTypes);
  };

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const currentYear = new Date().getFullYear();
  const itemsFirstRow = [
    {
      icon: <Icon name="trophy" size="big" />,
      value: performanceSection?.annualRanking?.position
        ? performanceSection?.annualRanking?.position?.toString()
        : '0',
      text: t('performance_section:ranking_position', { currentYear })
    },
    {
      icon: <Icon name="star" size="big" />,
      value: performanceSection?.annualRanking
        ? performanceSection?.annualRanking?.totalPoints?.toString()
        : '0',
      text: t('performance_section:points')
    },
    {
      icon: <Icon name="report" size="big" />,
      value: performanceSection?.totalPublishedPosts
        ? performanceSection?.totalPublishedPosts?.toString()
        : '0',
      text: t('performance_section:generated_content')
    }
  ];
  const networksBoxItems = [
    {
      linked: infoUser?.linkedFacebook,
      network: 'facebook',
      percentage: performanceSection?.postsByNetwork?.facebook
    },
    {
      linked: infoUser?.linkedTwitter,
      network: 'twitter',
      percentage: performanceSection?.postsByNetwork?.twitter
    },
    {
      linked: infoUser?.linkedLinkedin,
      network: 'linkedin',
      percentage: performanceSection?.postsByNetwork?.linkedin
    },
    {
      linked: infoUser?.linkedInstagram,
      network: 'instagram',
      percentage: performanceSection?.postsByNetwork?.instagram
    },
    {
      linked: infoUser?.linkedWhatsapp,
      network: 'whatsapp',
      percentage: performanceSection?.postsByNetwork?.whatsapp
    },
    {
      linked: infoUser?.linkedTiktok,
      network: 'tiktok',
      percentage: performanceSection?.postsByNetwork?.tiktok
    }
  ];

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <GeneralDataBox items={itemsFirstRow} />

      <GridWrapper className="hide-on-mobile">
        <SingleDataBox>
          <StyledTooltipWrapper
            visible={visible?.potentialReach}
            onMouseOver={() => changeTooltip('potentialReach', true)}
            onMouseOut={() => changeTooltip('potentialReach', false)}
          >
            <MetricBox
              iconName="total-reach"
              value={nFormatter(performanceSection?.potentialReach)}
              text={t('performance_section:potential_reach')}
            />
            <TooltipMessage>
              {t('admin_dashboard:potential_reach_tooltip')}
            </TooltipMessage>
          </StyledTooltipWrapper>
        </SingleDataBox>

        <SingleDataBox>
          <StyledTooltipWrapper
            visible={visible?.estimatedScope}
            onMouseOver={() => changeTooltip('estimatedScope', true)}
            onMouseOut={() => changeTooltip('estimatedScope', false)}
          >
            <MetricImgBox
              iconName={iconGraph}
              value={nFormatter(performanceSection?.estimatedReach)}
              text={t('admin_dashboard:estimated_reach')}
            />
            <TooltipMessage>
              {t('admin_dashboard:estimated_reach_tooltip')}
            </TooltipMessage>
          </StyledTooltipWrapper>
        </SingleDataBox>

        <SingleDataBox>
          <NetworkParticipationBox
            onConnect={() => alert('Maybe some other time')}
            items={networksBoxItems}
          />
        </SingleDataBox>
        <SingleDataBox>
          <MetricBox
            iconName="flag"
            value={`${performanceSection?.totalParticipatedCampaigns}/${performanceSection?.totalInvitedCampaigns}`}
            text={t('performance_section:campaigns_participation')}
          />
        </SingleDataBox>
      </GridWrapper>

      <MobileWrapper className="show-on-mobile">
        <MetricBox
          iconName="total-reach"
          value={nFormatter(performanceSection?.potentialReach)}
          text={t('performance_section:potential_reach')}
          topText={true}
        />

        <MetricImgBox
          iconName={iconGraph}
          value={nFormatter(performanceSection?.potentialReach)}
          text={t('admin_dashboard:estimated_reach')}
        />

        <NetworkParticipationBox
          onConnect={() => alert('Maybe some other time')}
          items={networksBoxItems}
          topText={true}
        />
        <MetricBox
          iconName="flag"
          value={`${performanceSection?.totalParticipatedCampaigns}/${performanceSection?.totalInvitedCampaigns}`}
          text={t('performance_section:campaigns_participation')}
          topText={true}
        />
      </MobileWrapper>
    </Suspense>
  );
};

export default PerformanceSection;
