import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';

const useIsSuperAdmin = (): boolean => {
  const { currentUser } = useSelector(selectUser);
  if (currentUser.role == 'super') {
    return true;
  }
  return false;
};

export default useIsSuperAdmin;
