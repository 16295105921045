import React, { Suspense, useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { List as LoadingState } from 'react-content-loader';
import { UseFormMethods, FieldErrors } from 'react-hook-form';

import { AdminData } from 'services/rest/Admin/updateAdmin';

import Phone from 'components/inputs/Phone';
import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';
import Spinner from 'components/Spinner/Spinner';

import { ButtonStyled, InputWrapper, SectionForm } from '../../Styled';

export interface AdminProfileFormProps {
  company: any;
  infoUser: any;
  loader: boolean;
  loading: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<AdminData>;
  register: UseFormMethods['register'];
  setPhone: any;
  allowContentsEmail: any;
  setAllowContentsEmail: any;
}

const AdminProfileForm: React.FC<AdminProfileFormProps> = props => {
  const {
    company,
    loader,
    infoUser,
    loading = true,
    handleSubmit,
    register,
    setPhone,
    allowContentsEmail,
    setAllowContentsEmail,
    errors
  } = props;
  const { t } = useTranslation([
    'group_page',
    'settings',
    'two_factor_authentication'
  ]);
  const [adminstrator, setAdminstrator] = useState<AdminData>(infoUser);
  const handleOnChange = (e: FormEvent<HTMLInputElement>) => {
    setAdminstrator({
      ...adminstrator,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }
  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <form onSubmit={handleSubmit} className="flex-wrapper">
        <SectionForm>
          <InputWrapper>
            <label>{t('name')}</label>
            <div className="w-100">
              <Input
                type="text"
                placeholder={t('name')}
                name="name"
                ref={register}
                defaultValue={infoUser?.name}
                onChange={handleOnChange}
                error={errors.name?.message}
                data-testid="name"
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('email')}</label>
            <div className="w-100">
              <Input
                type="text"
                placeholder={t('email')}
                name="email"
                ref={register}
                onChange={handleOnChange}
                defaultValue={infoUser?.email}
                error={errors.email?.message}
                data-testid="email"
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('mobile')}</label>
            <div className="w-100">
              <Phone
                defaultCountry={company?.countryCode}
                name="phone"
                placeholder="Enter phone number"
                value={infoUser?.phone}
                onChange={setPhone}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('settings:allow_contents_email')}</label>
            <div className="checkbox-parent bonus-checkbox">
              <Input
                id="allowContentsEmail"
                type="checkbox"
                name="allowContentsEmail"
                className="swipe-checkbox"
                checked={allowContentsEmail}
                ref={register}
                onChange={e => setAllowContentsEmail(e?.currentTarget?.checked)}
              />
            </div>
          </InputWrapper>
        </SectionForm>
        <ButtonStyled>
          {!loader ? (
            <Button value={t('save')} variant="primary" />
          ) : (
            <Spinner name="sign-up" />
          )}
        </ButtonStyled>
      </form>
    </Suspense>
  );
};
export default AdminProfileForm;
