import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { selectUser } from 'redux/slices/userSlice';

import CreateAwardForm, { userSchema } from './CreateAwardForm';

import { awsUploadFileHelper } from 'helpers/awsFileHelper';
import { generateRandAlphaNumStr } from 'Utils/StringRandom';
import { getUrlS3 } from 'constants/setup.constants';

import { ErrorAlert } from 'components/Alert';
import { createAward, AwardData } from 'services/rest/Award/createAward';

const NewAwardPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['awards_section', 'validations']);

  const { register, handleSubmit, errors } = useForm<AwardData>({
    resolver: yupResolver(userSchema)
  });

  const [backendError, setBackendError] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState<Array<any>>([]);
  const [groupsIds, setGroupsIds] = useState([]);
  const { currentUser } = useSelector(selectUser);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onSubmit = async (awardData: AwardData) => {
    setLoader(true);
    if (image.length === 0) {
      setBackendError(t('awards_section:file_info_box'));
      setLoader(false);
      return;
    }
    const award = awardData as any;
    award.companyId = currentUser.companyId;
    award.groupsIds = groupsIds;
    award.periodValue = award.periodType === 'custom' ? 0 : award.periodValue;
    award.year =
      award.periodType === 'month' || award.periodType === 'quarter'
        ? award.periodYear
        : 0;
    if (
      award.periodValue === '' ||
      award.year === '' ||
      (award.periodType === 'custom' && startDate === null && endDate === null)
    ) {
      setBackendError(t('awards_section:period_error'));
      setLoader(false);
      return;
    }
    const { initialDate, finishDate } = setDates(award);
    award.startDate = initialDate;
    award.finishDate = finishDate;
    const namePhoto = generateRandAlphaNumStr();
    await awsUploadFileHelper(
      currentUser?.token,
      image,
      'AWARD',
      namePhoto,
      true,
      setBackendError,
      t('validations:file_error')
    );
    award.photoUrl = getUrlS3('awards', namePhoto);

    const { data, errors } = await createAward(currentUser?.token, award);
    if (data) {
      setLoader(false);
      history.push('/admin/award', t('awards_section:created_award'));
    }
    if (errors) {
      setBackendError(errors.data);
      setLoader(false);
    }
  };

  const setDates = (award: any) => {
    const dates = {} as any;
    const quarters = [
      [1, 3],
      [4, 6],
      [7, 9],
      [10, 12]
    ];
    switch (award.periodType) {
      case 'month':
        dates.initialDate = new Date(award.periodYear, award.periodValue - 1);
        dates.finishDate = new Date(award.periodYear, award.periodValue, 0);
        return dates;
      case 'quarter':
        dates.initialDate = new Date(
          award.periodYear,
          quarters[award.periodValue - 1][0] - 1
        );
        dates.finishDate = new Date(
          award.periodYear,
          quarters[award.periodValue - 1][1],
          0
        );
        return dates;
      case 'year':
        dates.initialDate = new Date(award.periodValue, 0);
        dates.finishDate = new Date(award.periodValue, 12, 0);
        return dates;
      case 'custom':
        dates.initialDate = startDate;
        dates.finishDate = endDate;
        return dates;
      default:
        return dates;
    }
  };

  const handleImagen = (select: Array<any>) => {
    setImage(select);
  };

  return (
    <>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      <CreateAwardForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        handleImage={handleImagen}
        setGroupsIds={setGroupsIds}
        loader={loader}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </>
  );
};

export default NewAwardPage;
