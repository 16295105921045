import Client from 'config/axios';

export interface CampaignData {
  name: string;
  photo: string;
  description: string;
  companyId: number;
  startDate: string;
  finishDate: string;
  includesFacebook: boolean;
  includesTwitter: boolean;
  includesLinkedin: boolean;
  includesInstagram: boolean;
  includesTiktok: boolean;
  includesWhatsapp: boolean;
  groupsIds: Array<number>;
  groups: Array<any>;
  ambassadorsIds: Array<number>;
  facebook: {
    publish: number;
    reactions: number;
    comments: number;
    shared: number;
  };
  twitter: {
    publish: number;
    reactions: number;
    comments: number;
    shared: number;
  };
  linkedin: {
    publish: number;
    reactions: number;
    comments: number;
  };
  instagram: {
    publish: number;
  };
  tiktok: {
    publish: number;
  };
  whatsapp: {
    publish: number;
  };
  contentFacebook: string;
  contentTwitter: string;
  contentLinkedin: string;
  contentLinkedinShare: string;
  contentInstagram: string;
  contentTiktok: string;
  contentWhatsapp: string;
  twitterContents: any;
  linkedinContents: any;
  tiktokContents: any;
  contentPerSocialNetwork: {
    facebook: {
      content: string;
      type: 'text';
    };
    twitter: {
      content: string;
      content2: string;
      content3: string;
      type: string;
      picturesUrls: Array<string>;
    };
    linkedin: {
      content: string;
      content2: string;
      content3: string;
      type: string;
      picturesUrls: Array<string>;
      contentParticipation?: string;
    };
    instagram: {
      content: string;
      type: string;
      picturesUrls: Array<string>;
    };
    tiktok: {
      content: string;
      type: string;
      picturesUrls: Array<string>;
    };
    whatsapp: {
      content: string;
      type: string;
      picturesUrls: Array<string>;
    };
  };
  allowExtraDates: boolean;
}

export const createCampaign = async (
  token: string,
  campaign: CampaignData,
  twitterURL: any,
  linkedinURL: any,
  instagramURL: any,
  tiktokURL: any,
  whatsappURL: any,
  type: any
) => {
  const objectSocialNetworks = async (
    content: any,
    typeUpload: string,
    urlFile: any,
    contentParticipation?: string
  ) => {
    let objectSN;
    let newContent: { [key: string]: string };
    if (typeof content == 'string') {
      newContent = { content: content };
    } else {
      newContent = { ...content };
    }
    switch (typeUpload) {
      case 'image':
        objectSN = {
          ...newContent,
          type: typeUpload,
          picturesUrls: urlFile
        };
        break;
      case 'video':
        objectSN = {
          ...newContent,
          type: typeUpload,
          videoUrl: urlFile
        };
        break;
      case 'pdf':
        objectSN = {
          ...newContent,
          type: typeUpload,
          pdfUrl: urlFile
        };
        break;
      case 'share':
        objectSN = {
          content: contentParticipation,
          type: typeUpload,
          urnLi: urlFile,
          url: newContent.content
        };
        break;
      default:
        objectSN = {
          ...newContent,
          type: typeUpload
        };
    }
    return objectSN;
  };

  const body = {
    name: campaign.name,
    photo: campaign.photo,
    includesFacebook: campaign.includesFacebook,
    includesTwitter: campaign.includesTwitter,
    includesLinkedin: campaign.includesLinkedin,
    includesInstagram: campaign.includesInstagram,
    includesTiktok: campaign.includesTiktok,
    includesWhatsapp: campaign.includesWhatsapp,
    description: campaign.description,
    companyId: campaign.companyId,
    groupsIds: campaign.groupsIds,
    ambassadorsIds: campaign.ambassadorsIds,
    allowExtraDates: campaign.allowExtraDates,
    startDate: campaign.startDate,
    finishDate: campaign.finishDate,
    pointsValue: {
      facebook: campaign.facebook,
      twitter: campaign.twitter,
      linkedin: campaign.linkedin,
      instagram: campaign.instagram,
      tiktok: campaign.tiktok,
      whatsapp: campaign.whatsapp
    },
    contentPerSocialNetwork: {
      facebook: {
        content: campaign.contentFacebook,
        type: 'text'
      },
      twitter: await objectSocialNetworks(
        campaign.twitterContents,
        twitterURL && twitterURL?.url?.length > 0 ? twitterURL?.type : 'text',
        twitterURL?.url
      ),
      linkedin: await objectSocialNetworks(
        campaign.linkedinContents,
        linkedinURL && linkedinURL.url?.length > 0 ? linkedinURL?.type : 'text',
        linkedinURL?.url,
        linkedinURL?.contentParticipation
      ),
      instagram: await objectSocialNetworks(
        campaign.contentInstagram,
        instagramURL?.type,
        instagramURL?.url
      ),
      tiktok: await objectSocialNetworks(
        campaign.tiktokContents,
        tiktokURL?.type,
        tiktokURL?.url
      ),
      whatsapp: await objectSocialNetworks(
        campaign.contentWhatsapp,
        whatsappURL?.type,
        whatsappURL?.url
      )
    }
  };

  try {
    const { data } = await Client.post('campaign', body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.response.response.data };
  }
};

export const updateCampaign = async (
  token: string,
  campaign: CampaignData,
  twitterURL: any,
  linkedinURL: any,
  instagramURL: any,
  tiktokURL: any,
  whatsappURL: any,
  id: number,
  type: any,
  campaignOld: any,
  pointsValue: any
) => {
  if (!id) {
    id = 0;
  }
  const objectSocialNetworks = async (
    content: any,
    typeUpload: string,
    urlFile: any,
    oldContent: string,
    contentParticipation?: string
  ) => {
    let objectSN;

    if (!content) {
      content = oldContent;
    }

    let newContent: { [key: string]: string };
    if (typeof content == 'string') {
      newContent = { content: content };
    } else {
      newContent = { ...content };
    }
    switch (typeUpload) {
      case 'image':
        objectSN = {
          ...newContent,
          type: typeUpload,
          picturesUrls: urlFile
        };
        break;
      case 'video':
        objectSN = {
          ...newContent,
          type: typeUpload,
          videoUrl: urlFile
        };
        break;
      case 'pdf':
        objectSN = {
          ...newContent,
          type: typeUpload,
          pdfUrl: urlFile
        };
        break;
      case 'share':
        objectSN = {
          content: contentParticipation,
          type: typeUpload,
          urnLi: urlFile,
          url: newContent.content
        };
        break;
      default:
        objectSN = {
          ...newContent,
          type: typeUpload
        };
    }
    return objectSN;
  };

  const body = {
    name: campaign.name,
    photo: campaign.photo,
    includesFacebook: campaign.includesFacebook,
    includesTwitter: campaign.includesTwitter,
    includesLinkedin: campaign.includesLinkedin,
    includesInstagram: campaign.includesInstagram,
    includesTiktok: campaign.includesTiktok,
    includesWhatsapp: campaign.includesWhatsapp,
    description: campaign.description,
    companyId: campaign.companyId,
    groupsIds: campaign.groupsIds,
    ambassadorsIds: campaign.ambassadorsIds,
    allowExtraDates: campaign.allowExtraDates,
    startDate: campaign.startDate,
    finishDate: campaign.finishDate,
    pointsValue: {
      facebook:
        pointsValue?.facebook?.publish == undefined
          ? campaign?.facebook
          : pointsValue.facebook,
      twitter:
        pointsValue?.twitter?.publish == undefined
          ? campaign?.twitter
          : pointsValue.twitter,
      linkedin:
        pointsValue?.linkedin?.publish == undefined
          ? campaign.linkedin
          : pointsValue?.linkedin,
      instagram:
        pointsValue?.instagram?.publish == undefined
          ? campaign?.instagram
          : pointsValue.instagram,
      tiktok:
        pointsValue?.tiktok?.publish == undefined
          ? campaign?.tiktok
          : pointsValue.tiktok,
      whatsapp:
        pointsValue?.whatsapp?.publish == undefined
          ? campaign?.whatsapp
          : pointsValue.whatsapp
    },
    contentPerSocialNetwork: {
      facebook: {
        content: campaign.contentFacebook,
        type: 'text'
      },
      twitter: await objectSocialNetworks(
        campaign?.twitterContents,
        twitterURL && twitterURL?.url?.length > 0 ? twitterURL?.type : 'text',
        twitterURL?.url,
        campaignOld?.contentPerSocialNetwork?.twitter?.content
      ),
      linkedin: await objectSocialNetworks(
        campaign?.linkedinContents,
        linkedinURL && linkedinURL.url?.length > 0 ? linkedinURL?.type : 'text',
        linkedinURL?.url,
        campaignOld?.contentPerSocialNetwork?.linkedin?.content,
        linkedinURL?.contentParticipation
      ),
      instagram: await objectSocialNetworks(
        campaign?.contentInstagram,
        instagramURL?.type,
        instagramURL?.url,
        campaignOld?.contentPerSocialNetwork?.instagram?.content
      ),
      tiktok: await objectSocialNetworks(
        campaign?.tiktokContents,
        tiktokURL && tiktokURL.url?.length > 0 ? tiktokURL?.type : 'text',
        tiktokURL?.url,
        campaignOld?.contentPerSocialNetwork?.tiktok?.content
      ),
      whatsapp: await objectSocialNetworks(
        campaign?.contentWhatsapp,
        whatsappURL && whatsappURL.url?.length > 0 ? whatsappURL?.type : 'text',
        whatsappURL?.url,
        campaignOld?.contentPerSocialNetwork?.whatsapp?.content
      )
    }
  };
  try {
    const { data } = await Client.post(`campaign/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.response.response.data };
  }
};
