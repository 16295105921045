import styled from 'styled-components';

import { Breakpoints } from 'styles/Constants';
import ChevronLeft from 'images/icons/chevron-left.svg';
import ChevronRight from 'images/icons/chevron-right.svg';

export const StyledCarousel = styled.div`
  &.campaign-ambassador {
    .alice-carousel__stage .alice-carousel__stage-item {
      width: 360px !important;
    }
    .alice-carousel__stage-item * {
      text-align: start;
    }
  }
  &.width-auto {
    .alice-carousel__stage {
      display: flex;
      justify-content: space-evenly;
    }
    .alice-carousel__stage > li {
      width: 250px !important;
      margin-right: 30px;
    }
  }
  .alice-carousel__stage > li {
    margin-top: 10px;
  }
  .alice-carousel__stage-item * {
    text-align: center;
  }
  .alice-carousel {
    li {
      .sc-dtwoBo {
        max-width: 450px;
      }
    }
  }
  .alice-carousel__next-btn,
  .alice-carousel__prev-btn {
    position: absolute;
    width: auto;
    top: 35%;
    @media (max-width: ${Breakpoints.large}) {
      visibility: hidden;
    }
  }

  .alice-carousel__next-btn {
    right: -36px;

    @media (max-width: ${Breakpoints.large}) {
      right: -10px;
    }
  }

  .alice-carousel__prev-btn {
    left: -26px;

    @media (max-width: ${Breakpoints.large}) {
      left: -4px;
    }
  }

  .alice-carousel__next-btn-wrapper,
  .alice-carousel__prev-btn-wrapper {
    p {
      @include box-shadow(0 1px 6px 0 rgba(79, 79, 79, 0.38));
      background-repeat: no-repeat;
      background-position: center;
      background-color: #fff;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      &.__inactive {
        visibility: hidden;
      }
    }

    span {
      display: none;
    }
  }

  .alice-carousel__next-btn-wrapper p {
    background-image: url(${ChevronRight});
    box-shadow: 0 1px 6px 0 rgba(79, 79, 79, 0.38);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  .alice-carousel__prev-btn-wrapper p {
    background-image: url(${ChevronLeft});
    box-shadow: 0 1px 6px 0 rgba(79, 79, 79, 0.38);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
`;
