import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Facebook as LoadingState } from 'react-content-loader';

import AwardsList from './AwardsList/AwardsList';
import AwardsListWeb from './AwardsList/AwardsListWeb';
import SectionWrapper from 'pages/Layout/SectionWrapper';

import { selectUser } from 'redux/slices/userSlice';

import {
  selectAwards,
  setAwardsList,
  setLoading
} from 'redux/slices/Award/AwardSlice';
import { getAward } from 'services/rest/Award/Award';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';
import { getAwardsList } from 'services/rest/Award/getAwardsList';

const AwardPage = () => {
  const { t } = useTranslation(['dates', 'awards_section']);

  const dispatch = useDispatch();

  const { currentUser } = useSelector(selectUser);
  const { awards = [], loading } = useSelector(selectAwards);

  const periodTypes = [
    { label: t('dates:period_type.month'), value: 'month' },
    { label: t('dates:period_type.quarter'), value: 'quarter' },
    { label: t('dates:period_type.year'), value: 'year' },
    { label: t('dates:period_type.custom'), value: 'custom' }
  ];

  const [periodType, setPeriodType] = useState<OptionTypeBase>(periodTypes[0]);

  const getData = async () => {
    if (currentUser.role === 'admin') {
      await getAwardsList(currentUser?.token, 100, 1, periodType.value)
        .then(response => {
          dispatch(setAwardsList(response.data.data.docs));
          dispatch(setLoading(false));
        })
        .catch(error => {
          dispatch(error);
          dispatch(setLoading(false));
        });
    } else {
      await getAward(currentUser?.token, periodType.value)
        .then(response => {
          dispatch(setAwardsList(response.data));
          dispatch(setLoading(false));
        })
        .catch(error => {
          dispatch(error);
          dispatch(setLoading(false));
        });
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
  }, [periodType]);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <SectionWrapper>
        <h1 className="section-title">{t('awards_section:awards')}</h1>
        <DropdownSelect
          onSelect={option => setPeriodType(option)}
          value={periodType}
          defaultValue={periodTypes}
          options={periodTypes}
        />
        {awards.length > 0 ? (
          <>
            <AwardsListWeb awards={awards} />
            <AwardsList awards={awards} />
          </>
        ) : (
          <EmptyStateBox
            description={t('awards_section:empty_state_description')}
          />
        )}
      </SectionWrapper>
    </Suspense>
  );
};

export default AwardPage;
