import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Instagram as LoadingState } from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { selectRouter } from 'redux/slices/Router/RouterSlice';
import { postNetworkToken } from 'services/rest/Network/Network';
import { selectUser, setUserInfo } from 'redux/slices/userSlice';
import { getInfoUser } from 'services/rest/logout';

interface Network {
  network: string;
}

interface ITwitterAccess {
  oauth_token: string;
  oauth_verifier: string;
}

export const getAccessTokenNetwork = (resquet: string): string => {
  const getToken = resquet.split('=');
  const toClearToken = getToken[1].split('&');
  const token = toClearToken[0];
  return token;
};

export const getAccessTokenTwitter = (resquet: string): ITwitterAccess => {
  const getToken = resquet.split('=');
  const toClearToken = getToken[1].split('&');
  const token = toClearToken[0];
  return { oauth_token: token, oauth_verifier: getToken[2] };
};

export const getAccessTokenTiktok = (resquet: string): string => {
  const getToken = resquet.split('=');
  const token = getToken[1];
  return token;
};

const CallBack = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { network } = useParams<Network>();

  const { currentUser } = useSelector(selectUser);
  const { router } = useSelector(selectRouter);

  const { hash, search } = useLocation();

  const getData = async (
    network: string,
    token: string,
    secret: string,
    verifier: string,
    screenName: string,
    code: string
  ) => {
    const campaignId = localStorage.getItem('idCampaignConnect');
    await postNetworkToken(
      currentUser?.token,
      {
        token: token,
        followers: 1,
        photo: '',
        uid: 1,
        secret: secret,
        verifier: verifier,
        screenName: screenName,
        code: code
      },
      network
    )
      .then(response => {
        if (response.data) {
          getInfoUser(currentUser?.user?.id, currentUser?.token).then(
            response => {
              dispatch(setUserInfo(response.data));

              history.replace(campaignId ? `/campaigns/${campaignId}` : router);
            }
          );
        } else {
          history.replace(campaignId ? `/campaigns/${campaignId}` : router);
        }
      })
      .catch(error => {
        history.replace(campaignId ? `/campaigns/${campaignId}` : router);
        dispatch(error);
      });
  };

  switch (network) {
    case 'facebook':
      if (hash != '') {
        const token = getAccessTokenNetwork(hash);
        useEffect(() => {
          getData(network, token, '', '', '', '');
        }, []);
      }
      break;
    case 'linkedin':
      if (search != '') {
        const code = getAccessTokenNetwork(search);
        useEffect(() => {
          getData(network, '', '', '', '', code);
        }, []);
      }
      break;
    case 'twitter':
      if (search != '') {
        useEffect(() => {
          const code = getAccessTokenTwitter(search);
          getData(network, code.oauth_token, '', code.oauth_verifier, '', '');
        }, []);
      }
      break;
    case 'tiktok':
      if (search != '') {
        useEffect(() => {
          const code = getAccessTokenTiktok(search);
          getData(network, '', '', '', '', code);
        }, []);
      }
      break;
  }
  return <LoadingState height="230px" width="100%" />;
};

export default CallBack;
