import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import Input from 'components/inputs/Input';

import Button from 'components/buttons/Button';

import { SectionForm, InputWrapper, ButtonStyled } from '../../Styled';

import { CountryData } from 'services/rest/SuperAdmin/Country/createCountry';

import { RequiredString } from 'helpers/yupHelper';
import Spinner from 'components/Spinner/Spinner';

export interface CountryFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<CountryData>;
  register: UseFormMethods['register'];
  setValue: any;
  control: any;
  loader: boolean;
}

export const userSchema = yup.object().shape({
  name: RequiredString()
});

const CountryForm: React.FC<CountryFormProps> = props => {
  const { onSubmit, errors, register, loader } = props;
  const { t } = useTranslation(['super_admin_country']);
  return (
    <SectionWrapper>
      <NavigationLink
        text={t('super_admin_country:form:back')}
        direction="back"
        to={'/countries'}
      />
      <h1>{t('super_admin_country:create_country')}</h1>
      <form onSubmit={onSubmit}>
        <SectionForm>
          <InputWrapper>
            <label>{t('super_admin_country:form:name')}</label>
            <div className="w-100">
              <Input
                name="name"
                type="text"
                placeholder={t('super_admin_country:form:placeholder_name')}
                ref={register}
                error={errors.name?.message}
                data-testid="name"
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_country:form:time_zone')}</label>
            <div className="w-100">
              <Input
                name="defaultTimeZone"
                type="text"
                placeholder={t('super_admin_country:form:time_zone')}
                ref={register}
                error={errors.defaultTimeZone?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_country:form:dial_code')}</label>
            <div className="w-100">
              <Input
                name="dialCode"
                type="text"
                placeholder={t('super_admin_country:form:dial_code')}
                ref={register}
                error={errors.dialCode?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_country:form:code')}</label>
            <div className="w-100">
              <Input
                name="code"
                type="text"
                placeholder={t('super_admin_country:form:code')}
                ref={register}
                error={errors.code?.message}
              />
            </div>
          </InputWrapper>
        </SectionForm>
        <ButtonStyled>
          {!loader ? (
            <Button
              value={t('super_admin_country:create_country')}
              variant="primary"
            />
          ) : (
            <Spinner name="sign-up" />
          )}
        </ButtonStyled>
      </form>
    </SectionWrapper>
  );
};

export default CountryForm;
