import React from 'react';
import GroupAdminPage from 'pages/GroupAdminPage';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import NewGroupPage from 'pages/GroupAdminPage/NewGroupPage';
import GroupDetailPage from 'pages/GroupAdminPage/GroupDetailPage';
import NewEmployee from 'pages/GroupAdminPage/NewEmployee';
import EditEmployee from 'pages/GroupAdminPage/EditEmployee';
import GroupAdminBulkUpload from 'pages/GroupAdminPage/GroupAdminBulkUpload';

const GroupAdminRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/new`}>
        <NewGroupPage />
      </Route>

      <Route exact path={`${match.path}/newEmployee/:groupId/:name`}>
        <GroupAdminBulkUpload />
      </Route>

      <Route exact path={`${match.path}/newEmployee/:groupId`}>
        <NewEmployee />
      </Route>

      <Route exact path={`${match.path}/editEmployee/:groupId/:ambassadorId`}>
        <EditEmployee />
      </Route>

      <Route exact path={`${match.path}/detail/:groupId`}>
        <GroupDetailPage />
      </Route>

      <Route path={`${match.path}`}>
        <GroupAdminPage />
      </Route>
    </Switch>
  );
};

export default GroupAdminRouter;
