import Client from 'config/axios';

export const updateStatusCampaign = async (
  token: string,
  campaignId: number,
  status: string
) => {
  const body = {
    status
  };
  try {
    const { data } = await Client.post(
      `update-status-campaign/${campaignId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return { data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
