import styled, { css } from 'styled-components';
import { Colors } from 'styles/Constants';

export interface StyledCalendarInputProps {
  open?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const StyledCalendarInput = styled.div<StyledCalendarInputProps>`
  border: 1px solid ${Colors.primaryLightGray};
  width: 350px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  @media (max-width: 410px) {
    width: 100%;
  }

  ${({ open }) =>
    open &&
    css`
      border-color: ${Colors.primaryDarkBlue};
    `};

  ${({ error }) =>
    error &&
    css`
      border-color: ${Colors.inputError};
    `};

  label {
    color: ${Colors.normalBlack};
    display: block;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin: 0;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;

export default StyledCalendarInput;
