import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import CampaignHeader from './CampaignHeader';
import PostsList from './PostsList';
import CampaignDetailRanking from './CampaignDetailRanking';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import { selectUser } from 'redux/slices/userSlice';
import { getCampaignDataById } from 'services/rest/Campaign/getCampaignDataById';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

export interface CampaignParams {
  campaignId: string;
}

const CampaignDetailPage = () => {
  const { t } = useTranslation(['campaigns_page']);
  const { campaignId } = useParams<CampaignParams>();
  const { currentUser } = useSelector(selectUser);
  const [campaign, setCampaign] = useState<ICampaigns>();
  const [loading, setLoading] = useState(true);
  const { company } = useSelector(selectCompany);

  const getCampaignData = async () => {
    const { data } = await getCampaignDataById(
      currentUser?.token,
      campaignId,
      true,
      true
    );
    if (data) {
      setCampaign(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCampaignData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }
  const pathName = new URLSearchParams(window.location.search);
  let newPath = '';

  if (pathName.has('All')) {
    newPath = '/campaigns';
  }
  return (
    <>
      <SectionWrapper>
        <NavigationLink
          text={t('back')}
          direction="back"
          to={newPath || '/dashboard'}
        />
        <CampaignHeader campaign={campaign} />
      </SectionWrapper>

      <SectionWrapper>
        <h1 className="section-title">{t('publish_content')}</h1>
        <PostsList
          campaign={campaign}
          campaignStatus={campaign?.status}
          getRankingData={getCampaignData}
        />
      </SectionWrapper>

      {company?.company_permission?.allowShowRanking && (
        <SectionWrapper className="blue-wrapper">
          <h1 className="section-title">{t('ranking')}</h1>
          <CampaignDetailRanking
            ranking={campaign?.ranking}
            getRankingData={getCampaignData}
            currentUser={currentUser}
          />
        </SectionWrapper>
      )}
    </>
  );
};

export default CampaignDetailPage;
