import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { Instagram as LoadingState } from 'react-content-loader';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

import { Ambassador } from 'services/rest/Ambassador/createAmbassador';

import SectionWrapper from 'pages/Layout/SectionWrapper';

import Input from 'components/inputs/Input';
import Phone from 'components/inputs/Phone';
import Button from 'components/buttons/Button';
import NavigationLink from 'components/links/NavigationLink';
import Spinner from 'components/Spinner/Spinner';

import {
  ButtonStyled,
  InputWrapper,
  SectionForm,
  TextCheckbox
} from './Styled';
import 'react-phone-number-input/style.css';

export interface EditEmployeeFormProps {
  employee: any;
  loading: boolean;
  company: any;
  loader: boolean;
  groupId: string;
  errors: FieldErrors<Ambassador>;
  handleSubmit: (event: React.FormEvent) => void;
  register: UseFormMethods['register'];
  groups: any;
  setLanguage: any;
  language: any;
  setPhone: any;
  phone: string;
  allowBonus: boolean;
  setAllowBonus: any;
  allowSuggestedPost: boolean;
  setAllowSuggestedPost: any;
  handleCheck: (group: { id: number; name: string }) => void;
}

const EditEmployeeForm: React.FC<EditEmployeeFormProps> = props => {
  const {
    employee,
    loading,
    company,
    loader,
    groupId,
    errors,
    handleSubmit,
    register,
    setLanguage,
    language,
    groups,
    setPhone,
    phone,
    allowBonus,
    setAllowBonus,
    allowSuggestedPost,
    setAllowSuggestedPost,
    handleCheck
  } = props;
  const { t } = useTranslation(['group_page', 'validations']);
  const backGroup = `/admin/group/detail/${groupId}`;

  const languages = [
    { label: t('spanish'), value: 'es' },
    { label: t('english'), value: 'en' },
    { label: t('portuguese'), value: 'pt' }
  ];

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }
  const defaultSelection = languages.find(({ value }) => value === language);

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <SectionWrapper>
        <NavigationLink text={t('back')} direction="back" to={backGroup} />
        <h1>{t('edit_employee')}</h1>
        <form onSubmit={handleSubmit}>
          <SectionForm>
            <InputWrapper>
              <label>{t('name')}</label>
              <div className="w-100">
                <Input
                  type="text"
                  placeholder={t('name')}
                  name="name"
                  defaultValue={employee?.name}
                  ref={register}
                  error={errors.name?.message}
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <label>{t('last_name')}</label>
              <div className="w-100">
                <Input
                  name="lastName"
                  defaultValue={employee?.lastName}
                  type="text"
                  placeholder={t('last_name')}
                  ref={register}
                  error={errors.lastName?.message}
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <label>{t('emailAdress')}</label>
              <div className="w-100">
                <Input
                  type="text"
                  name="email"
                  defaultValue={employee?.email}
                  placeholder={t('example')}
                  ref={register}
                  error={errors.email?.message}
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <label>{t('mobile')}</label>
              <Phone
                defaultCountry={company?.countryCode}
                name="phone"
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
              />
            </InputWrapper>
            {company?.company_permission.allowCodeAmbassador && (
              <InputWrapper>
                <label>{t('code')}</label>
                <div className="w-100">
                  <Input
                    type="text"
                    name="code"
                    placeholder={t('code')}
                    defaultValue={employee?.code}
                    ref={register}
                    error={errors.code?.message}
                  />
                </div>
              </InputWrapper>
            )}
            <InputWrapper>
              <label>{t('language')}</label>
              <ReactSelect
                className="dropdown-filter-container m-l-0"
                classNamePrefix="dropdown-filter-content"
                options={languages}
                defaultValue={defaultSelection}
                onChange={e => setLanguage(e?.value)}
              />
            </InputWrapper>
            {company?.company_permission?.allowShowBonus && (
              <InputWrapper>
                <label>{t('enable_bonus')}</label>
                <div className="checkbox-parent bonus-checkbox">
                  <Input
                    id="allowBonus"
                    type="checkbox"
                    name="allowBonus"
                    className="swipe-checkbox"
                    checked={allowBonus}
                    onChange={e => setAllowBonus(e?.currentTarget?.checked)}
                  />
                </div>
              </InputWrapper>
            )}
            {company?.company_permission?.allowShowSuggestedPosts && (
              <InputWrapper>
                <label>{t('enable_suggested_posts')}</label>
                <div className="checkbox-parent bonus-checkbox">
                  <Input
                    id="allowSuggestedPost"
                    type="checkbox"
                    name="allowSuggestedPost"
                    className="swipe-checkbox"
                    checked={allowSuggestedPost}
                    onChange={e =>
                      setAllowSuggestedPost(e?.currentTarget?.checked)
                    }
                  />
                </div>
              </InputWrapper>
            )}
            <InputWrapper>
              <label>{t('group(s)')}</label>
              <div className="checkbox-parent">
                {groups?.map((group: any, index: number) => {
                  return (
                    <div className="checkbox-child" key={index}>
                      <Input
                        type="checkbox"
                        name="groups"
                        onChange={() => handleCheck(group)}
                        checked={employee?.groupsIds?.includes(group?.id)}
                        disabled={group?.isAll}
                      />
                      <TextCheckbox>{group?.name}</TextCheckbox>
                    </div>
                  );
                })}
              </div>
            </InputWrapper>
          </SectionForm>
          <ButtonStyled>
            {!loader ? (
              <Button value={t('save')} variant="primary" />
            ) : (
              <Spinner name="sign-up" />
            )}
          </ButtonStyled>
        </form>
      </SectionWrapper>
    </Suspense>
  );
};
export default EditEmployeeForm;
