import Client from 'config/axios';

export const rejectBonus = async (token: string, id: any, message: any) => {
  const body = {
    bonusId: id,
    message: message
  };
  const { data } = await Client.post(`reject-bonus`, body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
