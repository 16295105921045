import styled from 'styled-components';

const StyledInput = styled.div`
  input {
    -webkit-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    border: 1px solid #979797;
    color: #4d4d4d;
    font-size: 0.8125rem;
    border-radius: 32px;
    padding: 10px 15px;
    font-weight: 400;
    max-width: 100%;
    display: block;
    outline: none;
  }
`;

export default StyledInput;
