import Client from 'config/axios';

export const getCompanyCampaigns = async (
  token: string,
  limit: number,
  page: number
) => {
  const { data } = await Client.get(`campaigns?limit=${limit}&page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};

export const getCompanyCampaignsId = async (token: string, id: number) => {
  return await Client.get(`campaign/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
