import React from 'react';
import { useSelector } from 'react-redux';
import { selectCompanyStyle } from 'redux/slices/CompanyStyle/CompanyStyleSlice';

const LegalViewer = () => {
  const { companyStyle } = useSelector(selectCompanyStyle);
  return (
    <>
      <object
        data={companyStyle?.TosUrl}
        type="application/pdf"
        width="100%"
        height="900px"
      ></object>
    </>
  );
};

export default LegalViewer;
