import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  id: number;
  companyId: number;
}

export interface InfoUser {
  id: number;
  companyId: number;
  name: string;
  lastName: string;
  email: string;
  password: string;
  salt: string;
  photo: string;
  phone: string;
  pushCode: string;
  suspiciousBadPractice: boolean;
  registered: boolean;
  deleted: boolean;
  linkedFacebook: boolean;
  linkedTwitter: boolean;
  linkedLinkedin: boolean;
  linkedInstagram: boolean;
  linkedWhatsapp: boolean;
  linkedTiktok: boolean;
  allowBonus: boolean;
  totalFollowers: number;
  groups: any;
  groupsIds: [];
  language: string;
  token: any;
  createdAt: string;
  updatedAt: string;
  company_id: number;
  social_network: any;
}

export interface User {
  id: number;
  companyId: number;
  registered: boolean;
}

export interface UserState {
  token: string;
  infoUser: InfoUser;
  user: User;
  role: string;
  companyId: number;
  loading: boolean;
}

const setInfoUser = (state: any, action: { payload: string }) => {
  return {
    ...state,
    infoUser: action.payload
  };
};

const setUserLoading = (state: any, action: { payload: any }) => {
  return {
    ...state,
    loading: action.payload
  };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: {},
    infoUser: {},
    loading: false
  },
  reducers: {
    login: (state, { payload }: PayloadAction<UserState>) => {
      state.currentUser = {
        ...payload
      };
    },
    logout: state => {
      state.currentUser = {};
      state.infoUser = {};
    },
    register: state => {
      state.currentUser = {};
    },
    expiredSession: state => {
      state.currentUser = 'expired';
      state.infoUser = 'expired';
    },
    setUserInfo: setInfoUser,
    setUserInfoLoading: setUserLoading
  }
});

export const {
  login,
  logout,
  setUserInfo,
  setUserInfoLoading,
  expiredSession
} = userSlice.actions;

export const selectLoading = (state: { user: { loading: any } }) => state.user;

export const selectUserInfo = (state: { user: { infoUser: InfoUser } }) =>
  state.user;

export const selectUser = (state: { user: { currentUser: UserState | any } }) =>
  state.user;
export default userSlice.reducer;
