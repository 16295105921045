import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export const PostDetailWrapper = styled.div`
  p.small-paragraph {
    font-size: 0.875rem;
    text-align: center;
    margin: 0 0 5px 0;
    font-weight: 500;
  }

  a {
    width: 90%;
  }
`;

export const HeardMargin = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  h1 {
    color: ${Colors.primaryDarkBlue};
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 15px;
  }

  p.description {
    margin: 0 0 20px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 16px;
    font-style: italic;
    font-weight: bold;
    color: #333333;
  }

  span {
    margin-bottom: 15px;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    color: ${Colors.secondaryDarkPink};
  }

  .margin_img {
    width: 420px;
    border-radius: 10px;
    height: 420px;
    object-fit: cover;
    background-color: #ffffff;
  }

  .margin_container {
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 100px;
  }

  .people-tags {
    padding-left: 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .circle-tag.plus-tag {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: #004fea;
    background: #f0f0f0;
    font-weight: 500;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .circle_people {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .name_tag_container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
  }

  .name_tag {
    background: #fe135f;
    border-radius: 10px;
  }

  .tag {
    display: inline-block;
    margin: 0 10px;
    color: ${Colors.normalWhite};
    border-radius: 5px;
    padding: 8px 5px;
    font-weight: 400;
    font-size: 16px;
  }
  .option {
    display: flex;
    margin-right: 10px;
    img {
      margin-top: 15px;
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }
    svg {
      margin-top: 15px;
      margin-right: 10px;
    }
  }
  .subtitle {
    margin-right: 5px;
    font-weight: bold;
  }
  .social-networks {
    display: flex;

    span {
      margin-bottom: 0;
    }
  }
  .action-button {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    svg {
      height: 22px;
      width: 22px;
      margin: 0 5px 0 15px;
    }

    a {
      color: ${Colors.primaryDarkBlue};
      text-transform: uppercase;
      text-decoration: none;
      margin-bottom: 25px;
      font-weight: 500;
      font-size: 12px;
      margin-top: 3px;

      &:focus,
      &:hover {
        color: ${Colors.primaryDarkBlue};
        text-decoration: none;
      }
    }

    .vl {
      border-left: 3px solid ${Colors.primaryDarkBlue};
      height: 25px;
      margin-left: 15px;
    }
  }
`;

export const TextStyled = styled.p`
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: ${Colors.secondaryNormalGrey};
`;

export const Filter = styled.div`
  display: flex;
  grid-column: span 8 / auto;

  button {
    border-radius: 20px;
    font-size: 0.6875rem;
    padding: 6px 15px;
    height: 35px;
    margin-right: 20px;
  }

  input {
    width: 30%;
    margin-right: 20px;
  }

  span {
    margin-top: 7px;
  }
`;

export const HeaderSection = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
`;

export const StyleTable = styled.div`
  margin-top: 40px;
  .column {
    background-color: ${Colors.normalWhite};
    position: relative;
    font-weight: 500;
    font-size: 1.125rem;
    color: ${Colors.secondaryNormalGrey};
    padding: 10px 0px;
    border: none;
    font-weight: bold;

    .sn-icon {
      display: flex;
      justify-content: center;
    }
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 20px;
    font-size: 14px;
    font-weight: 500;
    color: ${Colors.secondaryNormalGrey};
    margin-bottom: 10px;
    max-width: 100%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .table_container {
    font-size: 1.125rem;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .column-body {
    box-shadow: 0 1px 6px 0 rgb(79 79 79 / 38%);
    transition: all 0.5s ease-out 0s;
    background: ${Colors.normalWhite};
    margin-bottom: 20px;
    border-radius: 10px;
    z-index: 2;
    height: 70px;

    &.yellow-bg {
      background-color: #ffffcc;
    }

    &.red-bg {
      background-color: #ffdddd;
    }

    th {
      font-family: 'Montserrat', sans-serif;
      font-size: 1.125rem;
      padding: 16px;
      border-collapse: separate;
      border-spacing: 0 20px;
      font-size: 14px;
      font-weight: 500;
      color: ${Colors.secondaryNormalGrey};
      border-bottom: none;
    }

    img {
      height: 22px;
      width: 22px;
    }

    .pointer {
      cursor: pointer;
    }

    .user-photo {
      border-radius: 100%;
      height: 50px;
      width: 50px;
    }
  }
`;

export const ButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  button {
    text-align: center;
    margin: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
`;

export const BodyWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
`;

export const MetricsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  @media (min-width: ${Breakpoints.medium}) {
    grid-column-gap: 20px;
  }

  > div {
    grid-column: span 4;
    margin-bottom: 20px;
  }

   {
    > div:nth-of-type(n + 4) {
      grid-column: span 3;
    }
    > div:nth-of-type(n + 5) {
      grid-column: span 3;
    }
    > div:nth-of-type(n + 6) {
      grid-column: span 6;
    }
    .single-data-box {
      display: grid;
      margin-bottom: 0px;
      height: 100%;
    }
    .currency {
      color: #004fea;
      margin-top: -25px;
      font-weight: 700;
    }
  }

  svg[name='flag'] {
    stroke-width: 30px;
    stroke: ${Colors.primaryDarkBlue};
  }
`;

export const SocialInfoStyled = styled.div`
  div &.contentPoints {
    display: flex;
    justify-content: space-evenly;
    @media (max-width: ${Breakpoints.xxLarge}) {
      flex-direction: column;
      align-items: center;
    }
  }

  img {
    height: 20px;
    width: 20px;
    &.points {
      height: 15px;
      width: 15px;
      margin-left: 5px;
    }
  }
`;
export const ErrorSpan = styled.span`
  padding-left: 10px;
  font-weight: 500;
  font-size: 13px;
  grid-column: 2;
  color: #ff303f;
`;
export const NetworkFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items; center;
    input{
      margin: 0 20px;
    }
`;

export const SocialButtton = styled.button`
  background: none;
  border: none;
  zoom: 150%;
  filter: grayscale(1);
  cursor: pointer;
  &.selected {
    filter: grayscale(0);
    zoom: 200%;
  }
`;
