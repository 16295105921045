import React from 'react';
import { useTranslation } from 'react-i18next';
import iconEditOutline from 'images/icons/edit-outline.svg';
import { IAward } from 'redux/slices/Award/Award.interface';
import { groupNameByObj } from 'helpers/groupsHelper';
import { AwardsContainer } from 'pages/AwardPageAdmin/StyledAwardPageAdmin';
import AwardSingleCard from 'pages/AwardPageAdmin/AwardSingleCard';

export interface AwardsListWebProps {
  awards: Array<IAward>;
}

const AwardsListWeb: React.FC<AwardsListWebProps> = props => {
  const { awards } = props;
  const { t } = useTranslation(['dates', 'awards_section']);

  const getPeriod = (periodType: string, periodValue: string) => {
    const translationPage = t(`dates:${periodType}`, {
      returnObjects: true
    });

    return periodType === 'year'
      ? periodValue
      : translationPage[parseInt(periodValue) - 1];
  };

  const activeAwards = awards.filter(award => {
    const data = award as any;
    return data.status !== 'completed';
  });

  return (
    <div className="hide-on-mobile">
      {activeAwards.length > 0 && (
        <AwardsContainer>
          {activeAwards.map((award, index) => {
            return (
              <AwardSingleCard
                key={index}
                id={award.id}
                photoUrl={award.photoUrl}
                group={groupNameByObj(award.groups)}
                description={award.description}
                periodValue={getPeriod(award.periodType, award.periodValue)}
                iconEdit={iconEditOutline}
              />
            );
          })}
        </AwardsContainer>
      )}
    </div>
  );
};

export default AwardsListWeb;
