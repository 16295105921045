import React from 'react';

import { FooterTable } from './StyledTableFooter';
import { useTranslation } from 'react-i18next';

export interface TableFooterProps {
  postsPerPage: any;
  totalPosts: any;
  paginate: any;
  currentPage: any;
}
const TableFooter: React.FC<TableFooterProps> = props => {
  const { postsPerPage, totalPosts, paginate, currentPage } = props;
  const { t } = useTranslation(['layout']);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return totalPosts > 0 ? (
    <FooterTable>
      {currentPage > 1 && (
        <div className="prev-button">
          <button onClick={() => paginate(currentPage - 1)}>
            {t('layout:prev')}
          </button>
        </div>
      )}
      <div className="total-pages">
        {currentPage} - {pageNumbers.length}
      </div>
      {pageNumbers[pageNumbers.length - 1] !== currentPage && (
        <div className="next-button">
          <button onClick={() => paginate(currentPage + 1)}>
            {t('layout:next')}
          </button>
        </div>
      )}
    </FooterTable>
  ) : (
    <></>
  );
};

export default TableFooter;
