import React from 'react';

import Icon from 'components/icons/Icon';
import FieldError from 'components/inputs/FieldError';
import Input from 'components/inputs/Input';
import { useTranslation } from 'react-i18next';

import { MarginUploadFile } from './StyleUploadExcel';

interface IUploadFileProps {
  name?: string;
  ref?: any;
  onChange?: any;
  errors?: boolean;
  nameFile?: string;
  setNameFile?: any;
  setExcelError?: any;
}

const UploadExcel: React.FC<IUploadFileProps> = props => {
  const {
    name,
    ref,
    onChange,
    errors,
    nameFile,
    setNameFile,
    setExcelError
  } = props;
  const { t } = useTranslation(['upload_excel']);
  return (
    <>
      {!nameFile ? (
        <MarginUploadFile className={errors ? 'error-file' : ''}>
          <>
            <Icon name="file" size="big" color="#979797" />
            <h3>{t('title')}</h3>
            <p>{t('section')}</p>
            <Input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={onChange}
              name={name}
              ref={ref}
            />
            {errors && <FieldError text={t('section')} />}
          </>
        </MarginUploadFile>
      ) : (
        <>
          <span>{nameFile}</span>
          <Icon
            size="small"
            name="close-rounded"
            onClick={() => {
              setNameFile('');
              setExcelError('');
            }}
            color="rgba(0,0,0,0.54)"
          />
        </>
      )}
    </>
  );
};

export default UploadExcel;
