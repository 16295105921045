import React, { Suspense } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Facebook as LoadingState } from 'react-content-loader';

import useAdmin from 'hooks/useIsAdmin.hook';

import AdminCard from './EmployeeCard/AdminCard';
import AdminPerformanceSection from './PerformanceSection/AdminPerformanceSection';
import CampaignsSectionAdmin from './CampaignsSection/CampaignsSectionAdmin';
import ChallengesSection from './ChallengesSection/ChallengesSection';
import GroupSection from './GroupSection';
import ButtonCreate from 'components/buttons/ButtonCreate';

import add_purple from 'images/icons/add_purple.svg';
import add_blue from 'images/icons/add_blue.svg';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import AwardsSection from './AwardsSection';
import RankingSection from './RankingSection';
import { useFetch } from 'hooks/useFetch.hook';
import { getInfoUser } from 'services/rest/User/getInfoUser';
import { selectUser } from 'redux/slices/userSlice';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import ContentsSection from './ContentsSection';

const DashboardAdminPage = () => {
  const { t } = useTranslation([
    'admin_dashboard',
    'awards_section',
    'group_page',
    'employee_dashboard',
    'campaign_section',
    'employee_dashboard',
    'challenge',
    'bonus'
  ]);
  const { currentUser } = useSelector(selectUser);
  const history = useHistory();
  const { company } = useSelector(selectCompany);
  const { data, loading } = useFetch(
    getInfoUser(
      currentUser.user.id,
      currentUser?.token,
      useAdmin() ? 'administrator' : 'ambassador'
    )
  );

  const userData = data as any;

  const fullName = useAdmin()
    ? `${userData?.name}`
    : `${userData?.name} ${userData?.lastName}`;

  const userPhoto = useAdmin() ? company?.logo : userData?.photo;

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <AdminCard userPhoto={userPhoto} userName={fullName} />
        <SectionWrapper>
          <AdminPerformanceSection />
        </SectionWrapper>
        <SectionWrapper>
          <div className="section-head">
            <h1 className="section-title">
              {t('campaign_section:active_campaigns')}
            </h1>
            <ButtonCreate
              onClick={() => history.replace('/campaigns/admin/new')}
              color="#b721ff"
              value={t('admin_dashboard:create_campaign')}
              href={add_purple}
            />
          </div>
          <CampaignsSectionAdmin />
          <NavigationLink
            text={t('admin_dashboard:see_campaigns')}
            direction={'forward'}
            to={'admin/campaigns'}
          />
        </SectionWrapper>
        <SectionWrapper>
          <div className="section-head">
            <h1 className="section-title">{t('challenge:challenges')}</h1>
            <ButtonCreate
              onClick={() => history.replace('/challenges/new')}
              color="#b721ff"
              value={t('challenge:create_challenge')}
              href={add_purple}
            />
          </div>
          <ChallengesSection />
          <NavigationLink
            text={t('admin_dashboard:see_challenges')}
            direction={'forward'}
            to={'/creators'}
          />
        </SectionWrapper>
        <SectionWrapper>
          <div className="section-head">
            <h1 className="section-title">{t('bonus:bonus_title')}</h1>
          </div>
          <ContentsSection />
          <NavigationLink
            text={t('admin_dashboard:see_contents')}
            direction={'forward'}
            to={'/creators'}
          />
        </SectionWrapper>
        <SectionWrapper>
          <div className="section-head">
            <h1 className="section-title">{t('group_page:groups')}</h1>
            <ButtonCreate
              onClick={() => history.replace('/admin/group/new')}
              color="#004fea"
              value={t('admin_dashboard:create_group')}
              href={add_blue}
            />
          </div>
          <GroupSection />
          <NavigationLink
            text={t('admin_dashboard:see_group')}
            direction={'forward'}
            to={'admin/group'}
          />
        </SectionWrapper>
        <AwardsSection />
        <SectionWrapper className="blue-wrapper">
          <h1 className="section-title">{t('employee_dashboard:ranking')}</h1>
          <RankingSection />
        </SectionWrapper>
      </Suspense>
    </>
  );
};

export default DashboardAdminPage;
