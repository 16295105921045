import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { DivIcon, CardWrapper, MarginDiv, MarginText } from './Styled';
import iconTrash from 'images/icons/trash.png';
import iconEye from 'images/icons/eyes.svg';
import iconEditOutline from 'images/icons/edit-outline.svg';
import Tag from 'components/Tag/Tag';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import { deleteChallenge } from 'services/rest/Challenge/deleteChallenge';

export interface ChallengeAdminCardProps {
  key: number;
  challenge: any;
  timeLine: any;
  setLoading: any;
  changeChallenge: any;
  setChangeChallenge: any;
}

const ChallengeAdminCard: React.FC<ChallengeAdminCardProps> = props => {
  const {
    key,
    challenge,
    timeLine,
    setLoading,
    changeChallenge,
    setChangeChallenge
  } = props;
  const { currentUser } = useSelector(selectUser);
  const history = useHistory();
  const { t } = useTranslation(['campaign_card', 'challenge']);
  const socialNetworks = [
    'facebook',
    'twitter',
    'linkedin',
    'instagram',
    'tiktok',
    'snapchat',
    'youtube',
    'threads',
    'others'
  ];

  const onSubmit = async (challengeId: any, isDeletedBonus: any) => {
    setLoading(true);
    const data = await deleteChallenge(
      currentUser?.token,
      challengeId,
      isDeletedBonus
    );
    if (data.data) {
      setChangeChallenge(!changeChallenge);
    }
  };

  const deleteAlert = (challengeId: number) => {
    Swal.fire({
      text: t('challenge:confirmation_delete_message'),
      input: 'checkbox',
      inputValue: false,
      inputPlaceholder: t('challenge:checkbox_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(challengeId, Boolean(result.value));
      }
    });
  };

  const goDetail = (id: number) => {
    window.open(`/challenges/detail/${id}`, '_self');
  };

  return (
    <CardWrapper
      key={key}
      className="hide-on-mobile activated-campaign content-box content-box-horizontal flex-box-container hoverable small"
    >
      {challenge.status === 'pending' && (
        <Tag value="Proximamente" variant="invert" />
      )}
      <div className="content-box-header content-img pointer">
        <Link to={`/challenges/detail/` + challenge.id}>
          <img src={challenge.photo} alt="new" />
        </Link>
      </div>
      <MarginText className="m-auto pointer">
        <Link to={`/challenges/detail/` + challenge.id}>
          <p className="time-line">
            {timeLine(challenge.startDate, challenge.finishDate)}
          </p>
          <div className={challenge.name.length > 50 ? 'overflow-elipsis' : ''}>
            <h2>{challenge.name}</h2>
            <p className="social-networks">
              {socialNetworks.map(network => {
                return (
                  challenge[network] && (
                    <SocialMediaIcon
                      name={network === 'others' ? 'otros' : network}
                      size="small"
                      shape="square"
                    />
                  )
                );
              })}
            </p>
          </div>
        </Link>
      </MarginText>
      <div
        className="content-box-body campaign-stats"
        onClick={() => goDetail(challenge.id)}
      >
        <MarginDiv className="division-right">
          <p>{challenge.ambassadorsParticipating}</p>
          <h3>{t('campaign_card:active_ambassadors')}</h3>
        </MarginDiv>
        <MarginDiv>
          <p>{challenge.totalParticipation}</p>
          <h3>{t('campaign_card:total_contents')}</h3>
        </MarginDiv>
      </div>
      <DivIcon>
        {challenge.status !== 'completed' && (
          <div>
            <button
              onClick={() =>
                history.replace({
                  pathname: `/challenges/${challenge.id}/edit`
                })
              }
            >
              <img alt="EditIcon" src={iconEditOutline} />
            </button>
          </div>
        )}
        {challenge.status === 'completed' && (
          <div>
            <button
              onClick={() =>
                history.replace({
                  pathname: `/challenges/${challenge.id}/view`
                })
              }
            >
              <img alt="eyeIcon" src={iconEye} />
            </button>
          </div>
        )}
        {challenge.status !== 'completed' && (
          <div>
            <button onClick={() => deleteAlert(challenge.id)}>
              <img alt="iconTrash" src={iconTrash} />
            </button>
          </div>
        )}
      </DivIcon>
    </CardWrapper>
  );
};

export default ChallengeAdminCard;
