import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Instagram as LoadingState } from 'react-content-loader';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { selectUser } from 'redux/slices/userSlice';
import { yupResolver } from '@hookform/resolvers';

import { sortGroups } from 'helpers/groupsHelper';
import { RequiredString, String } from 'helpers/yupHelper';

import { getGroupsList } from 'services/rest/Group/getGroupsList';
import {
  createAmbassador,
  Ambassador
} from 'services/rest/Ambassador/createAmbassador';

import { ErrorAlert, SuccessAlert } from 'components/Alert';

import NewEmployeeForm from './NewEmployeeForm';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

interface GroupParams {
  groupId: string;
}

const NewEmployee = () => {
  const { t } = useTranslation(['group_page', 'validations']);
  const userSchema = yup.object().shape({
    name: RequiredString().max(70, t('validations:length_error', { max: 70 })),
    lastName: RequiredString().max(
      70,
      t('validations:length_error', { max: 70 })
    ),
    email: RequiredString()
      .email(t('validations:email'))
      .max(100, t('validations:length_error', { max: 100 })),
    code: String()
      .trim()
      .max(20, t('validations:length_error', { max: 20 }))
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(userSchema)
  });
  const { groupId } = useParams<GroupParams>();
  const backGroup = `/admin/group/detail/${groupId}`;
  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const { company } = useSelector(selectCompany);
  const [employee, setEmployee] = useState<any>({
    groups: {},
    groupsIds: []
  });
  const [groups, setGroups] = useState<
    { id: number; name: string; isAll: boolean }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [createError, setCreateError] = useState<string | null>(null);
  const [createSuccess, setCreateSuccess] = useState<string | null>(null);
  const [language, setLanguage] = useState<string>(company?.language);
  const [phone, setPhone] = useState(employee?.phone);
  const [allowBonus, setAllowBonus] = useState<boolean>(false);
  const [allowSuggestedPost, setAllowSuggestedPost] = useState<boolean>(false);

  const getAllGroupData = async () => {
    const { data } = await getGroupsList(currentUser?.token, 500, 1);
    if (data) {
      setGroups(sortGroups(data.data.docs));
      setLoading(false);
    }
  };

  const handleCheck = (group: { id: number; name: string }) => {
    const { id, name } = group;
    if (employee?.groupsIds?.includes(id)) {
      const copyGroups = employee?.groups;
      delete copyGroups[id];
      setEmployee({
        ...employee,
        groupsIds: employee?.groupsIds?.filter((emGId: number) => emGId !== id),
        groups: copyGroups
      });
      return;
    }
    setEmployee({
      ...employee,
      groupsIds: [...employee?.groupsIds, id],
      groups: {
        ...employee?.groups,
        [id]: {
          name
        }
      }
    });
  };

  useEffect(() => {
    getAllGroupData();
  }, []);

  const onSubmit = async (ambassadorData: Ambassador) => {
    setLoader(true);
    groups?.forEach((group: { id: number; name: string; isAll: boolean }) => {
      const { id, name, isAll } = group;
      if (isAll || id === parseInt(groupId)) {
        employee.groupsIds.push(id);
        const groups = {
          ...employee?.groups,
          [id]: {
            name
          }
        };
        employee.groups = groups;
      }
    });
    ambassadorData.language = language || company?.language;
    ambassadorData.companyId = company?.id;
    ambassadorData.phone = phone;
    ambassadorData.allowBonus = allowBonus;
    ambassadorData.allowSuggestedPost = allowSuggestedPost;
    ambassadorData.groups = employee?.groups;
    ambassadorData.groupsIds = employee?.groupsIds;
    const { data, errors } = await createAmbassador(
      currentUser?.token,
      ambassadorData
    );
    if (data) {
      history.push(backGroup, 'success');
    }
    if (errors) {
      setCreateError(errors?.data);
    }
    setLoader(false);
  };

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {createError && (
        <ErrorAlert
          text={createError}
          allowClose={true}
          onClose={() => setCreateError(null)}
        />
      )}

      {createSuccess && (
        <SuccessAlert
          text={createSuccess}
          allowClose={true}
          onClose={() => setCreateSuccess(null)}
        />
      )}
      <NewEmployeeForm
        employee={employee}
        loading={loading}
        company={company}
        loader={loader}
        groupId={groupId}
        errors={errors}
        handleSubmit={handleSubmit(onSubmit)}
        register={register}
        groups={groups}
        setLanguage={setLanguage}
        phone={phone}
        setPhone={setPhone}
        allowBonus={allowBonus}
        setAllowBonus={setAllowBonus}
        allowSuggestedPost={allowSuggestedPost}
        setAllowSuggestedPost={setAllowSuggestedPost}
        handleCheck={handleCheck}
      />
    </>
  );
};
export default NewEmployee;
