const regextValidation = (URL: string, test: any) => {
  const regex = new RegExp(test);
  return regex.test(URL);
};

export const facebookURLs = (URL: string) => {
  if (!URL.startsWith('https://www.facebook.com/')) {
    return false;
  }

  if (URL.includes('/posts/')) {
    return regextValidation(URL, '/[a-zA-Z._-]+/posts/[A-Za-z0-9]+');
  }

  if (URL.includes('/photos/a.')) {
    return regextValidation(
      URL,
      '/([a-zA-Z._-]+(/[a-zA-Z._-]+)+)[0-9]*.[0-9]+/[0-9]+'
    );
  }

  if (URL.includes('fbid=')) {
    return (
      regextValidation(URL, '[0-9]+&set=a[0-9]*.[0-9]+&type=3') ||
      regextValidation(URL, '[0-9]+&set=a[0-9]*.[0-9]+') ||
      regextValidation(URL, '[0-9]+&set=pcb.[0-9]+')
    );
  }

  if (URL.includes('/videos/')) {
    return (
      regextValidation(URL, '/[a-zA-Z._-]+/videos/[0-9]+') ||
      regextValidation(URL, '/[0-9]+/videos/[0-9]+')
    );
  }

  if (URL.includes('/watch/')) {
    return regextValidation(URL, /\/watch\/\?v=[0-9]+/g);
  }

  if (URL.includes('/reel/')) {
    return regextValidation(URL, '[a-zA-Z._-]+/reel/[0-9]+');
  }

  return false;
};
