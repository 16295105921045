import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './Company.state';
import { ICompany } from './Company.interface';

const setCompanyLoading = (state: any, action: { payload: any }) => {
  return {
    ...state,
    loading: action.payload
  };
};

const setCompany = (state: any, action: { payload: ICompany }) => {
  return {
    ...state,
    company: action.payload
  };
};

export const selectCompany = (state: { company: any }) => state.company;

const CompanySlice = createSlice({
  name: 'companySlice',
  initialState: {
    loading: false,
    company: initialState
  },
  reducers: {
    setLoading: setCompanyLoading,
    setCompanyData: setCompany
  }
});

export const { setLoading, setCompanyData } = CompanySlice.actions;

export default CompanySlice.reducer;
