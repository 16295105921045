import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import CalendarWrapper from 'components/Calendar/StyledCalendar';
import StyledCalendarInput from 'components/Calendar/CalendarInput/StyledCalendarInput';

export const SelectContainer = styled.div`
  width: 140px;
  select {
    width: 100% !important;
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 1px solid #979797 !important;
    background-color: transparent !important;
    color: #3e66fe !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
  }
`;

export const FiltersForm = styled.div`
  text-align: center;
  display: block;
  flex-wrap: wrap;

  div.dropdown-filter-content__control {
    width: 100%;
    @media (max-width: ${Breakpoints.medium}) {
      width: 100% !important;
    }
  }

  div.dropdown-filter-content__menu {
    @media (max-width: ${Breakpoints.medium}) {
      width: 95% !important;
    }
  }

  div.dropdown-filter-container {
    @media (max-width: ${Breakpoints.medium}) {
      margin-bottom: 15px;
    }
  }
`;

export const FirstFilters = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 3fr 3fr;

  input {
    @media (min-width: ${Breakpoints.medium}) {
      margin-right: 10px;
    }
  }

  span.filter-by-label {
    margin: 20px auto;
    display: block;

    @media (min-width: ${Breakpoints.medium}) {
      display: inline;
      margin: 0 20px;
    }
  }
`;

export const SecondFilters = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr 3fr 2fr;

  ${StyledCalendarInput} {
    border: none;
    border-bottom: 0.85px solid ${Colors.inactive};
    border-radius: 0;
    max-width: 300px;

    &:hover,
    &:focus-within {
      border-bottom: 0.85px solid ${Colors.primaryDarkBlue};
    }

    label {
      color: ${Colors.primaryDarkBlue};
      text-transform: uppercase;
    }
  }

  ${CalendarWrapper} {
    padding: 0 10px 12px;
  }
`;

export const ButtonDownload = styled.div`
  button {
    padding: 5px 20px;
    font-size: 12px;
    text-align: center;
    border: 1px solid ${Colors.primaryDarkBlue};
    background-color: ${Colors.primaryDarkBlue};
    background-image: linear-gradient(136deg, #3e65fe, #3ebbfe);
    svg {
      margin: 0;
      width: 15px;
      height: 15px;
    }
  }
`;
