import Client from 'config/axios';

export interface CurrencyData {
  name: string;
  code: string;
  sign: string;
  value: string;
}

export const createCurrency = async (token: string, currency: CurrencyData) => {
  const body = {
    name: currency?.name,
    code: currency?.code,
    sign: currency?.sign,
    value: currency?.value
  };

  try {
    const { data } = await Client.post('currency', body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
