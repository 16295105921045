import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Instagram as LoadingState } from 'react-content-loader';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import CampaignsList from './CampaignsList';
import NavigationLink from 'components/links/NavigationLink';

import { selectUser } from 'redux/slices/userSlice';
import { getCampaingsActived } from 'services/rest/Campaings';
import {
  setListCampaigns,
  setLoading
} from 'redux/slices/Campaigns/CampaignsSlice';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';
import EmptyCampaign from 'images/empty_states/empty_campaigns.png';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import { MarginAdminPage, FilterSection } from '../CampaignsAdminPage/styled';

const CampaignsPage = () => {
  const { t } = useTranslation([
    'campaigns_page',
    'campaign_section',
    'campaign_card'
  ]);

  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectUser);
  const { campaigns = [], loading } = useSelector(
    ({ campaigns }: any) => campaigns
  );

  const statusOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: t('campaign_card:actived'), value: 'activated' },
    { label: t('campaign_card:completed'), value: 'completed' },
    { label: t('campaign_card:pending'), value: 'pending' }
  ];
  const [selectedStatus, setStatus] = useState(statusOptions[0]);
  const [campaignByStatus, setCampaignByStatus] = useState([]);

  const getData = async () => {
    const { data } = await getCampaingsActived(currentUser?.token, false);
    dispatch(setListCampaigns(data.data));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const activatedCampaigns = campaigns?.filter(
    (campaign: ICampaigns) => campaign.status === 'activated'
  );

  const pendingCampaigns = campaigns.filter(
    (campaign: ICampaigns) => campaign.status === 'pending'
  );

  const completedCampaigns = campaigns?.filter(
    (campaign: ICampaigns) => campaign.status === 'completed'
  );

  const selectOption = (event: any) => {
    let filter = [] as any;
    if (event.value !== 'ALL') {
      filter = campaigns.filter(
        (campaign: any) => campaign.status === event.value
      );
    }
    setCampaignByStatus(filter);
    setStatus(event);
    return filter;
  };

  return (
    <>
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <SectionWrapper>
          <NavigationLink text={t('back')} direction="back" to="/dashboard" />
          <MarginAdminPage>
            <p>{t('campaign_section:active_campaigns')}</p>
          </MarginAdminPage>
          <FilterSection>
            <DropdownSelect
              options={statusOptions}
              value={selectedStatus}
              onSelect={selectOption}
            />
          </FilterSection>
        </SectionWrapper>
        {campaignByStatus.length > 0 && (
          <>
            <SectionWrapper>
              <CampaignsList listCampaigns={campaignByStatus} />
            </SectionWrapper>
          </>
        )}
        {campaignByStatus.length === 0 && activatedCampaigns?.length > 0 && (
          <>
            <SectionWrapper>
              <h2 className="section-title">{t('active')}</h2>
              <CampaignsList listCampaigns={activatedCampaigns} />
            </SectionWrapper>
          </>
        )}
        {campaignByStatus.length === 0 && pendingCampaigns?.length > 0 && (
          <>
            <SectionWrapper>
              <h2 className="section-title">{t('pending')}</h2>
              <CampaignsList listCampaigns={pendingCampaigns} />
            </SectionWrapper>
          </>
        )}
        {campaignByStatus.length === 0 && completedCampaigns?.length > 0 && (
          <>
            <SectionWrapper>
              <h2 className="section-title">{t('completed')}</h2>
              <CampaignsList listCampaigns={completedCampaigns.slice(0, 3)} />
            </SectionWrapper>
          </>
        )}
        {campaigns?.length === 0 && (
          <EmptyStateBox
            image={EmptyCampaign}
            description={t('campaign_section:empty_state_description')}
          />
        )}
      </Suspense>
    </>
  );
};

export default CampaignsPage;
