import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods } from 'react-hook-form';
import Input from 'components/inputs/Input';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { InputWrapper, EmailPermissions } from './Styled';

export interface EmailAccordionProps {
  permissions: any;
  handleOnClick: any;
  register: UseFormMethods['register'];
}

const EmailAccordion: React.FC<EmailAccordionProps> = props => {
  const { permissions, handleOnClick, register } = props;
  const { t } = useTranslation('change_permissions');

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h1>{t('email_permissions')}</h1>
      </AccordionSummary>
      <AccordionDetails>
        <EmailPermissions>
          <InputWrapper>
            <label>{t('allowEmailNotifications')}</label>
            <div className="checkbox-parent">
              <Input
                id="allowEmailNotifications"
                type="checkbox"
                name="allowEmailNotifications"
                className="swipe-checkbox"
                checked={permissions?.allowEmailNotifications}
                ref={register}
                onClick={handleOnClick}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowWelcomeEmail')}</label>
            <div
              className={
                !permissions?.allowEmailNotifications
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowWelcomeEmail"
                type="checkbox"
                name="allowWelcomeEmail"
                className="swipe-checkbox"
                checked={permissions?.allowWelcomeEmail}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowEmailNotifications}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowCampaignEmail')}</label>
            <div
              className={
                !permissions?.allowEmailNotifications
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowCampaignEmail"
                type="checkbox"
                name="allowCampaignEmail"
                className="swipe-checkbox"
                checked={permissions?.allowCampaignEmail}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowEmailNotifications}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowCampaignAwardsEmail')}</label>
            <div
              className={
                permissions?.allowCampaignEmail
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowCampaignAwardsEmail"
                type="checkbox"
                name="allowCampaignAwardsEmail"
                className="swipe-checkbox"
                checked={permissions?.allowCampaignAwardsEmail}
                ref={register}
                onClick={handleOnClick}
                disabled={!permissions?.allowCampaignEmail}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowChallengeEmail')}</label>
            <div
              className={
                !permissions?.allowEmailNotifications
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowChallengeEmail"
                type="checkbox"
                name="allowChallengeEmail"
                className="swipe-checkbox"
                checked={permissions?.allowChallengeEmail}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowEmailNotifications}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowAwardsEmail')}</label>
            <div
              className={
                !permissions?.allowEmailNotifications
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowAwardsEmail"
                type="checkbox"
                name="allowAwardsEmail"
                className="swipe-checkbox"
                checked={permissions?.allowAwardsEmail}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowEmailNotifications}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowBonusAdminEmail')}</label>
            <div
              className={
                !permissions?.allowEmailNotifications
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowBonusAdminEmail"
                type="checkbox"
                name="allowBonusAdminEmail"
                className="swipe-checkbox"
                checked={permissions?.allowBonusAdminEmail}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowEmailNotifications}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowRejectedBonusEmail')}</label>
            <div
              className={
                !permissions?.allowEmailNotifications
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowRejectedBonusEmail"
                type="checkbox"
                name="allowRejectedBonusEmail"
                className="swipe-checkbox"
                checked={permissions?.allowRejectedBonusEmail}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowEmailNotifications}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('allowApprovedBonusEmail')}</label>
            <div
              className={
                !permissions?.allowEmailNotifications
                  ? `checkbox-parent`
                  : `checkbox-parent disabled`
              }
            >
              <Input
                id="allowApprovedBonusEmail"
                type="checkbox"
                name="allowApprovedBonusEmail"
                className="swipe-checkbox"
                checked={permissions?.allowApprovedBonusEmail}
                ref={register}
                onClick={handleOnClick}
                disabled={permissions?.allowEmailNotifications}
              />
            </div>
          </InputWrapper>
        </EmailPermissions>
      </AccordionDetails>
    </Accordion>
  );
};
export default EmailAccordion;
