import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PrivateRoute from 'router/PrivateRoute';
import CampaignsRouter from 'router/CampaignsRouter';
import GroupRouter from 'router/GroupAdminRouter';
import CallBackRouter from 'router/CallBackRouter';
import SuperAdminCompanyRouter from 'router/SuperAdminCompanyRouter';
import SuperAdminCountryRouter from 'router/SuperAdminCountryRouter';

import OnboardingLayout from 'pages/OnboardingLayout/OnboardingLayout';
import LoginPage from 'pages/LoginPage';
import DashboardPage from 'pages/DashboardPage';
import Legal from 'pages/Legal';
import AwardPage from 'pages/AwardPage/AwardPage';
import FrequentQuestions from 'pages/FrequentQuestions';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ChangePasswordPage from 'pages/ChangePasswordPage';
import SocialNetworkPage from 'pages/SocialNetwork';
import Bonus from 'pages/Bonus';
import EditAwardPage from 'pages/AwardPageAdmin/EditAwardPage';
import Settings from 'pages/Settings';
import TwoFA from 'pages/Settings/AdminSettings/2FA';
import Companies from 'pages/SuperAdmin/Companies';
import Countries from 'pages/SuperAdmin/Countries';
import Currencies from 'pages/SuperAdmin/Currencies';

import Administrators from 'pages/SuperAdmin/Administrators';
import CompanyDisallowed from 'pages/CompanyDisallowed';
import NotFoundPage from 'pages/404';

import './App.css';
import RegisterPage from 'pages/RegisterPage';
import CampaignsAdminPage from 'pages/CampaignsAdminPage';
import AwardAdminRouter from 'router/AwardAdminRouter';
import ReportsAdminRouter from 'router/ReportsAdminRouter';

import useAdmin from 'hooks/useIsAdmin.hook';
import useIsSuperAdmin from 'hooks/useIsSuperAdmin.hook';

import { AllowBonus } from 'helpers/bonusHelper';
import SuperAdminCurrencyRouter from 'router/SuperAdminCurrencyRouter';
import SuperAdminAdministratorRouter from 'router/SuperAdminAdministratorRouter';
import ChallengesRouter from 'router/ChallengesRouter';
import SuggestedPostsRouter from 'router/SuggestedPostsRouter';
import Creators from 'pages/Creators';
import { useTranslation } from 'react-i18next';
import CompanySettings from 'pages/CompanySettings';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompanyStyle,
  setDataStyle
} from 'redux/slices/CompanyStyle/CompanyStyleSlice';
import { getCurrentCompany } from 'helpers/companyHelper';
import { getCompanyDataBySubdomain } from 'services/rest/Company/GetCompanyData';

function App() {
  const { companyStyle } = useSelector(selectCompanyStyle);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyStyle?.language) {
      i18n.changeLanguage(companyStyle?.language);
    }
  }, [companyStyle?.language]);

  const getDataStyles = async () => {
    const subdomain = getCurrentCompany();
    const dataStyles = await getCompanyDataBySubdomain(subdomain);
    if (dataStyles) {
      dispatch(setDataStyle(dataStyles));
    }
  };

  useEffect(() => {
    getDataStyles();
  }, []);

  return (
    <Switch>
      <Route exact path="/sign_in">
        {!companyStyle?.deleted && (
          <OnboardingLayout className="login-wrapper">
            <LoginPage />
          </OnboardingLayout>
        )}
        {companyStyle?.deleted && (
          <Redirect
            to={{
              pathname: '/companyDisallowed'
            }}
          />
        )}
        {companyStyle?.body === null &&
          (window.location.href = 'https://www.fluvip.com/advocates')}
      </Route>

      <Route exact path="/sign_up/:email?">
        {!companyStyle?.deleted && (
          <OnboardingLayout className="login-wrapper">
            <RegisterPage />
          </OnboardingLayout>
        )}
        {companyStyle?.deleted && (
          <Redirect
            to={{
              pathname: '/companyDisallowed'
            }}
          />
        )}
        {companyStyle?.body === null &&
          (window.location.href = 'https://www.fluvip.com/advocates')}
      </Route>

      <Route exact path="/forgot_password">
        {!companyStyle?.deleted && (
          <OnboardingLayout className="login-wrapper">
            <ForgotPasswordPage />
          </OnboardingLayout>
        )}
        {companyStyle?.deleted && (
          <Redirect
            to={{
              pathname: '/companyDisallowed'
            }}
          />
        )}
        {companyStyle?.body === null &&
          (window.location.href = 'https://www.fluvip.com/advocates')}
      </Route>

      <Route exact path="/change_password/:token">
        {!companyStyle?.deleted && (
          <OnboardingLayout className="login-wrapper">
            <ChangePasswordPage />
          </OnboardingLayout>
        )}
        {companyStyle?.deleted && (
          <Redirect
            to={{
              pathname: '/companyDisallowed'
            }}
          />
        )}
        {companyStyle?.body === null &&
          (window.location.href = 'https://www.fluvip.com/advocates')}
      </Route>

      <Route exact path="/legal">
        <Legal />
      </Route>

      <PrivateRoute exact path="/dashboard">
        <DashboardPage />
      </PrivateRoute>

      <Route exact path="/social_network">
        <SocialNetworkPage />
      </Route>

      <Route path="/:network/callback">
        <CallBackRouter />
      </Route>

      <PrivateRoute path="/campaigns">
        <CampaignsRouter />
      </PrivateRoute>

      <PrivateRoute path="/challenges">
        <ChallengesRouter />
      </PrivateRoute>

      <PrivateRoute path="/suggested-posts">
        <SuggestedPostsRouter />
      </PrivateRoute>

      <PrivateRoute path="/creators">
        <Creators />
      </PrivateRoute>

      <PrivateRoute path="/admin/group">
        {useAdmin() ? (
          <GroupRouter />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </PrivateRoute>

      <PrivateRoute path="/admin/campaigns">
        {useAdmin() ? (
          <CampaignsAdminPage />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </PrivateRoute>

      <PrivateRoute path="/admin/award">
        {useAdmin() ? (
          <AwardAdminRouter />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </PrivateRoute>

      <PrivateRoute path="/award/:awardId/edit">
        <EditAwardPage />
      </PrivateRoute>

      <PrivateRoute path="/admin/reports">
        {useAdmin() ? (
          <ReportsAdminRouter />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </PrivateRoute>

      <PrivateRoute exact path="/awards">
        <AwardPage />
      </PrivateRoute>

      <PrivateRoute exact path="/faq">
        <FrequentQuestions />
      </PrivateRoute>

      <PrivateRoute exact path="/bonus">
        {AllowBonus() ? (
          <Bonus />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'AllowBonus' }
            }}
          />
        )}
      </PrivateRoute>

      <PrivateRoute exact path="/companies">
        <Companies />
      </PrivateRoute>

      <PrivateRoute exact path="/countries">
        <Countries />
      </PrivateRoute>

      <PrivateRoute exact path="/currencies">
        <Currencies />
      </PrivateRoute>

      <PrivateRoute exact path="/administrators">
        <Administrators />
      </PrivateRoute>

      <Route exact path="/">
        {useIsSuperAdmin() ? (
          <Redirect
            to={{
              pathname: '/companies',
              state: { from: '/' }
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: '/' }
            }}
          />
        )}
      </Route>

      <PrivateRoute exact path="/company-settings">
        <CompanySettings />
      </PrivateRoute>

      <PrivateRoute path="/company">
        <SuperAdminCompanyRouter />
      </PrivateRoute>

      <PrivateRoute path="/country">
        <SuperAdminCountryRouter />
      </PrivateRoute>

      <PrivateRoute path="/currency">
        <SuperAdminCurrencyRouter />
      </PrivateRoute>

      <PrivateRoute path="/administrators">
        <SuperAdminAdministratorRouter />
      </PrivateRoute>

      <PrivateRoute path="/settings">
        <Settings />
      </PrivateRoute>

      <PrivateRoute path="/two-factor-authentication">
        {useAdmin() ? (
          <TwoFA />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </PrivateRoute>

      <Route path="/companyDisallowed">
        <CompanyDisallowed />
      </Route>

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}

export default App;
