import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  GridWrapper,
  ImgMobile,
  MarginMobileText,
  MarginText,
  MobileWrapper,
  ColorButton
} from './StyledNoDataCard';
import Button from 'components/buttons/Button';

export interface NoDataCardProps {
  title: string;
  text: string;
  img: string;
  button: any;
}

const NoDataCard: React.FC<NoDataCardProps> = props => {
  const { title, text, img, button } = props;
  const history = useHistory();
  return (
    <>
      <GridWrapper className="hide-on-mobile no-data-card content-box content-box-horizontal flex-box-container hoverable small">
        <div className="content-box-header content-img">
          <img src={img} alt="new" />
        </div>
        <MarginText className="content-box-data">
          <div>
            <h2>{title}</h2>
            <p>{text}</p>
          </div>
          <div className="content-button">
            <ColorButton>
              <Button
                className="hide-on-mobile"
                value={button[0].text}
                variant="primary"
                onClick={() => history.replace({ pathname: button[0].action })}
              />
            </ColorButton>
          </div>
        </MarginText>
      </GridWrapper>
      <MobileWrapper className="show-on-mobile">
        <ImgMobile>
          <img src={img} alt="new" width="60%" />
        </ImgMobile>
        <MarginMobileText>
          <div>
            <h2>{title}</h2>
            <p>{text}</p>
          </div>
          <div className="content-button">
            <ColorButton>
              <Button
                className="show-on-mobile"
                value={button[0].text}
                variant="primary"
                onClick={() => history.replace({ pathname: button[0].action })}
              />
            </ColorButton>
          </div>
        </MarginMobileText>
      </MobileWrapper>
    </>
  );
};

export default NoDataCard;
