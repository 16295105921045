import React, { Suspense, useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import moment from 'moment';
import 'moment/locale/es';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import AdminBonus from './AdminBonus';
import AmbassadorBonus from './AmbassadorBonus';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getBonus } from 'services/rest/Bonus/getBonus';
import { getBonusByAmbassador } from 'services/rest/Bonus/getBonusByAmbassador';
import { useTranslation } from 'react-i18next';
import Button from 'components/buttons/Button';
import { createBonus } from 'services/rest/Bonus/createBonus';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import 'react-responsive-modal/styles.css';
import {
  MarginAmbassadorPage,
  SocialButtton,
  PostDetailWrapper
} from './Styled';
import { Colors } from 'styles/Constants';
import Modal from 'react-responsive-modal';
import {
  validFbUrl,
  validTwUrl,
  validLkUrl,
  validIgUrl,
  validTkUrl,
  validSnapUrl,
  validYtUrl,
  validThUrl,
  validOthersUrl
} from 'helpers/socialUrlValidate';

export interface iBonus {
  id: string;
  ambassadorName: string;
  createdAt: string;
  socialNetwork: string;
  deleted: boolean;
  status: string;
}

const timeLine = (startDate: any) => {
  startDate = moment(startDate);
  const startMonth = startDate.format('MMMM');
  const startDay = startDate.format('D');
  const startYear = startDate.format('YYYY');
  return `${startDay} ${startMonth} ${startYear}`;
};

const Bonus = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [socialNetwork, setSocialNetwork] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { currentUser } = useSelector(selectUser);
  const [bonus, setBonus] = useState<Array<iBonus>>([]);
  const [loading, setLoading] = useState(true);
  const urlPost = document.getElementById('url-input') as any;
  const [pendingPostMessage, setPendingPostMessage] = useState(false);
  const { t } = useTranslation(['bonus', 'campaign_section']);
  const getData = async () => {
    const { data } =
      currentUser.role === 'admin'
        ? await getBonus(currentUser?.token, 2500, 1)
        : await getBonusByAmbassador(currentUser?.token, 1000, 1);
    if (data) {
      setBonus(data?.data?.docs);
    }
    setLoading(false);
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const handleIconClick = (icon: any, socialNetwork: any) => {
    setSelectedIcon(icon);
    setSocialNetwork(socialNetwork);
    setErrorMessage(false);
  };
  const handleCreateBonus = async (
    token: any,
    url: any,
    socialNetwork: any
  ) => {
    const { data, errors } = await createBonus(token, url, socialNetwork);
    if (data) {
      setPendingPostMessage(true);
      setLoader(false);
    }
    if (errors) {
      setLoader(false);
    }
  };
  const onPublish = async () => {
    setLoader(true);
    if (socialNetwork === 'facebook' && validFbUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'twitter' && validTwUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'linkedin' && validLkUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'instagram' && validIgUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'tiktok' && validTkUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'snapchat' && validSnapUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'youtube' && validYtUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'threads' && validThUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'otros' && validOthersUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else {
      setErrorMessage(true);
      setLoader(false);
    }
  };

  return (
    <>
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <SectionWrapper>
          <MarginAmbassadorPage>
            <h1>{t('bonus:bonus_title')}</h1>
            {currentUser.role === 'ambassador' && (
              <Button
                value={t('campaign_section:create_bonus')}
                variant="secondary"
                onClick={openModal}
              />
            )}
          </MarginAmbassadorPage>
          {currentUser.role === 'admin' ? (
            <AdminBonus bonus={bonus} timeLine={timeLine} />
          ) : (
            <AmbassadorBonus bonus={bonus} timeLine={timeLine} />
          )}
          <Modal
            open={isOpen}
            onClose={closeModal}
            center
            styles={{ modal: { textAlign: 'center' } }}
          >
            {isOpen && !pendingPostMessage && (
              <>
                <div>
                  <div>
                    <p>{t('campaign_section:text_modal')}</p>
                    <span>
                      <b>{t('campaign_section:warning_text')}</b>
                    </span>
                  </div>
                  <div>
                    <p>{t('campaign_section:select_social_network')}</p>
                    <div>
                      <SocialButtton
                        className={selectedIcon === 'icon1' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon1', 'facebook')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="facebook"
                          color={Colors.facebook}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon2' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon2', 'twitter')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="twitter"
                          color={Colors.twitter}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon3' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon3', 'linkedin')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="linkedin"
                          color={Colors.linkedin}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon4' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon4', 'instagram')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="instagram"
                          color={Colors.instagram}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon5' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon5', 'tiktok')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="tiktok"
                          color={Colors.tiktok}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon6' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon6', 'snapchat')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="snapchat"
                          color={Colors.snapchat}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon7' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon7', 'youtube')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="youtube"
                          color={Colors.youtube}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon8' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon8', 'threads')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="threads"
                          color={Colors.threads}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon9' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon9', 'otros')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="otros"
                          color={Colors.otros}
                        />
                      </SocialButtton>
                    </div>
                  </div>
                  <p>Post URL:</p>
                  <input
                    id="url-input"
                    type="url"
                    className="bonus-box"
                    placeholder={t('campaign_section:post_url')}
                  />
                </div>
                {errorMessage && (
                  <p
                    className="error-message-challenge"
                    style={{ color: 'red', fontWeight: 'bold' }}
                  >
                    {t('campaign_section:error_message')}
                  </p>
                )}
                <Button
                  value={t('campaign_section:send')}
                  variant="tertiary"
                  onClick={onPublish}
                  loader={loader}
                />
              </>
            )}
            {pendingPostMessage && (
              <PostDetailWrapper>
                <p className="small-paragraph">
                  {t('campaign_section:created_bonus')}
                </p>
                <Button
                  value={t('campaign_section:participate_again')}
                  variant="success"
                  as="a"
                  size="medium"
                  target="_blank"
                  onClick={() => {
                    setIsOpen(true);
                    setPendingPostMessage(false);
                    setSelectedIcon(null);
                    setSocialNetwork(null);
                  }}
                />
              </PostDetailWrapper>
            )}
          </Modal>
        </SectionWrapper>
      </Suspense>
    </>
  );
};

export default Bonus;
