import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import Input from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/buttons/Button';
import TextArea from 'components/inputs/TextArea';
import UploadFileImageSimple from 'components/inputs/UploadFile/UploadFileImageSimple';
import {
  SectionForm,
  InputWrapper,
  FileSection,
  FileWrapper,
  FlexWrapper,
  ButtonStyled
} from '../../Styled';

import { CompanyData } from 'services/rest/SuperAdmin/Company/createCompany';
import { arrayOrderForSelect } from 'helpers/arrayOrderForSelect';
import Spinner from 'components/Spinner/Spinner';

export interface CreateCompanyFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<CompanyData>;
  register: UseFormMethods['register'];
  setValue: any;
  control: any;
  loader: boolean;
  countries: any;
  currencies: any;
  selectCountry: any;
  setSelectCountry: any;
  selectCurrency: any;
  setSelectCurrency: any;
  selectLanguage: any;
  setSelectLanguage: any;
  handleImage: any;
}

const CreateCompanyForm: React.FC<CreateCompanyFormProps> = props => {
  const {
    onSubmit,
    errors,
    register,
    setValue,
    loader,
    countries,
    currencies,
    selectCountry,
    setSelectCountry,
    selectCurrency,
    setSelectCurrency,
    selectLanguage,
    setSelectLanguage,
    handleImage
  } = props;
  const { t } = useTranslation(['super_admin_company']);
  const languages = [
    { text: t('super_admin_company:form:spanish'), value: 'es' },
    { text: t('super_admin_company:form:english'), value: 'en' },
    { text: t('super_admin_company:form:portuguese'), value: 'pt' }
  ];
  return (
    <SectionWrapper>
      <NavigationLink
        text={t('super_admin_company:form:back')}
        direction="back"
        to={'/companies'}
      />
      <FlexWrapper>
        <h1>{t('super_admin_company:create_company')}</h1>
      </FlexWrapper>
      <form onSubmit={onSubmit}>
        <SectionForm>
          <InputWrapper>
            <label>{t('super_admin_company:form:name')}</label>
            <div className="w-100">
              <Input
                name="name"
                type="text"
                placeholder={t('super_admin_company:form:name')}
                ref={register}
                error={errors.name?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_company:form:subdomain')}</label>
            <div className="w-100">
              <Input
                name="subdomain"
                type="text"
                placeholder={t('super_admin_company:form:subdomain')}
                ref={register}
                error={errors.subdomain?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_company:form:language')}</label>
            <div className="w-100">
              <Select
                options={languages}
                value={selectLanguage}
                name="language"
                placeholder={t('super_admin_company:form:select_an_option')}
                register={register}
                error={errors.language?.type}
                onChange={e => {
                  setSelectLanguage(e.target.value);
                }}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_company:form:country')}</label>
            <div className="w-100">
              <Select
                options={arrayOrderForSelect(countries)}
                value={selectCountry}
                name="country"
                placeholder={t('super_admin_company:form:select_an_option')}
                register={register}
                error={errors.country?.type}
                onChange={e => {
                  setSelectCountry(e.target.value);
                }}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_company:form:currency')}</label>
            <div className="w-100">
              <Select
                options={arrayOrderForSelect(currencies)}
                value={selectCurrency}
                name="currency"
                placeholder={t('super_admin_company:form:select_an_option')}
                register={register}
                error={errors.currency?.type}
                onChange={e => {
                  setSelectCurrency(e.target.value);
                }}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_company:form:ambassadors_limit')}</label>
            <div className="w-100">
              <Input
                name="ambassadorsLimit"
                type="number"
                placeholder={t('super_admin_company:form:ambassadors_limit')}
                ref={register}
                error={errors.ambassadorsLimit?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_company:form:license_date')}</label>
            <div className="w-100">
              <Input
                name="licenseDate"
                type="date"
                ref={register}
                error={errors.licenseDate?.message}
              />
            </div>
          </InputWrapper>
          <FileSection>
            <InputWrapper className="inputFile">
              <label>{t('super_admin_company:form:backgroundImage')}</label>
              <FileWrapper>
                <UploadFileImageSimple
                  name="backgroundImage"
                  handleImage={handleImage}
                  className="socialNetworkFile"
                  content="backgroundImage"
                  maxFileSize={1000}
                  infoBox={t('super_admin_company:form:info_box_file')}
                  allowedFileTypes="image/jpeg"
                />
              </FileWrapper>
            </InputWrapper>
            <InputWrapper className="inputFile">
              <label>{t('super_admin_company:form:profileLogo')}</label>
              <FileWrapper>
                <UploadFileImageSimple
                  name="logo"
                  handleImage={handleImage}
                  className="socialNetworkFile"
                  content="logo"
                  maxFileSize={1000}
                  infoBox={t('super_admin_company:form:info_box_file')}
                  allowedFileTypes="image/svg+xml"
                />
              </FileWrapper>
            </InputWrapper>
            <InputWrapper className="inputFile">
              <label>{t('super_admin_company:form:emailLogo')}</label>
              <FileWrapper>
                <UploadFileImageSimple
                  name="emailLogo"
                  handleImage={handleImage}
                  className="socialNetworkFile"
                  content="emailLogo"
                  maxFileSize={1000}
                  infoBox={t('super_admin_company:form:info_box_file')}
                  allowedFileTypes="image/png"
                />
              </FileWrapper>
            </InputWrapper>
            <InputWrapper className="inputFile">
              <label>{t('super_admin_company:form:tosUrl')}</label>
              <FileWrapper>
                <UploadFileImageSimple
                  name="tosUrl"
                  handleImage={handleImage}
                  className="socialNetworkFile"
                  content="tosUrl"
                  maxFileSize={1000}
                  infoBox={t('super_admin_company:form:info_box_file')}
                  allowedFileTypes="application/pdf"
                />
              </FileWrapper>
            </InputWrapper>
          </FileSection>
        </SectionForm>
        <ButtonStyled>
          {!loader ? (
            <Button
              value={t('super_admin_company:create_company')}
              variant="primary"
            />
          ) : (
            <Spinner name="sign-up" />
          )}
        </ButtonStyled>
      </form>
    </SectionWrapper>
  );
};

export default CreateCompanyForm;
