import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { List as LoadingState } from 'react-content-loader';
import { useLocation } from 'react-router-dom';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { StyleTable } from '../Styled';
import { getCompanies } from 'services/rest/SuperAdmin/Company/getCompanies';
import { selectUser } from 'redux/slices/userSlice';
import { SuccessAlert } from 'components/Alert';
import CompaniesTable from './CompaniesTable';
import { Box, Tab, Tabs, Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Companies = () => {
  const { t } = useTranslation(['super_admin_company']);
  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<any | null>(null);
  const [changeCompany, setChangeCompany] = useState<boolean | null>(false);
  const [valueTab, setValueTab] = useState(0);

  const location = useLocation();
  const { state } = location as any;
  const [backendSuccess, setBackendSuccess] = useState<any | null>(null);

  const showAlertSuccessCompanyCreated = async () => {
    if (typeof state === 'string') {
      setBackendSuccess(state);
    }
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const getData = async () => {
    const { data } = await getCompanies(currentUser?.token, 500, 1);
    setCompanies(data);
    showAlertSuccessCompanyCreated();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [changeCompany]);

  const activatedCompanies = companies?.filter(
    (company: any) => !company.deleted
  );
  const deactivatedCompanies = companies?.filter(
    (company: any) => company.deleted
  );

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}
      <Box sx={{ borderColor: 'divider' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} centered>
          <Tab label={t('super_admin_company:activated')} {...a11yProps(0)} />
          <Tab label={t('super_admin_company:deactivated')} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <TabPanel value={valueTab} index={0}>
          <SectionWrapper>
            <StyleTable>
              <CompaniesTable
                companies={activatedCompanies}
                changeCompany={changeCompany}
                setChangeCompany={setChangeCompany}
                setLoading={setLoading}
              />
            </StyleTable>
          </SectionWrapper>
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <SectionWrapper>
            <StyleTable>
              <CompaniesTable
                companies={deactivatedCompanies}
                changeCompany={changeCompany}
                setChangeCompany={setChangeCompany}
                setLoading={setLoading}
              />
            </StyleTable>
          </SectionWrapper>
        </TabPanel>
        <TabPanel value={valueTab} index={1}></TabPanel>
      </Suspense>
    </>
  );
};

export default Companies;
