import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  extractHashtags,
  extractMentions,
  extractUrls
} from 'components/inputs/EditorInput/strategies';

import FieldError from 'components/inputs/FieldError/Styled';

export interface ContentValidatorProps {
  originalContent: string;
  content: string;
}

const areArraysEqual = (newList: Array<string>, oldList: Array<string>) => {
  return oldList.every(item => newList.indexOf(item) >= 0);
};

const validateHashtags = (newContent: string, originalContent: string) => {
  const newHashtags = extractHashtags(newContent);
  const oldHashtags = extractHashtags(originalContent);

  return areArraysEqual(newHashtags, oldHashtags);
};

const validateMentions = (newContent: string, originalContent: string) => {
  const newMentions = extractMentions(newContent);
  const oldMentions = extractMentions(originalContent);

  return areArraysEqual(newMentions, oldMentions);
};

const validateUrls = (newContent: string, originalContent: string) => {
  const newUrls = extractUrls(newContent);
  const oldUrls = extractUrls(originalContent);

  return areArraysEqual(newUrls, oldUrls);
};

const getContentSchema = (
  originalContent: string,
  errorMessage: string,
  requiredFieldMessage: string
) => yup.string().required(requiredFieldMessage);
// .test('valid-hashtags', errorMessage, value =>
//   validateHashtags(value as string, originalContent)
// )
// .test('valid-mentions', errorMessage, value =>
//   validateMentions(value as string, originalContent)
// )
// .test('valid-urls', errorMessage, value =>
//   validateUrls(value as string, originalContent)
// );

const ContentValidator: React.FC<ContentValidatorProps> = props => {
  let schemaErrors: Array<yup.ValidationError> = [];
  const { originalContent, content } = props;

  const { t } = useTranslation(['content_editor']);

  const schemaValidator = useMemo(
    () =>
      getContentSchema(
        originalContent,
        t('content_error'),
        t('required_field')
      ),
    [originalContent]
  );

  try {
    schemaValidator.validateSync(content);
  } catch ({ errors }) {
    schemaErrors = errors;
  }

  if (schemaErrors.length === 0) return null;

  const errorMessage = schemaErrors.join(',');

  return <FieldError>{errorMessage}</FieldError>;
};

export default ContentValidator;
