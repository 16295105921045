import React from 'react';

import {
  ContentVerticalBox,
  HeaderVerticalBox,
  NetworkContent,
  BodyVerticalBox
} from './StyledVerticalBox';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';

export interface VerticalBoxProps {
  header?: JSX.Element;
  networks?: Array<string>;
  disabled?: boolean;
  className?: string;
}

const VerticalBox: React.FC<VerticalBoxProps> = props => {
  const { header, networks, disabled, children, className } = props;

  const filter = networks?.filter(network => network != '');

  return (
    <ContentVerticalBox disabled={disabled} className={className}>
      <HeaderVerticalBox>
        {header}
        {networks && (
          <NetworkContent>
            {filter?.map((network, index) => (
              <SocialMediaIcon
                name={network}
                size="medium"
                variant="ligth"
                key={index}
              />
            ))}
          </NetworkContent>
        )}
      </HeaderVerticalBox>
      <BodyVerticalBox>{children}</BodyVerticalBox>
    </ContentVerticalBox>
  );
};

export default VerticalBox;
