import Client from 'config/axios';

export interface SuggestedPostData {
  name: string;
  description: string;
  content: string;
  observationContent: string;
  multimedia: any;
  ambassadorId: number;
  companyId: number;
  startDate: string;
  finishDate: string;
  includesFacebook: boolean;
  includesTwitter: boolean;
  includesLinkedin: boolean;
  includesInstagram: boolean;
  includesTiktok: boolean;
  includesWhatsapp: boolean;
  contentFacebook: string;
  contentTwitter: string;
  contentLinkedin: string;
  contentInstagram: string;
  contentTiktok: string;
  contentWhatsapp: string;
  contentPerSocialNetwork: {
    facebook: {
      content: string;
      type: 'text';
    };
    twitter: {
      content: string;
      type: string;
      picturesUrls: Array<string>;
    };
    linkedin: {
      content: string;
      type: string;
      picturesUrls: Array<string>;
    };
    instagram: {
      content: string;
      type: string;
      picturesUrls: Array<string>;
    };
    tiktok: {
      content: string;
      type: string;
      picturesUrls: Array<string>;
    };
    whatsapp: {
      content: string;
      type: string;
      picturesUrls: Array<string>;
    };
  };
  status: string;
}

export const createSuggestedPost = async (
  token: string,
  suggestedPost: SuggestedPostData,
  twitterURL: any,
  linkedinURL: any,
  instagramURL: any,
  tiktokURL: any,
  whatsappURL: any,
  type: any
) => {
  const objectSocialNetworks = async (
    content: string,
    typeUpload: string,
    urlFile: any
  ) => {
    let objectSN;
    switch (typeUpload) {
      case 'image':
        objectSN = {
          content: content,
          type: typeUpload,
          picturesUrls: urlFile,
          hashtags: false,
          bitly: []
        };
        break;
      case 'video':
        objectSN = {
          content: content,
          type: typeUpload,
          videoUrl: urlFile
        };
        break;
      case 'pdf':
        objectSN = {
          content: content,
          type: typeUpload,
          pdfUrl: urlFile
        };
        break;
      default:
        objectSN = {
          content: content,
          type: typeUpload
        };
    }
    return objectSN;
  };

  const body = {
    name: suggestedPost.name,
    description: suggestedPost.description,
    includesFacebook: suggestedPost.includesFacebook,
    includesTwitter: suggestedPost.includesTwitter,
    includesLinkedin: suggestedPost.includesLinkedin,
    includesInstagram: suggestedPost.includesInstagram,
    includesTiktok: suggestedPost.includesTiktok,
    includesWhatsapp: suggestedPost.includesWhatsapp,
    companyId: suggestedPost.companyId,
    ambassadorId: suggestedPost.ambassadorId,
    status: suggestedPost.status,
    contentPerSocialNetwork: {
      facebook: {
        content: suggestedPost.contentFacebook,
        type: 'text'
      },
      twitter: await objectSocialNetworks(
        suggestedPost.contentTwitter,
        twitterURL && twitterURL?.url?.length > 0 ? twitterURL?.type : 'text',
        twitterURL?.url
      ),
      linkedin: await objectSocialNetworks(
        suggestedPost.contentLinkedin,
        linkedinURL && linkedinURL.url?.length > 0 ? linkedinURL?.type : 'text',
        linkedinURL?.url
      ),
      instagram: await objectSocialNetworks(
        suggestedPost.contentInstagram,
        instagramURL?.type,
        instagramURL?.url
      ),
      tiktok: await objectSocialNetworks(
        suggestedPost.contentTiktok,
        tiktokURL?.type,
        tiktokURL?.url
      ),
      whatsapp: await objectSocialNetworks(
        suggestedPost.contentWhatsapp,
        whatsappURL?.type,
        whatsappURL?.url
      )
    }
  };
  try {
    const { data } = await Client.post('suggested_post/create', body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.data };
  }
};
