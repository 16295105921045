import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods, FieldErrors } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';

import { ChangePasswordData } from 'services/rest/authentication';

import {
  StyledForm,
  PoweredByWrapper,
  NoAccountSection,
  ResetText
} from './Styled';
import { InputWrapper } from 'components/inputs/VerticalWrapper';
import Button from 'components/buttons/Button';
import { BrowsersFooter } from 'components/Footer';
import AdvocatesProLogo from 'images/logos/advocates_logo.svg';
import { RequiredString } from 'helpers/yupHelper';
import ThreeDots from 'components/loaders/ThreeDots';
import ReactPasswordChecklist from 'react-password-checklist';
import { useSelector } from 'react-redux';
import { selectCompanyStyle } from 'redux/slices/CompanyStyle/CompanyStyleSlice';

export interface ChangePasswordFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<ChangePasswordData>;
  register: UseFormMethods['register'];
  loader: any;
  showPassword?: boolean;
  onClickEye?: any;
}

export const userSchema = yup.object().shape({
  password: RequiredString()
    .min(5, 'login_form.password_min_length')
    .max(40, 'login_form.password_max_length'),
  confirmPassword: RequiredString()
    .min(5, 'login_form.password_min_length')
    .max(40, 'login_form.password_max_length')
});

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = props => {
  const {
    errors,
    onSubmit,
    register,
    loader,
    onClickEye,
    showPassword
  } = props;
  const { t } = useTranslation(['change_password_form', 'register_form']);
  const { companyStyle, loading } = useSelector(selectCompanyStyle);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);

  return (
    <>
      <NavLink to="/" className="logo">
        {loading ? (
          <ThreeDots />
        ) : (
          <>
            <img
              className="hide-on-mobile"
              src={companyStyle?.emailLogo}
              alt="Home"
            />
            <img
              className="show-on-mobile mobile-logo"
              src={companyStyle?.emailLogo}
              alt="Home"
            />
          </>
        )}
      </NavLink>

      <ResetText>
        <p>{t('change_password_form:reset_password')}</p>
        <span>{t('change_password_form:description_password')}</span>
      </ResetText>

      <StyledForm onSubmit={onSubmit}>
        <InputWrapper
          label={t('change_password_form:password')}
          name="password"
          type="password"
          ref={register}
          error={errors.password?.message}
          data-testid="password"
          onChange={e => setPassword(e.target.value)}
          onClickEye={onClickEye}
          showPassword={showPassword}
        />

        <InputWrapper
          label={t('change_password_form:confirm_password')}
          name="confirmPassword"
          type="password"
          ref={register}
          error={errors.confirmPassword?.message}
          data-testid="confirmPassword"
          className="confirm-password"
          onChange={e => setConfirmPassword(e.target.value)}
          onClickEye={onClickEye}
          showPassword={showPassword}
        />

        <ReactPasswordChecklist
          rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
          minLength={8}
          value={password}
          valueAgain={confirmPassword}
          messages={{
            minLength: 'La contraseña tiene más de 8 caracteres.',
            specialChar: 'La contraseña tiene caracteres especiales.',
            number: 'La contraseña tiene un número.',
            capital: 'La contraseña tiene una letra mayúscula.',
            match: 'Las contraseñas coinciden.'
          }}
          onChange={isValid => setValidPassword(isValid)}
        />
        <Button
          value={t('change_password_form:change_password')}
          variant="primary"
          onClick={() => onSubmit}
          loader={loader}
          disabled={!validPassword}
        />
      </StyledForm>

      <NoAccountSection>
        <p>{t('change_password_form:have_account')}</p>
        <NavLink to="/sign_in" className="register">
          {t('change_password_form:sign_in')}
        </NavLink>
      </NoAccountSection>

      <PoweredByWrapper>
        <p>Powered By</p>
        <img src={AdvocatesProLogo} alt="AdvocatesPro" />
      </PoweredByWrapper>

      <BrowsersFooter />
    </>
  );
};

export default ChangePasswordForm;
