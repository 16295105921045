import Client from 'config/axios';
import camelcaseKeys from 'camelcase-keys';

export const LogoutRequest = async () => {
  const { data } = await Client.delete('/auth/sign_out');
  return { data };
};

export const Logout = async () => {
  try {
    const { data } = await LogoutRequest();
    localStorage.removeItem('ironmanAuth');

    return { data: camelcaseKeys(data) };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data.errors };
  }
};

export const getInfoUser = async (id: number, token: string) => {
  try {
    const path = `ambassador/${id}`;
    const { data } = await Client.get(path, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data.data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result?.data?.errors };
  }
};

export const getInfoAdmin = async (id: number, token: string) => {
  try {
    const path = `administrator/${id}`;
    const { data } = await Client.get(path, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return { data: data.data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data.errors };
  }
};
