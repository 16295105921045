import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import cx from 'classnames';
import { Colors, Breakpoints } from 'styles/Constants';

import Svg from 'images/icons/icons.svg';

export interface IconProps {
  name: string;
  className?: string;
  color?: string;
  hoverColor?: string;
  size?: string;
  value?: string;
  onClick?: () => void;
}

const small = css`
  height: 20px;
  width: 20px;
`;

const medium = css`
  height: 30px;
  width: 30px;
`;

const big = css`
  height: 35px;
  width: 35px;
`;

const Sizes: Record<string, FlattenSimpleInterpolation> = {
  small,
  medium,
  big
};

const StyledSvg = styled.svg<IconProps>`
  ${({ size }) => Sizes[size as string]};
  ${({ size }) =>
    size === 'big' &&
    css`
      @media (max-width: ${Breakpoints.medium}) {
        height: 22px;
        width: 22px;
      }
    `}

  ${({ hoverColor }) =>
    hoverColor &&
    css`
      &:hover {
        fill: ${hoverColor};
      }
    `}
`;

const Icon: React.FC<IconProps> = props => {
  const {
    className,
    name,
    color = Colors.primaryDarkBlue,
    size = 'medium',
    value,
    ...otherProps
  } = props;

  return (
    <StyledSvg
      className={cx('icon', className)}
      name={name}
      fill={color}
      size={size}
      {...otherProps}
    >
      <use xlinkHref={`${Svg}#icon-${name}`} />
      {value}
    </StyledSvg>
  );
};

export default Icon;
