import React, { InputHTMLAttributes, DetailedHTMLProps } from 'react';
import cx from 'classnames';
import eye from 'images/icons/eyes.svg';
import eyeStrikethrough from 'images/icons/eyes_strikethrough.svg';

import StyledInput from './StyledInput';
import FieldError from 'components/inputs/FieldError';

export type DefaultProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  HTMLInputElement;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string;
  error?: string;
  onClickEye?: any;
  showPassword?: boolean;
}

const Input = React.forwardRef<DefaultProps, InputProps>(
  ({ type, error, ...otherProps }, ref) => (
    <>
      <StyledInput
        ref={ref}
        type={otherProps.showPassword ? 'text' : type}
        className={cx({ 'field-error': Boolean(error) })}
        {...otherProps}
      />
      {otherProps.onClickEye && (
        <div
          className="custom-eye"
          style={{
            float: 'right',
            width: otherProps.width,
            right: 5,
            position: 'relative',
            bottom: '45%',
            height: 0
          }}
        >
          <img
            alt="icon"
            width={15}
            height={22}
            onClick={otherProps.onClickEye}
            src={otherProps.showPassword ? eyeStrikethrough : eye}
          ></img>
        </div>
      )}

      {error && <FieldError text={error} />}
    </>
  )
);

Input.defaultProps = {
  type: 'text'
};

Input.displayName = 'Input';

export default Input;
