import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { createCurrency } from 'services/rest/SuperAdmin/Currency/createCurrency';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { RequiredString, String } from 'helpers/yupHelper';
import CreateCurrencyForm from './CreateCurrencyForm';
import { max } from 'date-fns';
import { useHistory } from 'react-router-dom';

const CreateCurrency: React.FC = () => {
  const { t } = useTranslation(['super_admin_currency', 'validations']);
  const userSchema = yup.object().shape({
    name: RequiredString().max(50, t('validations:length_error', { max: 50 })),
    code: RequiredString().max(3, t('validations:length_error', { max: 3 })),
    sign: RequiredString().max(6, t('validations:length_error', { max: 6 }))
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(userSchema)
  });

  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const [loader, setLoader] = useState(false);
  const onSubmit = async (CurrencyData: any) => {
    setLoader(true);
    const data = await createCurrency(currentUser?.token, CurrencyData);
    if (data) {
      setLoader(false);
      history.push('/currencies');
    }
  };
  return (
    <>
      <CreateCurrencyForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setValue={setValue}
        control={control}
        loader={loader}
      />
    </>
  );
};

export default CreateCurrency;
