import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import ConnectedEmployeeCard from './EmployeeCard';
import CampaignsSection from './CampaignsSection';
import PerformanceSection from './PerformanceSection';
import AwardsSection from './AwardsSection';
import RankingSection from './RankingSection';
import Challenges from '../ChallengesPage';

import { ErrorAlert } from 'components/Alert';

import useAdmin from 'hooks/useIsAdmin.hook';

import DashboardAdminPage from './DashboardAdminPage';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import { selectUser } from 'redux/slices/userSlice';
import { getInfoUser } from 'services/rest/User/getInfoUser';
import { useFetch } from 'hooks/useFetch.hook';

const DashboardPage = () => {
  const { t } = useTranslation(['employee_dashboard']);
  const location = useLocation();
  const { state } = location as any;
  const [backendError, setBackendError] = useState<any>(
    state?.from === 'admin'
  );
  const { company } = useSelector(selectCompany);
  const { currentUser } = useSelector(selectUser);

  if (useAdmin()) {
    return <DashboardAdminPage />;
  }
  const { data } = useFetch(
    getInfoUser(currentUser?.user?.id, currentUser?.token, currentUser?.role)
  );
  const userData = data as any;

  return (
    <>
      {backendError && (
        <ErrorAlert
          text={t('employee_dashboard:not_permissions')}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}
      <ConnectedEmployeeCard />
      <CampaignsSection />
      {userData?.allowBonus && company?.company_permission?.allowShowBonus && (
        <Challenges />
      )}
      <SectionWrapper>
        <h1 className="section-title">{t('employee_dashboard:profile')}</h1>
        <PerformanceSection />
      </SectionWrapper>
      {company?.company_permission?.allowShowAwards && <AwardsSection />}
      {company?.company_permission?.allowShowRanking && (
        <SectionWrapper className="blue-wrapper">
          <h1 className="section-title">{t('employee_dashboard:ranking')}</h1>
          <RankingSection />
        </SectionWrapper>
      )}
    </>
  );
};

export default DashboardPage;
