import Client from 'config/axios';

export const getCampaignById = async (
  token: string,
  id: any,
  allParticipants: string
) => {
  const { data } = await Client.get(
    `campaign/${id}?allParticipants=${allParticipants}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return {
    data
  };
};
