import styled from 'styled-components';

export const ImageCropperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(10, 10, 10, 0.95);
  flex-direction: column;
  padding-top: 60px;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;

  .open-cropper {
    width: 80%;
    height: 80%;
  }

  .button-margin {
    margin: auto;
  }
`;
