import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UpdateCurrency from 'pages/SuperAdmin/Currencies/UpdateCurrency';
import CreateCurrency from 'pages/SuperAdmin/Currencies/CreateCurrency';

const SuperAdminCurrencyRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/new`}>
        <CreateCurrency />
      </Route>
      <Route exact path={`${match.path}/updateCurrency/:currencyId`}>
        <UpdateCurrency />
      </Route>
    </Switch>
  );
};

export default SuperAdminCurrencyRouter;
