import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { List as LoadingState } from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Input from 'components/inputs/Input';
import Icon from 'components/icons/Icon';
import Button from 'components/buttons/Button';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { Filter, StyleTable, HeaderSection, ButtonSection } from '../Styled';
import iconTrash from 'images/icons/trash.png';
import { useFetch } from 'hooks/useFetch.hook';
import { getCountries } from 'services/rest/SuperAdmin/Country/getCountries';
import { selectUser } from 'redux/slices/userSlice';

const Countries = () => {
  const { t } = useTranslation(['super_admin_country']);
  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const [loader, setLoader] = useState(false);
  const { data } = useFetch(getCountries(currentUser?.token));
  const countries = data as any;

  const countryDelete = async (countryId: number) => {
    setLoader(true);
  };
  const go = (id: number) => {
    history.replace(`/country/UpdateCountry/${id}`);
  };
  return (
    <SectionWrapper>
      <StyleTable>
        <HeaderSection>
          <Filter>
            <Input
              type="search"
              placeholder={t('super_admin_country:search')}
            />
          </Filter>
          <ButtonSection>
            <Button
              value={t('super_admin_country:create_country')}
              variant="primary"
              onClick={() => history.replace({ pathname: `/country/new` })}
            />
          </ButtonSection>
        </HeaderSection>
        <Suspense fallback={<LoadingState height="230px" width="100%" />}>
          <TableContainer component={Paper} className="table_container">
            <Table
              sx={{ minWidth: 750 }}
              size="medium"
              aria-label="a dense table"
              className="table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="column">
                    {t('super_admin_country:id')}
                  </TableCell>
                  <TableCell align="center" className="column">
                    {t('super_admin_country:name')}
                  </TableCell>
                  <TableCell align="center" className="column">
                    {t('super_admin_country:code')}
                  </TableCell>
                  <TableCell align="center" className="column">
                    {t('super_admin_country:defaultTimeZone')}
                  </TableCell>
                  <TableCell align="center" className="column">
                    {t('super_admin_country:dialCode')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {countries?.map((country: any) => {
                  return (
                    <TableRow
                      key={country.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                      className="column-body"
                    >
                      <TableCell align="center" component="th" scope="row">
                        {country.id}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {country.name}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {country.code}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {country.defaultTimeZone}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {country.dialCode}
                      </TableCell>
                      <TableCell align="center">
                        <Icon
                          name="edit"
                          size="small"
                          onClick={() => go(country.id)}
                        />
                        <img
                          alt="iconTrash"
                          src={iconTrash}
                          onClick={() => countryDelete(country.id)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Suspense>
      </StyleTable>
    </SectionWrapper>
  );
};

export default Countries;
