import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectUser } from 'redux/slices/userSlice';

import {
  Permissions,
  changePermissions
} from 'services/rest/Company/changePermissions';
import { getCompanyDataById } from 'services/rest/Company/GetCompanyData';

import ChangePermissionsForm from './ChangePermissionsForm';

import { ErrorAlert, SuccessAlert } from 'components/Alert';

const ChangePermissions = () => {
  const { t } = useTranslation('change_permissions');
  const { currentUser } = useSelector(selectUser);
  const { companyId, token } = currentUser;
  const [loading, setLoading] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<Permissions>();
  const { register, handleSubmit } = useForm<Permissions>();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const getCompanyPermission = async () => {
    const data = await getCompanyDataById(token, companyId);
    if (data) {
      setPermissions(data?.company_permission);
      setLoading(false);
    }
  };

  const onSubmit = async (permissionsData: Permissions) => {
    setLoader(true);
    permissionsData.id = permissions?.id as number;
    permissionsData.companyId = permissions?.companyId as number;
    const { data, errors } = await changePermissions(token, permissionsData);
    if (data) {
      setSuccess(t('success'));
      getCompanyPermission();
    }
    if (errors) {
      setError(errors?.data);
    }
    setLoader(false);
  };

  useEffect(() => {
    getCompanyPermission();
  }, []);

  return (
    <>
      {error && (
        <ErrorAlert
          text={error}
          allowClose={true}
          onClose={() => setError('')}
        />
      )}
      {success && (
        <SuccessAlert
          text={success}
          allowClose={true}
          onClose={() => setSuccess('')}
        />
      )}
      <ChangePermissionsForm
        permissions={permissions}
        setPermissions={setPermissions}
        loading={loading}
        loader={loader}
        register={register}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default ChangePermissions;
