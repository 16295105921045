import Client from 'config/axios';

export interface GroupData {
  name: string;
  companyId: number;
  records: any;
}

export const createGroup = async (token: string, groupData: GroupData) => {
  try {
    const { data } = await Client.post('group', groupData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
