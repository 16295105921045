import Client from 'config/axios';

export const updateGroup = async (
  token: string,
  id: any,
  group: any,
  param?: string
) => {
  const url = param ? `group/${id}?${param}` : `group/${id}`;
  try {
    const { data } = await Client.post(url, group, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
