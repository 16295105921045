import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

export const DescriptionHeader = styled.div`
  h1 {
    color: ${Colors.primaryDarkBlue};
    font-weight: bold;
    font-size: 25px;

    @media (min-width: ${Breakpoints.medium}) {
      font-size: 3rem;
    }
  }

  span.dates {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 15px;
    color: #bd10e0;
    display: flex;

    svg {
      margin-right: 10px;
      fill: #bd10e0;
    }

    @media (min-width: ${Breakpoints.medium}) {
      font-size: 18px;
    }
  }
  span.challenge-detail-dates {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 15px;
    color: #05559c;
    display: flex;

    svg {
      margin-right: 10px;
      fill: #05559c;
    }

    @media (min-width: ${Breakpoints.medium}) {
      font-size: 18px;
    }
  }
  p.rules {
    font-weight: bold;
  }
  .challenge-detail-name {
    color: #d112fe;
  }
`;

export const TotalPointsCircle = styled.div`
  background-image: linear-gradient(209deg, #3e65fe, #3ebbfe);
  font-size: 1.125rem;
  border-radius: 100%;
  text-align: center;
  line-height: 60px;
  font-weight: bold;
  display: block;
  height: 60px;
  margin: 10px;
  width: 60px;
  color: #fff;

  @media (min-width: ${Breakpoints.medium}) {
    line-height: 100px;
    font-size: 3rem;
    height: 100px;
    width: 100px;
  }
`;

export const TotalPoints = styled.div`
  justify-self: center;

  @media (max-width: ${Breakpoints.medium}) {
    justify-self: left;
    align-items: center;
    display: flex;
  }

  h3 {
    color: ${Colors.primaryDarkBlue};
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    width: 120px;
    margin: 0;
  }
`;
