import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, MarginImg } from './StyledCampaignsSection';
import VerticalBox from 'components/boxes/VerticalBox';
import Tag from 'components/Tag/Tag';
import Button from 'components/buttons/Button';
import WithoutImage from 'images/backgrounds/campaign_without_image.png';
import { useMonthAndDayRange } from 'helpers/dateHelpers';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import { network } from 'Utils/Networks';

const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
  evt.currentTarget.src = WithoutImage;
};

const CallToAction: React.FC<ICampaigns> = props => {
  const { id, status } = props;

  const { t } = useTranslation(['campaign_section']);
  const history = useHistory();

  if (status == 'pending') {
    return (
      <Button
        value={t('campaign_section:coming_soon')}
        disabled={true}
        size="medium"
        variant="primary"
      />
    );
  }

  return (
    <Button
      value={
        status == 'completed'
          ? t('campaign_section:see_results')
          : t('campaign_section:participate')
      }
      size="medium"
      variant="primary"
      onClick={() => history.replace({ pathname: `/campaigns/${id}` })}
    />
  );
};

const CampaignCard: React.FC<ICampaigns> = props => {
  const {
    photo,
    startDate,
    status,
    finishDate,
    name,
    description,
    includesFacebook,
    includesLinkedin,
    includesTwitter,
    includesInstagram,
    includesTiktok,
    includesWhatsapp
  } = props;

  const findNetworks = network(
    includesFacebook,
    includesTwitter,
    includesLinkedin,
    includesInstagram,
    includesTiktok,
    includesWhatsapp
  );

  const dateRange = useMonthAndDayRange(startDate, finishDate);

  return (
    <Card>
      <VerticalBox
        header={
          <div>
            <Tag value={dateRange} variant="normal" />
            <MarginImg>
              <img
                alt="userPhoto"
                onError={evt => addDefaultPicture(evt)}
                src={photo || WithoutImage}
              />
            </MarginImg>
          </div>
        }
        networks={findNetworks}
        disabled={status == 'pending'}
        className="campaign-card"
      >
        <h2 className={name.length > 50 ? 'overflow-elipsis' : ''}>{name}</h2>
        <p>{description}</p>
        <CallToAction {...props} />
      </VerticalBox>
    </Card>
  );
};

export default CampaignCard;
