import styled from 'styled-components';
import { Colors } from 'styles/Constants';

const StyledNetworkParticipationBox = styled.div`
  h3 {
    width: auto;
    display: block;
    margin: 0 auto;
    color: #333333;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-family: inherit;
    margin-bottom: 15px;
  }
`;

export const StyledButtonCollection = styled.div`
  display: flex;
  justify-content: center;

  button {
    display: inline-block;
    text-align: center;
    margin-right: 5px;
  }
  &.button-collection {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 5px;
    .instagram {
      background-color: ${Colors.instagram};
    }
    .facebook {
      background-color: ${Colors.facebook};
    }
    .linkedin {
      background-color: ${Colors.linkedin};
    }
    .twitter {
      background-color: ${Colors.twitter};
    }
    > div {
      text-align: center !important;
      grid-column: span 6;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1px;
    }
  }
`;

export default StyledNetworkParticipationBox;
