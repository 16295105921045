import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';
export const Filter = styled.div`
  display: flex;
  grid-column: span 8 / auto;

  button {
    border-radius: 20px;
    font-size: 0.6875rem;
    padding: 6px 15px;
    height: 35px;
    margin-right: 20px;
  }

  input {
    width: 30%;
    margin-right: 20px;
  }

  span {
    margin-top: 7px;
  }
`;
export const StyleTable = styled.div`
  .column {
    background-color: ${Colors.normalWhite};
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: ${Colors.secondaryNormalGrey};
    padding: 10px 0px;
    border: none;
    font-weight: bold;
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 20px;
    font-size: 1rem;
    color: ${Colors.secondaryNormalGrey};
    margin-bottom: 10px;
    max-width: 100%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .table_container {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .column-body {
    box-shadow: 0 1px 6px 0 rgb(79 79 79 / 38%);
    transition: all 0.5s ease-out 0s;
    background: ${Colors.normalWhite};
    margin-bottom: 20px;
    border-radius: 10px;
    z-index: 2;
    height: 70px;

    &.yellow-bg {
      background-color: #ffffcc;
    }

    &.red-bg {
      background-color: #ffdddd;
    }

    th {
      font-family: 'Montserrat', sans-serif;
      font-size: 1.125rem;
      padding: 16px;
      border-collapse: separate;
      border-spacing: 0 20px;
      font-size: 1rem;
      color: ${Colors.secondaryNormalGrey};
      border-bottom: none;
    }

    img {
      height: 22px;
      width: 22px;
    }

    .pointer {
      cursor: pointer;
    }

    .text-cap {
      text-transform: capitalize;
    }
  }
`;
export const HeaderSection = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
`;
export const ButtonSection = styled.div`
  display: grid;
  justify-content: end;
  grid-column: span 4 / auto;
`;

export const HeardMargin = styled.div`
  display: flex;
  align-items: center;
  .option {
    display: flex;
    margin-right: 10px;
    img {
      margin-top: 15px;
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }
    svg {
      margin-top: 15px;
      margin-right: 10px;
      height: 22px;
      width: 22px;
    }
  }
`;

export const TextStyled = styled.p`
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: ${Colors.secondaryNormalGrey};
`;

export const SectionForm = styled.div`
  @media (max-width: ${Breakpoints.medium}) {
    width: 100%;
  }

  .disabled-field {
    cursor: not-allowed;
    opacity: 0.7;
    select,
    textarea,
    input {
      pointer-events: none;
    }
  }
`;

export const ButtonStyled = styled.div`
  button {
    text-align: center;
    margin: auto;
    margin-top: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
`;

export const InputWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
  margin: 20px;

  .checkbox-parent {
    position: relative;
  }

  .bonus-checkbox {
    display: flex;
    align-items: center;
  }

  .checkbox-child {
    float: left;
  }

  label {
    color: #4d4d4d;
    font-size: 1rem;
    font-weight: 600;
    grid-column: span 2;
  }

  .w-100 {
    grid-column: span 10;
  }

  .dropdown-filter-container {
    grid-column: span 10;
    margin: 0px !important;
  }

  .checkbox {
    display: flex;
    width: 100%;
    margin: 10px 0 0px 10px;
    @media (max-width: ${Breakpoints.medium}) {
      width: 50%;
      margin-top: 0px;
      margin-right: 1px;
    }
  }

  div.dropdown-filter-container {
    margin-top: 15px;
    margin-left: 6%;
    margin-right: 20%;
    @media (max-width: ${Breakpoints.medium}) {
      display: grid;
      width: 100%;
      margin-left: 0%;
    }
  }

  div.dropdown-filter-content__control {
    width: 300px;
    border: 1px solid #979797;
    border-radius: 30px;
    background-color: transparent;
    box-shadow: none;
    height: 30px;

    &:hover,
    &:focus-within {
      border-color: ${Colors.primaryDarkBlue};
      cursor: pointer;
    }
  }

  div.dropdown-filter-content__single-value {
    color: #3e66fe;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
  }

  div.dropdown-filter-content__value-container {
    margin-top: -6px;
  }

  span.dropdown-filter-content__indicator-separator {
    display: none;
  }

  div.dropdown-filter-content__indicator {
    color: rgba(0, 0, 0, 0.54);
  }

  div.dropdown-filter-content__placeholder {
    padding-bottom: 15px;
    font-size: 15px;
    color: ${Colors.inactive};
  }

  div.dropdown-filter-content__menu {
    margin-top: 1px;
    width: 170px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    z-index: 5;
  }

  div.dropdown-filter-content__menu-list {
    padding-top: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  div.dropdown-filter-content__option {
    padding: 6px 8px;
    font-size: 14px;
  }

  div.dropdown-filter-content__option--is-focused {
    background: transparent;
  }

  div.dropdown-filter-content__option:hover,
  div.dropdown-filter-content__option--is-focused:hover,
  div.dropdown-filter-content__option--is-selected {
    background: rgba(0, 0, 0, 0.08);
    font-weight: 500;
    color: #3e66fe;
  }
  .dropdown-filter-content__control--is-disabled {
    opacity: 0.7;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FileWrapper = styled.div`
  grid-column: span 10;
`;

export const FileSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .inputFile {
    display: block;
    text-align: center;
  }
`;
