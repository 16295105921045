import styled, { css } from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

export interface OnboardingWrapperProps {
  brandBackground: string;
  backgroundStyles: string;
  responsiveStyle: string;
}

export const OnboardingWrapper = styled.div<OnboardingWrapperProps>`
${({ brandBackground }) =>
  css`
    background-image: url(${brandBackground});
  `}
  justify-content center;
  background-size: cover;
  flex-direction: column;
  display: flex;

  @media (min-width: ${Breakpoints.medium}) {
    min-height: 100vh;
  }

  @media (min-width: ${Breakpoints.large}) {
    grid-template-columns: 55% 45%;
    justify-items: center;
    align-items: center;
    display: grid;

    ${({ brandBackground }) =>
      css`
        background-image: url(${brandBackground});
      `}

    background-size: cover;
    background-position: 0 0, 90% top, 100% bottom;
    background-attachment: fixed;
    background-repeat: no-repeat;
    ${({ backgroundStyles }) =>
      css`
        ${backgroundStyles};
      `})};
  }
`;

export const OnboardingMobileBackground = styled.div<OnboardingWrapperProps>`
  @media (max-width: ${Breakpoints.medium}) {
    position: fixed;
    width: 100%;
    height: 35vh;

    ${({ brandBackground }) =>
      css`
        background-image: url(${brandBackground});
      `}

    ${({ responsiveStyle }) =>
      css`
        ${responsiveStyle};
      `})};
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: contain;
  }
`;

export const FormWrapper = styled.div`
  background: ${Colors.normalWhite};
  border-radius: 28px;
  padding: 20px 45px;
  margin: 51vw 0 0 0;
  position: relative;
  display: block;
  max-width: 100%;

  &.login-wrapper {
    div.alert {
      bottom: unset;
      padding: 15px;
      top: 5px;
      left: 0;
    }
  }

  @media (min-width: ${Breakpoints.medium}) {
    box-shadow: 0 10px 19px 0 rgba(161, 161, 161, 0.5);
    background-color: #fff;
    border-radius: 6px;
    padding: 40px 80px;
    max-width: 510px;
    margin: 0 auto;
    grid-column: 2;

    &.login-wrapper {
      div.alert {
        position: initial;
        margin-top: -25px;
      }

      max-width: 450px;
      padding: 30px 0;

      > * {
        padding: 0 70px;
      }
    }
  }

  .logo {
    margin: -40px 0 0 0;
    text-align: center;
    display: block;

    @media (min-width: ${Breakpoints.medium}) {
      margin: 0 0 40px 0;
    }

    img {
      max-width: 100%;
      max-height: 100%;

      &.mobile-logo {
        box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.2);
        background: #ffffff;
        border-radius: 50%;
        position: relative;
        margin-top: -12px;
        padding: 15px;
        height: 100px;
        width: 100px;
      }
    }
  }
`;
