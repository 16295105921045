import Client from 'config/axios';

export const getAwardById = async (token: string, id: any) => {
  try {
    const { data } = await Client.get(`award/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
