import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ReportsAdminPage from 'pages/ReportsAdminPage';

const ReportsAdminRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`}>
        <ReportsAdminPage />
      </Route>
    </Switch>
  );
};

export default ReportsAdminRouter;
