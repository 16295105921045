import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import UpdateCountry from 'pages/SuperAdmin/Countries/UpdateCountry';
import CreateCountry from 'pages/SuperAdmin/Countries/CreateCountry';

const SuperAdminCountryRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/new`}>
        <CreateCountry />
      </Route>
      <Route exact path={`${match.path}/updateCountry/:countryId`}>
        <UpdateCountry />
      </Route>
    </Switch>
  );
};

export default SuperAdminCountryRouter;
