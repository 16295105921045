import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { selectUser } from 'redux/slices/userSlice';
import { Company, updateCompany } from 'services/rest/Company/updateCompany';
import { getCompanyDataById } from 'services/rest/Company/GetCompanyData';
import { getCurrencies } from 'services/rest/Currency/getCurrencies';
import { RequiredString } from 'helpers/yupHelper';
import { awsUploadFileHelper } from 'helpers/awsFileHelper';
import { companyFileName } from 'helpers/companyFileName';
import { getUrlS3 } from 'constants/setup.constants';
import EditCompanyForm from './EditCompanyForm';
import { ErrorAlert, SuccessAlert } from 'components/Alert';

const EditCompany = () => {
  const { t } = useTranslation(['update_company', 'validations']);
  const userSchema = yup.object().shape({
    name: RequiredString().max(100, t('validations:length_error', { max: 100 }))
  });
  const { currentUser } = useSelector(selectUser);
  const { companyId, token, role } = currentUser;
  const [loading, setLoading] = useState<boolean>(true);
  const [company, setCompany] = useState<any>();
  const [currency, setCurrency] = useState<any>(company?.currencyId);
  const [language, setLanguage] = useState<any>(company?.language);
  const [loader, setLoader] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm<Company>({
    resolver: yupResolver(userSchema)
  });
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [backgroundImage, setBackgroundImage] = useState<Array<any>>([]);
  const [logoImage, setLogoImage] = useState<Array<any>>([]);
  const [emailLogoImage, setEmailLogoImage] = useState<Array<any>>([]);
  const [tosPdf, setTosPdf] = useState<Array<any>>([]);
  const [fileError, setFileError] = useState<boolean>(false);

  const getCompanyData = async () => {
    const data = await getCompanyDataById(token, companyId);
    if (data) {
      const currency = await getCurrencies(token);
      data.currencies = currency;
      setCompany(data);
      setCurrency(data?.currencyId);
      setLanguage(data?.language);
      setLoading(false);
    }
  };

  const handleImage = (select: Array<any>, content: string) => {
    if (select.length > 0) {
      switch (content) {
        case 'backgroundImage':
          setBackgroundImage(select);
          setFileError(false);
          break;
        case 'logo':
          setLogoImage(select);
          setFileError(false);
          break;
        case 'emailLogo':
          setEmailLogoImage(select);
          setFileError(false);
          break;
        case 'tosUrl':
          setTosPdf(select);
          setFileError(false);
          break;
      }
    } else {
      setFileError(true);
    }
  };

  const onSubmit = async (companyData: Company) => {
    setLoader(true);
    if (fileError) {
      setError(t('validations:file_required'));
      setLoader(false);
      return;
    }
    if (backgroundImage.length > 0) {
      const backgroundName = companyFileName(
        backgroundImage,
        company?.subdomain,
        'background'
      );
      await awsUploadFileHelper(
        currentUser?.token,
        backgroundImage[0],
        'COMPANY',
        backgroundName,
        false,
        setError,
        t('validations:file_error'),
        company?.subdomain
      );
      companyData.backgroundImage = getUrlS3(
        'companies',
        backgroundName,
        company?.subdomain
      );
    }
    if (logoImage.length > 0) {
      await awsUploadFileHelper(
        currentUser?.token,
        logoImage[0],
        'COMPANY',
        `${company?.subdomain}_logo.svg`,
        false,
        setError,
        t('validations:file_error'),
        company?.subdomain
      );
      companyData.logo = getUrlS3(
        'companies',
        `${company?.subdomain}_logo.svg`,
        company?.subdomain
      );
    }
    if (emailLogoImage.length > 0) {
      await awsUploadFileHelper(
        currentUser?.token,
        emailLogoImage[0],
        'COMPANY',
        `${company?.subdomain}_logo.png`,
        false,
        setError,
        t('validations:file_error'),
        company?.subdomain
      );
      companyData.emailLogo = getUrlS3(
        'companies',
        `${company?.subdomain}_logo.png`,
        company?.subdomain
      );
    }
    if (tosPdf.length > 0) {
      await awsUploadFileHelper(
        currentUser?.token,
        tosPdf[0],
        'LEGAL',
        `legal_${company?.subdomain}.pdf`,
        false,
        setError,
        t('validations:file_error'),
        company?.subdomain
      );
      companyData.TosUrl = `legal_${company?.subdomain}.pdf`;
    }
    companyData.id = company?.id;
    companyData.role = role;
    companyData.language = language;
    companyData.currencyId = currency;
    const { data, errors } = await updateCompany(token, companyData);
    if (data) {
      getCompanyData();
      setLoader(false);
      setSuccess(t('success'));
      window.location.reload();
    }
    if (errors) {
      setError(errors?.data);
      setLoader(false);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <>
      {error && (
        <ErrorAlert
          text={error}
          allowClose={true}
          onClose={() => setError('')}
        />
      )}
      {success && (
        <SuccessAlert
          text={success}
          allowClose={true}
          onClose={() => setSuccess('')}
        />
      )}
      <EditCompanyForm
        company={company}
        loading={loading}
        loader={loader}
        errors={errors}
        register={register}
        handleSubmit={handleSubmit(onSubmit)}
        handleImage={handleImage}
        setCurrency={setCurrency}
        setLanguage={setLanguage}
      />
    </>
  );
};

export default EditCompany;
