import React from 'react';

import { StyledErrorReport } from './StyledReportErrors';

export interface ErrorsReportProps {
  file: any;
}

const ErrorsReport: React.FC<ErrorsReportProps> = props => {
  const { file } = props;

  return (
    <StyledErrorReport>
      <h2>Reporte de errores</h2>
      <div className="grid-block grid-push-to-bottom">
        <div className="span-sm-8">
          <p className="small">
            Hemos generado un reporte con los usuarios que tienen errores en el
            contenido. Por favor corrige los errores e inténtalo de nuevo.
          </p>
        </div>
        <div className="span-sm-4 button-download-container">
          <a
            className="button-secondary button-medium"
            href={file}
            download="error.xlsx"
            target="_blank"
            rel="noreferrer"
            role="button"
          >
            Descargar
          </a>
        </div>
      </div>
    </StyledErrorReport>
  );
};

export default ErrorsReport;
