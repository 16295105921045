import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { BoxWrapper } from './Styled';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  orientation: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, orientation, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${orientation}-tabpanel-${index}`}
      aria-labelledby={`${orientation}-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number, orientation: string) {
  return {
    id: `${orientation}-tab-${index}`,
    'aria-controls': `${orientation}-tabpanel-${index}`
  };
}

interface TabsProps {
  orientation?: any;
  className?: string;
  tabsTitle: any;
  tabsContent: any;
  tab?: number;
  setTab?: (tab: number) => void;
}

const TabsWrapper: React.FC<TabsProps> = props => {
  const {
    className = '',
    orientation = 'horizontal',
    tabsTitle,
    tabsContent,
    tab = 0,
    setTab
  } = props;
  const [value, setValue] = React.useState(tab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (setTab) {
      setTab(newValue);
    }
  };

  useEffect(() => {
    setValue(() => tab);
  }, [tab]);

  return (
    <BoxWrapper orientation={orientation}>
      <Box
        sx={{
          bgcolor: 'background.paper'
        }}
      >
        <Tabs
          className={className}
          orientation={orientation}
          variant={'fullWidth'}
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {tabsTitle.map((tabTitle: any) => {
            return (
              <Tab
                key={tabTitle?.id}
                label={tabTitle?.label}
                {...a11yProps(tabTitle?.id - 1, orientation)}
              />
            );
          })}
        </Tabs>
        <div className="TabsPanel-box">
          {tabsContent.map((tabContent: any) => {
            return (
              <TabPanel
                key={tabContent?.id}
                value={value}
                index={tabContent?.id - 1}
                orientation={orientation}
              >
                {tabContent?.children}
              </TabPanel>
            );
          })}
        </div>
      </Box>
    </BoxWrapper>
  );
};

export default TabsWrapper;
