import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import TwitterText from 'twitter-text';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers';
import { RequiredString } from 'helpers/yupHelper';
import { facebookURLs } from 'Utils/FacebookURLs';
import { Facebook as LoadingState } from 'react-content-loader';
import { selectUser } from 'redux/slices/userSlice';
import { generateRandAlphaNumStr } from 'Utils/StringRandom';
import { awsUploadVideoHelper } from 'helpers/awsVideoHelper';
import {
  awsUploadFileHelper,
  setFileNameHelper
} from 'helpers/awsCampaignFileHelper';

import { getUrlS3, getUrlVideoS3 } from 'constants/setup.constants';

import { ErrorAlert } from 'components/Alert';
import { SuccessAlert } from 'components/Alert';
import CreateSuggestedPostForm from './CreateSuggestedPostForm';
import { createSuggestedPost } from 'services/rest/SuggestedPost/createSuggestedPost';

const CreateSuggestedPostPage: React.FC = () => {
  const no_characters = /^(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'"ª|!· º\\,<.>/?]{5}).+/;
  const no_mentions = /^(?:(?!\B@).\n*)+$/;
  const { t } = useTranslation(['suggested_post', 'validations']);
  const userSchema = yup.object().shape({
    name: RequiredString().max(
      150,
      t('validations:length_error', { max: 150 })
    ),
    description: RequiredString().max(
      1800,
      t('validations:length_error', { max: 1800 })
    )
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(userSchema)
  });

  const [backendError, setBackendError] = useState<string | null>(null);
  const [errorSocialNetwork, setErrorSocialNetwork] = useState<string>('');
  const [fileErrorInstagram, setFileErrorInstagram] = useState<string>('');
  const [fileErrorTwitter, setFileErrorTwitter] = useState<string>('');
  const [fileErrorLinkedin, setFileErrorLinkedin] = useState<string>('');
  const [fileErrorWhatsapp, setFileErrorWhatsapp] = useState<string>('');
  const [fileErrorTiktok, setFileErrorTiktok] = useState<string>('');
  const [backendSuccess, setBackendSuccess] = useState<string | null>(null);
  // const [image, setImage] = useState<Array<any>>([]);
  const [imageTwitter, setImageTwitter] = useState<Array<any>>([]);
  const [imageLinkedin, setImageLinkedin] = useState<Array<any>>([]);
  const [imageInstagram, setImageInstagram] = useState<Array<any>>([]);
  const [imageWhatsapp, setImageWhatsapp] = useState<Array<any>>([]);
  const [imageTiktok, setImageTiktok] = useState<Array<any>>([]);
  const [type, setType] = useState({
    instagram: 'text',
    twitter: 'text',
    linkedin: 'text',
    whatsapp: 'text',
    tiktok: 'text'
  });
  const [loader, setLoader] = useState(false);
  const [uploadedTwitter, setUploadedTwitter] = useState<boolean | null>(null);
  const [uploadedLinkedin, setUploadedLinkedin] = useState<boolean | null>(
    null
  );
  const [uploadedInstagram, setUploadedInstagram] = useState<boolean | null>(
    null
  );
  const [uploadedTiktok, setUploadedTiktok] = useState<boolean | null>(null);
  const [uploadedWhatsapp, setUploadedWhatsapp] = useState<boolean | null>(
    null
  );
  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const [contentLimits, setContentLimits] = useState({
    twitter: 280,
    linkedin: 1500,
    instagram: 700,
    whatsapp: 280,
    tiktok: 150
  });

  const [invalidLimit, setInvalidLimit] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    whatsapp: false,
    tiktok: false
  });

  const handleTypeFile = (body: any) => {
    setType(body);
  };

  const handleImagen = (select: Array<any>, content: string) => {
    switch (content) {
      // case 'photo':
      //   setImage(select);
      //   break;
      case 'twitter':
        setImageTwitter(select);
        break;
      case 'linkedin':
        setImageLinkedin(select);
        break;
      case 'instagram':
        setImageInstagram(select);
        break;
      case 'whatsapp':
        setImageWhatsapp(select);
        break;
      case 'tiktok':
        setImageTiktok(select);
        break;
    }
  };

  const regextValidationTwitter = (content: string) => {
    const regex = new RegExp(no_characters);
    return regex.test(content);
  };

  const regextValidationLinkedin = (content: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(content);
  };
  const regextValidationTiktok = (content: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(content);
  };

  const onSubmit = async (suggestedPostData: any) => {
    setLoader(true);
    if (
      !suggestedPostData.includesFacebook &&
      !suggestedPostData.includesTwitter &&
      !suggestedPostData.includesLinkedin &&
      !suggestedPostData.includesInstagram &&
      !suggestedPostData.includesWhatsapp &&
      !suggestedPostData.includesTiktok
    ) {
      setErrorSocialNetwork(t('error_social_network'));
      setBackendError(t('error_social_network'));
      setLoader(false);
      return;
    }

    if (suggestedPostData.includesTwitter) {
      const validationContent = regextValidationTwitter(
        suggestedPostData.contentTwitter
      );
      const contentTwitterLength = suggestedPostData.contentTwitter
        ? TwitterText.getTweetLength(suggestedPostData.contentTwitter)
        : 0;
      if (!suggestedPostData.contentTwitter) {
        setBackendError(t('error_text_twitter'));
        setLoader(false);
        return;
      } else if (!validationContent) {
        setBackendError(t('characters'));
        setLoader(false);
        return;
      } else if (contentTwitterLength > contentLimits.twitter) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          twitter: true
        });
        setLoader(false);
        return;
      }
    }

    if (suggestedPostData.includesLinkedin) {
      const validationContent = regextValidationLinkedin(
        suggestedPostData.contentLinkedin
      );
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (suggestedPostData.contentLinkedin || '').match(urlPattern) || []
      ).length;
      const contentLinkedinLength = suggestedPostData.contentLinkedin
        ? suggestedPostData.contentLinkedin
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;
      if (
        (type.linkedin === 'share' || type.linkedin === 'text') &&
        !suggestedPostData.contentLinkedin
      ) {
        setBackendError(t('error_text_linkedin'));
        setLoader(false);
        return;
      } else if (!validationContent) {
        setBackendError(t('pattern'));
        setLoader(false);
        return;
      } else if (contentLinkedinLength > contentLimits.linkedin) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          linkedin: true
        });
        setLoader(false);
        return;
      }
    }

    if (
      suggestedPostData.includesFacebook &&
      !facebookURLs(suggestedPostData.contentFacebook)
    ) {
      setBackendError(t('error_url'));
      setLoader(false);
      return;
    }

    if (suggestedPostData.includesInstagram) {
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (suggestedPostData.contentInstagram || '').match(urlPattern) || []
      ).length;
      const contentInstagramLength = suggestedPostData.contentInstagram
        ? suggestedPostData.contentInstagram
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;
      if (imageInstagram.length == 0) {
        setBackendError(t('error_info_instagram'));
        setFileErrorInstagram(t('error_info_instagram'));
        setLoader(false);
        return;
      } else if (contentInstagramLength > contentLimits.instagram) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          instagram: true
        });
        setLoader(false);
        return;
      }
    }
    if (suggestedPostData.includesTiktok) {
      const validationContent = regextValidationTiktok(
        suggestedPostData.contentTiktok
      );
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (suggestedPostData.contentTiktok || '').match(urlPattern) || []
      ).length;
      const contentTiktokLength = suggestedPostData.contentTiktok
        ? suggestedPostData.contentTiktok
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;
      if (imageTiktok.length == 0) {
        setBackendError(t('error_info_tiktok'));
        setFileErrorTiktok(t('error_info_tiktok'));
        setLoader(false);
        return;
      } else if (!validationContent) {
        setBackendError(t('pattern_tiktok'));
        setLoader(false);
        return;
      } else if (contentTiktokLength > contentLimits.tiktok) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          tiktok: true
        });
        setLoader(false);
        return;
      }
    }

    if (suggestedPostData.includesWhatsapp) {
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (suggestedPostData.contentWhatsapp || '').match(urlPattern) || []
      ).length;
      const contentWhatsappLength = suggestedPostData.contentWhatsapp
        ? suggestedPostData.contentWhatsapp
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;
      if (imageWhatsapp.length == 0) {
        setBackendError(t('error_info_whatsapp'));
        setFileErrorWhatsapp(t('error_info_wa_text'));
        setLoader(false);
        return;
      } else if (contentWhatsappLength > contentLimits.whatsapp) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          whatsapp: true
        });
        setLoader(false);
        return;
      }
    }

    if (
      (type.twitter === 'image' &&
        suggestedPostData.includesTwitter &&
        imageTwitter.length == 0) ||
      (type.twitter === 'video' &&
        suggestedPostData.includesTwitter &&
        imageTwitter.length == 0)
    ) {
      setBackendError(t('error_info_twitter'));
      setLoader(false);
      return;
    }

    if (
      (type.linkedin === 'image' &&
        suggestedPostData.includesLinkedin &&
        imageLinkedin.length == 0) ||
      (type.linkedin === 'video' &&
        suggestedPostData.includesLinkedin &&
        imageLinkedin.length == 0) ||
      (type.linkedin === 'pdf' &&
        suggestedPostData.includesLinkedin &&
        imageLinkedin.length == 0)
    ) {
      setBackendError(t('error_info_linkedin'));
      setFileErrorLinkedin(t('error_info_file_ln'));
      setLoader(false);
      return;
    }

    if (
      type.tiktok === 'video' &&
      suggestedPostData.includesTiktok &&
      imageTiktok.length == 0
    ) {
      setBackendError(t('error_info_tiktok'));
      setFileErrorTiktok(t('error_info_file_tk'));
      setLoader(false);
      return;
    }

    suggestedPostData.companyId = currentUser.user.companyId;

    // if (image) {
    //   const namePhoto = generateRandAlphaNumStr();
    //   const uploadedPhoto = await awsUploadFileHelper(
    //     currentUser?.token,
    //     image,
    //     true,
    //     'CAMPAIGN',
    //     namePhoto,
    //     t,
    //     setBackendError
    //   );
    //   if (uploadedPhoto) {
    //     suggestedPostData.photo = getUrlS3('campaigns', namePhoto);
    //   } else {
    //     setBackendError(t('validations:file_error'));
    //     setLoader(false);
    //     return;
    //   }
    // }

    let instagram: any;
    let whatsapp: any;
    let tiktok: any;

    if (suggestedPostData.includesInstagram) {
      if (type.instagram === 'image') {
        const nameInstagram = setFileNameHelper(imageInstagram[0], false);
        const uploadedInstagramPhoto = await awsUploadFileHelper(
          currentUser?.token,
          imageInstagram[0],
          false,
          'CAMPAIGN',
          nameInstagram,
          t,
          setBackendError
        );
        if (uploadedInstagramPhoto) {
          instagram = {
            type: type['instagram'],
            url: [getUrlS3('campaigns', nameInstagram)]
          };
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
      if (type.instagram === 'video') {
        setUploadedInstagram(false);
        const nameInstagram = setFileNameHelper(imageInstagram, false);
        const uploadedInstagram = await awsUploadVideoHelper(
          currentUser?.token,
          imageInstagram,
          'CAMPAIGN',
          nameInstagram,
          t,
          setBackendError
        );
        if (uploadedInstagram) {
          instagram = {
            type: type['instagram'],
            url: getUrlS3('campaigns', nameInstagram)
          };
          setUploadedInstagram(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    }
    if (suggestedPostData.includesTiktok) {
      if (type.tiktok === 'video') {
        setUploadedTiktok(false);
        const nameTiktok = setFileNameHelper(imageTiktok, false);
        const uploadedTiktok = await awsUploadVideoHelper(
          currentUser?.token,
          imageTiktok,
          'CAMPAIGN',
          nameTiktok,
          t,
          setBackendError
        );
        if (uploadedTiktok) {
          tiktok = {
            type: type['tiktok'],
            url: getUrlS3('campaigns', nameTiktok)
          };
          setUploadedInstagram(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    }

    if (suggestedPostData.includesWhatsapp) {
      if (type.whatsapp === 'image') {
        const nameWhatsapp = setFileNameHelper(imageWhatsapp[0], false);
        const uploadedWhatsappPhoto = await awsUploadFileHelper(
          currentUser?.token,
          imageWhatsapp[0],
          false,
          'CAMPAIGN',
          nameWhatsapp,
          t,
          setBackendError
        );
        if (uploadedWhatsappPhoto) {
          whatsapp = {
            type: type['whatsapp'],
            url: [getUrlS3('campaigns', nameWhatsapp)]
          };
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
      if (type.whatsapp === 'video') {
        setUploadedWhatsapp(false);
        const nameWhatsapp = setFileNameHelper(imageWhatsapp, false);
        const uploadedWhatsapp = await awsUploadVideoHelper(
          currentUser?.token,
          imageWhatsapp,
          'CAMPAIGN',
          nameWhatsapp,
          t,
          setBackendError
        );
        if (uploadedWhatsapp) {
          whatsapp = {
            type: type['whatsapp'],
            url: getUrlS3('campaigns', nameWhatsapp)
          };
          setUploadedWhatsapp(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    }

    let twitter: any;
    let linkedin: any;

    if (suggestedPostData.includesTwitter) {
      if (type.twitter === 'image') {
        const twitterFile = [];
        if (imageTwitter) {
          setUploadedTwitter(false);
        }
        for (const file of imageTwitter) {
          if (!file?.file?.type) {
            const name = file.url.split('/').reverse()[0];
            twitterFile.push({ name });
            continue;
          }
          const nameTwitter = setFileNameHelper(file, false);
          await awsUploadFileHelper(
            currentUser?.token,
            file,
            false,
            'CAMPAIGN',
            nameTwitter,
            t,
            setBackendError
          );
          twitterFile.push({ name: nameTwitter });
        }
        twitter = {
          type: type['twitter'],
          url: twitterFile.map((file: any) => getUrlS3('campaigns', file?.name))
        };
      }
      if (type.twitter === 'video') {
        setUploadedTwitter(false);
        const nameTwitter = setFileNameHelper(imageTwitter, false);
        const uploadedTwitter = await awsUploadVideoHelper(
          currentUser?.token,
          imageTwitter,
          'CAMPAIGN',
          nameTwitter,
          t,
          setBackendError
        );
        if (uploadedTwitter) {
          twitter = {
            type: type['twitter'],
            url: getUrlS3('campaigns', nameTwitter)
          };
          setUploadedTwitter(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    }

    if (suggestedPostData.includesLinkedin) {
      if (type.linkedin === 'image') {
        const linkedinFile = [];
        if (imageLinkedin) {
          setUploadedLinkedin(false);
        }
        for (const file of imageLinkedin) {
          if (!file?.file?.type) {
            const name = file.url.split('/').reverse()[0];
            linkedinFile.push({ name });
            continue;
          }
          const nameLinkedin = setFileNameHelper(file, false);
          await awsUploadFileHelper(
            currentUser?.token,
            file,
            false,
            'CAMPAIGN',
            nameLinkedin,
            t,
            setBackendError
          );
          linkedinFile.push({ name: nameLinkedin });
        }
        linkedin = {
          type: type['linkedin'],
          url: linkedinFile.map((file: any) =>
            getUrlS3('campaigns', file?.name)
          )
        };
      }
      if (type.linkedin === 'video') {
        setUploadedLinkedin(false);
        const nameLinkedin = setFileNameHelper(imageLinkedin, false);
        const uploadedLinkedin = await awsUploadVideoHelper(
          currentUser?.token,
          imageLinkedin,
          'CAMPAIGN',
          nameLinkedin,
          t,
          setBackendError
        );
        if (uploadedLinkedin) {
          linkedin = {
            type: type['linkedin'],
            url: getUrlS3('campaigns', nameLinkedin)
          };
          setUploadedLinkedin(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
      if (type.linkedin === 'pdf') {
        const nameLinkedin = setFileNameHelper(imageLinkedin[0], false);
        const uploadedLinkedin = await awsUploadFileHelper(
          currentUser?.token,
          imageLinkedin[0],
          false,
          'CAMPAIGN',
          nameLinkedin,
          t,
          setBackendError
        );
        if (uploadedLinkedin) {
          linkedin = {
            type: type['linkedin'],
            url: getUrlS3('campaigns', nameLinkedin)
          };
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    }

    const { data, errors } = await createSuggestedPost(
      currentUser?.token,
      suggestedPostData,
      twitter,
      linkedin,
      instagram,
      tiktok,
      whatsapp,
      type
    );

    if (data) {
      history.push('/', t('success_message'));
    }

    if (errors) {
      setBackendError(errors.data);
      return;
    }

    setLoader(false);
  };

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}

      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      <CreateSuggestedPostForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setValue={setValue}
        handleImage={handleImagen}
        control={control}
        handleTypeFile={handleTypeFile}
        errorSocialNetwork={errorSocialNetwork}
        fileErrorInstagram={fileErrorInstagram}
        fileErrorTwitter={fileErrorTwitter}
        fileErrorLinkedin={fileErrorLinkedin}
        fileErrorWhatsapp={fileErrorWhatsapp}
        fileErrorTiktok={fileErrorTiktok}
        loader={loader}
        contentLimits={contentLimits}
        invalidLimit={invalidLimit}
        uploadedTwitter={uploadedTwitter}
        uploadedLinkedin={uploadedLinkedin}
        uploadedInstagram={uploadedInstagram}
        uploadedTiktok={uploadedTiktok}
        uploadedWhatsapp={uploadedWhatsapp}
      />
    </>
  );
};

export default CreateSuggestedPostPage;
