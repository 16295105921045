import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import useAdmin from 'hooks/useIsAdmin.hook';
import CreateSuggestedPostPage from 'pages/SuggestedPostsPage/CreateSuggestedPostsPage';
import SuggestedPosts from 'pages/SuggestedPostsPage';
import SuggestedPostDetail from 'pages/SuggestedPostsPage/DetailSuggestedPost';

const SuggestedPostsRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/new`}>
        {!useAdmin() ? (
          <CreateSuggestedPostPage />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </Route>

      <Route exact path={`${match.path}`}>
        <SuggestedPosts />
      </Route>

      <Route exact path={`${match.path}/:id/detail`}>
        {useAdmin() ? (
          <SuggestedPostDetail />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </Route>
    </Switch>
  );
};

export default SuggestedPostsRouter;
