import React, { Suspense, FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { List as LoadingState } from 'react-content-loader';
import { UseFormMethods } from 'react-hook-form';
import Button from 'components/buttons/Button';
import Spinner from 'components/Spinner/Spinner';

import { ButtonStyled, SectionForm } from './Styled';
import GeneralAccordion from './GeneralAccordion';
import EmailAccordion from './EmailAccordion';
import AppAccordion from './AppAccordion';

export interface ChangePermissionsFormProps {
  permissions: any;
  setPermissions: any;
  loading: boolean;
  loader: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  register: UseFormMethods['register'];
}

const ChangePermissionsForm: React.FC<ChangePermissionsFormProps> = props => {
  const {
    permissions,
    setPermissions,
    loader,
    loading,
    handleSubmit,
    register
  } = props;
  const { t } = useTranslation('change_permissions');

  const handleOnClick = (e: FormEvent<HTMLInputElement>) => {
    switch (e.currentTarget.name) {
      case 'allowEmailNotifications':
        setPermissions({
          ...permissions,
          allowEmailNotifications: e.currentTarget.checked,
          allowWelcomeEmail: e.currentTarget.checked,
          allowCampaignEmail: e.currentTarget.checked,
          allowChallengeEmail: e.currentTarget.checked,
          allowAwardsEmail: e.currentTarget.checked,
          allowBonusAdminEmail: e.currentTarget.checked,
          allowRejectedBonusEmail: e.currentTarget.checked,
          allowApprovedBonusEmail: e.currentTarget.checked
        });
        break;
      case 'allowPushNotification':
        setPermissions({
          ...permissions,
          allowPushNotification: e.currentTarget.checked,
          allowCampaignPush: e.currentTarget.checked,
          allowAwardsPush: e.currentTarget.checked,
          allowApprovedBonusPush: e.currentTarget.checked,
          allowRejectedBonusPush: e.currentTarget.checked,
          allowCampaignReminderPush: e.currentTarget.checked,
          allowChallengePush: e.currentTarget.checked,
          allowChallengeReminderPush: e.currentTarget.checked
        });
        break;
      default:
        setPermissions({
          ...permissions,
          [e.currentTarget.name]: e.currentTarget.checked
        });
        break;
    }
  };

  useEffect(() => {
    if (permissions?.allowCampaignEmail === false) {
      setPermissions((prev: Permissions) => ({
        ...prev,
        allowCampaignAwardsEmail: false
      }));
    }
  }, [permissions?.allowCampaignEmail]);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <form onSubmit={handleSubmit}>
        <SectionForm>
          <div>
            <GeneralAccordion
              permissions={permissions}
              handleOnClick={handleOnClick}
              register={register}
            />
            <EmailAccordion
              permissions={permissions}
              handleOnClick={handleOnClick}
              register={register}
            />
            <AppAccordion
              permissions={permissions}
              handleOnClick={handleOnClick}
              register={register}
            />
          </div>
        </SectionForm>
        <ButtonStyled>
          {!loader ? (
            <Button value={t('save')} variant="primary" />
          ) : (
            <Spinner name="sign-up" />
          )}
        </ButtonStyled>
      </form>
    </Suspense>
  );
};
export default ChangePermissionsForm;
