import React, { useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SuccessAlert } from 'components/Alert';
import 'moment/locale/es';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Bonus from 'pages/Bonus';
import Challenges from 'pages/ChallengesPage';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Creators = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(['bonus', 'challenge']);
  const location = useLocation();
  const { state } = location as any;
  const [backendSuccess, setBackendSuccess] = useState<any | null>(null);
  const [valueTab, setValueTab] = useState(0);

  const showAlertSuccessChallengeCreated = async () => {
    if (typeof state === 'string') {
      setBackendSuccess(state);
    }
  };

  const getData = async () => {
    showAlertSuccessChallengeCreated();
    setLoading(false);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}
      <SectionWrapper>
        <NavigationLink
          text={t('challenge:back')}
          direction="back"
          to="/dashboard"
        />
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs value={valueTab} onChange={handleChangeTab} centered>
            <Tab label={t('challenge:challenges')} {...a11yProps(0)} />
            <Tab label={t('bonus:bonus_title')} {...a11yProps(1)} />
          </Tabs>
        </Box>
      </SectionWrapper>
      <>
        <TabPanel value={valueTab} index={0}>
          <Challenges />
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <Bonus />
        </TabPanel>
      </>
    </>
  );
};

export default Creators;
