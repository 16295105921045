import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { selectUser } from 'redux/slices/userSlice';

import { RequiredString } from 'helpers/yupHelper';

import { PasswordData, changePassword } from 'services/rest/changePassword';

import { ErrorAlert, SuccessAlert } from 'components/Alert';

import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = () => {
  const { t } = useTranslation(['settings', 'validations']);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = useState<string | null>(null);
  const userSchema = yup.object().shape({
    currentPassword: RequiredString()
      .min(5, 'login_form.password_min_length')
      .max(40, 'login_form.password_max_length'),
    newPassword: RequiredString()
      .min(5, 'login_form.password_min_length')
      .max(40, 'login_form.password_max_length'),
    confirmPassword: RequiredString()
      .min(5, 'login_form.password_min_length')
      .max(40, 'login_form.password_max_length')
  });
  const { register, handleSubmit, errors } = useForm<PasswordData>({
    resolver: yupResolver(userSchema)
  });
  const { currentUser } = useSelector(selectUser);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (passwordData: PasswordData) => {
    passwordData.role = currentUser?.role;
    setLoader(true);
    const { data, errors } = await changePassword(
      currentUser?.token,
      passwordData,
      currentUser?.user?.id
    );
    if (data) {
      setUpdateSuccess(t('settings:update_admin'));
    }
    if (errors) {
      setUpdateError(errors?.data);
    }
    setLoader(false);
  };

  return (
    <>
      {updateError && (
        <ErrorAlert
          text={updateError}
          allowClose={true}
          onClose={() => setUpdateError(null)}
        />
      )}

      {updateSuccess && (
        <SuccessAlert
          text={updateSuccess}
          allowClose={true}
          onClose={() => setUpdateSuccess(null)}
        />
      )}
      <ChangePasswordForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        loader={loader}
        t={t}
        showPassword={showPassword}
        onClickEye={() => {
          setShowPassword(!showPassword);
        }}
      />
    </>
  );
};

export default ChangePassword;
