import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './Ranking.state';

const setRankingSliceLoading = (state: any, action: { payload: any }) => {
  return {
    ...state,
    loading: action.payload
  };
};

const setRanking = (state: any, action: { payload: string }) => {
  return {
    ...state,
    ranking: action.payload
  };
};

export const selectRanking = (state: { ranking: any }) => state.ranking;

const RankingSlice = createSlice({
  name: 'ranking',
  initialState: {
    initialState,
    loading: false
  },
  reducers: {
    setRankingList: setRanking,
    setLoading: setRankingSliceLoading
  }
});

export const { setRankingList, setLoading } = RankingSlice.actions;

export default RankingSlice.reducer;
