import { useEffect, useState } from 'react';

export const useFetch = (service: any) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    service.then((data: any) => {
      setData(data);
      setLoading(false);
    });
  }, []);
  return { data, loading };
};
