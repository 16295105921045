import styled, { css } from 'styled-components';

import { Breakpoints } from 'styles/Constants';

export type ProgressBarContainerProps = {
  inactive?: boolean;
};

export type ProgressBarBoxProps = {
  disabled?: boolean;
};

export type SimpleProgressBarProps = {
  color?: string;
  percentage: string;
};

export const SimpleProgressBar = styled.div<SimpleProgressBarProps>`
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  background-color: grey;
  position: absolute;
  height: 7px;

  ${({ percentage }) =>
    css`
      width: ${percentage}%;
    `}
  ${({ percentage }) =>
    percentage === '100' &&
    css`
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
    `}

  ${({ color }) =>
    css`
      background-color: ${color};
    `}
`;

export const ProgressBarBox = styled.div<ProgressBarBoxProps>`
  align-items: center;
  display: flex;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}

  @media (max-width: ${Breakpoints.medium}) {
    margin: 5px;
  }

  > span.icon,
  > svg {
    margin: 10px 10px 10px 0;
    display: block;
    text-align: center;

    @media (max-width: ${Breakpoints.medium}) {
      min-height: 19px;
      min-width: 19px;
      height: 19px;
      width: 19px;
    }
  }

  span.progress-bar-number {
    text-align: right;
    font-weight: 500;
    font-size: 10px;
    min-width: 28px;
    color: #878787;

    @media (min-width: ${Breakpoints.medium}) {
      min-width: 39px;
      font-size: 14px;
    }
  }
`;

export const ProgressBarContainer = styled.span<ProgressBarContainerProps>`
  background-color: #e2e3e5;
  border-radius: 30px;
  position: relative;
  padding: 0px 1px;
  display: block;
  width: 100%;
  height: 7px;

  ${({ inactive }) =>
    inactive &&
    css`
      margin-top: 10px;
      width: 300px;
    `}
`;
