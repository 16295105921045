import React from 'react';
import PhoneInput from 'react-phone-number-input';
import StyledInput from './StyledInput';

interface PhoneProps {
  defaultCountry: any;
  name?: string;
  placeholder?: string;
  value: any;
  onChange: any;
}

const Phone: React.FC<PhoneProps> = props => {
  const {
    defaultCountry,
    name = 'phone',
    placeholder = 'Enter a number',
    value,
    onChange
  } = props;

  return (
    <StyledInput>
      <PhoneInput
        defaultCountry={defaultCountry}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </StyledInput>
  );
};

export default Phone;
