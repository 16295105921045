import React from 'react';
import { StyledSpinner } from './StyledSpinner';

export interface SpinnerProps {
  name?: any;
  description?: string;
  size?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ description, size }) => (
  <StyledSpinner>
    {description !== undefined ? <p>{description}</p> : <></>}
    <div className="container-loader">
      <div className={size ? `${size} loader` : `loader`}></div>
    </div>
  </StyledSpinner>
);

export default Spinner;
