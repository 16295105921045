import { createSlice } from '@reduxjs/toolkit';

const setRouter = (state: any, action: { payload: any }) => {
  return {
    ...state,
    router: action.payload
  };
};

const RouterSlice = createSlice({
  name: 'router',
  initialState: {
    router: {}
  },
  reducers: {
    setRouterCurrent: setRouter
  }
});

export const selectRouter = (state: { router: { router: string } }) =>
  state.router;

export const { setRouterCurrent } = RouterSlice.actions;

export default RouterSlice.reducer;
