import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export const MarginUploadFile = styled.div`
  overflow-y: hidden;
  text-align: center;
  background-color: #f2f2f2 !important;
  font-family: 'Montserrat' !important;
  border-radius: 25px !important;
  border: 2px dashed #979797 !important;
  height: 200px !important;
  width: 230px !important;
  padding: 15px !important;

  @media (max-width: ${Breakpoints.small}) {
    margin-left: 4%;
    margin-right: 20%;
    margin-top: 5%;
    width: 250px !important;
  }

  input {
    width: 250px;
    height: 200px;
    opacity: 0 !important;
    position: relative;
    bottom: 150px;
  }

  h3 {
    font-size: 1rem;
    margin-left: 12%;
    margin-right: 12%;
    font-weight: bold;
  }

  p {
    font-size: 13px !important;
    color: ${Colors.primaryGray} !important;
    text-align: center;
  }
`;

export const MarginPreview = styled.div`
  display: flex;
  flex-direction: column;
  img {
    background-color: darkgray;
    border-radius: 10px;
    object-fit: contain;
    height: 200px;
    width: 200px;
  }
  video {
    background-color: darkgray;
    border-radius: 10px;
    object-fit: contain;
    height: 200px;
    width: 200px;
  }
  .icons {
    display: flex;

    & .icon {
      cursor: pointer;
      margin-right: 5px;
    }
  }
`;

export const ErrorSpan = styled.span`
  font-weight: 500;
  font-size: 13px;
  grid-column: 2;
  color: #ff303f;
`;

export const MarginOption = styled.div`
  display: inline-flex;
  img {
    width: 40px;
  }
  p {
    background: none !important;
    font-family: inherit !important;
    line-height: 1 !important;
    border: 0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    color: #2275d7 !important;
  }
`;

export const MarginPhoto = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  img {
    border-radius: 10px;
    object-fit: cover;
    height: 50px;
    width: 50px;
  }
`;

export const MarginPhotos = styled.div`
  overflow-y: scroll;
  height: 75%;
`;
export const CloseButton = styled.div`
  position: absolute;
  right: 0;
  margin: -15px;
`;
export const StatusSuccess = styled.div`
  position: absolute;
  right: 0;
`;
