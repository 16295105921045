import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './Award.state';

const setAwardSliceLoading = (state: any, action: { payload: any }) => {
  return {
    ...state,
    loading: action.payload
  };
};

const setAward = (state: any, action: { payload: string }) => {
  return {
    ...state,
    awards: action.payload
  };
};

export const selectAwards = (state: { awards: any }) => state.awards;

const AwardSlice = createSlice({
  name: 'award',
  initialState: {
    award: initialState,
    awards: [],
    loading: false
  },
  reducers: {
    setAwardsList: setAward,
    setLoading: setAwardSliceLoading
  }
});

export const { setAwardsList, setLoading } = AwardSlice.actions;

export default AwardSlice.reducer;
