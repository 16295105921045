import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import {
  FilterCampaigns,
  HeardMargin,
  SocialInfoStyled,
  StyleTable,
  StyledTooltipWrapper,
  TextStyled,
  TooltipMessage
} from '../Styled';
import iconEye from 'images/icons/eyes.svg';
import iconStar from 'images/icons/star.svg';
import Icon from 'components/icons/Icon';
import Input from 'components/inputs/Input';
import iconTrash from 'images/icons/trash.png';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import { approveBonus } from 'services/rest/Bonus/approveBonus';
import { rejectBonus } from 'services/rest/Bonus/rejectBonus';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import MetricsBonusSectionAdmin from './MetricsBonusSectionAdmin';
import { deleteBonus } from 'services/rest/Bonus/deleteBonus';
import EmployeeAvatar from 'images/avatars/employee.png';

interface AdminBonusProps {
  bonus: any;
  timeLine: any;
}

const AdminBonus: React.FC<AdminBonusProps> = props => {
  const { bonus, timeLine } = props;
  const { t } = useTranslation([
    'campaign_card',
    'bonus',
    'campaign_section',
    'challenge'
  ]);
  const statusOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: t('bonus:pending'), value: 'pending' },
    { label: t('bonus:approved'), value: 'approved' },
    { label: t('bonus:rejected'), value: 'rejected' }
  ];
  const socialOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Twitter', value: 'twitter' },
    { label: 'Linkedin', value: 'linkedin' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Tiktok', value: 'tiktok' },
    { label: 'Snapchat', value: 'snapchat' },
    { label: 'Youtube', value: 'youtube' },
    { label: 'Threads', value: 'threads' },
    { label: t('campaign_section:others'), value: 'otros' }
  ];
  const [filterText, setFilterText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedSocial, setSelectedSocial] = useState(socialOptions[0]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { currentUser } = useSelector(selectUser);

  const [currentBonus, setCurrentBonus] = useState(bonus);

  const newBonus = currentBonus.filter((info: any) => {
    const data = info;
    let result =
      data.ambassadorName.toLowerCase().indexOf(filterText.toLowerCase()) != -1;
    if (!result) {
      result = data.reference.toString().indexOf(filterText.toString()) != -1;
    }
    return result;
  });

  const getFilterSocial = (filter: any, value: string) => {
    return filter.filter(
      (bonus: any) => bonus.socialNetwork.toLowerCase() === value
    );
  };

  const selectStatusOption = (event: any) => {
    setSelectedStatus(event);
    setPage(0);
  };

  const selectSocialOption = (event: any) => {
    setSelectedSocial(event);
    setPage(0);
  };

  const newBonusStatus =
    selectedStatus.value !== 'ALL'
      ? newBonus.filter(
          (content: any) => content.status === selectedStatus.value
        )
      : newBonus;

  const newBonusSocial =
    selectedSocial.value !== 'ALL'
      ? getFilterSocial(newBonusStatus, selectedSocial.value)
      : newBonusStatus;

  const filteredBonus = newBonusSocial;

  const [visible, setVisible] = useState<any>(
    filteredBonus?.map(() => {
      return {
        status: false
      };
    })
  );

  const showTooltip = (index: any, status: any) => {
    const tooltipTypes = {
      ...visible,
      [index]: { status: status }
    };
    setVisible(tooltipTypes);
  };

  const go_to = (url: string): void => {
    const http = 'http://';
    const https = 'https://';
    url = url.includes(http) || url.includes(https) ? url : https + url;
    window.open(url, '_blank');
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = async (
    bonusId: any,
    message: any,
    typeAction: any,
    points?: any
  ) => {
    let data;
    switch (typeAction) {
      case 'APPROVE':
        data = await approveBonus(currentUser?.token, bonusId, message, points);
        if (data.data) {
          setCurrentBonus((prevState: any) =>
            prevState.map((b: any) =>
              b.id === bonusId
                ? { ...b, status: 'approved', points, message }
                : b
            )
          );
        }
        break;
      case 'REJECT':
        data = await rejectBonus(currentUser?.token, bonusId, message);
        if (data.data) {
          setCurrentBonus((prevState: any) =>
            prevState.map((b: any) =>
              b.id === bonusId ? { ...b, status: 'rejected', message } : b
            )
          );
        }
        break;
      case 'DELETE':
        data = await deleteBonus(currentUser?.token, bonusId);
        if (data.data) {
          setCurrentBonus((prevState: any) =>
            prevState.filter((b: any) => b.id !== bonusId)
          );
        }
        break;
    }
  };

  const approveAlert = (bonusId: number) => {
    let defaultPoints = 10;
    const htmlPoints = 'type="number"';

    Swal.fire({
      title: t('bonus:approve_bonus_question'),
      icon: 'warning',
      customClass: {
        inputLabel: 'custom-swal-margin'
      },
      html: `<div>
      <label><b>${t('bonus:approve_bonus_reasons')}</b></label>
      </br>
      <textarea id='approve_reason' name="textarea" class="textArea-bonus" rows="3" cols="50" placeholder="${t(
        'bonus:place_holder_reject'
      )}"></textarea>    
      </div>
      <p><b>${t('bonus:points_for_bonus')}</b></p>
      <input id="points" ${htmlPoints} class="bonus-box" placeholder="${t(
        'bonus:points_placeholder'
      )}">`,

      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true,
      preConfirm: async () => {
        const reasonContent = document.getElementById('approve_reason') as any;
        const pointsValue = document.getElementById('points') as any;
        defaultPoints =
          !isNaN(pointsValue?.value) && pointsValue?.value > 0
            ? pointsValue?.value
            : defaultPoints;

        if (reasonContent?.value.length > 280) {
          Swal.showValidationMessage(t('bonus:limit_reason'));
        } else {
          onSubmit(bonusId, reasonContent?.value, 'APPROVE', defaultPoints);
        }
      }
    });
  };

  const rejectAlert = (bonusId: number) => {
    Swal.fire({
      title: t('bonus:reject_bonus_question'),
      icon: 'warning',
      customClass: {
        inputLabel: 'custom-swal-margin'
      },
      html: `<div>
      <label><b>${t('bonus:reject_bonus_reasons')}</b></label>
      </br>
      <textarea id='reject_reason' name="textarea" class="textArea-bonus" rows="3" cols="50" placeholder="${t(
        'bonus:place_holder_reject'
      )}"></textarea>    
      </div>`,

      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true,
      preConfirm: async () => {
        const reasonContent = document.getElementById('reject_reason') as any;
        if (reasonContent?.value.length > 280) {
          Swal.showValidationMessage(t('bonus:limit_reason'));
        } else {
          onSubmit(bonusId, reasonContent?.value, 'REJECT');
        }
      }
    });
  };

  const deleteAlert = (bonusId: number) => {
    Swal.fire({
      text: t('bonus:confirmation_delete_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(bonusId, '', 'DELETE');
      }
    });
  };

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  return (
    <>
      <MetricsBonusSectionAdmin />
      <h1>{t('bonus:contents_list')}</h1>
      <StyleTable>
        <FilterCampaigns>
          <Input
            type="search"
            placeholder={t('bonus:bonus_filter')}
            value={filterText}
            onChange={e => {
              setFilterText(e.target.value);
            }}
          />
          <span>{t('campaign_card:filter')}</span>
          <DropdownSelect
            options={statusOptions}
            value={selectedStatus}
            onSelect={selectStatusOption}
          />
          <DropdownSelect
            options={socialOptions}
            value={selectedSocial}
            onSelect={selectSocialOption}
          />
        </FilterCampaigns>
        <HeardMargin>
          <div className="option">
            <img alt="iconEye" src={iconEye} />
            <TextStyled>{t('bonus:view_bonus')}</TextStyled>
          </div>
          <div className="option">
            <Icon name="check-rounded" color="#7ed321" size="small" />
            <TextStyled>{t('bonus:approve')}</TextStyled>
          </div>
          <div className="option">
            <Icon name="close-rounded" color="red" size="small" />
            <TextStyled>{t('bonus:reject')}</TextStyled>
          </div>
        </HeardMargin>
        <TableContainer component={Paper} className="table_container">
          <Table
            sx={{ minWidth: 750 }}
            size="medium"
            aria-label="a dense table"
            className="table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" className="column" colSpan={2}>
                  {`${t('bonus:ambassador')} (${t('bonus:reference')})`}
                </TableCell>
                <TableCell align="center" className="column">
                  {t('bonus:social_network')}
                </TableCell>
                <TableCell align="center" className="column">
                  {t('bonus:post')}
                </TableCell>
                <TableCell align="center" className="column">
                  {t('challenge:challenge')}
                </TableCell>
                <TableCell align="center" className="column" colSpan={2} />
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredBonus?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredBonus
              ).map((bonus: any, index: number) => {
                const row = bonus as any;
                return (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0
                      }
                    }}
                    className={`column-body ${
                      row.status === 'pending' ? 'pending-bg' : ''
                    }`}
                  >
                    <TableCell component="th" scope="row" align="left">
                      <img
                        src={row?.ambassador?.photo || EmployeeAvatar}
                        className="user-photo"
                        alt="User"
                        onError={evt => addDefaultPicture(evt)}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className="name"
                    >
                      <StyledTooltipWrapper
                        visible={visible[index]?.status}
                        onMouseOver={() => showTooltip(index, true)}
                        onMouseOut={() => showTooltip(index, false)}
                      >
                        {`${row.ambassadorName} (${row.reference})`}
                        {
                          <TooltipMessage className="ranking-tooltip">
                            <span>
                              {t('bonus:publication_date')}
                              {timeLine(row.createdAt)}
                            </span>
                            {row.decisionDate && (
                              <span>
                                {t('bonus:decision_date')}
                                {timeLine(row.decisionDate)}
                              </span>
                            )}
                            {row.message && (
                              <span>
                                {t(`bonus:${row.status}_message`)}
                                {row.message}
                              </span>
                            )}
                          </TooltipMessage>
                        }
                      </StyledTooltipWrapper>
                    </TableCell>
                    <TableCell component="th" align="center">
                      <SocialMediaIcon
                        name={row.socialNetwork.toLowerCase()}
                        size="medium"
                        className="social-network"
                      />
                    </TableCell>
                    <TableCell align="center" className="icons">
                      <img
                        alt="iconEye"
                        src={iconEye}
                        onClick={() => go_to(row.url)}
                        className="pointer"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row?.challenge?.name}
                    </TableCell>
                    <TableCell align="center">
                      {row.status === 'approved' ? (
                        <SocialInfoStyled className="total-points">
                          <Icon
                            name="check-rounded"
                            color="#7ed321"
                            size="small"
                          />
                          <b>{row.points ? row.points : 0}</b>
                          <img
                            alt="iconStar"
                            className="points"
                            src={iconStar}
                          />
                        </SocialInfoStyled>
                      ) : row.status === 'rejected' ? (
                        <Icon name="close-rounded" color="red" size="small" />
                      ) : (
                        <>
                          <Icon
                            name="check-rounded"
                            color="#7ed321"
                            size="small"
                            onClick={() => approveAlert(row.id)}
                            className="pointer"
                          />
                          <Icon
                            name="close-rounded"
                            color="red"
                            size="small"
                            onClick={() => rejectAlert(row.id)}
                            className="pointer"
                          />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {bonus.status === 'pending' && (
                        <img
                          alt="iconTrash"
                          src={iconTrash}
                          className="pointer"
                          onClick={() => deleteAlert(row.id)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={currentBonus?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyleTable>
    </>
  );
};

export default AdminBonus;
