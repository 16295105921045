import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import {
  FilterCampaigns,
  HeardMargin,
  StyleTable,
  TextStyled
} from '../Styled';
import iconEye from 'images/icons/eyes.svg';
import Input from 'components/inputs/Input';
import iconTrash from 'images/icons/trash.png';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { deleteSuggestedPost } from 'services/rest/SuggestedPost/deleteSuggestedPost';
import Tag from 'components/Tag';

interface AmbassadorSuggestedPostProps {
  suggestedPosts: any;
  setLoading: any;
  changeSuggestedPost: any;
  setChangeSuggestedPost: any;
}

const AmbassadorSuggestedPost: React.FC<AmbassadorSuggestedPostProps> = props => {
  const {
    suggestedPosts,
    setLoading,
    changeSuggestedPost,
    setChangeSuggestedPost
  } = props;
  const { t } = useTranslation(['campaign_card', 'suggested_post']);
  const statusOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: t('suggested_post:pending'), value: 'pending' },
    { label: t('suggested_post:viewed'), value: 'viewed' }
  ];
  const socialOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Twitter', value: 'twitter' },
    { label: 'Linkedin', value: 'linkedin' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Whatsapp', value: 'whatsapp' }
  ];
  const [filterText, setFilterText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedSocial, setSelectedSocial] = useState(socialOptions[0]);
  const [filterOption, setFilterOption] = useState(suggestedPosts);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { currentUser } = useSelector(selectUser);

  const getFilterSocial = (filter: any, value: string) => {
    return filter.filter((suggestedPost: any) => suggestedPost[value]);
  };

  const getFilterStatus = (filter: any, value: string) => {
    return filter.filter(
      (suggestedPost: any) => suggestedPost.status === value
    );
  };

  const selectStatusOption = (event: any) => {
    let filter = suggestedPosts;
    const filterStatus = event.value.toLowerCase();

    if (filterStatus !== 'all') {
      filter = getFilterStatus(filter, filterStatus);
    }
    if (selectedSocial.value.toLowerCase() !== 'all') {
      filter = getFilterSocial(filter, selectedSocial.value.toLowerCase());
    }
    setFilterOption(filter);
    setSelectedStatus(event);
  };

  const selectSocialOption = (event: any) => {
    let filter = suggestedPosts;
    const filterSocial = event.value.toLowerCase();

    if (filterSocial !== 'all') {
      filter = getFilterSocial(filter, filterSocial);
    }
    if (selectedStatus.value.toLowerCase() !== 'all') {
      filter = getFilterStatus(filter, selectedStatus.value.toLowerCase());
    }
    setFilterOption(filter);
    setSelectedSocial(event);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmitDelete = async (suggestedPostId: any) => {
    setLoading(true);
    const data = await deleteSuggestedPost(currentUser?.token, suggestedPostId);
    if (data) {
      setChangeSuggestedPost(!changeSuggestedPost);
    }
  };

  const deleteAlert = (suggestedPostId: number) => {
    Swal.fire({
      text: t('suggested_post:confirmation_delete_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmitDelete(suggestedPostId);
      }
    });
  };

  const showSuggestedPost = (suggestedPost: any) => {
    Swal.fire({
      width: '40em',
      icon: 'warning',
      title: suggestedPost.name,
      html: `<div style="text-align: left">     
      <b>${t('suggested_post:description_sn')}: </b> <span>${
        suggestedPost.description
      }</span> <br> <br>
      <b>${t('suggested_post:social_networks_content')}: </b> <br> 
      <label>${suggestedPost.includesFacebook ? 'Facebook' : ''}</label>
      <span>${
        suggestedPost?.contentPerSocialNetwork?.facebook?.content
          ? suggestedPost?.contentPerSocialNetwork?.facebook.content
          : ''
      }</span> <br>   
      <label>${suggestedPost.includesTwitter ? 'X' : ''}</label>
      <span>${
        suggestedPost?.contentPerSocialNetwork?.twitter?.content
          ? suggestedPost?.contentPerSocialNetwork?.twitter.content
          : ''
      }</span> <br> 
      <label>${suggestedPost.includesLinkedin ? 'Linkedin' : ''}</label>
      <span>${
        suggestedPost?.contentPerSocialNetwork?.linkedin?.content
          ? suggestedPost?.contentPerSocialNetwork?.linkedin.content
          : ''
      }</span> <br> 
      <label>${suggestedPost.includesInstagram ? 'Instagram' : ''}</label>
      <span>${
        suggestedPost?.contentPerSocialNetwork?.instagram?.content
          ? suggestedPost?.contentPerSocialNetwork?.instagram.content
          : ''
      }</span> <br> 
      <label>${suggestedPost.includesWhatsapp ? 'WhatsApp' : ''}</label>
      <span>${
        suggestedPost?.contentPerSocialNetwork?.whatsapp?.content
          ? suggestedPost?.contentPerSocialNetwork?.whatsapp.content
          : ''
      }</span> <br> 
      <label>${suggestedPost.includesTiktok ? 'TikTok' : ''}</label>
      <span>${
        suggestedPost?.contentPerSocialNetwork?.tiktok?.content
          ? suggestedPost?.contentPerSocialNetwork?.tiktok.content
          : ''
      }</span>      
      </div>`,
      customClass: {
        confirmButton: 'bonus-button'
      }
    });
  };

  const newSuggestedPosts = suggestedPosts.filter((info: any) => {
    const data = info;
    const ambassadorName = data?.ambassador?.fullName?.toLowerCase();
    const content = data?.name?.toLowerCase();
    const result =
      ambassadorName.indexOf(filterText.toLowerCase()) != -1 ||
      content.indexOf(filterText.toLowerCase()) != -1;
    return result;
  });

  const currentSuggestedPosts = filterText ? newSuggestedPosts : filterOption;

  return (
    <StyleTable>
      <FilterCampaigns>
        <Input
          type="search"
          placeholder={t('suggested_post:suggested_post_filter')}
          value={filterText}
          onChange={e => {
            setFilterText(e.target.value);
          }}
        />
        <span>{t('campaign_card:filter')}</span>
        <DropdownSelect
          options={statusOptions}
          value={selectedStatus}
          onSelect={selectStatusOption}
        />
        <DropdownSelect
          options={socialOptions}
          value={selectedSocial}
          onSelect={selectSocialOption}
        />
      </FilterCampaigns>
      <HeardMargin>
        <div className="option">
          <img alt="iconEye" src={iconEye} />
          <TextStyled>{t('suggested_post:view_suggested_post')}</TextStyled>
        </div>
      </HeardMargin>
      <TableContainer component={Paper} className="table_container">
        <Table
          sx={{ minWidth: 750 }}
          size="medium"
          aria-label="a dense table"
          className="table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" className="column">
                {t('suggested_post:contents_sn')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('suggested_post:description_sn')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('suggested_post:ambassador')}
              </TableCell>
              <TableCell align="center" className="column">
                {t('suggested_post:social_network')}
              </TableCell>
              <TableCell align="center" className="column"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? currentSuggestedPosts.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : currentSuggestedPosts
            ).map((suggestedPost: any) => {
              const row = suggestedPost;
              return (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    }
                  }}
                  className="column-body"
                >
                  <TableCell
                    component="th"
                    align="center"
                    className="name_content_post"
                  >
                    {row.name.length > 50
                      ? `${row.name.slice(0, 50)}...`
                      : row.name}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {row.description.length > 50
                      ? `${row.description.slice(0, 50)}...`
                      : row.description}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.ambassador.fullName}
                  </TableCell>
                  <TableCell
                    component="th"
                    align="center"
                    className="column-networks"
                    scope="row"
                  >
                    <div className="content-networks">
                      {row.includesFacebook && (
                        <Tag variant="bottom" network="facebook" />
                      )}
                      {row.includesTwitter && (
                        <Tag variant="bottom" network="twitter" />
                      )}
                      {row.includesLinkedin && (
                        <Tag variant="bottom" network="linkedin" />
                      )}
                      {row.includesInstagram && (
                        <Tag variant="bottom" network="instagram" />
                      )}
                      {row.includesWhatsapp && (
                        <Tag variant="bottom" network="whatsapp" />
                      )}
                      {row.includesTiktok && (
                        <Tag variant="bottom" network="tiktok" />
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="center" className="icons">
                    <img
                      alt="iconEye"
                      src={iconEye}
                      onClick={() => showSuggestedPost(row)}
                      className="pointer"
                    />
                    {row.status === 'pending' && (
                      <img
                        alt="iconTrash"
                        src={iconTrash}
                        className="pointer"
                        onClick={() => deleteAlert(row.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500]}
        component="div"
        count={currentSuggestedPosts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyleTable>
  );
};

export default AmbassadorSuggestedPost;
