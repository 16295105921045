import React, { useEffect, useRef, useState } from 'react';

import Button from 'components/buttons/Button';
import Calendar from 'components/Calendar';
import Icon from 'components/icons/Icon';
import EditorInput from 'components/inputs/EditorInput';
import EditorInputLinkedin from 'components/inputs/EditorInput/EditorInputLinkedin';
import Input from 'components/inputs/Input';
import TextArea from 'components/inputs/TextArea';
import UploadFileCropper from 'components/inputs/UploadFileCropper';
import UploadFileImageSimple from 'components/inputs/UploadFile/UploadFileImageSimple';
import UploadFileMultipleImage from 'components/inputs/UploadFile/UploadFileMultipleImage';
import UploadFileVideo from 'components/inputs/UploadFile/UploadFileVideo';
import NavigationLink from 'components/links/NavigationLink';
import Spinner from 'components/Spinner/Spinner';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { Controller, FieldErrors, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import {
  Igroup,
  IGroupAmssador
} from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';
import { CampaignData } from 'services/rest/Campaign/createCampaign';
import { Colors } from 'styles/Constants';
import { Hourt, Minutes } from 'Utils/Hourt';
import { selectUser } from 'redux/slices/userSlice';
import { useSelector } from 'react-redux';

import {
  Column,
  ErrorSpan,
  FlexSection,
  FormTextArea,
  FormWrapper,
  GridComponent,
  InputsWrapper,
  LabelForm,
  MarginButton,
  NetworkFlexContainer,
  NumberWrapper,
  SocialNetworkContent,
  TimeZoneCampaign,
  StyledCheckBoxTerms
} from './Styled';
import { facebookURLs } from 'Utils/FacebookURLs';
import { getLinkedinPost } from 'services/rest/Campaign/getLinkedinPost';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import Tabs from 'components/Tabs';

const https = 'https://';
const www = '(?:www.)';
const fb = 'facebook.com';
const no_mentions = /^(?:(?!\B@).\n*)+$/;
const no_characters = /^(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'"ª|!· º\\,<.>/?]{5}).+/;
const no_links_mentions = /^(?!(https?:\/\/|ftp:\/\/|(?:www\.)?[a-zA-Z0-9.-]+\.(com|net|org|edu|gov|mil|aero|asia|biz|cat|coop|info|int|jobs|mobi|museum|name|pro|tel|travel|arpa|root|nato|xxx|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|ss|st|su|sv|sx|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)(\/[a-zA-Z0-9%_.~+-]*)*(\?[a-zA-Z0-9%_.~+=-]*)?(#[a-zA-Z0-9_.~+-]*)?)|(?:@[a-zA-Z0-9_]+|#\w+)).*$/;

export interface CreateCampaignFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<CampaignData>;
  register: UseFormMethods['register'];
  handleClick?: any;
  handleParticipation: any;
  groupsAmbassador: IGroupAmssador;
  getData?: any;
  handleImage: any;
  control: any;
  handleTypeFile: any;
  errorSocialNetwork?: string;
  fileErrorTwitter?: string;
  fileErrorLinkedin?: string;
  fileErrorInstagram?: string;
  fileErrorTiktok?: string;
  fileErrorWhatsapp?: string;
  loader: any;
  setHourt: any;
  setMinutes: any;
  sHour: any;
  sMinutes: any;
  contentLimits: any;
  invalidLimit: any;
  uploadedTwitter: boolean | null;
  uploadedLinkedin: boolean | null;
  uploadedInstagram: boolean | null;
  uploadedTiktok: boolean | null;
  uploadedWhatsapp: boolean | null;
  linkedinUrn: any;
  setLinkedinUrn: any;
  linkedinUrnError: any;
  setLinkedinUrnError: any;
  checkBox: any;
  setCheckBox: any;
  socialContent: {
    twitter: {
      [key: string]: string;
    };
    linkedin: {
      [key: string]: string;
    };
    tiktok: {
      [key: string]: string;
    };
  };
  setSocialContent: React.Dispatch<
    React.SetStateAction<{
      twitter: {
        [key: string]: string;
      };
      linkedin: {
        [key: string]: string;
      };
      tiktok: {
        [key: string]: string;
      };
    }>
  >;
}

const Option = (
  id: number,
  name: string,
  key: any,
  onChange: any,
  type: string,
  group: Array<Igroup>
) => {
  const select: Igroup = { id: id, name: name };
  const selectGroup = group.find(group => group.id == id);
  return (
    <div
      className="checkbok-wrapper"
      key={key}
      id={id.toString()}
      onClick={() => onChange(select, type)}
    >
      <input
        id={id.toString()}
        type="checkbox"
        onChange={() => onChange(select, type)}
        defaultChecked={!!selectGroup}
        checked={!!selectGroup}
      />
      <Icon
        className="icon-Group"
        name={type == 'group' ? `group` : 'single-user'}
        color="#9b9b9b"
        size="small"
      />
      <label className="label-groups">{name}</label>
    </div>
  );
};

const CreateCampaignForm: React.FC<CreateCampaignFormProps> = props => {
  const {
    errors,
    onSubmit,
    register,
    handleClick,
    groupsAmbassador: { groups = [], ambassador = [] },
    getData,
    handleParticipation,
    handleImage,
    handleTypeFile,
    control,
    errorSocialNetwork = '',
    fileErrorTwitter = '',
    fileErrorLinkedin = '',
    fileErrorInstagram = '',
    fileErrorTiktok = '',
    fileErrorWhatsapp = '',
    loader,
    setHourt,
    setMinutes,
    sHour,
    sMinutes,
    contentLimits,
    invalidLimit,
    uploadedTwitter,
    uploadedLinkedin,
    uploadedInstagram,
    uploadedTiktok,
    uploadedWhatsapp,
    linkedinUrn,
    setLinkedinUrn,
    linkedinUrnError,
    setLinkedinUrnError,
    checkBox,
    setCheckBox,
    socialContent,
    setSocialContent
  } = props;
  const { t } = useTranslation(['campaigns_page']);
  const [onShow, setOnShow] = useState(true);
  const refOne = useRef<HTMLDivElement>(null);
  const [onURL, setOnURL] = useState({ URL: '', isInputValid: true });
  const [onTiktok, setOnTiktok] = useState({ URL: '', isInputValid: true });
  const [onTwitter, setOnTwitter] = useState({ URL: '', isInputValid: true });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { company } = useSelector(selectCompany);
  const [validationInput, setValidationInput] = useState('');
  const [nameInput, setNameInput] = useState('');
  const { currentUser } = useSelector(selectUser);
  const [checked, setChecked] = useState({
    facebook: true,
    twitter: true,
    linkedin: true,
    instagram: true,
    tiktok: true,
    whatsapp: true
  });

  const [file, setFile] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    tiktok: false,
    whatsapp: false
  });

  const [type, setType] = useState({
    twitter: 'text',
    linkedin: 'text',
    instagram: 'text',
    tiktok: 'text',
    whatsapp: 'text'
  });

  const currentDate = new Date();
  const [group, setGroup] = useState<Array<Igroup>>([]);
  const [ambassadors, setAmbassadors] = useState<Array<Igroup>>([]);
  const [inputParticipation, setInputParticipation] = useState('');

  const defaultCurrentTime = (param: any) => {
    const date = new Date();
    const currentTime = date.toLocaleTimeString('en-US', { hour12: false });
    const splitTime = currentTime.split(':');
    return param == 'hour' ? parseInt(splitTime[0]) : parseInt(splitTime[1]);
  };

  const timezone = company?.timezone;

  useEffect(() => {
    handleParticipation(group, ambassadors);
  }, [group, ambassadors]);

  useEffect(() => {
    handleTypeFile(type);
  }, [type]);

  useEffect(() => {
    getData(inputParticipation);
  }, [inputParticipation]);

  useEffect(() => {
    document.addEventListener('click', closeOpenShow, true);
  }, []);

  useEffect(() => {
    setMinutes(defaultCurrentTime('minutes'));
    setHourt(defaultCurrentTime('hour'));
  }, []);

  const closeOpenShow = (e: any) => {
    if (refOne.current && onShow && !refOne.current.contains(e.target)) {
      setOnShow(true);
    }
  };

  const handlRemoveGroup = (idItem: any) => {
    const newGroup = group.filter(group => group.id != idItem);
    setGroup(newGroup);
  };

  const handlRemoveAmbbassador = (idItem: any) => {
    const newGroup = ambassadors.filter(group => group.id != idItem);
    setAmbassadors(newGroup);
  };

  const handleAddGroup = (select: Igroup, type: string) => {
    switch (type) {
      case 'group':
        if (group.length == 0) {
          setGroup([select]);
        } else {
          const selectGroup = group.find(group => group.id == select.id);
          if (selectGroup) {
            handlRemoveGroup(selectGroup.id);
            setInputParticipation('');
            break;
          }
          setGroup([...group, select]);
        }
        setInputParticipation('');
        break;
      case 'ambassadors':
        if (ambassadors.length == 0) {
          setAmbassadors([select]);
        } else {
          const selectGroup = ambassadors.find(group => group.id == select.id);
          if (selectGroup?.id == select.id) {
            handlRemoveAmbbassador(selectGroup.id);
            setInputParticipation('');
            break;
          }
          setAmbassadors([...ambassadors, select]);
        }
        setInputParticipation('');
        break;
    }
  };

  const hourt = Hourt.map(hourt => {
    return { label: hourt, value: hourt };
  });

  const minutes = Minutes.map(minutes => {
    return { label: minutes, value: minutes };
  });

  const onSelectedTypeFile = (socialNetwork: string) => {
    const socialNetworkSelected = file as any;
    const sns = socialNetworkSelected[socialNetwork];
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: !sns
    };
    setFile(socialNetworks);
  };

  const onSelectedType = (socialNetwork: string, typeContent: string) => {
    const socialNetworkSelected = type as any;
    const selectedSNS = socialNetworkSelected[socialNetwork];

    if (selectedSNS === typeContent) {
      const socialNetworks = {
        ...socialNetworkSelected,
        [socialNetwork]: 'text'
      };
      setType(socialNetworks);
    } else {
      const socialNetworks = {
        ...socialNetworkSelected,
        [socialNetwork]: typeContent
      };
      setType(socialNetworks);
    }
  };

  const onSelectedSocialNetwork = (socialNetwork: string) => {
    const socialNetworkSelected = checked as any;
    const sns = socialNetworkSelected[socialNetwork];
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: !sns
    };
    setChecked(socialNetworks);
  };

  const onSelectCalendar = (startDate: Date, endDate: Date) => {
    if (startDate == null) {
      setStartDate(startDate);
    }

    if (endDate == null) {
      setEndDate(endDate);
    }

    handleClick(
      startDate,
      endDate,
      defaultCurrentTime('hour'),
      defaultCurrentTime('minutes')
    );
  };

  const onChange = (e: any) => {
    setInputParticipation(e.target.value);
  };

  const onChangeTypeContent = (content: string, type: string) => {
    onSelectedTypeFile(content);
    onSelectedType(content, type);
  };

  const onChangeHourtMinute = (hour: any, minutes: any) => {
    setHourt(hour);
    setMinutes(minutes);
  };

  const regextValidation = (URL: string, test: any) => {
    const regex = new RegExp(test);
    return regex.test(URL);
  };

  const getLinkedinReshare = async (event: any) => {
    const { value } = event.target ?? '';
    if (value) {
      const lkResponse = await getLinkedinPost(currentUser?.token, value);
      if (
        lkResponse.data.urnLi &&
        ['share', 'ugcPost'].some(val => lkResponse.data.urnLi.includes(val))
      ) {
        const urnLinkedin = lkResponse.data.urnLi.replace(
          'https://www.linkedin.com/feed/update/',
          ''
        );
        setLinkedinUrn(urnLinkedin);
        setLinkedinUrnError('');
      } else {
        setLinkedinUrn('');
        setLinkedinUrnError(t('error_urn_linkedin'));
      }
    }
  };

  const updateUrlVal = (event: any) => {
    setValidationInput('');
    const { value } = event.target;
    if (!regextValidation(value, https) || !value.startsWith(https)) {
      setValidationInput(t('https_errors'));
      return;
    }

    if (!regextValidation(value, www)) {
      setValidationInput(t('www_errors'));
      return;
    }

    if (!regextValidation(value, fb)) {
      setValidationInput(t('facebook_errors'));
      return;
    }

    if (value.length > 200) {
      setValidationInput(t('count_validation'));
      return;
    }

    const isInputValid = !value || facebookURLs(value);
    setValidationInput('');
    setOnURL({
      URL: value,
      isInputValid
    });
  };

  const updateUrlValTiktok = (event: any) => {
    const isInputValid = !event || regextValidationTiktok(event);

    setOnTiktok({
      URL: event,
      isInputValid
    });
  };

  const updateTwitterField = (event: any) => {
    const isInputValid = !event || regextValidationTwitter(event);
    setOnTwitter({
      URL: event,
      isInputValid
    });
  };

  const regextValidationTiktok = (URL: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(URL);
  };

  const regextValidationTwitter = (URL: string) => {
    const regex = new RegExp(no_characters);
    return regex.test(URL);
  };

  const firstName = (e: any) => {
    const text = e.target.value;
    setNameInput(text.charAt(0).toUpperCase() + text.slice(1));
  };

  const open = (urnLi: string) => {
    window.open(
      `https://www.linkedin.com/feed/update/${urnLi}`,
      '_blank',
      'noreferrer'
    );
  };

  const [socialContentTab, setSocialContentTab] = useState({
    twitter: 0,
    linkedin: 0,
    tiktok: 0
  });

  const addSocialContent = (social: 'twitter' | 'linkedin' | 'tiktok') => {
    const keys = Object.keys(socialContent[social]);
    if (keys.length > 2) {
      return;
    }

    setSocialContent(prev => ({
      ...prev,
      [social]: {
        ...prev[social],
        [`content${keys.length + 1}`]: ''
      }
    }));
  };

  const removeSocialContent = (social: 'linkedin' | 'twitter' | 'tiktok') => {
    const keys = Object.keys(socialContent[social]);
    const lastIndex = keys.length;
    const socialContentCopy = { ...socialContent[social] };

    if (lastIndex - 2 >= 0) {
      setSocialContentTab(prev => ({ ...prev, [social]: lastIndex - 2 }));
    }

    if (lastIndex === 1) {
      return;
    }

    delete socialContentCopy[`content${lastIndex}`];

    setSocialContent(prev => ({
      ...prev,
      [social]: socialContentCopy
    }));
  };

  const handleChangeSocialContent = (
    value: string,
    index: number,
    social: 'linkedin' | 'twitter' | 'tiktok'
  ) => {
    setSocialContent(prev => ({
      ...prev,
      [social]: {
        ...socialContent[social],
        [`content${index === 0 ? '' : index + 1}`]: value
      }
    }));
  };

  return (
    <SectionWrapper className="hide-on-mobile">
      <NavigationLink text={t('back')} direction="back" to="/admin/campaigns" />
      <h1>{t('create_campaign')}</h1>
      <form onSubmit={onSubmit}>
        <FormWrapper>
          <InputsWrapper>
            <LabelForm>
              <div>
                <label>{t('name_campaign')}</label>
              </div>
              <div>
                <Input
                  type="text"
                  name="name"
                  ref={register}
                  value={nameInput}
                  error={errors.name?.message}
                  data-testid="name"
                  max={150}
                  maxLength={150}
                  onChange={e => {
                    firstName(e);
                  }}
                />
              </div>
              <div className="marginFile">
                <UploadFileCropper
                  icon="photo"
                  name="photo"
                  handleImage={handleImage}
                  id="content-photo"
                  maxFileSize={5000}
                  ref={register}
                  infoBox={t('info_box_photo_content')}
                />
              </div>
            </LabelForm>
            <LabelForm>
              <div>
                <label>{t('description_campaign')}</label>
              </div>
              <div>
                <TextArea
                  name="description"
                  rows={8}
                  ref={register}
                  error={errors.description?.message}
                  placeholder={t('suggestion_description')}
                  maxLength={500}
                />
              </div>
            </LabelForm>
            <LabelForm>
              <div>
                <label>{t('date_campaign')}</label>
                <p>{t('suggestion_date')}</p>
              </div>
              <GridComponent>
                <FlexSection className="notranslate">
                  <Icon size="big" name="custom-calendar" color="#9b9b9b" />
                  <Calendar
                    onChange={onSelectCalendar}
                    startDate={currentDate}
                    defaultStartDate={startDate}
                    defaultEndDate={endDate}
                    withinSameMonth={!checkBox}
                  />
                </FlexSection>
                <FlexSection>
                  <ReactSelect
                    options={hourt}
                    defaultValue={hourt[defaultCurrentTime('hour')]}
                    onChange={e => onChangeHourtMinute(e?.value, sMinutes)}
                    name="hourtCampaign"
                    className="dropdown-filter-container"
                    classNamePrefix="dropdown-filter-content"
                    isDisabled={startDate === null}
                  />
                  <span id="spanPoint">:</span>
                  <ReactSelect
                    name="hourtCampaign"
                    options={minutes}
                    onChange={e => onChangeHourtMinute(sHour, e?.value)}
                    defaultValue={minutes[defaultCurrentTime('minute')]}
                    className="dropdown-filter-container"
                    classNamePrefix="dropdown-filter-content"
                    isDisabled={startDate === null}
                  />
                  <TimeZoneCampaign>
                    <label>
                      <b>{t('timezone_campaign')}</b>
                    </label>
                    <label>{timezone}</label>
                  </TimeZoneCampaign>
                </FlexSection>
                <FlexSection>
                  <StyledCheckBoxTerms>
                    <Input
                      id="extraordinal"
                      checked={checkBox}
                      name="allowExtraDates"
                      type="checkbox"
                      ref={register}
                      onClick={() => {
                        setCheckBox(!checkBox);
                        setStartDate(new Date());
                        setEndDate(new Date());
                      }}
                    />
                    <label>
                      <p>{t('activate_extraordinary')}</p>
                    </label>
                  </StyledCheckBoxTerms>
                </FlexSection>
              </GridComponent>
            </LabelForm>
            <LabelForm>
              <div>
                <label>{t('participants_campaign')}</label>
                <p>{t('suggestion_participants')}</p>
              </div>
              <div ref={refOne}>
                <FlexSection>
                  <input
                    type="search"
                    name="searcher"
                    autoComplete="off"
                    placeholder={
                      group.length > 0 || ambassadors.length > 0
                        ? `Grupos +${group.length} /Personas +${ambassadors.length}`
                        : 'Buscar grupos o personas'
                    }
                    className={
                      group.length > 0 || ambassadors.length > 0
                        ? `input-search selected`
                        : 'input-search'
                    }
                    value={inputParticipation}
                    onClick={() => {
                      setOnShow(!onShow);
                    }}
                    onChange={e => onChange(e)}
                  />
                </FlexSection>
                {onShow ? (
                  <></>
                ) : (
                  <div className="search-containe notranslate">
                    <div className="search-result notranslate">
                      {groups.map((groups, key: any) =>
                        Option(
                          groups.id,
                          groups.name,
                          key,
                          handleAddGroup,
                          'group',
                          group
                        )
                      )}
                      {ambassador.map((ambassador, key: any) =>
                        Option(
                          ambassador.id,
                          ambassador.name + ' ' + ambassador.lastName,
                          key,
                          handleAddGroup,
                          'ambassadors',
                          ambassadors
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </LabelForm>
            <LabelForm>
              <div>
                <label>{t('social_networks_c')}</label>
                <p>{t('suggestion_social_networks')}</p>
              </div>
              <div className="network">
                <NetworkFlexContainer>
                  <Icon size="small" name="facebook" color={Colors.facebook} />
                  <Input
                    id="sna_facebook"
                    type="checkbox"
                    name="includesFacebook"
                    ref={register}
                    checked={checked.facebook}
                    onChange={() => onSelectedSocialNetwork('facebook')}
                    error={errors.includesFacebook?.message}
                  />
                </NetworkFlexContainer>
                <NetworkFlexContainer>
                  <Icon size="small" name="twitter" color={Colors.twitter} />
                  <Input
                    id="sna_twitter"
                    type="checkbox"
                    name="includesTwitter"
                    ref={register}
                    checked={checked.twitter}
                    onChange={() => onSelectedSocialNetwork('twitter')}
                  />
                </NetworkFlexContainer>
                <NetworkFlexContainer>
                  <Icon size="small" name="linkedin" color={Colors.linkedin} />
                  <Input
                    id="sna_linkedin"
                    type="checkbox"
                    name="includesLinkedin"
                    ref={register}
                    checked={checked.linkedin}
                    onChange={() => onSelectedSocialNetwork('linkedin')}
                  />
                </NetworkFlexContainer>
                <NetworkFlexContainer>
                  <Icon
                    size="small"
                    name="instagram"
                    color={Colors.instagram}
                  />
                  <Input
                    id="sna_instagram"
                    type="checkbox"
                    name="includesInstagram"
                    ref={register}
                    checked={checked.instagram}
                    onChange={() => onSelectedSocialNetwork('instagram')}
                  />
                </NetworkFlexContainer>
                <NetworkFlexContainer>
                  <Icon size="small" name="whatsapp" color={Colors.whatsapp} />
                  <Input
                    id="sna_whatsapp"
                    type="checkbox"
                    name="includesWhatsapp"
                    ref={register}
                    checked={checked.whatsapp}
                    onChange={() => onSelectedSocialNetwork('whatsapp')}
                  />
                </NetworkFlexContainer>
                <NetworkFlexContainer>
                  <Icon size="small" name="tiktok" color={Colors.tiktok} />
                  <Input
                    id="sna_tiktok"
                    type="checkbox"
                    name="includesTiktok"
                    ref={register}
                    checked={checked.tiktok}
                    onChange={() => onSelectedSocialNetwork('tiktok')}
                  />
                </NetworkFlexContainer>
                {errorSocialNetwork && (
                  <ErrorSpan>{errorSocialNetwork}</ErrorSpan>
                )}
              </div>
            </LabelForm>
            <LabelForm>
              <div className="marginLabel">
                <label>{t('score_campaign')}</label>
                <p>{t('suggestion_score')}</p>
              </div>
              <div>
                {checked.facebook && (
                  <div className="marginColumn">
                    <Icon
                      size="small"
                      name="facebook"
                      color={Colors.facebook}
                    />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={10}
                          name="facebook.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('share_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={3}
                          name="facebook.shared"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('comment_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={2}
                          name="facebook.comments"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('reaction_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={1}
                          name="facebook.reactions"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}
                {checked.twitter && (
                  <div className="marginColumn">
                    <Icon size="small" name="twitter" color={Colors.twitter} />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={10}
                          name="twitter.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('retweet_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={2}
                          name="twitter.shared"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('reply_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={2}
                          name="twitter.comments"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('like_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={1}
                          name="twitter.reactions"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}
                {checked.linkedin && (
                  <div className="marginColumn">
                    <Icon
                      size="small"
                      name="linkedin"
                      color={Colors.linkedin}
                    />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={10}
                          name="linkedin.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('comment_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={2}
                          name="linkedin.comments"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('reactions_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={1}
                          name="linkedin.reactions"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}

                {checked.instagram && (
                  <div className="marginColumn">
                    <Icon
                      size="small"
                      name="instagram"
                      color={Colors.instagram}
                    />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={10}
                          name="instagram.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}

                {checked.whatsapp && (
                  <div className="marginColumn">
                    <Icon
                      size="small"
                      name="whatsapp"
                      color={Colors.whatsapp}
                    />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={10}
                          name="whatsapp.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}
                {checked.tiktok && (
                  <div className="marginColumn">
                    <Icon size="small" name="tiktok" color={Colors.tiktok} />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={10}
                          name="tiktok.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('share_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={3}
                          name="tiktok.shared"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('comment_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={2}
                          name="tiktok.comments"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('like_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          defaultValue={1}
                          name="tiktok.reactions"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}

                <div className="borde" />
              </div>
            </LabelForm>
            <FormTextArea>
              <div className="marginLabel">
                <label>{t('contents_sn')}</label>
                <p>{t('suggestion_content')}</p>
              </div>
              <div>
                {checked.facebook && (
                  <SocialNetworkContent>
                    <div>
                      <Icon
                        size="small"
                        name="facebook"
                        color={Colors.facebook}
                      />
                    </div>
                    <label>{t('url_post')}</label>
                    <Input
                      name="contentFacebook"
                      ref={register({
                        required: checked.facebook
                      })}
                      data-testid="name"
                      onChange={updateUrlVal}
                      error={
                        errors.contentFacebook?.type === 'required'
                          ? t('required_field')
                          : ''
                      }
                      type="text"
                      placeholder={t('place_holder_fb')}
                    />
                    {validationInput && (
                      <ErrorSpan>{validationInput}</ErrorSpan>
                    )}
                    {(!onURL.isInputValid ||
                      errors.contentFacebook?.type == 'pattern') && (
                      <ErrorSpan>{t('error_url')}</ErrorSpan>
                    )}
                  </SocialNetworkContent>
                )}
                {checked.twitter && (
                  <SocialNetworkContent className="with-file">
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="twitter"
                          color={Colors.twitter}
                        />
                      </div>
                      <label>{t('description_tweet')}</label>
                      <Tabs
                        className="multiple-contents"
                        orientation="horizontal"
                        tabsTitle={Object.keys(socialContent.twitter).map(
                          (content, index) => ({
                            id: index + 1,
                            label: `${t('campaigns_page:option')} ${index + 1}`
                          })
                        )}
                        tab={socialContentTab.twitter}
                        tabsContent={Object.keys(socialContent.twitter).map(
                          (content, index) => ({
                            id: index + 1,
                            children: (
                              <div className="MarginEditText">
                                <Controller
                                  name={`contentTwitter${
                                    index === 0 ? '' : `${index + 1}`
                                  }`}
                                  control={control}
                                  rules={{
                                    required: checked.twitter,
                                    pattern: no_characters,
                                    maxLength: contentLimits.twitter
                                  }}
                                  defaultValue={
                                    socialContent.twitter[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ]
                                  }
                                  render={({ value, onChange, ref }) => (
                                    <EditorInput
                                      isTwitter={true}
                                      ref={ref}
                                      className="content-editor"
                                      placeholder=""
                                      maxCharacters={contentLimits.twitter}
                                      content={value}
                                      onChange={e => {
                                        onChange(e);
                                        updateTwitterField(e);
                                        handleChangeSocialContent(
                                          e,
                                          index,
                                          'twitter'
                                        );
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            )
                          })
                        )}
                      />

                      <div className="styled_border">
                        <Icon
                          className={
                            type.twitter == 'image' || type.twitter == 'text'
                              ? 'icon-tw-active'
                              : 'icon-tw-noactive'
                          }
                          size="medium"
                          name="photo"
                          color={Colors.twitter}
                          onClick={() =>
                            onChangeTypeContent('twitter', 'image')
                          }
                        />
                        <Icon
                          className={
                            type.twitter == 'video' || type.twitter == 'text'
                              ? 'icon-tw-active'
                              : 'icon-tw-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() =>
                            onChangeTypeContent('twitter', 'video')
                          }
                        />
                        <div className="tabs-controls">
                          {Object.keys(socialContent.twitter).length < 3 && (
                            <Icon
                              className={'icon-ln-active'}
                              size="medium"
                              name="add"
                              color={Colors.twitter}
                              onClick={() => addSocialContent('twitter')}
                            />
                          )}
                          {Object.keys(socialContent.twitter).length > 1 && (
                            <Icon
                              className={'icon-ln-noactive'}
                              size="medium"
                              name="less"
                              color={Colors.twitter}
                              onClick={() => removeSocialContent('twitter')}
                            />
                          )}
                        </div>
                      </div>
                      {errors.contentTwitter?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {fileErrorTwitter && (
                        <ErrorSpan>{fileErrorTwitter}</ErrorSpan>
                      )}
                      {(errors.contentTwitter?.type === 'pattern' ||
                        !onTwitter.isInputValid) && (
                        <ErrorSpan>{t('characters')}</ErrorSpan>
                      )}
                      {invalidLimit.twitter && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.twitter === 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.twitter"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="twitter"
                          ref={register}
                          infoBox={t('info_box_video_twitter')}
                          uploaded={uploadedTwitter}
                        />
                      )}
                      {type.twitter === 'image' && (
                        <UploadFileMultipleImage
                          uploaded={uploadedTwitter}
                          icon="photo"
                          name="contentPerSocialNetwork.twitter"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="twitter"
                          ref={register}
                          infoBox={t('info_box_photo_twitter')}
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
                {checked.linkedin && (
                  <SocialNetworkContent className="with-file">
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="linkedin"
                          color={Colors.linkedin}
                        />
                      </div>
                      <label>{t('description_post_ln')}</label>
                      <Tabs
                        className="multiple-contents"
                        orientation="horizontal"
                        tabsTitle={Object.keys(socialContent.linkedin).map(
                          (content, index) => ({
                            id: index + 1,
                            label: `${t('campaigns_page:option')} ${index + 1}`
                          })
                        )}
                        tab={socialContentTab.linkedin}
                        tabsContent={Object.keys(socialContent.linkedin).map(
                          (content, index) => ({
                            id: index + 1,
                            children: (
                              <div className="MarginEditText">
                                <Controller
                                  name={`contentLinkedin${
                                    index === 0 ? '' : `${index + 1}`
                                  }`}
                                  control={control}
                                  rules={{
                                    required: checked.linkedin,
                                    pattern: no_mentions,
                                    maxLength: contentLimits.linkedin
                                  }}
                                  defaultValue={
                                    socialContent.linkedin[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ]
                                  }
                                  render={({ value, onChange, ref }) => (
                                    <EditorInputLinkedin
                                      isTwitter={false}
                                      ref={ref}
                                      className="content-editor"
                                      placeholder=""
                                      maxCharacters={contentLimits.linkedin}
                                      content={value}
                                      onChange={e => {
                                        onChange(e);
                                        handleChangeSocialContent(
                                          e,
                                          index,
                                          'linkedin'
                                        );
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            )
                          })
                        )}
                      />

                      <div className="styled_border">
                        <Icon
                          className={
                            type.linkedin == 'image' || type.linkedin == 'text'
                              ? 'icon-ln-active'
                              : 'icon-ln-noactive'
                          }
                          size="medium"
                          name="photo"
                          color={Colors.twitter}
                          onClick={() =>
                            onChangeTypeContent('linkedin', 'image')
                          }
                        />
                        <Icon
                          className={
                            type.linkedin == 'video' || type.linkedin == 'text'
                              ? 'icon-ln-active'
                              : 'icon-ln-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() =>
                            onChangeTypeContent('linkedin', 'video')
                          }
                        />
                        <Icon
                          className={
                            type.linkedin == 'pdf' || type.linkedin == 'text'
                              ? 'icon-ln-active'
                              : 'icon-ln-noactive'
                          }
                          size="medium"
                          name="pdf"
                          color={Colors.twitter}
                          onClick={() => onChangeTypeContent('linkedin', 'pdf')}
                        />
                        {Object.keys(socialContent.linkedin).length == 1 && (
                          <Icon
                            className={
                              type.linkedin == 'share' ||
                              type.linkedin == 'text'
                                ? 'icon-ln-active'
                                : 'icon-ln-noactive'
                            }
                            size="medium"
                            name="link"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('linkedin', 'share')
                            }
                          />
                        )}
                        {type.linkedin != 'share' && (
                          <div className="tabs-controls">
                            {Object.keys(socialContent.linkedin).length < 3 && (
                              <Icon
                                className={'icon-ln-active'}
                                size="medium"
                                name="add"
                                color={Colors.twitter}
                                onClick={() => addSocialContent('linkedin')}
                              />
                            )}
                            {Object.keys(socialContent.linkedin).length > 1 && (
                              <Icon
                                className={'icon-ln-noactive'}
                                size="medium"
                                name="less"
                                color={Colors.twitter}
                                onClick={() => removeSocialContent('linkedin')}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      {type.linkedin == 'share' && (
                        <>
                          <label>{t('url_post')}</label>
                          {linkedinUrn && (
                            <Icon
                              className="open"
                              size="small"
                              name="eye"
                              onClick={() => open(linkedinUrn)}
                              color="#0077b5"
                            />
                          )}
                          <Input
                            name="contentLinkedinShare"
                            ref={register({
                              required: checked.linkedin
                            })}
                            type="text"
                            placeholder={t('placeholder_lk')}
                            data-testid="name"
                            onChange={getLinkedinReshare}
                            error={
                              errors.contentLinkedin?.type === 'required'
                                ? t('required_field')
                                : ''
                            }
                          />
                        </>
                      )}
                      {errors.contentLinkedin?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {fileErrorLinkedin && (
                        <ErrorSpan>{fileErrorLinkedin}</ErrorSpan>
                      )}
                      {invalidLimit.linkedin && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                      {type.linkedin == 'share' && linkedinUrnError && (
                        <ErrorSpan>{linkedinUrnError}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.linkedin == 'pdf' && (
                        <UploadFileImageSimple
                          icon="pdf"
                          name="contentPerSocialNetwork.linkedin"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="linkedin"
                          maxFileSize={10000}
                          ref={register}
                          infoBox={t('info_box_pdf_linkedin')}
                          allowedFileTypes="application/pdf"
                        />
                      )}
                      {type.linkedin == 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.linkedin"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="linkedin"
                          ref={register}
                          maxFileSize={200000}
                          maxFileDuration={600}
                          infoBox={t('info_box_video_linkedin')}
                          uploaded={uploadedLinkedin}
                        />
                      )}
                      {type.linkedin == 'image' && (
                        <UploadFileMultipleImage
                          uploaded={uploadedLinkedin}
                          icon="photo"
                          name="contentPerSocialNetwork.linkedin"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="linkedin"
                          ref={register}
                          infoBox={t('info_box_photo_linkedin')}
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
                {checked.instagram && (
                  <SocialNetworkContent className="with-file">
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="instagram"
                          color={Colors.instagram}
                        />
                      </div>
                      <label>{t('description_post')}</label>
                      <div className="MarginEditText">
                        <Controller
                          name="contentInstagram"
                          control={control}
                          rules={{
                            maxLength: contentLimits.instagram
                          }}
                          render={({ value = '', onChange, ref }) => (
                            <EditorInput
                              isTwitter={false}
                              ref={ref}
                              className="content-editor"
                              placeholder=""
                              maxCharacters={contentLimits.instagram}
                              content={value}
                              onChange={e => {
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="styled_border">
                        <Icon
                          className={
                            type.instagram == 'image' ||
                            type.instagram == 'text'
                              ? 'icon-ins-active'
                              : 'icon-ins-noactive'
                          }
                          size="medium"
                          name="photo"
                          color={Colors.twitter}
                          onClick={() =>
                            onChangeTypeContent('instagram', 'image')
                          }
                        />
                        <Icon
                          className={
                            type.instagram == 'video' ||
                            type.instagram == 'text'
                              ? 'icon-ins-active'
                              : 'icon-ins-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() =>
                            onChangeTypeContent('instagram', 'video')
                          }
                        />
                      </div>
                      {errors.contentInstagram?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {fileErrorInstagram && (
                        <ErrorSpan>{fileErrorInstagram}</ErrorSpan>
                      )}
                      {invalidLimit.instagram && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.instagram == 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.instagram"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="instagram"
                          maxFileSize={30000}
                          maxFileDuration={60}
                          ref={register}
                          infoBox={t('info_box_video_instagram')}
                          uploaded={uploadedInstagram}
                        />
                      )}
                      {type.instagram == 'image' && (
                        <UploadFileImageSimple
                          icon="photo"
                          name="contentPerSocialNetwork.instagram"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="instagram"
                          maxFileSize={5000}
                          ref={register}
                          infoBox={t('info_box_photo_instagram')}
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
                {checked.whatsapp && (
                  <SocialNetworkContent className="with-file">
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="whatsapp"
                          color={Colors.whatsapp}
                        />
                      </div>
                      <label>{t('description_wa')}</label>
                      <div className="MarginEditText">
                        <Controller
                          name="contentWhatsapp"
                          control={control}
                          rules={{
                            maxLength: contentLimits.whatsapp
                          }}
                          render={({ value = '', onChange, ref }) => (
                            <EditorInput
                              isTwitter={false}
                              ref={ref}
                              className="content-editor"
                              placeholder=""
                              maxCharacters={contentLimits.whatsapp}
                              content={value}
                              onChange={e => {
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="styled_border">
                        <Icon
                          className={
                            type.whatsapp == 'image' || type.whatsapp == 'text'
                              ? 'icon-ins-active'
                              : 'icon-ins-noactive'
                          }
                          size="medium"
                          name="photo"
                          color={Colors.twitter}
                          onClick={() =>
                            onChangeTypeContent('whatsapp', 'image')
                          }
                        />
                        <Icon
                          className={
                            type.whatsapp == 'video' || type.whatsapp == 'text'
                              ? 'icon-ins-active'
                              : 'icon-ins-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() =>
                            onChangeTypeContent('whatsapp', 'video')
                          }
                        />
                      </div>
                      {errors.contentWhatsapp?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {fileErrorWhatsapp && (
                        <ErrorSpan>{fileErrorWhatsapp}</ErrorSpan>
                      )}
                      {invalidLimit.whatsapp && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.whatsapp == 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.whatsapp"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="whatsapp"
                          maxFileSize={30000}
                          maxFileDuration={60}
                          ref={register}
                          infoBox={t('info_box_video_whatsapp')}
                          uploaded={uploadedWhatsapp}
                        />
                      )}
                      {type.whatsapp == 'image' && (
                        <UploadFileImageSimple
                          icon="photo"
                          name="contentPerSocialNetwork.whatsapp"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="whatsapp"
                          maxFileSize={5000}
                          ref={register}
                          infoBox={t('info_box_photo_whatsapp')}
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
                {checked.tiktok && (
                  <SocialNetworkContent className="with-file">
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="tiktok"
                          color={Colors.tiktok}
                        />
                      </div>
                      <label>{t('description_tk')}</label>
                      <Tabs
                        className="multiple-contents"
                        orientation="horizontal"
                        tabsTitle={Object.keys(socialContent.tiktok).map(
                          (content, index) => ({
                            id: index + 1,
                            label: `${t('campaigns_page:option')} ${index + 1}`
                          })
                        )}
                        tab={socialContentTab.tiktok}
                        tabsContent={Object.keys(socialContent.tiktok).map(
                          (content, index) => ({
                            id: index + 1,
                            children: (
                              <div className="MarginEditText">
                                <Controller
                                  name={`contentTiktok${
                                    index === 0 ? '' : `${index + 1}`
                                  }`}
                                  control={control}
                                  rules={{
                                    required: checked.tiktok,
                                    pattern: no_links_mentions,
                                    maxLength: contentLimits.tiktok
                                  }}
                                  defaultValue={
                                    socialContent.tiktok[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ]
                                  }
                                  render={({ value, onChange, ref }) => (
                                    <EditorInput
                                      isTwitter={false}
                                      ref={ref}
                                      className="content-editor"
                                      placeholder=""
                                      maxCharacters={contentLimits.tiktok}
                                      content={value}
                                      onChange={e => {
                                        onChange(e);
                                        updateUrlValTiktok(e);
                                        handleChangeSocialContent(
                                          e,
                                          index,
                                          'tiktok'
                                        );
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            )
                          })
                        )}
                      />

                      <div className="styled_border">
                        <Icon
                          className={
                            type.tiktok == 'video' || type.tiktok == 'text'
                              ? 'icon-ins-active'
                              : 'icon-ins-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() => onChangeTypeContent('tiktok', 'video')}
                        />
                        <div className="tabs-controls">
                          {Object.keys(socialContent.tiktok).length < 3 && (
                            <Icon
                              className={'icon-ln-active'}
                              size="medium"
                              name="add"
                              color={Colors.twitter}
                              onClick={() => addSocialContent('tiktok')}
                            />
                          )}
                          {Object.keys(socialContent.tiktok).length > 1 && (
                            <Icon
                              className={'icon-ln-noactive'}
                              size="medium"
                              name="less"
                              color={Colors.twitter}
                              onClick={() => removeSocialContent('tiktok')}
                            />
                          )}
                        </div>
                      </div>
                      {errors.contentTiktok?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {fileErrorTiktok && (
                        <ErrorSpan>{fileErrorTiktok}</ErrorSpan>
                      )}
                      {(errors.contentTiktok?.type === 'pattern' ||
                        !onTiktok.isInputValid) && (
                        <ErrorSpan>{t('pattern_tiktok')}</ErrorSpan>
                      )}
                      {invalidLimit.tiktok && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.tiktok == 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.tiktok"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="tiktok"
                          maxFileSize={30000}
                          maxFileDuration={300}
                          ref={register}
                          infoBox={t('info_box_video_tiktok')}
                          uploaded={uploadedTiktok}
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
              </div>
            </FormTextArea>
            <MarginButton>
              {!loader ? (
                <Button value={t('create_campaign')} variant="primary" />
              ) : (
                <Spinner name="sign-up" />
              )}
            </MarginButton>
          </InputsWrapper>
        </FormWrapper>
      </form>
    </SectionWrapper>
  );
};

export default CreateCampaignForm;
