import Client from 'config/axios';

export const getAward = async (token: string, type: string) => {
  return await Client.get('get-ambassador-awards-by-groups', {
    params: {
      type: type
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data)
    .catch(error => error);
};
