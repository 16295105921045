import React, { SyntheticEvent } from 'react';

import {
  PositionColumn,
  Column,
  UserNameHeader,
  Row,
  Table,
  Th,
  SocialInfoStyled
} from './StyledCampaignDetailTable';

import EmployeeAvatar from 'images/avatars/employee.png';
import iconStar from 'images/icons/star.svg';
import { useTranslation } from 'react-i18next';

export interface CampaignDetailTableProps {
  employees: any;
  currentEmployeeId: any;
}

const total_points = (total_points: any) => {
  return (
    <SocialInfoStyled>
      <div className="totalPoints">
        <b>{total_points ? total_points : 0}</b>
        <img alt="iconStar" src={iconStar} />
      </div>
    </SocialInfoStyled>
  );
};

const CampaignDetailTable: React.FC<CampaignDetailTableProps> = props => {
  const { employees, currentEmployeeId } = props;
  const { t } = useTranslation(['campaigns_page', 'group_page']);

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  return (
    <Table>
      <thead>
        <Row>
          <Th />
          <UserNameHeader>{t('group_page:name')}</UserNameHeader>
          <UserNameHeader className="center">
            {t('campaigns_page:total_points')}
          </UserNameHeader>
          <Th />
        </Row>
      </thead>
      <tbody>
        {employees.map((employee: any, index: any) => {
          const isCurrent = currentEmployeeId === employee.employeeId;
          return (
            <Row key={index} activeRow={isCurrent}>
              <PositionColumn collapsed>
                <div className="id">
                  <img
                    src={employee.photo || EmployeeAvatar}
                    className="user-photo"
                    alt="User"
                    onError={evt => addDefaultPicture(evt)}
                  />
                </div>
              </PositionColumn>

              <Column>
                {employee.name} {employee.lastName}
              </Column>
              <Column className="center">
                {total_points(employee.participations[0]?.totalPoints)}
              </Column>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};

export default CampaignDetailTable;
