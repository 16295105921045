import React, { Suspense, useState } from 'react';
import { List as LoadingState } from 'react-content-loader';
import { UseFormMethods, FieldErrors } from 'react-hook-form';

import { PasswordData } from 'services/rest/changePassword';

import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';

import { ButtonStyled } from '../Styled';
import ReactPasswordChecklist from 'react-password-checklist';
import {
  StyledForm,
  InputWrapper,
  InputChecklist
} from 'pages/Settings/ChangePassword/Styled';

export interface ChangePasswordProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<PasswordData>;
  register: UseFormMethods['register'];
  loader: boolean;
  t: any;
  showPassword: boolean;
  onClickEye: any;
}

const ChangePassword: React.FC<ChangePasswordProps> = props => {
  const {
    errors,
    onSubmit,
    register,
    loader,
    t,
    showPassword,
    onClickEye
  } = props;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);

  return (
    <>
      <StyledForm onSubmit={onSubmit}>
        <InputWrapper>
          <label>{t('settings:current_password')}</label>
          <Input
            name="currentPassword"
            type="password"
            ref={register}
            error={errors.currentPassword?.message}
            data-testid="password"
          />
        </InputWrapper>
        <InputWrapper>
          <label>{t('settings:new_password')}</label>
          <Input
            name="newPassword"
            type="password"
            ref={register}
            error={errors.newPassword?.message}
            data-testid="newPassword"
            onClickEye={onClickEye}
            showPassword={showPassword}
            onChange={e => setPassword(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <label>{t('settings:confirm_password')}</label>
          <Input
            name="confirmPassword"
            type="password"
            ref={register}
            error={errors.confirmPassword?.message}
            data-testid="confirmPassword"
            className="confirm-password"
            onClickEye={onClickEye}
            showPassword={showPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
        </InputWrapper>
        <InputChecklist>
          <ReactPasswordChecklist
            rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
            minLength={8}
            value={password}
            valueAgain={confirmPassword}
            messages={{
              minLength: 'La contraseña tiene más de 8 caracteres.',
              specialChar: 'La contraseña tiene caracteres especiales.',
              number: 'La contraseña tiene un número.',
              capital: 'La contraseña tiene una letra mayúscula.',
              match: 'Las contraseñas coinciden.'
            }}
            onChange={isValid => setValidPassword(isValid)}
          />
        </InputChecklist>

        <ButtonStyled>
          <Button
            value={t('settings:change_password')}
            variant="primary"
            loader={loader}
            disabled={!validPassword}
          />
        </ButtonStyled>
      </StyledForm>
    </>
  );
};
export default ChangePassword;
