import Client from 'config/axios';

export const getGroupAmbassador = async (
  token: string,
  page: number,
  limit: 500,
  text: string
) => {
  return await Client.get('search-for-ambassadors-and-groups?', {
    params: {
      text: text,
      page: page,
      limit: limit
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
