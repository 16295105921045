import Client from 'config/axios';

export interface Ambassador {
  id: number;
  name: string;
  lastName: string;
  role: string;
  language: string;
  email: string;
  phone: string;
  code: string;
  allowBonus: boolean;
  allowSuggestedPost: boolean;
  companyId: number;
  groups: any;
  groupsIds: [];
}

export const updateAmbassador = async (
  token: string,
  ambassador: Ambassador
) => {
  try {
    const { data } = await Client.post(
      `ambassador/${ambassador?.id}`,
      ambassador,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return { data: data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.response.response.data };
  }
};
