import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { DivIcon, GridWrapper, MarginText } from './Styled';
import iconTrash from 'images/icons/trash.png';
import Tag from 'components/Tag/Tag';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import useIsAdmin from 'hooks/useIsAdmin.hook';
import DefaultImage from 'images/empty_states/empty_state_search.png';
import { deleteAward } from 'services/rest/Award/deleteAward';
import { useFetch } from 'hooks/useFetch.hook';
import { awardCampaignBonusValidate } from 'services/rest/Award/awardCampaignBonusValidate';

export interface CampaignsAdminCardProp {
  key: number;
  id: any;
  group: string;
  photoUrl: string;
  periodValue: string;
  description: string;
  iconEdit: string;
}

const AwardSingleCard: React.FC<CampaignsAdminCardProp> = props => {
  const {
    key,
    id,
    group,
    photoUrl,
    periodValue,
    description,
    iconEdit
  } = props;
  const { currentUser } = useSelector(selectUser);
  const history = useHistory();
  const { t } = useTranslation(['campaign_card']);
  const { data } = useFetch(awardCampaignBonusValidate(currentUser?.token, id));

  const message = data as any;

  const onSubmit = async (token: any, awardId: any) => {
    const { data } = await deleteAward(token, awardId);
    if (data.data) {
      window.location.reload();
    }
  };

  const confirmAlert = (awardId: string) => {
    Swal.fire({
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('awards_section:yes'),
      cancelButtonText: t('awards_section:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(currentUser?.token, awardId);
      }
    });
  };

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = DefaultImage;
  };

  return (
    <GridWrapper key={key} className="hide-on-mobile hoverable small">
      {group && <Tag value={group} variant="invert" />}
      <div className="content-box-header content-img">
        <img
          alt={description}
          className="image-label"
          onError={evt => addDefaultPicture(evt)}
          src={photoUrl || DefaultImage}
        />
      </div>
      <MarginText className="m-auto">
        <p className="time-line">{periodValue}</p>
        <div className={description.length > 100 ? 'overflow-elipsis' : ''}>
          <h2>{description}</h2>
        </div>
      </MarginText>
      {useIsAdmin() && (
        <DivIcon>
          <div>
            <button
              onClick={() => history.replace({ pathname: `/award/${id}/edit` })}
              aria-label="Edit"
            >
              <img alt="iconEdit" src={iconEdit} />
            </button>
          </div>
          <div>
            <button onClick={() => confirmAlert(id)} aria-label="Delete">
              <img alt="Trash icon" src={iconTrash} />
            </button>
          </div>
        </DivIcon>
      )}
    </GridWrapper>
  );
};

export default AwardSingleCard;
