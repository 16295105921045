import Client from 'config/axios';

export interface TwoFactorData {
  code: string;
  secret2FA?: string;
}

export const validate2FACode = async (
  token: string,
  twoFactorData: TwoFactorData
): Promise<boolean> => {
  const { data } = await Client.post(
    'validate-code-2FA',
    {
      code: twoFactorData?.code,
      secret2FA: twoFactorData?.secret2FA
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  );
  return data?.data;
};
