import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FacebookShareWrapper, AdviceBox, ShareActionsWrapper } from './Styled';
import Button from 'components/buttons/Button';

import { usePostActions } from '../PostActions';
import {
  AcceptPostRequest,
  verifyTokenSocialNetwork
} from 'services/rest/post';

import FacebookPrivacyEs from 'images/icons/facebook_privacy_es.svg';
import FacebookPrivacyEn from 'images/icons/facebook_privacy_en.svg';
import FacebookPrivacyPt from 'images/icons/facebook_privacy_pt.svg';

import FacebookInstructionsEs from 'images/backgrounds/share_fb_es.gif';
import FacebookInstructionsEn from 'images/backgrounds/share_fb_en.gif';
import FacebookInstructionsPt from 'images/backgrounds/share_fb_pt.gif';
import { CampaignParams } from 'pages/CampaignDetailPage/CampaignDetailPage';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';

const privacyImages: Record<string, string> = {
  es: FacebookPrivacyEs,
  en: FacebookPrivacyEn,
  pt: FacebookPrivacyPt
};

const shareInstructions: Record<string, string> = {
  es: FacebookInstructionsEs,
  en: FacebookInstructionsEn,
  pt: FacebookInstructionsPt
};

export interface FacebookShareCardProps {
  postId: number;
  originalContent: string;
  provider: string;
  setShowButton?: any;
  getRankingData?: any;
  setPublicationLoader: any;
}

const FacebookShareCard: React.FC<FacebookShareCardProps> = props => {
  const { campaignId } = useParams<CampaignParams>();
  const { currentUser } = useSelector(selectUser);
  const {
    originalContent,
    postId,
    provider,
    setShowButton,
    getRankingData,
    setPublicationLoader
  } = props;
  const { t, i18n } = useTranslation([
    'content_editor',
    'validations',
    'settings'
  ]);
  const { onPublishPost, onErrorPost } = usePostActions();
  const [loaderShare, setLoaderShare] = useState(false);
  const [loaderFinish, setLoaderFinish] = useState(false);

  const onClickShare = async () => {
    setLoaderShare(true);
    const { data, errors } = await verifyTokenSocialNetwork(
      provider,
      currentUser?.token
    );
    if (data) {
      setLoaderShare(false);
      window.open(
        `https://facebook.com/sharer/sharer.php?u=${originalContent}&display=popup&ref=plugin&src=post`,
        'popup',
        'width=300,height=400,left=100,top=100,resizable=1'
      );
    }
    if (errors) {
      onErrorPost(t('settings:error_admin'));
      if (errors && errors.data) {
        onErrorPost(errors.data);
      }
      setLoaderShare(false);
    }
  };

  const onPublish = async () => {
    setLoaderFinish(true);
    setPublicationLoader(true);
    const { data, errors } = await AcceptPostRequest(
      Number(campaignId),
      originalContent,
      provider,
      currentUser?.token,
      '',
      false,
      ''
    );
    if (data) {
      onPublishPost(postId, data.data);
      setLoaderFinish(false);
      setPublicationLoader(false);
      setShowButton('published');
      getRankingData();
    }
    if (errors) {
      onErrorPost(t('settings:error_admin'));
      if (errors && errors.data) {
        onErrorPost(errors.data);
      }
      setLoaderFinish(false);
      setPublicationLoader(false);
      setShowButton('pending');
    }
  };

  return (
    <FacebookShareWrapper>
      <h2>{t('facebook_steps')}</h2>

      <AdviceBox>
        <ol>
          <li className="share">
            {t('click_on')}&nbsp;
            <strong>Share</strong>
            {t('share_facebook_content')}
          </li>

          <li>
            <span className="public-img">
              {t('check_post_is_public')}&nbsp;
              <img src={privacyImages[i18n.language]} alt="Facebook privacy" />
              &nbsp;{t('see_gif')}
            </span>
          </li>

          <li className="finish">
            {t('click_on')}&nbsp;
            <strong>{t('finish')}</strong>
            {t('sum_points')}
          </li>
        </ol>
      </AdviceBox>

      <img
        className="fb-share-instructions"
        src={shareInstructions[i18n.language]}
        alt="Facebook share instructions"
      />

      {/* {(company?.company_permission.allowFacebook ||
        company?.company_permission?.allowTest) && ( */}
      <ShareActionsWrapper>
        <Button
          icon="facebook"
          variant="facebook"
          value="Share"
          size="medium"
          onClick={onClickShare}
          loader={loaderShare}
        />
        <Button
          onClick={onPublish}
          className="finish"
          value={t('finish')}
          size="medium"
          loader={loaderFinish}
        />
      </ShareActionsWrapper>
      {/* )} */}
    </FacebookShareWrapper>
  );
};

export default FacebookShareCard;
