import styled, { css } from 'styled-components';

interface BoxWrapperProps {
  orientation?: string;
}

export const BoxWrapper = styled.div<BoxWrapperProps>`
  .MuiBox-root {
    ${({ orientation }) =>
      orientation === 'vertical' &&
      css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        .MuiTabs-vertical {
          grid-column: span 2;
        }
        .TabsPanel-box {
          grid-column: span 10;
          padding: 0 45px;
        }
      `}
    .multiple-contents {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
    .MuiButtonBase-root {
      color: #4d4d4d;
      font-size: 1rem;
      font-family: inherit;
      text-transform: none;
    }
    .MuiButtonBase-root.Mui-selected {
      font-weight: 600;
    }
  }
`;
