import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

import BackgroundDots from 'images/backgrounds/background_dots.png';

const SectionWrapper = styled.section`
  padding: 0 20px 30px;
  h1 {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 28px;
    @media (max-width: ${Breakpoints.medium}) {
      font-size: 18px;
    }
  }

  @media (min-width: ${Breakpoints.large}) {
    padding: 0 60px 70px;
  }

  @media (min-width: ${Breakpoints.xLarge}) {
    padding: 0 130px 50px;
  }
  div.section-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  &.blue-wrapper {
    background: #f1f8ff;
    padding-top: 30px;
  }

  &.dots-wrapper {
    background-image: url(${BackgroundDots});
    background-color: ${Colors.normalWhite};
    background-repeat: no-repeat;
    background-size: 100%;
  }

  h1.section-title,
  h2.section-title {
    color: ${Colors.normalBlack};
    font-weight: bold;
  }

  h1.section-title {
    letter-spacing: -1px;
    font-size: 18px;

    @media (min-width: ${Breakpoints.medium}) {
      font-size: 28px;
    }
  }

  h2.section-title {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 16px;

    @media (min-width: ${Breakpoints.medium}) {
      font-size: 20px;
    }
  }
`;

export default SectionWrapper;
