import React from 'react';
import { ContentBlock, CompositeDecorator } from 'draft-js';
import TwitterText from 'twitter-text';

import { Mention, Hashtag, Url } from './Styled';

type Callback = (start: number, end: number) => void;

interface StrategyComponentProps {
  offsetKey: string;
}

const findFromList = (text: string, list: string[], callback: Callback) => {
  list.forEach(item => {
    const regex = new RegExp(`@\\[${item}\\]`, 'g');
    let match, indexStart;

    while ((match = regex.exec(text)) !== null) {
      indexStart = match.index;
      callback(indexStart, indexStart + match[0].length);
    }
  });
};

const findFromListHastags = (
  text: string,
  list: string[],
  callback: Callback
) => {
  list.forEach(item => {
    const regex = new RegExp(item, 'g');
    let match, indexStart;

    while ((match = regex.exec(text)) !== null) {
      indexStart = match.index;
      callback(indexStart, indexStart + match[0].length);
    }
  });
};

export const extractMentionsLinkedin = (text: string): string[] => {
  const mentionRegex = /@\[[^\]]+\]/g;
  const matches = text.match(mentionRegex);

  if (matches) {
    return matches.map((mention: string) => {
      const cleanMention = mention.match(/@\[(.*?)\]/)?.[1] || '';
      return cleanMention;
    });
  }

  return [];
};

export const extractMentions = (text: string) =>
  TwitterText.extractMentions(text).map((mention: string) => `@${mention}`);

export const extractHashtags = (text: string) =>
  TwitterText.extractHashtags(text).map((hashtag: string) => `#${hashtag}`);

export const extractUrls = (text: string) => TwitterText.extractUrls(text);

const mentionStrategyLinkedin = (
  contentBlock: ContentBlock,
  callback: Callback
) => {
  const text = contentBlock.getText();

  const mentions = extractMentionsLinkedin(text);

  findFromList(text, mentions, callback);
};

const mentionStrategy = (contentBlock: ContentBlock, callback: Callback) => {
  const text = contentBlock.getText();
  const mentions = extractMentions(text);

  findFromListHastags(text, mentions, callback);
};

const hashtagStrategy = (contentBlock: ContentBlock, callback: Callback) => {
  const text = contentBlock.getText();
  const hashtags = extractHashtags(text);

  findFromListHastags(text, hashtags, callback);
};

const urlStrategy = (contentBlock: ContentBlock, callback: Callback) => {
  const text = contentBlock.getText();
  const urls = extractUrls(text);

  findFromListHastags(text, urls, callback);
};

const MentionSpan: React.FC<StrategyComponentProps> = props => {
  const { children, offsetKey } = props;

  return <Mention data-offset-key={offsetKey}>{children}</Mention>;
};

const HashtagSpan: React.FC<StrategyComponentProps> = props => {
  const { children, offsetKey } = props;
  return <Hashtag data-offset-key={offsetKey}>{children}</Hashtag>;
};

const UrlAnchor: React.FC<StrategyComponentProps> = props => {
  const { children, offsetKey } = props;

  return <Url data-offset-key={offsetKey}>{children}</Url>;
};

const TwitterDecorator = () => {
  return new CompositeDecorator([
    {
      strategy: mentionStrategy,
      component: MentionSpan
    },
    {
      strategy: hashtagStrategy,
      component: HashtagSpan
    },
    {
      strategy: urlStrategy,
      component: UrlAnchor
    }
  ]);
};

const LinkedinDecorator = () => {
  return new CompositeDecorator([
    {
      strategy: mentionStrategyLinkedin,
      component: MentionSpan
    },
    {
      strategy: hashtagStrategy,
      component: HashtagSpan
    },
    {
      strategy: urlStrategy,
      component: UrlAnchor
    }
  ]);
};

export {
  TwitterDecorator,
  LinkedinDecorator,
  MentionSpan,
  HashtagSpan,
  UrlAnchor,
  mentionStrategy,
  hashtagStrategy,
  urlStrategy
};
