import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { defaultProfilePicture } from 'helpers/profilePictureHelper';

import Button from 'components/buttons/Button';
import {
  UserHeader,
  AdminInfo,
  ButtonMargin,
  UserDataContainer,
  AdminButton
} from './StyledEmployeeCard';

export interface AdminCardProps {
  userPhoto: string;
  userName: string;
}

const AdminCard: React.FC<AdminCardProps> = props => {
  const { t } = useTranslation(['admin_dashboard']);
  const history = useHistory();

  const handlerCLick = () => {
    history.replace('campaigns/admin/new');
  };
  const { userPhoto, userName } = props;
  return (
    <>
      <UserHeader>
        <AdminInfo>
          <UserDataContainer>
            <img
              src={userPhoto || defaultProfilePicture(userName)}
              alt="userPhoto"
            />
            <div className="texts">
              <h1>{t('admin_dashboard:welcome')}</h1>
            </div>
          </UserDataContainer>
          <AdminButton>
            <Button
              value={t('admin_dashboard:create_campaign')}
              variant="primary"
              className="hide-on-mobile"
              onClick={handlerCLick}
            />
          </AdminButton>
        </AdminInfo>
      </UserHeader>
      <ButtonMargin className="show-on-mobile">
        <Button
          value={t('admin_dashboard:create_campaign')}
          variant="primary"
        />
      </ButtonMargin>
    </>
  );
};

export default AdminCard;
