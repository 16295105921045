import React from 'react';
import AwardsCarousel from './AwardsCarousel';
import { IAward } from 'redux/slices/Award/Award.interface';

export interface AwardsListProps {
  awards: Array<IAward>;
}

const AwardsList: React.FC<AwardsListProps> = props => {
  const { awards } = props;

  return (
    <div className="show-on-mobile">
      <AwardsCarousel awards={awards} />
    </div>
  );
};

export default AwardsList;
