export const validFbUrl = (str: string) => {
  const urlPattern = /^(https?:\/\/)?(www.)?facebook.com\/[a-zA-Z0-9(.)?]/;
  return !!urlPattern.test(str);
};
export const validTwUrl = (str: string) => {
  const urlPattern = /^(https?:\/\/)?(www.)?x.com\/[a-zA-Z0-9(.)?]/;
  return !!urlPattern.test(str);
};
export const validLkUrl = (str: string) => {
  const urlPattern = /^(https?:\/\/)?(www.)?linkedin.com\/[a-zA-Z0-9(.)?]/;
  return !!urlPattern.test(str);
};
export const validIgUrl = (str: string) => {
  const urlPattern = /^(https?:\/\/)?(www.)?instagram.com\/[a-zA-Z0-9(.)?]/;
  return !!urlPattern.test(str);
};
export const validTkUrl = (str: string) => {
  const urlPattern = /^(?:https?:\/\/)?(?:www.)?tiktok.com\/.*$/;
  return !!urlPattern.test(str);
};
export const validSnapUrl = (str: string) => {
  const urlPattern = /^(?:https?:\/\/)?(?:www.)?snapchat.com\/.*$/;
  return !!urlPattern.test(str);
};
export const validYtUrl = (str: string) => {
  const urlPattern = /^(?:https?:\/\/)?(?:www.)?(?:youtube.com\/|youtu.be\/).*$/;
  return !!urlPattern.test(str);
};
export const validThUrl = (str: string) => {
  const urlPattern = /^(?:https?:\/\/)?(?:www.)?threads.net\/.*$/;
  return !!urlPattern.test(str);
};
export const validOthersUrl = (str: string) => {
  const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})(\/[\w.-]*)*\/?$/;
  return !!urlPattern.test(str);
};
