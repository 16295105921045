import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import NavigationLink from 'components/links/NavigationLink';
import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';
import { SectionForm, InputWrapper, ButtonStyled } from '../../Styled';
import Spinner from 'components/Spinner/Spinner';
import { arrayOrderForSelect } from 'helpers/arrayOrderForSelect';
import Select from 'components/inputs/Select';
import DropdownSearchable from 'components/inputs/DropdownSearchable';
import { AdministratorData } from 'services/rest/SuperAdmin/Administrator/createAdministrator';

export interface CreateAdminFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<AdministratorData>;
  register: UseFormMethods['register'];
  setValue: any;
  control: any;
  loader: boolean;
  companies: any;
  selectCompany: any;
  setSelectCompany: any;
  setErrors: any;
}

const CreateAdminForm: React.FC<CreateAdminFormProps> = props => {
  const {
    onSubmit,
    errors,
    register,
    loader,
    companies,
    selectCompany,
    setSelectCompany,
    setValue,
    setErrors
  } = props;
  const { t } = useTranslation(['super_admin_administrator']);

  const options = companies?.map((company: any) => ({
    value: company.id,
    label: company.name
  }));

  useEffect(() => {
    register({ name: 'companyId' });
  }, []);

  return (
    <SectionWrapper>
      <NavigationLink
        text={t('super_admin_administrator:form:back')}
        direction="back"
        to={'/administrators'}
      />
      <h1>{t('super_admin_administrator:create_admin')}</h1>
      <form onSubmit={onSubmit}>
        <SectionForm>
          <InputWrapper>
            <label>{t('super_admin_administrator:form:company')}</label>
            <div className="w-100">
              <DropdownSearchable
                name="companyId"
                isSearchable
                placeHolder="Select..."
                options={options}
                onChange={(option: any) => {
                  setValue('companyId', option.value);
                  setSelectCompany(option.value);
                  setErrors('companyId', ' ');
                }}
                error={errors.companyId?.type}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_administrator:form:name')}</label>
            <div className="w-100">
              <Input
                name="name"
                type="text"
                placeholder={t('super_admin_administrator:form:name')}
                ref={register}
                error={errors.name?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_administrator:form:email')}</label>
            <div className="w-100">
              <Input
                name="email"
                type="text"
                placeholder={t('super_admin_administrator:form:email')}
                ref={register}
                error={errors.email?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_administrator:form:username')}</label>
            <div className="w-100">
              <Input
                name="username"
                type="text"
                placeholder={t('super_admin_administrator:form:username')}
                ref={register}
                error={errors.username?.message}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <label>{t('super_admin_administrator:form:password')}</label>
            <div className="w-100">
              <Input
                name="password"
                type="password"
                placeholder={t('super_admin_administrator:form:password')}
                ref={register}
                error={errors.password?.message}
                autoComplete="new-password"
              />
            </div>
          </InputWrapper>
        </SectionForm>
        <ButtonStyled>
          {!loader ? (
            <Button
              value={t('super_admin_administrator:create_admin')}
              variant="primary"
            />
          ) : (
            <Spinner name="sign-up" />
          )}
        </ButtonStyled>
      </form>
    </SectionWrapper>
  );
};

export default CreateAdminForm;
