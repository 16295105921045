import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { List as LoadingState } from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Input from 'components/inputs/Input';
import Icon from 'components/icons/Icon';
import Button from 'components/buttons/Button';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { Filter, StyleTable, HeaderSection, ButtonSection } from '../Styled';
import iconTrash from 'images/icons/trash.png';
import { useFetch } from 'hooks/useFetch.hook';
import { getCurrencies } from 'services/rest/SuperAdmin/Currency/getCurrencies';
import { selectUser } from 'redux/slices/userSlice';

const Currencies = () => {
  const { t } = useTranslation(['super_admin_currency']);
  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const { data } = useFetch(getCurrencies(currentUser?.token));
  const currencies = data as any;

  const go = (id: number) => {
    history.replace(`/currency/updateCurrency/${id}`);
  };
  return (
    <SectionWrapper>
      <StyleTable>
        <HeaderSection>
          <Filter>
            <Input
              type="search"
              placeholder={t('super_admin_currency:search')}
            />
          </Filter>
          <ButtonSection>
            <Button
              value={t('super_admin_currency:create_currency')}
              variant="primary"
              onClick={() => history.replace({ pathname: `/currency/new` })}
            />
          </ButtonSection>
        </HeaderSection>
        <Suspense fallback={<LoadingState height="230px" width="100%" />}>
          <TableContainer component={Paper} className="table_container">
            <Table
              sx={{ minWidth: 750 }}
              size="medium"
              aria-label="a dense table"
              className="table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="column">
                    {t('super_admin_currency:id')}
                  </TableCell>
                  <TableCell align="center" className="column">
                    {t('super_admin_currency:name')}
                  </TableCell>
                  <TableCell align="center" className="column">
                    {t('super_admin_currency:code')}
                  </TableCell>
                  <TableCell align="center" className="column">
                    {t('super_admin_currency:sign')}
                  </TableCell>
                  <TableCell align="center" className="column">
                    {t('super_admin_currency:value')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currencies?.map((currency: any) => {
                  return (
                    <TableRow
                      key={currency.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                      className="column-body"
                    >
                      <TableCell align="center" component="th" scope="row">
                        {currency.id}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {currency.name}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {currency.code}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {currency.sign}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {currency.value}
                      </TableCell>
                      <TableCell align="center">
                        <Icon
                          name="edit"
                          size="small"
                          onClick={() => go(currency.id)}
                        />
                        <img alt="iconTrash" src={iconTrash} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Suspense>
      </StyleTable>
    </SectionWrapper>
  );
};

export default Currencies;
