import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { Login2FAData } from 'services/rest/authentication';
import AdvocatesProLogo from 'images/logos/advocates_logo.svg';
import { LogoWrapper, PoweredByWrapper, StyledForm } from './Styled';
import { InputWrapper } from 'components/inputs/VerticalWrapper';
import Button from 'components/buttons/Button';
import { RequiredString } from 'helpers/yupHelper';
import { BrowsersFooter } from 'components/Footer';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCompanyStyle } from 'redux/slices/CompanyStyle/CompanyStyleSlice';

export interface Login2FAFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<Login2FAData>;
  register: UseFormMethods['register'];
  loader: boolean;
}

export const userSchema = yup.object().shape({
  code2FA: RequiredString().min(6, 'two_factor_authentication.code_length')
});

const Login2FAForm: React.FC<Login2FAFormProps> = props => {
  const { errors, onSubmit, register, loader } = props;
  const { t } = useTranslation(['two_factor_authentication']);
  const { companyStyle } = useSelector(selectCompanyStyle);

  return (
    <>
      <NavLink to="/" className="logo">
        <LogoWrapper>
          <>
            <img
              className="hide-on-mobile"
              src={companyStyle?.logo}
              alt="Home"
            />
            <img
              className="show-on-mobile mobile-logo"
              src={companyStyle?.logo}
              alt="Home"
            />
          </>
        </LogoWrapper>
      </NavLink>
      <StyledForm onSubmit={onSubmit}>
        <InputWrapper
          label={t('two_factor_authentication:login_title')}
          name="code2FA"
          type="number"
          ref={register}
          error={errors.code2FA?.message}
          data-testid="code2FA"
        />

        <Button
          value={t('two_factor_authentication:button_check')}
          variant="primary"
          loader={loader}
        />
      </StyledForm>
      <PoweredByWrapper>
        <p>Powered By</p>
        <img src={AdvocatesProLogo} alt="AdvocatesPro" />
      </PoweredByWrapper>

      <BrowsersFooter />
    </>
  );
};

export default Login2FAForm;
