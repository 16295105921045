import React from 'react';

import { logout } from 'redux/slices/userSlice';
import { createLog } from 'services/rest/Log/createLog';

export const userLogout = async (
  currentUser: any,
  userData: any,
  dispatch: any,
  history: any
) => {
  const body = {
    logType: 'logout',
    user: {
      companyId: userData.companyId,
      sub: userData.id,
      role: currentUser.role,
      userInfo: {
        name: userData.name,
        lastName: userData.lastName,
        username: userData.username,
        email: userData.email
      }
    }
  };
  const data = await createLog(body);

  if (data) {
    history.replace({ pathname: '/' });
    dispatch(logout());
  }
};
