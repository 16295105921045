import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import UpdateAdmin from 'pages/SuperAdmin/Administrators/UpdateAdmin';
import CreateAdmin from 'pages/SuperAdmin/Administrators/CreateAdmin';

const SuperAdminAdministratorsRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/new`}>
        <CreateAdmin />
      </Route>
      <Route exact path={`${match.path}/updateAdmin/:adminId`}>
        <UpdateAdmin />
      </Route>
    </Switch>
  );
};

export default SuperAdminAdministratorsRouter;
