import React, { Suspense, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { List as LoadingState } from 'react-content-loader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { selectUser } from 'redux/slices/userSlice';
import { RequiredString } from 'helpers/yupHelper';
import { getInfoUser } from 'services/rest/User/getInfoUser';
import { validatePassword, Data } from 'services/rest/Admin/validatePassword';
import { AdminData, updateAdmin } from 'services/rest/Admin/updateAdmin';
import iconPadlock from 'images/icons/padlock.png';
import Button from 'components/buttons/Button';
import Input from 'components/inputs/Input';
import Spinner from 'components/Spinner/Spinner';
import { ErrorAlert, SuccessAlert } from 'components/Alert';

import {
  Wrapper,
  IconSection,
  TextSection,
  ButtonSection,
  BadgetSection,
  FormSection,
  Form,
  FormWrapper,
  InputWrapper
} from './Styled';

const TwoFABadget = () => {
  const { t } = useTranslation(['settings', 'validations']);
  const history = useHistory();
  const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = useState<string | null>(null);
  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<any>();

  const userSchema = yup.object().shape({
    currentPassword: RequiredString().min(8, t('validations:min_error'))
  });

  const { register, handleSubmit, errors } = useForm<Data>({
    resolver: yupResolver(userSchema)
  });

  const getUserData = async () => {
    const data = await getInfoUser(
      currentUser?.user?.id,
      currentUser?.token,
      currentUser?.role
    );
    if (data) {
      setUserData(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const onSubmit = async (body: Data) => {
    setLoader(true);
    const isValid = await validatePassword(
      currentUser?.user?.id,
      currentUser?.token,
      body
    );
    if (isValid) {
      if (!userData?.allow2FA) {
        history.replace({ pathname: `/two-factor-authentication` });
      } else {
        const adminData = {} as AdminData;
        adminData.name = userData?.name;
        adminData.email = userData?.email;
        adminData.phone = userData?.phone;
        adminData.allow2FA = false;
        adminData.role = currentUser?.role;

        const { data, errors } = await updateAdmin(
          currentUser?.user?.id,
          currentUser?.token,
          adminData
        );

        if (data) {
          setLoading(true);
          getUserData();
          setShowPasswordForm(false);
          setLoading(false);
          setUpdateSuccess(t('settings:two_factor_disabled'));
        }
        if (errors) {
          setBackendError(t('settings:error_admin'));
        }
      }
    } else {
      setBackendError(t('password_error'));
    }
    setLoader(false);
  };

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      {updateSuccess && (
        <SuccessAlert
          text={updateSuccess}
          allowClose={true}
          onClose={() => setUpdateSuccess(null)}
        />
      )}
      <Wrapper>
        {!showPasswordForm ? (
          <BadgetSection>
            <IconSection>
              <img alt="iconPadlock" src={iconPadlock} />
            </IconSection>
            <TextSection>
              <h3>
                {!userData?.allow2FA ? t('not_enabled_2FA') : t('enabled_2FA')}
              </h3>
              {!userData?.allow2FA && <p>{t('not_enabled_2FA_description')}</p>}
            </TextSection>
            <ButtonSection>
              <Button
                value={
                  !userData?.allow2FA
                    ? t('enabled_2FA_button')
                    : t('disabled_2FA_button')
                }
                variant="secondary"
                onClick={() => {
                  setShowPasswordForm(true);
                }}
              />
            </ButtonSection>
          </BadgetSection>
        ) : (
          <FormSection>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormWrapper>
                <InputWrapper>
                  <label>{t('settings:current_password')}</label>
                  <div className="w-100">
                    <Input
                      name="currentPassword"
                      type="password"
                      ref={register}
                      error={errors.currentPassword?.message}
                      data-testid="password"
                    />
                  </div>
                </InputWrapper>
                {loader ? (
                  <Spinner name="sign-up" />
                ) : (
                  <ButtonSection>
                    <Button
                      value={t('cancel')}
                      variant="secondary"
                      onClick={() => {
                        setShowPasswordForm(false);
                      }}
                    />
                    <Button value={t('continue')} variant="primary" />
                  </ButtonSection>
                )}
              </FormWrapper>
            </Form>
          </FormSection>
        )}
      </Wrapper>
    </Suspense>
  );
};

export default TwoFABadget;
