import Axios from 'axios';
import Client from 'config/axios';

export const GetSignedUrlS3 = async (
  token: string,
  name: string,
  type: string,
  subdomain?: string
) => {
  let params = {};
  if (subdomain) {
    params = {
      fileName: name,
      type: type,
      subdomain: subdomain
    };
  } else {
    params = {
      fileName: name,
      type: type
    };
  }
  return await Client.get('get-signed-url-s3', {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const UploadSignedUrlToS3 = (url: string, file: any, format?: any) => {
  const config = {
    url: `${url}`
  };

  return Axios.put(config.url, file, {
    headers: { 'Content-Type': format, 'Content-Encoding': 'base64' }
  });
};
