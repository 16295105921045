import styled from 'styled-components';

import { Colors } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export const GridWrapper = styled.div`
  @media only screen and (max-width: 900px) {
    width: 120%;
  }

  ${BaseBox};

  .content-img {
    width: 150px;
    height: 150px;
    min-height: inherit !important;
    display: flex !important;
    align-items: center !important;
  }

  img {
    width: 100%;
    height: inherit;
    object-fit: contain;
  }

  display: grid;
  grid-template-columns: 200px 10fr 2fr;

  .time-line {
    margin: 0 0 5px 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 14px;
    color: ${Colors.primaryNormalPurple};
    text-transform: capitalize;
  }
  .social-networks {
    display: flex;
  }
  .format {
    margin-left: 15px;
  }
  .subtitle {
    margin-right: 5px;
    font-weight: bold;
  }
`;

export const MarginText = styled.div`
  padding: 5px;
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;

  &.m-auto{
    margin: auto 0px;
  }

  div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    h2 {
      font-weight: bold;
      position: relative;
      font-size: 18px;
      margin: 0;
    }
  }
}
`;

export const DivIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    width: 25px;
    margin: 5px;
  }
`;

export const DivIconChallenge = styled.div`
  display: flex;
  zoom: 80%;
  img {
    width: 50px;
  }
`;

export const Card = styled.div`
  margin: 0 5px 5px;

  p {
    width: auto;
    height: 80px;
  }
`;

export const EmptyCard = styled.div`
  margin: 0 10px 10px;
  width: 100%;
`;

export const MarginImg = styled.div`
  img {
    width: 80%;
    display: block;
    margin: 0px auto;
    min-width: 350px;
    min-height: 350px;
    max-width: 350px;
    max-height: 350px;
    object-fit: contain;
  }
`;
