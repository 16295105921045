import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';

const Th = styled.th`
  font-size: 0.8125rem;
  text-align: left;
  font-weight: 700;
  padding: 0px;

  @media (min-width: ${Breakpoints.medium}) {
    font-size: 1.125rem;
    padding: 0px 20px;
  }

  &.text-center {
    text-align: center;
    span {
      margin: 0 auto;
    }
  }
`;

export default Th;
