import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './CompanyMetricsSection.state';
import { ICompanyMetrics } from './CompanyMetrics.interface';

const setCompanyMetricsLoading = (state: any, action: { payload: any }) => {
  return {
    ...state,
    loading: action.payload
  };
};

const setCompanyMetrics = (
  state: any,
  action: { payload: ICompanyMetrics }
) => {
  return {
    ...state,
    companyMetrics: action.payload
  };
};

export const selectCompanyMetrics = (state: { companyMetrics: any }) =>
  state.companyMetrics;

const CompanyMetricsSlice = createSlice({
  name: 'companyMetricsSlice',
  initialState: {
    loading: false,
    companyMetrics: initialState
  },
  reducers: {
    setLoading: setCompanyMetricsLoading,
    setMetrics: setCompanyMetrics
  }
});

export const { setLoading, setMetrics } = CompanyMetricsSlice.actions;

export default CompanyMetricsSlice.reducer;
