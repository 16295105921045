import React, { Suspense, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Facebook as LoadingState } from 'react-content-loader';

import RankingTable from 'components/RankingTable';
import TableFooter from 'components/TableFooter';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';
import RankingFilters, { PlainFilters } from './RankingFilters';
import { getRanking } from 'services/rest/Ranking/Ranking';
import { selectUser, selectUserInfo } from 'redux/slices/userSlice';
import { getGroupsList } from 'services/rest/Group/getGroupsList';
import {
  selectRanking,
  setLoading,
  setRankingList
} from 'redux/slices/Ranking/RankingSlice';
import { parsePlainArray } from './helpers';
import { sortGroups } from 'helpers/groupsHelper';
import { createGenericTableReport } from 'Utils/Excel';
import { getInfoUser } from 'services/rest/User/getInfoUser';

export type Role = 'ambassador' | 'admin';

const RankingSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dates', 'ranking_filters']);
  const { currentUser } = useSelector(selectUser);
  const currentMonth = moment().format('M') as any;

  const periodTypeOptions = [
    { label: t('ranking_filters:month'), value: 'month' },
    { label: t('ranking_filters:quarter'), value: 'quarter' },
    { label: t('ranking_filters:year'), value: 'year' },
    { label: t('ranking_filters:custom'), value: 'custom' }
  ];

  const monthsData = t('dates:month', {
    returnObjects: true,
    defaultValue: []
  });

  const months = Array.isArray(monthsData)
    ? monthsData.map((month, index) => ({
        label: month,
        value: index + 1
      }))
    : [];

  const quartersData = t('dates:quarter', {
    returnObjects: true,
    defaultValue: []
  });

  const quarters = Array.isArray(quartersData)
    ? quartersData.map((quarter, index) => ({
        label: quarter,
        value: index + 1
      }))
    : [];

  const years = parsePlainArray([
    moment().year() as any,
    (moment().year() - 1) as any,
    (moment().year() - 2) as any,
    (moment().year() - 3) as any,
    (moment().year() - 4) as any,
    (moment().year() - 5) as any
  ]);

  const pointsOptions = [
    { label: t('ranking_filters:total_points'), value: 'totalPoints' },
    {
      label: t('ranking_filters:campaign_points'),
      value: 'campaignPoints'
    },
    { label: t('ranking_filters:bonus_points'), value: 'bonusPoints' }
  ];

  const [periodType, setPeriodType] = useState(periodTypeOptions[0]);
  const [periodValue, setPeriodValue] = useState<any>(months[currentMonth - 1]);
  const [groupsOptions, setGroupsOptions] = useState<any>([]);
  const [name, setName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<any>([]);
  const [year, setYear] = useState<any>(years[0]);
  const [points, setPoints] = useState(pointsOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const { infoUser } = useSelector(selectUserInfo);
  const [userData, setUserData] = useState<any>(infoUser);

  const getUserData = async () => {
    const { data } = await getInfoUser(
      currentUser?.user?.id,
      currentUser?.token,
      currentUser?.role
    );
    if (data) {
      setUserData(data);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getGroupData = async () => {
    const { data } = await getGroupsList(currentUser?.token, 100, 1);
    return data;
  };

  const [filters, setFilters] = useState<PlainFilters | null>({
    periodType: periodType && periodType.value,
    periodValue: periodValue && periodValue.value,
    groupName: groupsOptions.length > 0 && groupsOptions[0].value,
    name: '',
    year: year.value,
    startDate: '',
    finishDate: '',
    points: points && points.value
  });

  const { ranking = [], loading } = useSelector(selectRanking);

  const saveByteArray = (byte: any) => {
    return new Blob([byte], { type: 'xlxs' });
  };

  const getData = async (groupData: any) => {
    let orderGroups;
    if (groupData.data && currentUser.role === 'ambassador') {
      orderGroups = groupData.data.map((group: any) => {
        return { label: group.name, value: group.id };
      });
      setGroupsOptions(orderGroups);
    } else {
      orderGroups = sortGroups(groupData.data.docs).map((group: any) => {
        return { label: group.name, value: group.id };
      });
      setGroupsOptions(orderGroups);
    }
    await getRanking(
      currentUser?.token,
      filters?.periodType,
      Number(filters?.periodValue),
      Number(filters?.year),
      Number(
        selectedGroup.value > 0 ? selectedGroup.value : orderGroups[0].value
      ),
      Number(filters?.periodValue),
      filters?.name,
      filters?.startDate,
      filters?.finishDate,
      filters?.points
    )
      .then(response => {
        dispatch(setRankingList(response.data));
      })
      .catch(error => {
        dispatch(error);
      });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const fetchData = async () => {
      return await getGroupData();
    };
    fetchData()
      .then(response => {
        getData(response);
        dispatch(setLoading(false));
      })
      .catch(error => {
        dispatch(error);
        dispatch(setLoading(false));
      });
  }, [filters]);

  let new_ambassadors = [];
  if (ranking?.ranking?.length > 0) {
    new_ambassadors = ranking?.ranking?.filter((data: any) => {
      const users = data as any;
      const result =
        users?.ambassador.toLowerCase().indexOf(name.toLowerCase()) != -1;
      return result;
    });
  }

  const currentRanking = name
    ? new_ambassadors
    : new_ambassadors?.slice(indexOfFirstPost, indexOfLastPost);

  const onChangeFilters = (filters: any) => {
    setFilters(filters);
  };

  const onDownloadReport = async (documentName: string) => {
    const excelFile = await createGenericTableReport(
      `${t('ranking_filters:excel_header:title')} ${new Date()}`,
      [
        t('ranking_filters:excel_header:position'),
        t('ranking_filters:excel_header:ambassador'),
        t('ranking_filters:campaign_points'),
        t('ranking_filters:bonus_points'),
        t('ranking_filters:total_points')
      ],
      ranking.ranking.map((rankingData: any) => {
        return {
          position: `${rankingData.position}`,
          ambassador: rankingData.ambassador,
          campaignPoints: `${rankingData.campaignPoints}`,
          bonusPoints: `${rankingData.bonusPoints}`,
          totalPoints: `${rankingData.totalPoints}`
        };
      })
    );

    const toBuffer = Buffer.from(excelFile, 'base64');
    const toByteArray = saveByteArray(toBuffer);
    const toLink = window.URL.createObjectURL(toByteArray);

    const a = document.createElement('a');
    a.href = toLink;
    a.download = documentName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <RankingFilters
        userData={userData}
        role={currentUser.role as Role}
        setName={setName}
        name={name}
        periodType={periodType}
        setPeriodType={setPeriodType}
        periodTypeOptions={periodTypeOptions}
        periodValue={periodValue}
        setPeriodValue={setPeriodValue}
        months={months}
        quarters={quarters}
        years={years}
        currentMonth={currentMonth}
        groupsOptions={groupsOptions}
        setSelectedGroup={setSelectedGroup}
        year={year}
        setYear={setYear}
        points={points}
        setPoints={setPoints}
        pointsOptions={pointsOptions}
        onChangeFilters={onChangeFilters}
        onDownloadReport={onDownloadReport}
      />
      {loading && <LoadingState height="230px" width="100%" />}
      {ranking?.ranking?.length > 0 && loading == false ? (
        <RankingTable
          role={currentUser.role as Role}
          employees={currentRanking}
          currentEmployeeId={currentUser.user.id}
          userData={userData}
        />
      ) : (
        <EmptyStateBox />
      )}
      <TableFooter
        postsPerPage={postsPerPage}
        totalPosts={ranking?.ranking?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </Suspense>
  );
};

export default RankingSection;
