import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export const StyledForm = styled.form`
  grid-column: span 9;
  margin-bottom: 0;
  display: grid;

  .confirm-password {
    margin-bottom: 0px !important;
  }
`;

export const InputWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
  @media (max-width: ${Breakpoints.medium}) {
    display: grid;
  }

  label {
    color: #4d4d4d;
    font-size: 1rem;
    font-weight: 600;
    grid-column: span 3;
  }

  input {
    margin: 10px 0;
    @media (max-width: ${Breakpoints.medium}) {
      display: grid;
      width: 100%;
      margin-left: 0%;
    }
  }

  input {
    width: 100%;
    grid-column: span 7;
  }

  span {
    grid-column: span 7;
  }

  div.custom-eye {
    right: 25px !important;
    bottom: 16% !important;
  }
`;

export const InputChecklist = styled.div`
  padding: 3%;
  div {
    margin-top: 5px !important;
  }
`;
