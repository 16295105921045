import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import { selectUser } from 'redux/slices/userSlice';
import { AdminData, updateAdmin } from 'services/rest/Admin/updateAdmin';
import { getInfoUser } from 'services/rest/User/getInfoUser';
import { RequiredString } from 'helpers/yupHelper';

import { ErrorAlert, SuccessAlert } from 'components/Alert';

import AdminProfileForm from './AdminProfileForm';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

const AdminProfile = () => {
  const { currentUser } = useSelector(selectUser);
  const { t } = useTranslation(['settings', 'validations']);
  const [userData, setUserData] = useState<any>();
  const { company } = useSelector(selectCompany);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [phone, setPhone] = useState(userData?.phone);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = useState<string | null>(null);
  const [allowContentsEmail, setAllowContentsEmail] = useState<boolean>(false);

  const userSchema = yup.object().shape({
    name: RequiredString().max(70, t('validations:length_error')),
    email: RequiredString()
      .email(t('validations:email'))
      .max(100, t('validations:length_error', { max: 100 }))
  });
  const { register, handleSubmit, errors } = useForm<AdminData>({
    resolver: yupResolver(userSchema)
  });

  const getUserData = async () => {
    const data = await getInfoUser(
      currentUser?.user?.id,
      currentUser?.token,
      currentUser?.role
    );
    if (data) {
      setUserData(data);
      setAllowContentsEmail(data.allowContentsEmail);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const onSubmit = async (adminData: AdminData) => {
    setLoader(true);
    if (phone) {
      adminData.phone = phone;
    }
    adminData.role = currentUser?.role;

    const { data, errors } = await updateAdmin(
      currentUser?.user?.id,
      currentUser?.token,
      adminData
    );
    if (data) {
      getUserData();
      setUpdateSuccess(t('settings:update_admin'));
      setLoader(false);
    }
    if (errors) {
      setUpdateError(t('settings:error_admin'));
      setLoader(false);
    }
  };

  return (
    <>
      {updateError && (
        <ErrorAlert
          text={updateError}
          allowClose={true}
          onClose={() => setUpdateError(null)}
        />
      )}

      {updateSuccess && (
        <SuccessAlert
          text={updateSuccess}
          allowClose={true}
          onClose={() => setUpdateSuccess(null)}
        />
      )}
      <AdminProfileForm
        infoUser={userData}
        company={company}
        loader={loader}
        loading={loading}
        register={register}
        handleSubmit={handleSubmit(onSubmit)}
        errors={errors}
        setPhone={setPhone}
        allowContentsEmail={allowContentsEmail}
        setAllowContentsEmail={setAllowContentsEmail}
      />
    </>
  );
};

export default AdminProfile;
