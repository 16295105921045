import React from 'react';
import StyledMetricBox, { ValueContainer } from './StyledMetricImgBox';
import PlainNumber from 'components/numbers/PlainNumber';

export interface MetricBoxProps {
  iconName: string;
  value: string;
  text: any;
  money?: string;
  topText?: boolean;
}

const MetricImgBox: React.FC<MetricBoxProps> = props => {
  const { iconName, value, text, topText, money = '' } = props;
  return (
    <StyledMetricBox>
      {topText && <h3>{text}</h3>}

      <img alt="iconName" src={iconName} />

      <ValueContainer>
        <PlainNumber value={value} />
        {money && <label>{money}</label>}
      </ValueContainer>

      {!topText && <h3>{text}</h3>}
    </StyledMetricBox>
  );
};

export default MetricImgBox;
