import React from 'react';

import { Colors } from 'styles/Constants';

import SocialMediaIcon, { SocialMediaIconProps } from './SocialMediaIcon';

import Icon, { IconProps } from './Icon';
import RoundIcon, { RoundIconProps } from './RoundIcon';
import IconWithStatus from './IconWithStatus';

const FacebookIcon = (props: SocialMediaIconProps) => {
  return <SocialMediaIcon {...props} />;
};
FacebookIcon.defaultProps = {
  ...SocialMediaIcon.defaultProps,
  name: 'facebook'
};

const TwitterIcon = (props: SocialMediaIconProps) => {
  return <SocialMediaIcon {...props} />;
};
TwitterIcon.defaultProps = { ...SocialMediaIcon.defaultProps, name: 'twitter' };

const LinkedinIcon = (props: SocialMediaIconProps) => {
  return <SocialMediaIcon {...props} />;
};
LinkedinIcon.defaultProps = {
  ...SocialMediaIcon.defaultProps,
  name: 'linkedin'
};

const InstagramIcon = (props: SocialMediaIconProps) => {
  return <SocialMediaIcon {...props} />;
};
InstagramIcon.defaultProps = {
  ...SocialMediaIcon.defaultProps,
  name: 'instagram'
};

const TiktokIcon = (props: SocialMediaIconProps) => {
  return <SocialMediaIcon {...props} />;
};
TiktokIcon.defaultProps = {
  ...SocialMediaIcon.defaultProps,
  name: 'tiktok'
};

const EditIcon = (props: IconProps) => <Icon {...props} />;
EditIcon.defaultProps = {
  color: Colors.primaryDarkBlue,
  name: 'edit',
  size: 'big'
};

const TrashIcon = (props: IconProps) => <Icon {...props} />;
TrashIcon.defaultProps = {
  color: Colors.inactive,
  hoverColor: Colors.danger,
  name: 'trash-o',
  size: 'big'
};

const WarningIcon = (props: IconProps) => <Icon {...props} />;
WarningIcon.defaultProps = {
  name: 'warning',
  size: 'medium'
};

const TrophyIcon = (props: RoundIconProps) => <RoundIcon {...props} />;
TrophyIcon.defaultProps = {
  name: 'trophy',
  size: 'medium'
};

const CancelIcon = (props: IconProps) => <Icon {...props} />;
CancelIcon.defaultProps = {
  name: 'cancel',
  size: 'medium'
};

export {
  SocialMediaIcon,
  IconWithStatus,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  InstagramIcon,
  TiktokIcon,
  EditIcon,
  TrashIcon,
  WarningIcon,
  TrophyIcon,
  CancelIcon
};
