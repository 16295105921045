import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  GridWrapper,
  MarginDiv,
  MarginDivMobile,
  MobileWrapper
} from '../Styled';

import iconTrash from 'images/icons/trash.png';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { deleteGroup } from 'services/rest/Group/deleteGroup';

import { nFormatter } from 'helpers/formatHelper';
import { ErrorAlert } from 'components/Alert';

export interface GroupCard {
  id: number;
  name: string;
  totalAmbassadors: number;
  connectedAmbassadors: number;
  totalReach: number;
  isAll: boolean;
}

export type GroupAdminCardProp = Array<GroupCard>;

const GroupAdminCard: React.FC<GroupCard> = props => {
  const {
    id,
    name,
    totalAmbassadors,
    connectedAmbassadors,
    totalReach,
    isAll
  } = props;
  const { t } = useTranslation(['group_page']);
  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const [backendError, setBackendError] = useState<string | null>(null);

  const go = (id: number) => {
    history.replace(`/admin/group/detail/${id}`);
  };

  const onSubmit = async (token: any, groupId: any, removePermanent: any) => {
    const { data, errors } = await deleteGroup(token, groupId, removePermanent);
    if (data) {
      window.location.reload();
    }
    if (errors) {
      setBackendError(t('group_page:error_delete_group'));
    }
  };

  const confirmAlert = (groupId: any) => {
    Swal.fire({
      text: t('group_page:confirmation_message_group'),
      input: 'checkbox',
      inputValue: false,
      inputPlaceholder: t('group_page:checkbox_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('group_page:yes'),
      cancelButtonText: t('group_page:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(currentUser?.token, groupId, Boolean(result.value));
      }
    });
  };

  return (
    <>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}
      <GridWrapper className="hide-on-mobile group-card content-box content-box-horizontal flex-box-container hoverable big">
        <MarginDiv onClick={() => go(id)}>
          <h2>{name}</h2>
        </MarginDiv>
        <MarginDiv onClick={() => go(id)} className="division-right">
          <p>{nFormatter(totalAmbassadors)}</p>
          <h4>{t('group_page:guestAmbassadors')}</h4>
        </MarginDiv>
        <MarginDiv onClick={() => go(id)} className="division-right">
          <p>{nFormatter(connectedAmbassadors)}</p>
          <h4>{t('group_page:ambassadors')}</h4>
        </MarginDiv>
        <MarginDiv onClick={() => go(id)}>
          <p>{nFormatter(totalReach)}</p>
          <h4>{t('group_page:totalGroup')}</h4>
        </MarginDiv>
        <MarginDiv
          onClick={() => {
            isAll === false && confirmAlert(id);
          }}
        >
          {isAll === false && <img alt="iconTrash" src={iconTrash} />}
        </MarginDiv>
      </GridWrapper>
      <MobileWrapper className="show-on-mobile">
        <MarginDivMobile>
          <h2>{name}</h2>
          <button onClick={() => confirmAlert(id)}>
            <img alt="iconTrash" src={iconTrash} />
          </button>
        </MarginDivMobile>
        <MarginDivMobile onClick={() => go(id)}>
          <h4>{t('group_page:guestAmbassadors')}</h4>
          <p>{nFormatter(totalAmbassadors)}</p>
        </MarginDivMobile>
        <MarginDivMobile onClick={() => go(id)}>
          <h4>{t('group_page:ambassadors')}</h4>
          <p>{nFormatter(connectedAmbassadors)}</p>
        </MarginDivMobile>
        <MarginDivMobile onClick={() => go(id)}>
          <h4>{t('group_page:totalGroup')}</h4>
          <p>{nFormatter(totalReach)}</p>
        </MarginDivMobile>
      </MobileWrapper>
    </>
  );
};

export default GroupAdminCard;
