import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import CampaignsPage from 'pages/CampaignsPage/CampaignsPage';
import CampaignDetailPage from 'pages/CampaignDetailPage';
import NewCampaignsPage from 'pages/CampaignsAdminPage/NewCampaignsPage/NewCampaignsPage';
import CampaignsAllAdmin from 'pages/CampaignsAdminPage/CampaignsAllAdmin';
import CampaignsAdminDetail from 'pages/CampaignsAdminPage/CampaignsAdminDetail';
import EditCampaignNewPage from 'pages/CampaignsAdminPage/EditCampaignPage/EditCampaignsPage';
import useAdmin from 'hooks/useIsAdmin.hook';

const CampaignsRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/:campaignId`}>
        <CampaignDetailPage />
      </Route>

      <Route exact path={`${match.path}/admin/new`}>
        {useAdmin() ? (
          <NewCampaignsPage />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </Route>

      <Route exact path={`${match.path}/admin/:campaignId/edit`}>
        {useAdmin() ? (
          <EditCampaignNewPage />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </Route>

      <Route exact path={`${match.path}/admin/detail/:campaignId`}>
        {useAdmin() ? (
          <CampaignsAdminDetail />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </Route>

      <Route exact path={`${match.path}/admin/campaignsAll`}>
        {useAdmin() ? (
          <CampaignsAllAdmin />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: 'admin' }
            }}
          />
        )}
      </Route>

      <Route path={`${match.path}`}>
        <CampaignsPage />
      </Route>
    </Switch>
  );
};

export default CampaignsRouter;
