import React from 'react';
import {
  NavBarWrapper,
  NavBarHeader,
  NavBarLogo,
  NavBarLinks
} from './StyledNavBar';

import NavigationLinks, {
  NavigationLinkProps,
  NavigationLinksProps
} from './NavigationLinks';
import MobileLinks from './MobileLinks';

import Dropdown, { DropdownItemProps } from 'components/dropdowns/Dropdown';

import AdvocatesLogo from 'images/logos/advocates_logo.svg';
import AdvocatesLigthLogo from 'images/logos/advocates_light_logo.svg';

export interface NavBarProps extends NavigationLinksProps {
  id?: string;
  className?: string;
  userPhoto: string;
  userName: string;
  actions: Array<DropdownItemProps>;
  loading?: boolean;
}

const NavBar: React.FC<NavBarProps> = props => {
  const { id, className, userPhoto, userName, actions, links, loading } = props;

  const mobileLinks: Array<NavigationLinkProps> = actions.map(action => ({
    text: action.text,
    onClick: action.onClick
  }));

  return (
    <NavBarWrapper id={id} className={className}>
      <NavBarHeader>
        <NavBarLogo>
          <a href="/">
            <img className="dark-logo" src={AdvocatesLogo} alt="Advocates" />
            <img
              className="light-logo"
              src={AdvocatesLigthLogo}
              alt="Advocates"
            />
          </a>
        </NavBarLogo>
        <NavBarLinks>
          {!loading && (
            <>
              <span>{userName}</span>
              <img className="user-photo" src={userPhoto} alt="User" />
              <Dropdown className="desktop-links" items={actions} />
            </>
          )}
        </NavBarLinks>
      </NavBarHeader>
      <MobileLinks
        className="mobile-navigation"
        links={links.concat(mobileLinks)}
      />
      <NavigationLinks
        id="desktop_navigation"
        className="desktop-navigation"
        links={links}
      />
    </NavBarWrapper>
  );
};

export default NavBar;
