import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ChangePasswordForm, { userSchema } from './ChangePasswordForm';
import { ErrorAlert } from 'components/Alert';
import {
  ChangePasswordData,
  ChangePasswordRequest,
  Login
} from 'services/rest/authentication';
import { login } from 'redux/slices/userSlice';
import { getCurrentCompany } from 'helpers/companyHelper';

interface Token {
  token: string;
}

const ChangePasswordPage: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<ChangePasswordData>({
    resolver: yupResolver(userSchema)
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const subdomain = getCurrentCompany();
  const [backendError, setBackendError] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const { token } = useParams<Token>();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (changePasswordData: ChangePasswordData) => {
    changePasswordData.token = token;
    if (changePasswordData.password === changePasswordData.confirmPassword) {
      setLoader(true);
      const { data, errors } = await ChangePasswordRequest(changePasswordData);
      if (data) {
        const {
          data: { email }
        } = data;
        const { password } = changePasswordData;
        setLoader(false);
        dispatch(register());
        const { user, errors } = await Login({ email, password, subdomain });
        if (user) {
          dispatch(login(user));
          history.replace('/dashboard');
        }
        if (errors) {
          setBackendError(errors);
          setLoader(false);
        }
      }
      if (errors) {
        setLoader(false);
        setBackendError(errors.data);
      }
    }
  };

  return (
    <>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      <ChangePasswordForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        loader={loader}
        showPassword={showPassword}
        onClickEye={() => {
          setShowPassword(!showPassword);
        }}
      />
    </>
  );
};

export default ChangePasswordPage;
