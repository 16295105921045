export const sortGroups = (groups: any) => {
  groups.sort((first: any, second: any) =>
    first.name > second.name ? 1 : second.name > first.name ? -1 : 0
  );
  groups.forEach(function (group: any, i: number) {
    if (group.isAll) {
      groups.splice(i, 1);
      groups.unshift(group);
    }
  });
  return groups;
};

export const groupNameByObj = (groups: any) => {
  let response: any = false;
  if (groups) {
    const key = Object.keys(groups);
    if (key && key[0]) {
      response = groups[parseInt(key[0])].name;
    }
  }
  return response;
};
