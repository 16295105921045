import Client from 'config/axios';

export interface AdministratorData {
  name: string;
  username: string;
  email: string;
  password: string;
  companyId: string;
  role: string;
}

export const createAdministrator = async (
  token: string,
  administrator: AdministratorData
) => {
  try {
    const { data } = await Client.post('administrator', administrator, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
