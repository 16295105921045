import { createSlice } from '@reduxjs/toolkit';
import { IGroupAmssador } from './GroupAmbassador.interface';
import { initialState } from './GroupAmbassador.state';

const setGroupAmbassadorLoading = (state: any, action: { payload: any }) => {
  return {
    ...state,
    loading: action.payload
  };
};

const setGroupAmbassador = (
  state: any,
  action: { payload: IGroupAmssador }
) => {
  return {
    ...state,
    groupsAmbassador: action.payload
  };
};

export const selectGroupAmbassador = (state: { groupsAmbassador: any }) =>
  state.groupsAmbassador;

const GroupAmbassadorSlice = createSlice({
  name: 'groupsAmbassador',
  initialState: {
    loading: false,
    groupsAmbassador: initialState
  },
  reducers: {
    setLoadingGroupAmbassador: setGroupAmbassadorLoading,
    setData: setGroupAmbassador
  }
});

export const {
  setLoadingGroupAmbassador,
  setData
} = GroupAmbassadorSlice.actions;

export default GroupAmbassadorSlice.reducer;
