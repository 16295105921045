import Client from 'config/axios';

export interface CountryData {
  name: string;
  defaultTimeZone: string;
  dialCode: string;
  code: string;
}

export const createCountry = async (token: string, country: CountryData) => {
  const body = {
    name: country?.name,
    defaultTimeZone: country?.defaultTimeZone,
    dialCode: country?.dialCode,
    code: country?.code
  };

  try {
    const { data } = await Client.post('country', body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
