import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Logo = styled.div``;
export const InfoBox = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
`;
export const Text = styled.div`
  grid-column: span 6;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px;
  h1 {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
  }
`;
export const Banner = styled.div`
  grid-column: span 6;
  img {
    width: 500px;
  }
`;
