import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';

export const Ranking = styled.div`
  input {
    width: 300px;
    margin-bottom: 20px;
  }
`;

export const RankingScrollContainer = styled.div`
  padding: 0 15px 0 3px;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 7.5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }
`;
