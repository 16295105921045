import { createSlice } from '@reduxjs/toolkit';
import { ICampaigns } from './Campaigns.interface';

const setCampaignsLoading = (state: any, action: { payload: any }) => {
  return {
    ...state,
    loading: action.payload
  };
};

const setCampaigns = (state: any, action: { payload: string }) => {
  return {
    ...state,
    campaigns: action.payload
  };
};

export const selectCampaigns = (state: {
  campaign: { campaings: Array<ICampaigns> };
}) => state.campaign;

const campaignsSlice = createSlice({
  name: 'campaign',
  initialState: {
    loading: false,
    campaigns: []
  },
  reducers: {
    setListCampaigns: setCampaigns,
    setLoading: setCampaignsLoading
  }
});

export const { setListCampaigns, setLoading } = campaignsSlice.actions;

export default campaignsSlice.reducer;
