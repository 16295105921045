import styled, { css } from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import { Table, Th, Tr, Td } from 'components/Table';
import { StyledTooltipWrapper } from 'components/TooltipWrapper/StyledTooltipWrapper';

export { Table, Th };

const Padding60 = css`
  @media (min-width: ${Breakpoints.small}) {
    padding-right: 60px;
  }
`;

export const Row = styled(Tr)`
  &:nth-child(1) {
    svg.trophy {
      fill: ${Colors.gold};
      stroke: ${Colors.gold};
    }
  }

  &:nth-child(2) {
    svg.trophy {
      fill: ${Colors.silver};
      stroke: ${Colors.silver};
    }
  }

  &:nth-child(3) {
    svg.trophy {
      fill: ${Colors.bronze};
      stroke: ${Colors.bronze};
    }
  }

  ${({ activeRow }) =>
    activeRow &&
    css`
      ${PositionColumn} {
        p {
          font-weight: 700;
          color: #ffffff;
        }
      }

      ${StyledTooltipWrapper} {
        svg[name='warning'],
        svg[name='warning']:hover {
          stroke: #ffffff;
          fill: #ffffff;
        }
      }
    `}

  @media only screen and (max-width: ${Breakpoints.medium}) {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
  }
`;

export const PositionColumn = styled(Td)`
  display: flex;
  div.id {
    align-items: center;
    display: flex;
    justify-contain: center;
  }
  div.centro {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img.user-photo {
    border-radius: 100%;
    margin: 0 10px 0 25px;
    height: 50px;
    width: 50px;
  }
  img.deleted {
    border-radius: 0;
  }

  p {
    color: ${Colors.primaryDarkBlue};
    font-weight: 700;
    margin: 1px 0 0 0;

    @media (min-width: ${Breakpoints.medium}) {
      font-size: 1.5rem;
    }
  }

  svg[name='warning'] {
    stroke: #fcba03;
    fill: #fcba03;
  }
  svg[name='error-signal'] {
    stroke: #fcba03;
    fill: Red;
  }
`;

export const TrophyColumn = styled(Td)`
  span.trophy {
    box-shadow: none;
  }

  svg.trophy {
    stroke-width: 20px;
    stroke: #d4d4d4;
    fill: #d4d4d4;
  }

  ${Padding60};
`;

export const Column = styled(Td)`
  &.center {
    text-align: center;
  }

  @media (max-width: ${Breakpoints.medium}) {
    text-align: left;
  }
`;

export const UserNameHeader = styled(Th)`
  &.center {
    text-align: center;
    text-align: -webkit-center;
  }
`;

export const PointsHeader = styled(Th)`
  @media (min-width: ${Breakpoints.small}) {
    padding: 0px;
  }
`;

export const SocialInfoStyled = styled.div`
  div &.contentPoints {
    display: flex;
    justify-content: space-evenly;
    @media (max-width: ${Breakpoints.xxLarge}) {
      flex-direction: column;
      align-items: center;
    }
  }

  img {
    height: 20px;
    width: 20px;
    &.points {
      height: 15px;
      width: 15px;
      margin-left: 5px;
    }
  }

  .totalPoints {
    display: inline-block;
    align-items: center;
  }
`;

export const Status = styled.div`
  float: right;
  margin-left: -10px;
  z-index: 1;
`;
