import camelcaseKeys from 'camelcase-keys';
import Client from 'config/axios';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';

export interface LoginData {
  email: string;
  password: string;
  subdomain: any;
}

export interface Login2FAData {
  code2FA?: string;
}

export interface RegisterData {
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ForgotPasswordData {
  email: string;
}

export interface ChangePasswordData {
  token: string;
  password: string;
  confirmPassword: string;
}

export const LoginRequest = async (loginData: LoginData) => {
  const { data, headers } = await Client.post('login', loginData);
  return {
    data,
    headers: {
      'access-token': headers['access-token'],
      uid: headers['uid'],
      client: headers['client'],
      expiry: headers['expiry']
    }
  };
};

export const ForgotPasswordRequest = async (
  forgotPasswordData: ForgotPasswordData
) => {
  const { data, headers } = await Client.post(
    'reset-password',
    forgotPasswordData
  );
  return {
    data,
    headers: {
      'access-token': headers['access-token'],
      uid: headers['uid'],
      client: headers['client'],
      expiry: headers['expiry']
    }
  };
};

export const ChangePasswordRequest = async (
  changePasswordData: ChangePasswordData
) => {
  try {
    const { data } = await Client.post(
      'reset-password-token-email',
      changePasswordData
    );
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};

export const RegisterRequest = async (registerData: RegisterData) => {
  const { data, headers } = await Client.post('singup', registerData);
  return {
    data,
    headers: {
      'access-token': headers['access-token'],
      uid: headers['uid'],
      client: headers['client'],
      expiry: headers['expiry']
    }
  };
};

export const isLoggedIn = () => {
  const { currentUser } = useSelector(selectUser);
  if (currentUser?.token == null) {
    return false;
  }
  return true;
};

export const Login = async (loginData: LoginData) => {
  try {
    const {
      data: { data: user },
      headers
    } = await LoginRequest(loginData);

    localStorage.setItem('ironmanAuth', JSON.stringify(headers));

    return { user: camelcaseKeys(user) };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data.data };
  }
};

export const Register = async (registerData: RegisterData) => {
  try {
    const {
      data: { data: user },
      headers
    } = await RegisterRequest(registerData);

    localStorage.setItem('ironmanAuth', JSON.stringify(headers));

    return { user: camelcaseKeys(user) };
  } catch ({ response }) {
    return response;
  }
};

export const ForgotPassword = async (
  forgotPasswordData: ForgotPasswordData
) => {
  try {
    const {
      data: { data: user },
      headers
    } = await ForgotPasswordRequest(forgotPasswordData);

    localStorage.setItem('ironmanAuth', JSON.stringify(headers));

    return { user: camelcaseKeys(user) };
  } catch ({ response }) {
    const result = response as any;
    if (result.data?.data) {
      return result;
    }
    return { errors: result.data.errors };
  }
};
