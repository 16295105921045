import Client from 'config/axios';

export const deleteCampaign = async (token: string, campaignId: number) => {
  try {
    const { data } = await Client.delete(`campaign/${campaignId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return { data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.data };
  }
};
