import React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyStateWrapper from './Styled';
import DefaultImage from 'images/empty_states/empty_state_search.png';

export interface EmptyStateBoxProps {
  title?: string;
  description?: string;
  image?: string;
}

const EmptyStateBox: React.FC<EmptyStateBoxProps> = props => {
  const { title, description, image } = props;

  const { t } = useTranslation(['empty_state_box']);

  return (
    <EmptyStateWrapper>
      <h2>{title}</h2>
      <p>{description ?? t('empty_state_box:description')}</p>
      <img alt="EmptyImage" src={image ?? DefaultImage} />
    </EmptyStateWrapper>
  );
};

export default EmptyStateBox;
