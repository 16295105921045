import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers';
import { ErrorAlert } from 'components/Alert';
import Button from 'components/buttons/Button';
import ErrorsReport from 'components/errorsReport';
import Input from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import UploadExcel from 'components/inputs/UploadExcel';
import NavigationLink from 'components/links/NavigationLink';
import { RequiredString } from 'helpers/yupHelper';
import { headerValidations } from 'Utils/ExcelValidation';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectUser } from 'redux/slices/userSlice';
import { GroupData } from 'services/rest/Group/createGroup';
import { getGroupById } from 'services/rest/Group/getGroupById';
import { updateGroup } from 'services/rest/Group/updateGroup';
import { getExcelTemplate } from 'services/rest/Group/getExcelTemplate';
import * as XLSX from 'xlsx';
import * as yup from 'yup';

import { GroupCard } from '../GroupAdminCard/GroupAdminCard';
import { InputWrapper, SectionForm } from '../NewGroupPage/Styled';
import { MarginButton } from '../Styled';
import { MarginUpload, TextForm } from './Styled';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

interface GroupParams {
  groupId: string;
  name: string;
}

const GroupAdminBulkUpload = () => {
  const userSchema = yup.object().shape({
    name: RequiredString()
  });
  const { register, handleSubmit, errors } = useForm<GroupData>({
    resolver: yupResolver(userSchema)
  });

  const { t } = useTranslation(['group_page', 'group_section', 'upload_excel']);
  const { groupId, name } = useParams<GroupParams>();
  const backGroup = `/admin/group/detail/${groupId}`;
  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const [nameFile, setNameFile] = useState('');
  const [records, setRecords] = useState([]);
  const [fileErrors, setFileErrors] = useState(false);
  const [excelError, setExcelError] = useState<any>();
  const [selectedLanguage, setSelectedLanguage] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [backendError, setBackendError] = useState<string | null>(null);
  const [redirectToPage, setRedirectToPage] = useState<string | null>(null);
  const [currentGroup, setCurrentGroup] = useState<GroupCard>();
  const [excelTemplate, setExcelTemplate] = useState<any>();
  const { company } = useSelector(selectCompany);

  const typesOptions = [
    { text: t('spanish'), value: 'es' },
    { text: t('english'), value: 'en' },
    { text: t('portuguese'), value: 'pt' }
  ];

  const translationHeadersData = t('upload_excel:headers', {
    returnObjects: true,
    defaultValue: []
  });

  const translationHeaders = Array.isArray(translationHeadersData)
    ? translationHeadersData
    : [];

  const getData = async () => {
    const { data } = await getGroupById(currentUser?.token, groupId, 'false');
    setCurrentGroup(data.data);
  };

  const getTemplate = async () => {
    const { data } = await getExcelTemplate(currentUser?.token);
    if (data) {
      const toBuffer = Buffer.from(data.data.excel, 'base64');
      const toByteArray = saveByteArray(toBuffer);
      const toLink = window.URL.createObjectURL(toByteArray);
      setExcelTemplate(toLink);
    }
  };

  useEffect(() => {
    getData();
    getTemplate();
  }, []);

  const onSubmit = async (groupData: GroupData) => {
    if (records.length === 0 || nameFile === '') {
      setFileErrors(true);
      return;
    }

    setLoader(true);
    groupData.records = records;
    if (groupData.records.length <= 0) {
      setFileErrors(false);
      setLoader(false);
    } else {
      setFileErrors(false);
      groupData.companyId = currentUser.companyId;
      const param = 'addMassive=true';
      const { data, errors } = await updateGroup(
        currentUser?.token,
        groupId,
        groupData,
        param
      );
      if (data) {
        if (data.data.created) {
          setRedirectToPage('/admin/group?backend_success_group_created=true');
        } else {
          setLoader(false);
          const toBuffer = Buffer.from(data.data.excel, 'base64');
          const toByteArray = saveByteArray(toBuffer);
          const toLink = window.URL.createObjectURL(toByteArray);
          setExcelError(toLink);
        }
      }
      if (errors) {
        setBackendError(errors.data);
        setLoader(false);
      }
    }
  };

  const saveByteArray = (byte: any) => {
    return new Blob([byte], { type: 'xlxs' });
  };

  const filePathset = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setNameFile(e.target.files[0].name);
    setFileErrors(false);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const recordsData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const recordsToJSON = formatRecordsData(recordsData);
      setRecords(recordsToJSON);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const formatRecordsData = (records: any) => {
    records = records.filter((entry: any) => {
      return /\S/.test(entry);
    });
    const result = [] as any;
    const recordsFiltered = records.filter((data: any) => {
      const filtered = data?.filter((item: any) => {
        const value = item.toString().trim();
        return value.length > 0;
      });
      return filtered.length > 0;
    });
    if (recordsFiltered.length > 0) {
      const excelValidation = headerValidations(
        recordsFiltered[0],
        translationHeaders
      );
      if (excelValidation) {
        setBackendError(t(`upload_excel:headers:errors:${excelValidation}`));
        setNameFile('');
      } else {
        if (recordsFiltered.length > 1) {
          const onlyData = recordsFiltered.slice(1);
          if (onlyData.length > company?.ambassadorsLimit) {
            setBackendError(
              t('upload_excel:headers:errors:exceedsCompanyLimits')
            );
            setNameFile('');
          } else {
            onlyData.map((record: any) => {
              const obj = {} as any;
              obj.name = record[0];
              obj.lastName = record[1];
              obj.email = record[2];
              obj.phone = record[3];
              obj.code = record[4];
              result.push(obj);
            });
          }
        } else {
          setBackendError(t('group_section:empty_excel_error'));
          setNameFile('');
        }
      }
    } else {
      setBackendError(t('group_section:empty_excel_error'));
      setNameFile('');
    }
    return result;
  };

  if (redirectToPage) {
    history.replace(redirectToPage);
  }

  return (
    <>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}
      <SectionWrapper>
        <NavigationLink text={t('back')} direction="back" to={backGroup} />
        <h1>{t('addEmployeesGroup')}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SectionForm>
            <InputWrapper>
              <label>{t('name')}</label>
              <Input
                name="name"
                ref={register}
                type="text"
                placeholder={t('name')}
                value={name}
                error={errors.name?.message}
              />
            </InputWrapper>
            <InputWrapper>
              <label className="cutText">{t('selectLanguage')}</label>
              <Select
                className="dropdown-filter-container"
                options={typesOptions}
                value={selectedLanguage}
                name="language"
                data-testid="language"
                register={register}
                onChange={e => setSelectedLanguage(e.target.value)}
              />
            </InputWrapper>
            <TextForm>
              <p>
                {t('upload')}{' '}
                <a
                  href={excelTemplate}
                  download="template.xlsx"
                  target="_blank"
                  rel="noreferrer"
                  role="button"
                >
                  {t('group_page:download_here')}
                </a>
              </p>
            </TextForm>
            <MarginUpload>
              <UploadExcel
                name="records"
                ref={register}
                onChange={filePathset}
                errors={fileErrors}
                nameFile={nameFile}
                setNameFile={setNameFile}
                setExcelError={setExcelError}
              />
            </MarginUpload>
            {excelError && <ErrorsReport file={excelError} />}
          </SectionForm>
          <MarginButton>
            <Button
              value={t('add_employee')}
              variant="primary"
              loader={loader}
            />
          </MarginButton>
        </form>
      </SectionWrapper>
    </>
  );
};

export default GroupAdminBulkUpload;
