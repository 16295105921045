import React from 'react';

import BottonBoxNetwork from './BottonBoxNetwork';

export interface NetworkProps {
  network: string;
  linked: boolean;
}

export interface NetworkParticipationBoxProps {
  items: Array<NetworkProps>;
  setUpdateError: any;
  topText?: boolean;
}

const CardNetwork: React.FC<NetworkParticipationBoxProps> = props => {
  const { items, setUpdateError } = props;
  return (
    <>
      {items
        .slice(0)
        .reverse()
        .map((network, index) => (
          <BottonBoxNetwork
            items={network.network}
            key={index}
            status={network.linked}
            setUpdateError={setUpdateError}
          />
        ))}
    </>
  );
};

export default CardNetwork;
