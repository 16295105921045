import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export const HeardMargin = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  h1 {
    color: ${Colors.primaryDarkBlue};
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 15px;
  }

  p.description {
    margin: 0 0 20px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 15px;
    font-style: italic;
    font-weight: bold;
    color: #333333;
  }

  span {
    margin-bottom: 15px;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    color: ${Colors.secondaryDarkPink};
  }

  .margin_img {
    width: 420px;
    border-radius: 10px;
    height: 420px;
    object-fit: cover;
    background-color: #ffffff;
  }

  .margin_container {
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 100px;
  }

  .people-tags {
    padding-left: 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .circle-tag.plus-tag {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: #004fea;
    background: #f0f0f0;
    font-weight: 500;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .circle_people {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .name_tag_container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
  }

  .name_tag {
    background: #fe135f;
    border-radius: 10px;
  }

  .tag {
    display: inline-block;
    margin: 0 10px;
    color: ${Colors.normalWhite};
    border-radius: 5px;
    padding: 8px 5px;
    font-weight: 400;
    font-size: 16px;
  }
  .action-button {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    svg {
      height: 22px;
      width: 22px;
      margin: 0 5px 0 15px;
    }

    a {
      color: ${Colors.primaryDarkBlue};
      text-transform: uppercase;
      text-decoration: none;
      margin-bottom: 25px;
      font-weight: 500;
      font-size: 12px;
      margin-top: 3px;

      &:focus,
      &:hover {
        color: ${Colors.primaryDarkBlue};
        text-decoration: none;
      }
    }

    .vl {
      border-left: 3px solid ${Colors.primaryDarkBlue};
      height: 25px;
      margin-left: 15px;
    }
  }
  .option {
    display: flex;
    margin-right: 10px;
    img {
      margin-top: 15px;
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }
    svg {
      margin-top: 15px;
      margin-right: 10px;
    }
  }
`;

export const TextStyled = styled.p`
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: ${Colors.secondaryNormalGrey};
`;

export const BodyWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(20, 1fr);

  &.admin-campaign-detail {
    .tooltip-wrapper {
      margin-bottom: 20px;
    }
    .single-data-box {
      margin: 0px;
      height: 100%;
      display: grid;
    }
  }

  &.second {
    grid-template-columns: repeat(15, 1fr);
  }

  @media (min-width: ${Breakpoints.medium}) {
    grid-column-gap: 20px;
  }

  > div {
    grid-column: span 5;
  }

  svg[name='flag'] {
    stroke-width: 30px;
    stroke: ${Colors.primaryDarkBlue};
  }
`;

export const ResumenScrollContainer = styled.div`
  padding: 0 15px 0 3px;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 7.5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }
`;

export const InputStyled = styled.div`
  width: 30%;
`;

export const ButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  button {
    text-align: center;
    margin: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
`;
