import React from 'react';

export const getCurrentCompany = () => {
  const subdomain = window.location.hostname.split('.');
  let response = 'adpro';

  if (!window.location.hostname.includes('.advocatespro.com')) {
    switch (process.env.REACT_APP_ENV) {
      case 'qa':
        response = 'qa';
        break;
      case 'prod':
        response = 'adproqa';
        break;
      default:
        response = 'adpro';
        break;
    }
  } else {
    response = subdomain[0];
  }

  return response;
};

export const getCurrentCompanyStyles = () => {
  const styles = {
    bgColor: '#094964',
    textColor: '#FFFFFF',
    btnBgColor: '#05559C',
    btnTextColor: '#FFFFFF'
  };
  return styles;
};
