import React from 'react';
import { useTranslation } from 'react-i18next';

import Advocates from 'images/logos/advocates_logo.svg';
import Contract from 'images/backgrounds/contract.jpg';

import Button from 'components/buttons/Button';

import { Container, Logo, InfoBox, Text, Banner } from './Styled';

const CompanyDisallowed = () => {
  const { t } = useTranslation('company');
  return (
    <Container>
      <Logo>
        <img alt="company" src={Advocates} />
      </Logo>
      <InfoBox>
        <Text>
          <h1>{t('company_disallowed_title')}</h1>
          {t('company_disallowed_description')}
          <Button
            value={t('contact')}
            variant="primary"
            onClick={() => {
              window.location.assign('https://www.fluvip.com/advocates');
            }}
          />
        </Text>
        <Banner>
          <img alt="contract" src={Contract} />
        </Banner>
      </InfoBox>
    </Container>
  );
};

export default CompanyDisallowed;
