import Client from 'config/axios';

export interface Company {
  id: number;
  name: string;
  currencyId: number;
  language: string;
  TosUrl: string;
  logo: string;
  emailLogo: string;
  backgroundImage: string;
  role: string;
  licenseDate: any;
}

export const updateCompany = async (token: string, company: Company) => {
  try {
    const { data } = await Client.post(`company/${company?.id}`, company, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result?.response?.response?.data };
  }
};
