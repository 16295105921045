import styled from 'styled-components';
import { Colors } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export interface AwardsProps {
  hideOnMobile?: boolean;
}

export const Section_header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;

  button {
    border: 1px solid ${Colors.secondaryNormalPink};
    background-color: ${Colors.secondaryNormalPink};
  }
`;

export const SectionFilter = styled.div`
  display: flex;
  .margin {
    display: flex;
  }
  span {
    margin-top: 10px;
  }
`;
export const SectionCreater = styled.div`
  ${BaseBox}

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;

  img {
    width: 23%;
    height: 100%;
  }

  div {
    h2 {
      font-weight: bold;
      position: relative;
      font-size: 18px;
    }
    p {
      font-weight: 500;
      line-height: 1.5;
      font-size: 12px;
    }
  }

  button {
    margin-top: 20px;
    margin-right: 20px;
    border: 1px solid ${Colors.primaryDarkBlue};
    background-color: ${Colors.primaryDarkBlue};
    background-image: linear-gradient(136deg, #3e65fe, #3ebbfe);
  }
`;

export const MarginAdminPage = styled.div`
  display: flex;

  h2 {
    font-size: 20px;
    color: #4d4d4d;
    font-weight: bold;
  }
`;

export const AwardsContainer = styled.div`
  padding: 20px 0;
`;
