import React, { useEffect, useState, Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import NavigationLink from 'components/links/NavigationLink';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { Employees } from 'components/ResumenTable/ResumenTable';
import { getChallengeById } from 'services/rest/Challenge/getChallengeById';
import DetailChallengeAdmin from './DetailChallengeAdmin';
import DetailChallengeAmbassador from './DetailChallengeAmbassador';

interface challengeParams {
  challengeId: string;
}

export interface challengeDetails {
  id: number;
  name: string;
  photo: any;
  content: string;
  startDate: string;
  finishDate: string;
  groupsIds: any;
  allParticipants: any;
  bonusList: any;
}

const DetailChallengesPage = () => {
  const { challengeId } = useParams<challengeParams>();
  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [challenge, setChallenge] = useState<challengeDetails>();
  const [allParticipants, setAllParticipants] = useState<Employees>([]);
  const { t } = useTranslation(['admin_campaign_details', 'challenge']);
  const [socialNetworks, setSocialNetworks] = useState({
    facebook: false,
    twitter: false,
    linkedin: false,
    instagram: false,
    tiktok: false,
    snapchat: false,
    youtube: false,
    threads: false,
    otros: false
  });

  const getData = async () => {
    const { data } = await getChallengeById(
      currentUser?.token,
      parseInt(challengeId),
      true
    );
    const networks = {
      facebook: data.data.facebook,
      twitter: data.data.twitter,
      linkedin: data.data.linkedin,
      instagram: data.data.instagram,
      tiktok: data.data.tiktok,
      snapchat: data.data.snapchat,
      youtube: data.data.youtube,
      threads: data.data.threads,
      otros: data.data.others
    };
    setChallenge(data.data);
    setSocialNetworks(networks);
    setAllParticipants(data.data.ambassadorsIds);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <SectionWrapper>
          <NavigationLink
            text={t('challenge:back')}
            direction="back"
            to="/creators"
          />
          {currentUser.role === 'admin' && (
            <DetailChallengeAdmin
              challenge={challenge}
              allParticipants={allParticipants}
              socialNetworks={socialNetworks}
            ></DetailChallengeAdmin>
          )}
          {currentUser.role === 'ambassador' && (
            <DetailChallengeAmbassador
              challenge={challenge}
            ></DetailChallengeAmbassador>
          )}
        </SectionWrapper>
      </Suspense>
    </>
  );
};

export default DetailChallengesPage;
