import Client from 'config/axios';

export const createBonus = async (
  token: string,
  url: string,
  socialNetwork: string,
  challengeId?: number
) => {
  const body = {
    url,
    socialNetwork,
    challengeId
  };
  try {
    const { data } = await Client.post('bonus', body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.data };
  }
};
