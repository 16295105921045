import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import UpdateCompany from 'pages/SuperAdmin/Companies/UpdateCompany';
import CreateCompany from 'pages/SuperAdmin/Companies/CreateCompany';

const SuperAdminCompanyRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/new`}>
        <CreateCompany />
      </Route>
      <Route exact path={`${match.path}/updateCompany/:companyId`}>
        <UpdateCompany />
      </Route>
    </Switch>
  );
};

export default SuperAdminCompanyRouter;
