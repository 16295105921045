import React, { Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import GeneralDataBox from 'components/boxes/GeneralDataBox';
import Icon from 'components/icons/Icon';
import iconFlagCheck from 'images/icons/flag_check.svg';
import { getCompanyMetrics } from 'services/rest/CompanyMetrics/CompanyMetrics';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { useFetch } from 'hooks/useFetch.hook';
import iconApproved from 'images/icons/bonus_approved.svg';
import { nFormatter } from 'helpers/formatHelper';

const ContentsSection = () => {
  const { t } = useTranslation(['bonus']);
  const { currentUser } = useSelector(selectUser);
  const { token } = currentUser;
  const { data, loading } = useFetch(
    getCompanyMetrics({ token, typeFilter: 'all' })
  );
  const companyMetrics = data as any;

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const itemsFirstRow = [
    {
      icon: <img alt="iconFlagCheck" src={iconFlagCheck} />,
      value: nFormatter(companyMetrics?.totalBonus),
      text: t('bonus:total_bonus')
    },
    {
      icon: <img alt="iconApproved" src={iconApproved} />,
      value: nFormatter(companyMetrics?.totalBonusApproved),
      text: t('bonus:plural_approve')
    },
    {
      icon: <Icon name="report" size="big" />,
      value: Math.ceil(companyMetrics?.totalBonusPending),
      text: t('bonus:plural_pending')
    }
  ];
  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <GeneralDataBox items={itemsFirstRow} />
    </Suspense>
  );
};

export default ContentsSection;
