import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Background,
  FormWrapper,
  TextTitle,
  MessageText,
  QuestionText,
  ArgumentText,
  MarginBox,
  ButtonStyled
} from './Styled';
import CardNetwork, { NetworkProps } from './CardNetwork';
import Button from 'components/buttons/Button';
import NavigationLink from 'components/links/NavigationLink';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'redux/slices/userSlice';
import { ErrorAlert } from 'components/Alert';

const SocialNetwork = () => {
  const { t } = useTranslation(['social_network']);
  const history = useHistory();
  const { infoUser } = useSelector(selectUserInfo);
  const [updateError, setUpdateError] = useState<string | null>(null);

  const networksBoxItems: Array<NetworkProps> = [
    { network: 'twitter', linked: infoUser.linkedTwitter },
    { network: 'facebook', linked: infoUser.linkedFacebook }
  ];

  const networksBox: Array<NetworkProps> = [
    { network: 'tiktok', linked: infoUser.linkedTiktok },
    { network: 'instagram', linked: infoUser.linkedInstagram },
    { network: 'linkedin', linked: infoUser.linkedLinkedin }
  ];

  const linkedNetworkItem = networksBoxItems.filter(
    network => network.linked == true
  );

  const linkedNetworkBox = networksBox.filter(
    network => network.linked == true
  );

  const continueDashboard =
    linkedNetworkItem.length > 0 || linkedNetworkBox.length > 0
      ? 'primary'
      : 'default';

  const continueOnclick = () => {
    if (linkedNetworkItem.length > 0 || linkedNetworkBox.length > 0) {
      history.replace({ pathname: `/dashboard` });
    }
  };
  return (
    <>
      {updateError && (
        <ErrorAlert
          text={updateError}
          allowClose={true}
          onClose={() => setUpdateError(null)}
        />
      )}
      <Background>
        <FormWrapper>
          <NavigationLink
            text={t('social_network:skip')}
            direction="forward"
            to="/"
          />
          <TextTitle>
            <p>{t('social_network:welcome')}</p>
          </TextTitle>

          <MessageText>
            <p>{t('social_network:text_title')}</p>
          </MessageText>
          <QuestionText>
            <p>{t('social_network:question_networks')}</p>
          </QuestionText>

          <ArgumentText>
            <p>{t('social_network:argument')}</p>
          </ArgumentText>
          <MarginBox>
            <CardNetwork
              items={networksBoxItems}
              setUpdateError={setUpdateError}
            />
          </MarginBox>
          <MarginBox>
            <CardNetwork items={networksBox} setUpdateError={setUpdateError} />
          </MarginBox>
          <ButtonStyled>
            <Button
              value={t('social_network:continue')}
              variant={continueDashboard}
              onClick={continueOnclick}
            />
          </ButtonStyled>
        </FormWrapper>
      </Background>
    </>
  );
};

export default SocialNetwork;
