import Client from 'config/axios';

export const getGroupDataById = async (token: string, id: any) => {
  const { data } = await Client.get(`group/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    data
  };
};
