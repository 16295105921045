import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { createCurrency } from 'services/rest/SuperAdmin/Currency/createCurrency';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { RequiredString, String } from 'helpers/yupHelper';
import UpdateCurrencyForm from './UpdateCurrencyForm';
import { max } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks/useFetch.hook';
import { getCurrencies } from 'services/rest/Currency/getCurrencies';

const UpdateCurrency: React.FC = () => {
  const { t } = useTranslation(['validations']);
  const userSchema = yup.object().shape({
    name: RequiredString().max(50, t('validations:length_error', { max: 50 })),
    code: RequiredString().max(3, t('validations:length_error', { max: 3 })),
    sign: RequiredString().max(6, t('validations:length_error', { max: 6 }))
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(userSchema)
  });
  const { currentUser } = useSelector(selectUser);
  const [loader, setLoader] = useState(false);
  const [currency, setCurrency] = useState<any>();
  const { token, role } = currentUser;
  const { currencyId } = useParams<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const onSubmit = async (CurrencyData: any) => {
    setLoader(true);
    // const data = await createCurrency(currentUser?.token, CurrencyData);
    // if (data) {
    //   setLoader(false);
    // }
  };

  const getcurrencyData = async () => {
    const currency = await getCurrencies(token);
    const currentCurrency = currency.filter((item: any) => {
      return item.id == currencyId;
    });
    setCurrency(currentCurrency[0]);
    setLoading(false);
  };

  useEffect(() => {
    getcurrencyData();
  }, []);

  return (
    <>
      <UpdateCurrencyForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        currency={currency}
        errors={errors}
        setValue={setValue}
        control={control}
        loader={loader}
      />
    </>
  );
};

export default UpdateCurrency;
