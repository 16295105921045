import Client from 'config/axios';

type companyMetricsType = {
  token: string;
  typeFilter: string;
  year?: string;
  month?: string;
  quarter?: string;
};

export const getCompanyMetrics = async (params: companyMetricsType) => {
  let url = `get-metrics-dashboard-admin?typeFilter=${params.typeFilter}&year=${
    params.year ?? new Date().getFullYear()
  }`;
  if (params.month) {
    url += `&month=${params.month}`;
  }
  if (params.quarter) {
    url += `&quarter=${params.quarter}`;
  }
  return await Client.get(url, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${params.token}`
    }
  })
    .then(response => response.data.data)
    .catch(error => error);
};
