import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './PerfomanceSection.state';
import { IPerformanceSection } from './PerformanceSection.interface';

const setPerformanceSectionLoading = (state: any, action: { payload: any }) => {
  return {
    ...state,
    loading: action.payload
  };
};

const setPerformanceSection = (
  state: any,
  action: { payload: IPerformanceSection }
) => {
  return {
    ...state,
    performanceSection: action.payload
  };
};

export const selectPerformance = (state: { performanceSection: any }) =>
  state.performanceSection;

const PerformanceSectionSlice = createSlice({
  name: 'performanceSlice',
  initialState: {
    loading: false,
    performanceSection: initialState
  },
  reducers: {
    setLoading: setPerformanceSectionLoading,
    setPerformance: setPerformanceSection
  }
});

export const { setLoading, setPerformance } = PerformanceSectionSlice.actions;

export default PerformanceSectionSlice.reducer;
