import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { selectUser } from 'redux/slices/userSlice';
import { yupResolver } from '@hookform/resolvers';
import { RequiredString } from 'helpers/yupHelper';

import { getAmbassadorById } from 'services/rest/Ambassador/getAmbassadorById';
import {
  Ambassador,
  updateAmbassador
} from 'services/rest/Ambassador/updateAmbassador';

import { ErrorAlert, SuccessAlert } from 'components/Alert';

import AmbassadorProfileForm from './AmbassadorProfileForm';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

const AmbassadorProfile = () => {
  const { t } = useTranslation([
    'group_page',
    'profile',
    'register_form',
    'validations'
  ]);
  const userSchema = yup.object().shape({
    name: RequiredString().max(70, t('validations:length_error', { max: 70 })),
    lastName: RequiredString().max(
      70,
      t('validations:length_error', { max: 70 })
    )
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(userSchema)
  });
  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [ambassador, setAmbassador] = useState<any>();
  const [phone, setPhone] = useState(ambassador?.phone);
  const [language, setLanguage] = useState(ambassador?.language);
  const [loader, setLoader] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = useState<string | null>(null);
  const { company } = useSelector(selectCompany);

  const getAmbassadorData = async () => {
    const { data } = await getAmbassadorById(
      currentUser?.token,
      `${currentUser?.user?.id}`
    );
    if (data) {
      setAmbassador(data.data);
      setPhone(data?.data?.phone);
      setLanguage(data?.data?.language);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAmbassadorData();
  }, []);

  const onSubmit = async (ambassadorData: Ambassador) => {
    setLoader(true);
    ambassadorData.id = currentUser?.user?.id;
    ambassadorData.role = 'ambassador';
    ambassadorData.phone = phone;
    ambassadorData.language = language;
    ambassadorData.email = ambassador.email;
    ambassadorData.groupsIds = ambassador.groupsIds;
    const { data, errors } = await updateAmbassador(
      currentUser?.token,
      ambassadorData
    );
    if (data) {
      setUpdateSuccess(t('group_page:update_ambassador_success'));
    }
    if (errors) {
      setUpdateError(t('group_page:update_ambassador_error'));
    }
    setLoader(false);
  };

  return (
    <>
      {updateError && (
        <ErrorAlert
          text={updateError}
          allowClose={true}
          onClose={() => setUpdateError(null)}
        />
      )}

      {updateSuccess && (
        <SuccessAlert
          text={updateSuccess}
          allowClose={true}
          onClose={() => setUpdateSuccess(null)}
        />
      )}
      <AmbassadorProfileForm
        ambassador={ambassador}
        loading={loading}
        loader={loader}
        company={company}
        phone={phone}
        setPhone={setPhone}
        setLanguage={setLanguage}
        language={language}
        errors={errors}
        handleSubmit={handleSubmit(onSubmit)}
        register={register}
      />
    </>
  );
};
export default AmbassadorProfile;
