import { ICompanyMetrics } from './CompanyMetrics.interface';

export const initialState: ICompanyMetrics = {
  facebookCredentials: { apiKey: '', apiSecret: '' },
  twitterCredentials: { apiKey: '', apiSecret: '' },
  linkedinCredentials: { apiKey: '', apiSecret: '' },
  metric_dashboard: {
    activeCampaigns: 0,
    totalCampaigns: 0,
    averageParticipationPerCampaign: 0,
    accumulated_valuation: 0,
    total_engagement: 0,
    total_contents: 0,
    potential_reach: 0,
    estimated_reach: 0,
    totalGroups: 0,
    totalAmbassadors: 0,
    ambassadorsParticipating: 0
  },
  viewPostUrl: {
    facebook: '',
    twitter: '',
    linkedin: '',
    instagram: ''
  }
};
