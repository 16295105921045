import { IAward } from './Award.interface';

export const initialState: IAward = {
  id: 0,
  companyId: 0,
  photoUrl: '',
  description: '',
  startDate: '',
  finishDate: '',
  periodType: '',
  periodValue: '',
  status: 0,
  groups: { name: '' },
  groupsIds: [],
  createdAt: '',
  updatedAt: '',
  company_id: 0
};
